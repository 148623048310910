
.card-poster {
    width: 100%;
    padding: 25px;
    background-color: $color-white;

    &.background-grey {
        background-color: $color-ultra-light-grey;
    }

    &.background-black {
        background-color: $color-black;
    }
}