
.circle-colors {
    svg {
        display: block;
        margin: 0 auto;
    }

    path {
        stroke: transprent;
        stroke-width: 1px;
        stroke-linecap: butt;
    }
}
