.landscape-fallback {
    display: none;
    pointer-events: none;
}

@media (orientation: landscape) {
    .landscape-fallback {
        content: '';
        display: block;
        position: fixed;
        width: 100%;
        height: calc(100% - 56px);
        top: 56px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color-white;
        pointer-events: all;
        overflow: hidden;
        z-index: 80;

        svg {
            position: absolute;
            width: 70px;
            height: 70px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        @media #{$mq-large-up} {
            display: none;
        }
    }

    // if configurator-stage was scrolled out of view, fallback may not be shown
    .configurator__navigation.locked {
        & + .landscape-fallback {
            display: none;
        }
    }
}
