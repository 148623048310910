@media #{$mq-medium-down} {
    [data-module="lightbox"] {

        [data-type="lightbox"]:hover {

            .no-touch & {
                cursor: url('/static/assets/img/gui/rm_zoom_pointer.svg') 35 35, pointer;
            }
        }
    }
}

.lightboxWrapper {

    padding-top: 70px;

    @media #{$mq-medium-up} {
        padding-top: 100px;
    }

    @media (orientation: landscape) {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    @media #{$mq-small-only} {
        width: calc(100% + #{$padding-container-center-small * 2});
        margin-left: -$padding-container-center-small;
    }

    //@media #{$mq-medium-only} {
    //    width: calc(100% + #{$padding-container-center-medium * 2});
    //    margin-left: -$padding-container-center-medium;
    //}

    @media #{$mq-medium-down} {
        .media {
            overflow: auto;
            scrollbar-width: none;
            -ms-overflow-style: none;
        }

        .media::-webkit-scrollbar {
            display: none;
        }
    }


    figure.background {

        $ratio: 1.5;
        $factor: 1;
        $srcHeight: 620px; // 620px bezieht sich auf die aktuelle Bildergröße die ins Template kommt

        margin: auto;

        width: calc((100vh - 200px) * #{$ratio} );
        height: calc(100vh - 200px);

        //max-height: calc(100vh - 200px);
        max-height: $srcHeight;
        max-width: calc(#{$srcHeight} * #{$ratio});

        @media #{$mq-medium-up} {
            width: calc((100vh - 160px) * #{$ratio});
            height: auto;

            max-width: calc(100vw - 160px);
            max-height: calc(100vh - 160px);
        }

        @media (orientation: landscape) {
            // Variante no scroll
            width: calc((100vh - 40px) * #{$ratio} );
            height: calc(100vh - 40px);

            max-height: $srcHeight;
            max-width: calc(#{$srcHeight} * #{$ratio});

            // Variante nur vertical scroll
            //width: calc((100vw - #{$padding-container-center-medium * 2} ));
            //height: calc((100vw - #{$padding-container-center-medium * 2} ) / #{$ratio} );
            //
            //max-width: calc(#{$srcHeight} * #{$ratio});
            //max-height: $srcHeight;

            // Variante original size (anpassung von overlay notwendig)
            //width: calc(#{$srcHeight} * #{$ratio});
            //height: $srcHeight;
            //
            //max-width: calc(#{$srcHeight} * #{$ratio});
            //max-height: $srcHeight;
        }

        &::before {
            content: '';
            display: block;
            padding-top: 66.66666667%;
        }

    }
}