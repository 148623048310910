.series-card {}

.series-card__inner {
    --gap-vertical: var(--grid-gutter-horizontal);
    --gap-horizontal: var(--grid-gutter-vertical);

    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: var(--gap-vertical) var(--gap-horizontal);

    figure {
        width: 100%;
        overflow: visible;
    }

    .single-tag {
        margin-top: 10px;
    }

    @media #{$mq-large-up} {
        align-items: center;
    }
}

.colors__container {
    margin: 30px 0;
}

.series-card__button {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.series-card__details {
    --series-card-details-width: 100%;

    width: var(--series-card-details-width);

    @media #{$mq-large-down} {
        order: 2;
    }

    @media #{$mq-large-up} {
        --series-card-details-width: calc(100% / 12 * 4 - 0.5 * var(--gap-horizontal));
    }
}

.series-card__image {
    --series-card-image-width: 100%;

    width: var(--series-card-image-width);

    @media #{$mq-large-down} {
        order: 1;
    }

    @media #{$mq-large-up} {
        --series-card-image-width: calc(100% / 12 * 8 - 0.5 * var(--gap-horizontal));
    }
}

a.series-card__image {
    border-bottom: none;
    padding: 20px;

    @media #{$mq-large-up} {
        padding-left: 50px;
    }

    figure {
        transition: transform 0.3s ease-out;
    }

    &:hover {
        border-bottom: none;

        figure {
            transform: scale(1.02);
        }
    }
}
