.press-image-teaser {
    $color-black-shine-through: rgba(0, 0, 0, 0.1);

    figure {
        border: 1px solid $color-black-shine-through;

        &:not(.image-placeholder) {
            figcaption {
                visibility: hidden;
            }
        }
    }

    .container-text {
        padding-top: 25px;
        padding-bottom: 30px;
    }
}
