
$textareaHeight: 210px;

// todo: nötig?
.input-wrapper {
    display: block;
}

// OHNE TEXTAREA
input,
select {
    line-height: 45px;
    padding: 0 20px;
}

textarea {
    // padding-top / -bottom um niedriger line-height (wie p) auszugleichen
    padding: 15px 20px;
    line-height: 22px;
}

&.fake-input,
input,
textarea,
select {
    display: block;
    width: 100%;
    height: 45px;
    background: $color-ultra-light-grey;
    border: none;
    // todo - playground
    border-bottom: 1px solid $color-black; // $color-brand-green;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;

    transition: color 0.25s ease-in-out, background-color 0.25s ease-out;

    &.large-input {
        $height-small: 60px;
        $height-medium-large: 70px;

        border-bottom: 2px solid $color-black;
        height: $height-small;
        line-height: #{$height-small - 4px};
        padding-top: 0;
        padding-bottom: 0;
        font-size: 25px; // 16px;
        font-family: $font-flexo-thin;

        @media #{$mq-medium-up} {
            height: $height-medium-large;
            line-height: #{$height-medium-large - 4px};
            font-size: 28px;
            font-family: $font-flexo-thin;
        }
    }

    // ERROR
    &.error {
        color: $color-error;
        border-bottom-color: $color-error;
    }

    &:focus {
        outline: 0;
        outline: none;
    }
}

.wrapper-input {
    input,
    input:focus {
        &.hint {
            transition: color 0.25s ease-in-out, background-color 0.15s ease-out;
            background-color: $color-error;
        }
    }
}

input[readonly] {
    color: $color-medium-grey;
    pointer-events: none;
}

// Dunkler Hintergrund bei Focus nur innerhalb v. Formularen
.wrapper-input {
    input:focus,
    textarea:focus {
        background-color: $color-light-grey;
    }
}

textarea {
    height: $textareaHeight;
    resize: none;
}

.input__help-text {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 10px;
}

.input__text-info {
    width: 100%;
    height: auto;
    overflow: hidden;
    display: none;
    margin-top: 10px;
    transition: all 0.3s ease-in-out;

    &.active {
        display: flex; // tbd. animation?
    }

    ul, ol {
        font-size: inherit;
        line-height: inherit;
    }
}


.fake-input {
    display: flex;
    justify-content: space-between;
    color: $color-warm-grey;
    line-height: 44px;
    padding: 0 20px;

    &__check svg * {
        stroke: $color-green;
    }
}
