@mixin pill-color-label($bg: #2e2d2c, $color: #2e2d2c, $height: 50px, $borderwidth: 2px) {
    background: none;
    border: $borderwidth solid $bg;
    color: $color;

    height: $height;
    padding: 15px 20px;
}

// pill input
.radio-pill,
.checkbox-pill {
    &.pill--color {
        &.radio-pill--selected,
        &.checkbox-pill--selected {
            @media #{$mq-small-only} {
                flex-basis: 100%;
            }
        }

        &:not(.pill--preview) {
            &.radio-pill--selected,
            &:hover {
                .radio-pill__label-color {
                    border: 1px solid $color-white !important;
                }
            }
        }

        &.radio-pill--inactive {
            opacity: 0.4;

            &:hover {
                opacity: 1;
            }
        }

        input {
            @include hide-input-for-custom-checkbox();
        }

        label {
            @include pill-color-label;

            .radio-pill__label-wrapper {
                margin-left: 5px;
                font-size: 14px;

                &.pill--small {
                    font-size: 12px;
                }
            }

            .radio-pill__label-color {
                display: inline-block;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                cursor: default;
                margin: 2px;
                color: inherit;

                &.pill--small {
                    width: 16px;
                    height: 16px;
                }
            }

            &.pill--small {
                @include pill-color-label($height: 40px, $borderwidth: 1.75px)
            }
        }

        input[disabled] + label {
            pointer-events: none;
        }
    }
}
