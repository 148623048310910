
.ie8-wrapper .browser-unsupported,
.browser-unsupported.browser-unsupported--show {
    display: block;
}

.browser-unsupported {
    display: none;
    position: fixed;
    overflow: scroll;
    width: 100%;
    height: 100%;
    padding: 50px 0;
    z-index: $z-index-preloader;
    overflow: scroll;

    h1, p, a {
        text-align: left;
    }

    h1 + p {
        margin-top: 20px;
    }

    h1 {
        margin-top: 30px;

        @media #{$mq-small-only} {
            font-size: 30px;
            line-height: 38px;
        }
    }

    p, a {
        @media #{$mq-small-only} {
            font-size: 16px;
            line-height: 22px;
        }
    }
}

.browser-unsupported__table {
    display: table;
    width: 100%;
    height: 100%;
}

.browser-unsupported__table-cell {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.browser-unsupported__center {
    position: relative;
    width: 75%;
    min-width: 280px;
    max-width: 760px;
    top: -7%;
    margin: 0 auto;

    @media #{$mq-small-only} {
        top: 0;
    }
}

.browser-unsupported__downloads {
    margin-top: 20px;
    text-align: left;

    a {
        font-family: $font-flexo-regular;
        border-bottom: 1px solid $color-black;

        @media #{$mq-small-only} {
            display: block;
        }
    }

    a + a {
        margin-left: 75px;

        @media #{$mq-small-only} {
            margin-left: 0;
            margin-top: 10px;
        }
    }
}

.browser-unsupported__copy {
    margin-left: auto;
    margin-right: auto;

    br {
        content: '';
        display: block;
        margin: 2em;
    }
}

.browser-unsupported__warning,
.browser-unsupported__arrow {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.browser-unsupported__warning {
    width: 70px;
    height: 70px;
    background-image: url(/static/assets/img/fallback/fallback-exclamation-mark.png);

    @media #{$mq-small-only} {
        width: 47px;
        height: 47px;
    }
}

.browser-unsupported__arrow {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    top: 2px;
    margin-left: 10px;
    background-image: url(/static/assets/img/fallback/fallback-link-arrow.png);

    @media #{$mq-small-only} {
        width: 12px;
        height: 12px;
        top: 1px;
    }
}

