.family-overview {}

.family-overview__wrapper-content {
    @media #{$mq-small-only} {
        padding-top: 60px;
    }

    @media #{$mq-medium-up} {
        padding-top: 80px;
    }
}

.family-overview__cards {
    --gap-horizontal: 0;
    --gap-vertical: 70px;

    width: 100%;
    display: grid;
    grid-gap: var(--gap-vertical) var(--gap-horizontal);
    grid-template-columns: auto;

    @media #{$mq-medium-only} {
        grid-template-columns: 1fr 1fr;
        --gap-horizontal: var(--grid-gutter-horizontal);
    }

    @media #{$mq-large-up} {
        grid-template-columns: 1fr 1fr;
        --gap-horizontal: calc(100% / 12 * 2);
        --gap-vertical: 80px;
    }

    .tile-teaser {
        @media #{$mq-small-only} {
            margin: 0 -25px;
        }
    }
}
