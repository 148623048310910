$filter-margin-small: 12px;
$filter-margin-medium-up: 20px;

.filter-gui {
    padding: 40px 0 60px 0;
}

h3 + .filter-gui {
    margin-top: 20px;
}

.rb__container-filter {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 $filter-margin-small * -1 $filter-margin-small * -1;
    width: calc(100% + #{$filter-margin-small});

    @media #{$mq-medium-up} {
        margin: 0 0 $filter-margin-medium-up * -1 $filter-margin-medium-up * -1;
        width: calc(100% + #{$filter-margin-medium-up});
    }
}

.button-filter {
    $size-svg-small: 14px;
    $size-svg-medium-up: 16px;
    $padding-small: 12px;
    $padding-medium-up: 15px;

    margin: 0 0 $filter-margin-small $filter-margin-small;

    @media #{$mq-medium-up} {
        margin: 0 0 $filter-margin-medium-up $filter-margin-medium-up;
    }

    .rb__hidden-checkbox {
        @include hide-input-for-custom-checkbox();

        & + label {
            $height-small: 36px;
            position: relative;
            display: block;
            height: $height-small;
            line-height: $height-small - 2px;
            padding-left: $padding-small;
            padding-right: $padding-small + $size-svg-small + $padding-small;
            font-size: 16px;
            border-radius: $height-small / 2;
            border: 1px solid $color-black;
            background-color: $color-white;
            transition: color 0.2s ease-out, background-color 0.2s ease-out;
            cursor: pointer;
            // fake a little thicker border-line
            box-shadow: 0 0 0 1px rgba(0,0,0,0.15) inset;

            @media #{$mq-medium-up} {
                $height-medium-up: 40px;
                height: $height-medium-up;
                line-height: $height-medium-up - 2px;
                padding-left: $padding-medium-up;
                padding-right: $padding-medium-up + $size-svg-medium-up + $padding-medium-up;
                font-size: 17px;
                border-radius: $height-medium-up / 2;
            }
        }

        & + label:hover {
            .no-touch & {
                color: $color-white;
                background-color: $color-black;

                .svg_btn_filter {
                    fill: $color-white;
                }
            }
        }

        // & + label:hover,
        &:checked + label {
            color: $color-white;
            background-color: $color-black;

            .svg_btn_filter {
                fill: $color-white;
            }
        }

        &:checked + label {
            .svg_btn_filter {
                transform: rotate(45deg);
            }
        }
    }

    .svg_btn_filter {
        position: absolute;
        width: $size-svg-small;
        height: $size-svg-small;
        right: $padding-small;
        top: calc(50% - #{$size-svg-small / 2});
        opacity: 0.8;
        transform: rotate(0deg);
        transform-origin: 50%;
        backface-visibility: hidden;
        transition: fill 0.2s ease-out, transform 0.2s ease-out;

        @media #{$mq-medium-up} {
            width: $size-svg-medium-up;
            height: $size-svg-medium-up;
            right: $padding-medium-up;
            top: calc(50% - #{$size-svg-medium-up / 2});
        }
    }
}
