#main-nav {
    $height-btn-nav-small: 17px;
    $height-btn-nav-medium: 25px;
    $padding-cont-btn-small: 20px;
    $padding-cont-btn-medium: 40px;
    $mq-main-nav-large: "screen and (min-aspect-ratio: 1/1) and (min-width: 1024px)";

    display: none;
    position: fixed;
    left: 0;
    top: 0;
    z-index: $z-main-nav;

    &.active {
        display: block;
        height: 100%;
    }

    .wrapper-nav {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;

        display: flex;
        flex-direction: column;
    }

    .container-main {
        position: relative;
        width: 100%;
        max-width: 320px;
        height: 100%;

        @media #{$mq-medium-up} {
            width: auto;
            max-width: inherit;
        }
    }

    .container-main-content {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .container-links {
        flex-grow: 1000;
        display: flex;
        flex-direction: column;
        align-items: strech;
        padding-top: 69px;

        @media #{$mq-medium-up} {
            padding-top: #{$height-btn-nav-medium + ($padding-cont-btn-medium * 2)};
        }
    }

    .mask-overflow {
        @include hidden-scrollbar();
        overflow: auto;

        &.enable-scroll {
            overflow-x: visible;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }
    }

    .container-main-links {
        position: relative;
    }

    .container-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;

        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 25px;
        padding: 13px $padding-cont-btn-small $padding-cont-btn-small $padding-cont-btn-small;
        z-index: 4;

        @media #{$mq-medium-up} {
            justify-content: flex-start;
            padding: $padding-cont-btn-medium;
            width: calc(100% - 20px);
        }
    }

    .btn-nav {
        width: $height-btn-nav-medium;
        padding: 0;
        background-color: transparent;
        margin: 0 25px 0 0;

        &:after {
            display: block;
            width: calc(100% + 20px);
            height: calc(100% + 20px);
            top: -10px;
            left: -10px;
            background-color: $color-main-nav-grey;

            opacity: 0;
            transition: opacity 0.25s ease-out;
        }

        &:hover {
            &:after {
                .no-touch & {
                    opacity: 1;
                }
            }
        }

        &:before {
            position: static;
            font-size: $height-btn-nav-medium - 1;
            line-height: 25px;
            width: 24px;
            height: auto;
        }

        &.btn-close-nav {
            order: 1;
        }
        &.btn-open-search {
            order: 2;
        }
        &.btn-home {
            order: 3;
        }

        &.btn-go-back {
            order: 4;
            margin-right: 0;
            margin-left: auto;
        }

        @media #{$mq-medium-up} {
            height: $height-btn-nav-medium;
            margin: 0 30px 0 0;

            &:before {
                position: static;
                height: auto;
                line-height: $height-btn-nav-medium;
                font-size: $height-btn-nav-medium - 1;
            }
        }

        &.btn-go-back {
            transition: opacity 0.35s ease-in-out;
        }
    }

    .container-footer-links {
        display: flex;
        padding: $padding-cont-btn-small;

        @media #{$mq-small-only} {
            flex-wrap: wrap;
            // Ausgleich der footer-nav-items
            margin-top: -8px;
            margin-left: -15px;
        }

        @media #{$mq-medium-up} {
            padding: $padding-cont-btn-medium;
        }
    }

    .nav-item {
        position: relative;
        display: block;
        width: auto;
        min-width: 320px;
        padding: 0 50px 0 20px;
        font-family: $font-flexo-regular;
        line-height: 52px;
        border: none;
        white-space: nowrap;
        cursor: pointer;
        transition: background-color 0.25s ease-out;

        @media #{$mq-medium-up} {
            padding: 0 70px 0 40px;
        }


        &:hover {
            .no-touch & {
                background-color: $color-main-nav-grey;
            }
        }

        &.active {
            background-color: $color-main-nav-grey;
        }

        &.has-sub-items {
            &:before {
                position: absolute;
                top: 0;
                right: 20px;
                height: 100%;
                font-family: $font-icons;
                font-size: 14px;
                line-height: 52px;
                font-style: normal;
                text-transform: none;

                line-height: 16px;
                height: 16px;
                top: 50%;
                transform: translateY(-50%);

                margin-top: -1px;

                @media #{$mq-medium-up} {
                    right: 50px;
                }
            }

            &.nav-item-primary {
                &:before {
                    margin-top: 1px;
                }
            }
        }

        &.nav-item-primary {
            font-size: 30px;
            line-height: 56px;
            height: 56px;
            font-family: $font-flexo-thin;

            & + .nav-item-main {
                margin-top: 42px;

                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    height: 2px;
                    width: 24px;
                    top: -22px;
                    background-color: $color-white;
                }
            }
        }
    }

    .label-group {
        padding-left: 20px;
        margin-top: 30px;
        margin-bottom: 40px;
        font-size: 23px;
    }

    .nav-sub-item {
        width: 280px;
        padding: 0 20px;

        @media #{$mq-medium-up} {
            padding: 0 40px;
        }

        &:hover {
            .no-touch & {
                font-family: $font-flexo-bold;
            }
        }
    }

    .nav-footer-item {
        color: $color-black;
        font-size: 10px;
        line-height: 10px;
        font-family: $font-flexo-regular;
        border: none;
        padding: 5px;
        // Umbruch zwischen Text und img (Flagge) verhinden
        white-space: nowrap;

        &:hover {
            .no-touch & {
                text-decoration: underline;
            }
        }

        @media #{$mq-small-only} {
            margin-top: 8px;
            margin-left: 10px;
        }

        @media #{$mq-medium-up} {
            & + .nav-footer-item {
                margin-left: 20px;
            }
        }
    }

    .container-sub-links {
        position: absolute;
        top: 0;
        right: -320px;

        @media #{$mq-medium-up} {
            right: 0;
            transform: translate3d(100%, 0, 0);
        }
    }

    .group-sub-links {
        @include hidden-scrollbar();
        position: absolute;
        left: 0;
        top: 0;
        min-height: 100vh;
        height: 100%;
        pointer-events: none;
        padding-top: 104px;
        background-color: $color-main-nav-grey;

        opacity: 0;
        transition: opacity 0.25s ease-out 0.25s;

        &::selection {
            background: $color-main-nav-grey !important;
        }

        &.active {
            pointer-events: auto;
            transition: opacity 0.25s ease-out;
            opacity: 1;
        }

        &.enable-scroll {
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }

        .content-overflow {
            display: block;
        }
    }

    .separator-line {
        height: 2px;
        width: 24px;
        margin: 20px 0 20px 20px;
        background-color: $color-white;

        @media #{$mq-medium-up} {
            margin-left: 40px;
        }
    }

    @media #{$mq-small-only} {
        .wrapper-nav {
            overflow: hidden;
        }

        .container-sub-links {
            top: #{$height-btn-nav-medium + ($padding-cont-btn-small * 2)};
            z-index: 2;
            pointer-events: none;
            width: 100%;
            height: calc(100% - #{$height-btn-nav-medium + ($padding-cont-btn-small * 2)});
            overflow: hidden;
        }

        .group-sub-links {
            display: none;
            padding-top: 0;
            width: 100%;
            height: 100%;
            min-height: 100%;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            transition: none;
            background-color: $color-main-nav-grey;

            &.active {
                display: block;
            }
        }
    }
}
