.bike-advisor__results {
    position: relative;
    width: 100%;
    z-index: 2;
    padding-top: 30px * 3;
    background-color: $color-white;

    --grid-gutter-vertical: 80px;

    @media #{$mq-medium-up} {
        --grid-gutter-vertical: 100px; } }
