
.m16-bike-configurator {
    .container-features {
        display: flex;
        flex-direction: column;
    }

    .container-headline {
        order: 1;
    }

    .wrapper-price.first {
        order: 3;
    }

    .wrapper-price.last {
        order: 100;
    }

    .container-configuration {
        order: 101;
    }

    .group,
    .wrapper-price {
        margin-top: 70px;

        @media #{$mq-medium-up}
        {
            margin-top: 100px;
        }
    }

    .group.group-2 {
        margin-top: 0;
    }

    // placing the images between text- and button-container (@mediaQuery small-only)
    @media #{$mq-small-only} {
        .container-text.small-only {
            order: 1;
        }
        .container-figures {
            order: 2;
        }
        .container-text {
            order: 3;
        }
    }

    .feature {
        align-items: flex-start;

        //left
        &.odd {
            @media #{$mq-medium-only} {
                .m-5-extend {
                    width: calc((100% - 24px) / #{2.4} + #{$gap-4-medium});
                    margin-left: #{-$gap-3-medium - $gap-3-medium};
                }
            }

            @media #{$mq-large-up} {
                .l-5-extend {
                    width: calc((100% - 24px) / #{2.4} + #{$gap-4-large});
                    margin-left: #{-$gap-4-large + $gap-1-large};
                }
            }

            @media #{$mq-max-width-up} {
                .l-5-extend {
                    width: calc((100% - 24px) / #{2.4});
                    margin-left: $gap-1-large;
                }
            }
        }

        //right
        &.even {
            @media #{$mq-medium-up} {
                .container-figures {
                    order: 2;
                }

                .container-text {
                    order: 1;
                }

                .m-5-extend {
                    width: calc((100% - 24px) / #{2.4} + #{$gap-4-medium});
                    margin-right: #{-$gap-4-medium};
                }
            }

            @media #{$mq-large-up} {
                .l-5-extend {
                    width: calc((100% - 24px) / #{2.4} + #{$gap-4-large});
                    margin-right: #{-$gap-4-large};
                }
            }

            @media #{$mq-max-width-up} {
                .l-5-extend {
                    width: calc((100% - 24px) / #{2.4});
                    margin-right: 0;
                }
            }
        }

        .button-toggle-accordion {
            display: flex;

            @media #{$mq-medium-up} {
                display: none;
            }

        }

        &.medium-accordion .button-toggle-accordion {
            @media #{$mq-medium-only} {
                display: flex;
            }
        }
    }

    // second sub-feature must hide its image-container
    .feature + .feature {
        .container-figures {
            .mask-resize {
                display: none;
            }
        }
    }

    .container-figures {
        .content {
            @include relative-height(3/2);

            .wrapper-figure,
            figure {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    .container-figures {
        overflow: hidden;
        order: 1;

        @media #{$mq-small-only} {
            margin-bottom: 20px - 4px;

            &.mq-small {
                display: block;

                margin-top: 30px;
                margin-bottom: 0;
            }

            &.mq-medium {
                display: none;
            }
        }

        @media #{$mq-medium-only} {
            margin-bottom: 20px * 2 - 4px;
        }

        @media #{$mq-medium-up} {
            &.mq-medium {
                display: block;
            }

            &.mq-small {
                display: none;
            }
        }

        & > figure {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            transform: translateZ(0);

            &.show {
                opacity: 1;
            }
        }
    }

    .container-text {
        @media #{$mq-medium-up} {
            order: 2;
        }

        @media #{$mq-medium-only} {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
        }

        & > div,
        & section {
            width: 100%;

            &.error-message {
                width: 100%;
            }
        }

        & > h4 {
            margin-top: 35px;
        }
    }

    .description {
        & > * {
            &:first-child {
                margin-top: 0;
            }
        }
    }

    .accordion {
        width: 100%;
        margin-top: 10px;

        @media #{$mq-medium-only} {
            margin-top: 40px;
        }

        @media #{$mq-medium-up} {
            &.medium-expanded .mask-resize {
                overflow: visible;
            }
        }

        @media #{$mq-large-up} {
            margin-top: 3px;

            &.large-expanded .mask-resize {
                overflow: visible;
            }
        }

        .mask-resize.overflow-shown {
            overflow: visible;
        }

        .bar .label {
            flex-direction: column;
            align-items: left;

            p {
                font-family: $font-flexo-regular;
            }

            &.right {
                align-self: center;
            }
        }
    }

    .variants {
        display: flex;
        flex-wrap: wrap;

        @media #{$mq-small-only} {
            margin-top: 0;
        }

        @media #{$mq-medium-only} {
            margin-top: 0;
        }

        @media #{$mq-large-up} {
            margin-top: $p_lineheight * 2 - $p_off_bottom; //$gap-3-large;
        }

        & .wrapper-variant-button {
            display: block;
            position: relative;
            width: 50%;
            min-height: 50px;

            &:nth-child(even) button {
                border-right: none;
            }

            @media #{$mq-large-up} {
                width: calc(33.33% - #{$thickness-line * 2 / 3});
                &:nth-child(even) button {
                    border-right: $thickness-line solid $color-white;;
                }
            }

            button {
                height: 100%;
                width: 100%;
                border-bottom: $thickness-line solid $color-white;
                border-right: $thickness-line solid $color-white;
                padding: $gap-smallest-part;
                background-color: $color-ultra-light-grey;
                text-align: center;
                transform: translateZ(0);
                transition: color 0.25s ease-out, background-color 0.25s ease-in-out, opacity 0.5s ease-in-out;

                &:nth-child(2n) {
                    border-right: none;
                }

                @media #{$mq-large-up} {
                    &:nth-child(2n) {
                        border-right: $thickness-line solid $color-white;
                    }
                    &:nth-child(3n) {
                        border-right: none;
                    }
                }

                span {
                    display: block;
                    // sonst falsche event-targers
                    pointer-events: none;
                }

                &:hover {
                    .no-touch & {
                        background-color: $color-light-grey;
                    }
                }

                &.selected {
                    // todo - playground
                    background-color: $color-black; // $color-brand-green;
                    color: $color-white;
                    cursor: default;
                }

                // Erweitert die helper-Klasse .disabled
                &.disabled,
                &.disabled:hover {
                    font-family: $font-flexo-thin;
                    color: $color-black;
                    background-color: $color-ultra-light-grey;
                    cursor: default;
                    // pointer-events *müssen* hier aktiv bleiben
                    pointer-events: auto;
                }
            }

            &:nth-child(2n+1) {
                .btn-open-tooltip {
                    div.container-tooltip {
                        @media #{$mq-small-only} {
                            //left: 20px;
                            left: 7px;

                            &:before {
                                //left: 45.5%;
                                left: 50%;
                            }
                        }
                    }
                }
            }

        }
    }

    p a {
        margin-left: $gap-smallest-part;
    }

    .container-price {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
    }

    .bar.price {
        left: -100%;
        width: 300%;
        text-align: center;
        padding: #{$gap-2-small - 4px} 100% #{$gap-smallest-part * 2} 100%;
        transform: translateZ(0);

        @media #{$mq-medium-up} {
            display: flex;
        }

        br {
            display: none;
        }

        .item {
            display: block;
            line-height: 1em;
            margin-top: 10px;

            @media #{$mq-medium-up} {
                text-align: left;
                width: 50%;

                &:nth-child(2) {
                    text-align: right;
                }
            }
        }

        .suggested-price {
            font-family: $font-flexo-regular;
            font-size: 10px;

            @media #{$mq-medium-up} {
                text-align: right;
            }
        }

        h4, span {
            @media #{$mq-small-only} {
                font-size: 14px;
            }
        }

        span {
            font-family: $font-flexo-bold;

            &::-moz-selection {
                color: $color-white;
                background-color: $color-black;
            }

            &::selection {
                color: $color-white;
                background-color: $color-black;
            }
        }
    }

    .container-configuration {
        position: relative;
        left: -100%;
        width: 300%;
        text-align: center;
        padding: 0 100% $gap-3-small 100%;

        @media #{$mq-medium-up} {
            display: flex;
            justify-content: flex-start;
        }

        // exception: multiline for usage enabled
        .btn-base {
            @media #{$mq-small-only} {
                height: auto;
                line-height: 19px;
            }
        }
    }

    .container-sticky {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: $z-configurator-container-sticky;
    }

    .btn-open-tooltip {
        display: none;
        position: absolute;
        top: 5px;
        right: 7px;

        &.active {
            display: block;
        }

        &:hover {
            .no-touch & {
                div.container-tooltip {
                    display: block;
                }
            }
        }

        div.container-tooltip {
            display: none;
            width: 300px;
            height: auto;
            background: $color-warm-grey;
            padding: 20px;
            position: absolute;
            bottom: 35px;
            left: 50%;
            transform: translateX(-50%);

            p {
                color: $color-white;
            }

            &:before {
                content: '';
                position: absolute;
                bottom: -15px;
                left: 50%;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 20px 10px 0 10px;
                border-color: $color-warm-grey transparent transparent transparent;
                transform: translateX(-50%)
            }

            @media #{$mq-small-only} {
                left: -120px;

                &:before {
                    left: 92%;
                }
            }
        }
    }

    .btn-external-link {
        font-size: inherit;
        text-transform: uppercase;
    }

    .btn-print {
        @media #{$mq-small-only} {
            margin-bottom: 5px;
        }
    }
}

@media print {
    #wrapper-main {
        min-height: 0 !important;
    }

    main {
        padding: 0 !important;
    }

    .m14-bike-header,
    .m06-headline,
    .m15-feature-table,
    .retailer-search,
    .breadcrump,
    #container-fixed {
        display: none !important;
    }

    .m16-bike-configurator {
        .container-center:first-child,
        .container-sticky {
            display: none;
        }

        &:before {
            height: 0;
        }
    }

    body[data-type="page-bike"] {
        .container-center {
            width: 90%;
            max-width: 19cm;
            margin-top: -50px;
        }

        footer,
        #cms-top,
        .section:before,
        .section:after,
        .module:after {
            display: none;
        }
    }
}
