//sonderfall artikel: abstand zum button 50px
// this is used in m40 and should be moved to the module
// no global button style

.module.m09-article + .special-download-button {
    a.btn-icon-text.icon-download {
        // todo: am besten im gleichen Atemzug überarbeiten
        margin-top: -32px;
    }
}

.special-download-button {
    height: 70px;

    .btn-icon-text {
        margin-bottom: 10px;
    }
}
