.m01-header-animated {
    figure,
    .animated-content-spacer {
        @include relative-height(3/3);

        @media #{$mq-medium-up} {
            @include relative-height(21/12);
        }

        @media #{$mq-large-up} {
            @include relative-height(21/9.5);
        }

        @media #{$mq-extra-large-up} {
            @include relative-height(21/8);
        }
    }

    figure {
        position: fixed;
        top: 57px;
        height: auto;
        background-repeat: no-repeat;
        background-position: center top;
        transform-origin: 50% 0%;
        will-change: transform;

        @media #{$mq-medium-up} {
            top: 0;
        }
    }

    .breadcrump {
        padding: 0;

        &:after {
            display: none;
        }
    }

    &__wrapper-content {
        margin-bottom: 0 !important;

        .m01-header-animated--expand-mobile & {
            @media #{$mq-small-only} {
                display: none;
            }
        }
    }

    &__content-copy {
        .m01-header-animated--expand-mobile & {
            @media #{$mq-small-only} {
                display: none;
            }
        }
    }

    &__content-intro {
        .m01-header-animated--expand-mobile & {
            @media #{$mq-small-only} {
                display: none;
            }
        }

        .m01-header-animated--expand-desktop & {
            @media #{$mq-medium-up} {
                display: none;
            }
        }
    }

    .animated-content-wrapper {
        position: relative;
        margin-top: 30px;

        h1 {
            margin-top: 40px;
        }

        .text-spaces {
            position: relative;
        }

        .animated-price {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            padding: 10px;
            margin: 0;
            background-color: $color-black;

            span {
                color: white;
            }
        }

        .animated-content-background {
            position: absolute;
            display: block;
            top: -30px;
            left: -80px;
            width: calc(100% + 160px);
            height: calc(100% + 60px);
            z-index: 0;
            background: $color-white;
            pointer-events: none;
            will-change: transform;

            @media #{$mq-extra-large-up} {
                left: -120px;
                width: calc(100% + 240px);
            }
        }
    }

    &__accordion {
        display: none;

        @media #{$mq-medium-up} {
            margin-top: -12px!important;
        }

        .m01-header-animated--expand-mobile & {
            @media #{$mq-small-only} {
                display: block;
            }
        }

        .m01-header-animated--expand-desktop & {
            @media #{$mq-medium-up} {
                display: block;
            }
        }
    }

    &--static {
        .animated-content-wrapper {
            @media #{$mq-medium-up} {
                margin-top: 60px;
            }
        }

        &.m01-header-animated--hide-breadcrumb {
            .animated-content-wrapper {
                @media #{$mq-medium-up} {
                    margin-top: 135px;
                }
            }
        }
    }
}
