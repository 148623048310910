.myrm-orders {
    &.full-width-wrapper {
        padding-top: 30px;
        background-color: $color-nearly-white;
    }

    &.extra-padding--bottom {
        padding-bottom: 50px;
    }

    .myrm-orders__header {
        h4 {
            margin-bottom: 5px;
        }
    }

    .myrm_no-orders {
        @media #{$mq-medium-up} {
            padding-top: 15px;
        }

        .button-circle {
            float: right;
            margin-top: 30px;
        }
    }

    .button-download {
        display: flex;
        align-items: flex-start;

        &-text {
            border-bottom: 1px solid black;
            min-height: 20px;
        }

        &-icon svg {
            height: 20px;
            margin-right: 10px;
        }
    }

    .label--grey {
        margin-top: 30px;
        color: $color-gray;
    }
}


// refactored and better scoped css
.myrm-order-accordion {
    // Overrides of accordion
    &.accordion {

        // Details label
        .accordion__label-container {
            display: flex;
            flex-direction: row;
            transition: color 0.25s ease-out; // , border 0.25s ease-out;

            .uppercase {
                transition: color 0.25s ease-out;
            }

            .button-arrow {
                display: flex;
                justify-content: center;
                flex-direction: column;
                height: auto;
                padding-left: $gap-1-small;

                &:before {
                    transition: rotate(180deg), color 0.25s ease-out;
                }
            }
        }

        .bar,
        .content {
            padding: 20px 20px;
        }

        > li:nth-child(n+2) {
            margin-top: 30px;
        }

        > li,
        > .cms-plugin > li,
        > .container-li > li {

            &.expanded {
                .bar {
                    background-color: $color-white!important;
                }
            }

            &:hover {
                .no-touch & {
                    .bar {
                        background-color: $color-white!important;
                    }
                    .accordion__label-container {
                        color: $color-medium-grey;

                        .uppercase {
                            color: $color-medium-grey;
                        }
                    }

                    .button-arrow:before {
                        color: $color-medium-grey;
                    }
                }
            }
        }

        // todo: still needed?
        .bar__content {
            .button-disabled {
                .circle {
                    display: block;
                }
            }
        }
    }

    // Styles for myOrder accordion content
    &__status {
        display: flex;
        flex-direction: column;
        align-items: self-start;

        @media #{$mq-medium-up} {
            grid-column-start: auto;
        }

        &-icon {
            align-self: center;
        }
    }

    &__details {
        // List Styling entfernen
        list-style: none;
        padding-inline: 0;
        // Basic Styling
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: ($min-height-bar / 2) + $gap-1-small;
        align-items: flex-end;
        align-self: flex-end;
        transition: opacity 200ms;
        grid-column-start: 3;
        grid-column-end: auto;
        grid-row-start: 1;
        grid-row-end: auto;
    }

    @media #{$mq-small-only} {
        .accordion-body.accordion-body--white {
            transition: padding-top 200ms;
        }

        li.segment.expanded &__details {
            opacity: 0;
        }

        li.segment.expanded .accordion-body.accordion-body--white {
            padding-top: 0;
        }
    }

    // Styles for accordion body
    // todo: cleanup
    .accordion-body {
        &--white {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__label {
            margin-bottom: 15px;
            color: $color-warm-grey;
        }

        &__retoure {
            padding-top: 30px;

            @media #{$mq-medium-up} {
                padding-top: 0;
            }
        }

        &__container-buttons {
            display: flex;
            flex-direction: column;
            width: 100%;

            padding-bottom: 10px;

            @media #{$mq-medium-up} {
                flex-direction: row;
                margin-left: $background-circle-large + $gap-2-small;
                padding-bottom: $gap-3-small;
            }

            .button-circle {

                & + .button-circle {
                    margin-top: 15px;
                }

                &.button-next svg {
                    margin-left: 0;
                }

                .circle {
                    @media #{$mq-small-only} {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }

    // Styles for summary
    .accordion-product,
    .accordion-voucher {
        display: grid;
        grid-template-columns: repeat(2, minmax(min-content, 50%));
        grid-template-areas: 'description description' 'quantity price';
        grid-gap: 20px;

        @media #{$mq-medium-up} {
            grid-template-columns: 3fr 1fr 1fr 1fr;
            grid-template-areas: 'description quantity tax price';
            grid-gap: 15px;
        }

        > * + * {
            @media #{$mq-medium-up} {
                text-align: right;
            }
        }

        &__container--price {
            text-align: right;
        }
    }

    .accordion-product,
    .accordion-voucher {
        &__label {
            margin-bottom: 2px;

            @media #{$mq-medium-up} {
                margin-bottom: 15px;
            }
        }

        &__container--description {
            grid-area: description;
        }

        &__container--quantity {
            grid-area: quantity;
        }

        &__container--price {
            grid-area: price;
        }

        &__container--tax {
            grid-area: tax;

            @media #{$mq-medium-down} {
                display: none;
            }
        }
    }

    .accordion-product {
        &__copy-detail {
            color: $color-medium-grey;
            margin-top: 15px;
        }
    }

    .accordion-voucher {
        margin-top: 50px;

        @media #{$mq-medium-up} {
            margin-top: 40px;

            &__label {
                display: none;
            }
        }
    }

    .accordion-totals__container--total-netto,
    .accordion-totals {
        padding-top: 22px;
        margin-top: 22px;
        border-top: 1px solid $color-light-grey;
    }

    .accordion-totals {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .accordion-totals__container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: baseline;
            margin-bottom: 5px;
            font-size: 14px;
            line-height: 18px;

            p {
                color: $color-medium-grey;
            }

            &--total-price p {
                color: inherit;
            }

            @media #{$mq-medium-up} {
                justify-content: flex-end;
            }
        }

        .accordion-totals__label,
        .accordion-totals__value {
            @media #{$mq-medium-up} {
                text-align: right;
            }
        }

        .accordion-totals__label {
            small {
                display: block;
                line-height: 1.2;
            }
        }

        .accordion-totals__value {
            @media #{$mq-medium-up} {
                flex: 0 0 16.6666%;
                width: 16.6666%;
            }
        }
    }

    .accordion-order-details {
        display: flex;
        flex-direction: column;
        flex: 1 0 100%;

        @media #{$mq-medium-up} {
            flex-direction: row;
            justify-content: flex-start;

            > * {
                flex: 0 0 25%;
            }
        }

        .accordion__container--shipping,
        .accordion__container--shipping-method,
        .accordion__container--billing,
        .accordion__container--payment {

            &:not(:last-child) {
                margin-bottom: 30px;
            }

            @media #{$mq-medium-up} {
                padding-right: 15px;
                width: 35%;
            }
        }

        .accordion__container--shipping {

            @media #{$mq-medium-up} {
                width: 30%;
            }
        }

        .accordion__container--payment {
            .payment {

                &:not(:last-child) {
                    margin-bottom: 15px;
                }

                & + .shipping {
                    margin-top: 30px;
                }

                @media #{$mq-medium-up} {
                    margin-top: 0;
                }
            }
        }
    }

    .button-download {
        display: flex;
        align-items: flex-start;

        &-text {
            border-bottom: 1px solid black;
            min-height: 20px;
        }

        &-icon svg {
            height: 20px;
            margin-right: 10px;
        }
    }

    .button-return {
        display: flex;
        align-items: flex-start;

        &-text {
            border-bottom: 1px solid black;
            min-height: 20px;
        }

        &-icon svg {
            height: 20px;
            overflow: visible;
        }
    }

    .uppercase {
        font-weight: 100;
    }

    .label--grey {
        margin-top: 30px;
        color: $color-gray;
    }
}
