.bike-advisor__header {
    position: relative;
    height: calc(var(--vh, 1vh) * 100 - var(--top-bar-height));
    overflow: hidden;

    @include bike-advisor__size--medium() {
        height: calc(var(--vh, 1vh) * 100); } }

.bike-advisor__header-figure {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 0;
    // transform-origin: 50% 0
    backface-visibility: hidden;
    will-change: transform; }

figure {
    &.bike-advisor__header-figure {
        background-position: center bottom; } }

.bike-advisor__header-inner {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }

.bike-advisor__header-text {
    // force headline style to ck editors output
    h4 {
        p {
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
            letter-spacing: inherit; } } }

.bike-advisor__header-intro {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 90%;
    height: 100px;
    transform: translateX(-50%);
    background-color: $color-white; }

.bike-advisor__header-wrapper-button {
    display: flex;
    justify-content: center;
    margin-top: 120px;

    @include bike-advisor__size--medium() {
        margin-top: calc(var(--vh, 1vh) * 15); } }
