
.youtube {
    &__inner {
        background-color: $color-black;
        @include relative-height(16/9)
    }

    &__placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
    }

    &__iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &__cta {
        &--hidden {
            $timing-fn: cubic-bezier(0.52,-0.49, 0.89, 0.67);
            $duration: 0.4s;

            transition: transform $duration $timing-fn, opacity $duration $timing-fn;
            opacity: 0;
            transform: scale(0.8);
            pointer-events: none;
        }
    }
}
