.rm-status {
    display: block;
    position: relative;
    padding-left: 20px;
    line-height: 24px;
    color: $color-warm-grey;

    i {
        display: inline-block;
        position: absolute;
        width: 12px;
        height: 12px;
        top: 5px;
        left: 0;
        border-radius: 50%;
        background-color: $color-error;
    }

    .rm-status__active-text {
        display: none;
    }

    .rm-status__pending-text {
        display: none;
    }

    &.active {
        i {
            background-color: $color-product-available;
        }

        .rm-status__active-text {
            display: inline;
        }

        .rm-status__inactive-text {
            display: none;
        }

        .rm-status__pending-text {
            display: none;
        }
    }

    &.pending {
        i {
            // TODO: use correct color
            background-color: yellow;
        }

        .rm-status__active-text {
            display: none;
        }

        .rm-status__inactive-text {
            display: none;
        }

        .rm-status__pending-text {
            display: inline;
        }
    }
}