.button-disclaimer {
    $transition-options: 0.2s ease-out;

    display: flex;
    align-content: center;
    font-family: $font-flexo-regular;
    color: $color-medium-grey;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.05em;

    @media #{$mq-small-only} {
        margin-top: -4px;
    }

    span {
        margin-top: 1px;
        border-bottom: 1px solid $color-white;
        transition: color $transition-options, border-color $transition-options,
    }

    svg {
        width: 20px;
        height: 20px;
        fill: $color-medium-grey;
        margin-right: 5px;
        transition: fill $transition-options,
    }

    &:hover {
        .no-touch & {
            svg {
                fill: $color-black;
            }

            span {
                color: $color-black;
                border-bottom-color: $color-black;
            }
        }
    }
}