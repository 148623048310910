

$fontsize_breadcrump: 13px;
$fontsize_tables: 13px;

$fontsize_cards: 14px;
$lineheight_cards: 20px;

// p regular - paragraph
$pr_fontsize: 16px;
$pr_lineheight: 26px;
$pr_off_top: 6px;
$pr_off_bottom: 8px;

// p - paragraph
$p_fontsize: 14px;
$p_lineheight: 22px;
$p_off_top: 4px;
$p_off_bottom: 7px;

// p.poster - paragraph POSTER
$pposter_fontsize_sm: 18px;
$pposter_lineheight_sm: 25px;
$pposter_off_top_sm: 4px;
$pposter_off_bottom_sm: 8px;

$pposter_fontsize_l: 20px;
$pposter_lineheight_l: 28px;
$pposter_off_top_l: 5px;
$pposter_off_bottom_l: 8px;

// .text-small - TEXT-SMALL
$tsmall_fontsize: 12px;
$tsmall_lineheight: 18px;
$tsmall_off_top: 3px;
$tsmall_off_bottom: 5px;

// h1
$h1_fontsize_sm: 40px;
$h1_lineheight_sm: 51px;
$h1_off_top_sm: 7px;
$h1_off_bottom_sm: 15px;
//
$h1_fontsize_l: 45px;
$h1_lineheight_l: 57px;
$h1_off_top_l: 11px;
$h1_off_bottom_l: 20px;

// h2
$h2_fontsize_sm: 25px;
$h2_lineheight_sm: 32px;
$h2_off_top_sm: 5px;
$h2_off_bottom_sm: 9px;
//
$h2_fontsize_l: 30px;
$h2_lineheight_l: 39px;
$h2_off_top_l: 6px;
$h2_off_bottom_l: 11px;

// h3
$h3_fontsize_sm: 20px;
$h3_lineheight_sm: 26px;
$h3_off_top_sm: 4px;
$h3_off_bottom_sm: 7px;
//
$h3_fontsize_l: 25px;
$h3_lineheight_l: 32px;
$h3_off_top_l: 5px;
$h3_off_bottom_l: 9px;

// h4
$h4_fontsize_sm: 18px;
$h4_lineheight_sm: 23px;
$h4_off_top_sm: 3px;
$h4_off_bottom_sm: 7px;
//
$h4_fontsize_l: 20px;
$h4_lineheight_l: 26px;
$h4_off_top_l: 4px;
$h4_off_bottom_l: 7px;
