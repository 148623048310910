
.m11-lifestyle {
    .container-quote {
        @media #{$mq-medium-up} {
            @include relative-height(3/2);

            .quote {
                position: absolute;
                left: 0;
                top: 50%;
                width: 100%;
                transform: translateY(-50%);
            }
        }

        @media #{$mq-large-up} {
            .quote {
                left: 13%;
                width: 74%;
            }
        }
    }

    .l-3 figure {
        @media #{$mq-small-only} {
            @include relative-height(2/3);
        }

        @media #{$mq-medium-up} {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    .l-6 figure {
        @include relative-height(3/2);
    }

    h3 + p {
        @include gap-for(margin-top, $gap-1);
    }
}