.family-detail-intro {
    @media #{$mq-medium-down} {
        padding-top: 25px;
    }

    @media #{$mq-medium-up} {
        padding-top: 30px;
    }

    // prevents the copy from being cut off from the following section
    padding-bottom: 10px;

    .breadcrump {
        &:before,
        &:after {
            display: none;
        }
    }

    h1 {
        @media #{$mq-medium-down} {
            margin-top: 0;
        }

        @media #{$mq-medium-up} {
            margin-top: 40px;
        }
    }
}

.family-detail-intro--standalone {
    @media #{$mq-medium-down} {
        padding-top: 80px;
    }

    @media #{$mq-medium-up} {
        padding-top: 180px;
    }
}
