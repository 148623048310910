// import partials
@import "partials/configurator-navigation";
@import "partials/configurator-bar-purchase";
@import "partials/configurator-preview-area";
@import "partials/configurator-summary";

@import "partials/configurator-step-color-chooser";
@import "partials/configurator-step-options";

@import "partials/configurator-large-screens";

//vars
$mobile-step-height: 160px;
$tablet-step-height: 230px;

// main styles
.m16-bike-configurator-rb {
    &:before {
        height: 56px;

        @media #{$mq-small-only} {
            height: 0px;
        }
    }

    &:after {
       display: none;
    }

    .breadcrump {
        padding: 0;

        &:after {
            display: none;
        }
    }

    .configurator__stage {
        position: relative;
        display: block;
        height: calc(var(--vh, 1vh) * 100 - 56px - 50px);
        overflow: hidden;

        @media #{$mq-large-up} {
            display: flex;
            min-height: 480px;
        }

        &.custom-tooltips {
            .configurator__step-tooltip--mobile {
                z-index: 2;

                @media #{$mq-large-up} {
                    &.visible {
                        display: block!important;
                        width: calc(27% - -2px);
                        height: 100%;
                        min-height: 100%;
                        left: 40%;
                        opacity: 1;
                    }
                }
            }

            .configurator__step-tooltip--desktop {
                @media #{$mq-medium-up} {
                    display: none!important;
                }
            }
        }
    }

    .configurator__preview-area {
        // TODO: use width-mixin
        position: relative;
        width: 100%;
        height: calc(100% - #{$mobile-step-height} - 15px);

        @media #{$mq-medium-up} {
            height: calc(100% - #{$tablet-step-height});
        }

        @media #{$mq-large-up} {
            width: 67%;
            height: 100%;
        }
    }

    .configurator__step-area {
        // TODO: use width-mixin
        position: relative;
        display: inline-block;
        width: 100%;
        height: $mobile-step-height;

        @media #{$mq-medium-up} {
            height: $tablet-step-height;
            border-left: 2px solid white;
        }

        @media #{$mq-large-up} {
            width: 33%;
            height: 100%;
        }

        .configurator__step {
            position: absolute;
            display: flex;
            width: 100%;
            height: 100%;
            top: 0;
            align-items: flex-start;
            flex-wrap: nowrap;
            flex-direction: row;
            pointer-events: none;
            opacity: 0;
            transition: opacity .3s ease;
            overflow-x: scroll;
            overflow-y: visible;
            scrollbar-width: none;
            -webkit-overflow-scrolling: auto;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                display: none;
            }

            .configurator__step-item {
                height: 100%;
                flex-grow: 1;
                transition: flex 0.4s cubic-bezier(.28,.03,.41,.95);

                input {
                    opacity: 0;
                }

                label {
                    &:before {
                        display: none!important;
                    }
                }

                &.tooltip-is-visible {
                    .configurator__step-tooltip {
                        display: block;
                    }

                    input + label {
                        &:after {
                            display: block;
                        }
                    }
                }

                @media #{$mq-large-up} {
                    position: relative;
                    width: 100%;
                    height: 0;
                    overflow-x: visible;

                    &:last-child {
                        .configurator__step-tooltip {
                            top: auto;
                            bottom: 0;
                        }
                    }

                    &:hover:not(:disabled) {
                        flex-grow: 1.5;
                    }
                }
            }

            &.active {
                pointer-events: all;
                opacity: 1;
            }

            @media #{$mq-medium-up} {
                overflow-x: visible;
            }

            @media #{$mq-large-up} {
                flex-direction: column;
            }
        }
    }

    .configurator__step-tooltip {
        display: none;
        position: absolute;
        width: 100%;
        height: auto;
        bottom: 160px;
        right: auto;
        left: 0;
        padding: 30px 40px 30px 20px;
        color: $color-black;
        background: $color-light-grey;

        a + a {
            margin-left: 16px;
        }

        &.configurator__step-tooltip--mobile {

            p {
                font-size: 14px;
                line-height: 24px;
            }

            @media #{$mq-large-up} {
                display: none!important;
            }
        }

        &.configurator__step-tooltip--desktop {
            @media #{$mq-small-only} {
                display: none!important;
            }

            @media #{$mq-medium-only} {
                display: none!important;
            }
        }

        &.visible {
            display: block;
        }

        .configurator__step-tooltip-link {
            display: inline;
            line-height: 3.4em;
        }

        @media #{$mq-medium-up} {
            bottom: 230px;
            font-size: 16px;
            line-height: 26px;
        }

        @media #{$mq-large-up} {
            min-height: 100%;
            bottom: auto;
            right: calc(100%);
            left: auto;
        }
    }
}

.configurator__price-disclaimer-wrapper {
    display: flex;
    justify-content: space-between;
    align-content: center;
}
