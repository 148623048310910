
.m41-job-filter {
    .disabled,
    .disabled a {
        pointer-events: none;
        color: $color-light-grey !important;

        &.button-pager {
            &:before {
                pointer-events: none;
                color: $color-light-grey !important;
            }
        }

        &.reset-filters {
            a {
                font-size: 10px;
                border-bottom: 1px solid $color-light-grey !important;
            }
        }
    }

    .accordion {
        .label.right > span:first-child {
            margin-right: 13px;
        }

        li.segment {
            border-bottom: none;
        }
    }

    .background-grey {
        padding-top: 20px;
    }

    .wrapper-filter-results {
        margin-top: 0;
    }

    .filter-results {
        .cover {
            background-color: $color-ultra-light-grey;
        }
    }

    .filter-item,
    .filter-item.cms-plugin // Für Fix / Umbau via JS im CMS-Mode notwendig
    {
        display: block;
        margin-top: 10px;

        &:nth-child(n + 8) {
            display: none;
        }

        //margin nur zwischen filter items, anzahl immer analog zu js ändern
        &:nth-child(7n+1) {
            margin-top: 0;
        }
    }

    .container-pagination {
        padding: 25px 10px;
        display: flex;
        justify-content: center;

        .btn-pagination {
            padding: 0 10px;
            font-family: $font-flexo-bold;
            color: $color-medium-grey; // $color-brand-green-20blk;
            font-size: 14px;

            &.active {
                color: $color-black;
            }

            &.hidden {
                display: none;
            }

            &.invisible {
                visibility: hidden;
            }
        }
    }
}
