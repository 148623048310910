
.p_container_configuration {
    display: none;
}

@media print {
    .p_container_configuration {
        display: block;
        position: relative;
        font-size: 10pt;
        width: 100%;
        padding: 1.5cm 0 0 0;

        h2 {
            font-size: 20pt;
        }

        h3 {
            font-size: 16pt;
        }

        h3 + table {
            margin-top: 0.5cm;
        }

        .p_logo_rm {
            position: absolute;
            left: 0;
            top: 0;
            height: 0.5cm;
            font-size: 0.5cm;
            line-height: 0.5cm;

            &:before {
                font-family: $font-icons;
                -webkit-font-smoothing: antialiased;
                text-transform: none;
                font-size: 0.5cm;
                line-height: 0.5cm;
            }
        }

        .p_headline {
            width: 90%;
            border-bottom: 1pt solid $color-warm-grey;
        }

        .p_small {
            font-size: 8pt;
            line-height: 20px;
        }

        .p_bold {
            font-family: $font-flexo-bold;
        }

        .p_container_bike_code {
            display: inline-block;
            margin: 0.5cm 0;
            padding: 0.25cm;
            background-color: $color-brand-green;
            border: 2pt solid $color-brand-green;
            font-size: 12pt;
            line-height: 16pt;
        }

        .p_table_equipment,
        .p_table_features {
            width: 90%;
            font-size: 8pt;
            line-height: 20px;
            table-layout: auto;
            min-width: auto;

            td {
                word-wrap: break-word;
                padding: 0.25cm 0.35cm 0.25cm 0;

                &:nth-child(3) {
                    text-align: right;
                    padding: 0.25cm 0 0.25cm 0;
                }
            }

            span.text-small {
                display: none;
            }
        }

        .p_table_features, tbody, tr {
            position: static; overflow: visible; float: none;
        }

        .p_table_equipment {
            width: 90%;
        }

        .p_border_top {
            border-top: 1pt solid $color-warm-grey;
        }

        .p_container_split {
            display: inline-block;
            vertical-align: top;
            width: 56%;
            margin: 0.5cm 0;
        }

        .p_container_split + .p_container_split {
            width: 40%;
            margin-left: 3%;
        }

        .p_img_bike {
            width: 100%;
        }
    }
}
