.grid-visualizer {
    display: none;
    position: fixed;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10002;

    &.is-visible {
        display: block;
    }

    * {
        pointer-events: none;
        height: 100%;
    }

    .grid {
        > * {
            background-color: rgba(255, 0, 0, 0.2);
        }
    }
}