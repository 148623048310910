// Eigentliches Styling der Header-Varianten
.m01-header {
    overflow: hidden;

    video {
        position: absolute;
        left: 50%;
        top: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        // overflow: hidden;
        transform: translateX(-50%) translateY(-50%);
    }

    &.small,
    &.extra-small {
        .container-center .caption {
            width: 100%;
            @include gap-for(margin-top, $gap-2);

            @media #{$mq-medium-up} {
                width: 50%;
            }

            @media #{$mq-large-up} {
                width: 40%;
            }
        }
    }

    &.small {
        figure,
        .container-video {
            @include relative-height(3/3);

            @media #{$mq-medium-up} {
                @include relative-height(21/12);
            }

            @media #{$mq-large-up} {
                @include relative-height(21/9.5);
            }

            @media #{$mq-extra-large-up} {
                @include relative-height(21/8);
            }
        }

        // Videos müssen in einem festen Seitenverhältnis eingebettet werden,
        // so dass trotz nicht geladener Videos keine Fehler bei der Berechnung der Sections auftreten
        .container-video {
            position: relative;
            width: 100%;
            overflow: hidden;

            figure {
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        .slider {
            figure.slide {
                background-size: cover;

                &:nth-child(n+2) {
                    position: absolute;
                }
            }

            & > .container-captions {
                margin-left: auto;
                margin-right: auto;
                width: calc(100% + #{$gap-1-small} - #{$gap-2-small * 2});

                @media #{$mq-medium-only} {
                    width: calc(100% + #{$gap-1-small} - #{$gap-4-medium * 2});
                }

                @media #{$mq-large-only} {
                    width: calc(100% + #{$gap-1-small} - #{$gap-4-large * 2});
                }

                @media #{$mq-max-width-up} {
                    min-width: $screen-max-width;
                    width: 100%;
                }
            }

            .button-pager {
                // hide, as long as no proper layout is done
                display: none;
            }
        }
    }

    &.extra-small {
        figure {
            @include relative-height(3/2.5);

            @media #{$mq-medium-up} {
                @include relative-height(21/9);
            }

            @media #{$mq-large-up} {
                @include relative-height(21/8);
            }

            @media #{$mq-extra-large-up} {
                @include relative-height(21/6.5);
            }
        }
    }

    &.splitscreen {
        background: $color-black;

        figure {
            position: absolute;
            top: 0;

            @media #{$mq-small-only} {
                width: 100%;
                height: 50%;
            }

            @media #{$mq-medium-up} {
                // $offset-left: 35px;
                left: 50%;
                width: 50%; // calc(50% - 35px);
            }
        }

        .rb__hint-scroll {
            bottom: 15px;
        }
    }
}
