.content-toggle {
    p + .mask-toggle {
        margin-top: 30px;
    }

    .mask-toggle {
        //overflow: hidden;
        margin-bottom: 10px;
    }

    .container-toggle {
        display: none;

        &.active {
            display: block;
        }

        &.preview {
            .form-label {
                margin-bottom: 0;
            }

            .preview-group {
                margin-bottom: 20px;
            }

            .s-12 {
                margin-top: 0;
                margin-bottom: 0;

                @media #{$mq-medium-up} {
                    .preview-group:last-child {
                        margin-bottom: 0;
                    }
                }

                @media #{$mq-small-only} {
                    &:last-child {
                        .preview-group:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .error-wrapper-overlay {
                margin-top: -12px;
            }

            .preview-inner {
                padding: 20px;
                background-color: $color-white;

                + .wrapper-button {
                    margin-top: 30px;
                }

                &.inverted {
                    background-color: $color-ultra-light-grey;
                }
            }
        }

        &.edit-mode {
            .wrapper-form-fields {
                + .wrapper-button {
                    margin-top: 30px;
                }
            }
        }

        .grid {
            margin-top: 0;
            margin-bottom: 0;
        }


        // case when using flexbox e.g. m07-series-chooser
        &.is-flex.active {
            display: flex;
        }
    }
}
