
html.backdrop-dark {
    // overflow-fix in overlay-view (beim scrollen über html-Grenzen hinaus, z.b. bei Mac-Trackpad)
    background-color: #333;
}

#wrapper-main {
    // todo: absolute statt relative ohne Fehler möglich?
    position: absolute; // relative;
    width: 100%;
    min-height: calc(100vh + 1px);
}

.cms-ready #wrapper-main {
    position: relative;
}

body {
    // &.custom-select-expanded
    &.scrolling {
        pointer-events: none;
    }

    & * {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        box-sizing: border-box;
    }
}

#container-fixed {
    // still nothing here ;)
}

header {
    // todo - rebrush
    // margin-top: -56px;

    @media #{$mq-medium-up} {
        margin-top: 0;
    }
}

main {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    min-height: 100%;
    background-color: $color-white;
    padding-top: $height-top-bar;
    // todo: try to get along with "hidden", to enable "position sticky"
    overflow: hidden;

    @media #{$mq-medium-up} {
        padding-top: 0;
    }

    .rm-webview & {
        padding-top: 0 !important;

        @media #{$mq-medium-up} {
            padding-top: 0 !important;
        }
    }

    body[data-type="page-myrm"] & {
        padding-top: $height-top-bar + $height-myrm-sub-nav-s;

        & > section:last-of-type {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
        }

        @media #{$mq-medium-up} {
            padding-top: $height-top-bar + $height-myrm-sub-nav-m-l;
        }

        .rm-webview & {
            padding-top: $height-myrm-sub-nav-s !important;

            @media #{$mq-medium-up} {
                padding-top: $height-myrm-sub-nav-m-l !important;
            }
        }
    }

    // moves footer to page end
    footer {
        margin-top: auto;
    }
}

.spacer-privacy-layer {
    width: 100%;
    height: 0;
    background-color: transparent;

    @media #{$mq-small-only} {
        display: none;
    }
}

.section {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    background-color: $color-white;
}

.section > .cms-placeholder + .cms-plugin {
    display: block;

    &:before {
        content: '';
        display: block;
        width: 100%;
        @include gap-for(height, $gap-4, $gap-2);
    }
}

.container-max-width {
    position: relative;
    width: 100%;
    max-width: $screen-max-width;
    margin: 0 auto;
}

.container-center {
    width: calc(100% - #{$padding-container-center-small * 2});
    max-width: $screen-max-width;
    margin-left: auto;
    margin-right: auto;

    @media #{$mq-medium-up} {
        width: calc(100% - #{$padding-container-center-medium * 2});
    }

    @media #{$mq-large-up} {
        width: calc(100% - #{$padding-container-center-large * 2});
    }

    &--top-bar {
        width: calc(100% - #{20px * 2});

        @media #{$mq-medium-up} {
            width: calc(100% - #{40px * 2});
        }

        @media #{$mq-large-up} {
            width: calc(100% - #{40px * 2});
        }
    }
}
