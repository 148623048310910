
/**********************
    Fileupload
**********************/

// Wrapper, um css-Regeln mit :not zu vermeiden
.wrapper-upload {
    input {
        display: none;
    }

    input.error + label {
        color: $color-error;
    }

    &.filled .btn-base {
        @media #{$mq-small-only} {
            pointer-events: none;
            opacity: 0;
        }
    }

    .error-text.visible {
        display: block;
    }

    label.custom-file-upload {
        position: relative;
        height: 45px;
        background-color: $color-ultra-light-grey-hex;
        cursor: pointer;

        display: flex;
        transition: color 0.25s ease-in-out;

        & > * {
            white-space: nowrap;
            align-self: center;
        }
    }

    .btn-base {
        margin: 0;
        padding: 0 20px;
        min-width: 160px;
        white-space: nowrap;

        @media #{$mq-small-only} {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            transition: opacity 0.35s ease-in-out;
        }
    }

    .file-name {
        margin-left: 20px;
        overflow: hidden;
        text-overflow: ellipsis;

        flex-grow: 1000;
    }

    .file-size {
        margin-left: 5px;
    }

    .btn-delete-file {
        margin: 0 20px;

        &:before {
            content: 'X';
            font-family: $font-icons;
            -webkit-font-smoothing: antialiased;
            text-transform: none;
            font-size: 10px;
            vertical-align: bottom;
            width: 20px;
            line-height: 20px;
            // explizit setzen, dass nicht von .error überschrieben wird
            color: $color-black;

            transition: color 0.25s ease-out;
        }
    }
}
