
.form-label {
    position: relative;
    top: -3px;
    margin-bottom: #{-3px - 5px};

    &:last-child {
        margin-bottom: 0;
    }
}

button + input,
.form-label + input,
.form-label + textarea,
.form-label + .custom-select,
.form-label + .custom-file-upload {
    margin-top: 15px;
}

* + .error-text,
* + .optional-link {
    margin-top: 10px;
}

// todo: ask julia if these selectors are in usage anywhere?
// NO form-Tag with class "grid" found
// NOT ANY appearance of class "form-step" in any html-file
form.grid,
.form-step.grid {

    h2, h4, p {
        position: relative;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h2 {
        $top: -7px;
        $bottom: -11px;

        font-size: 30px;
        line-height: 39px;
        top: $top;
        margin-bottom: #{$top + $bottom};
    }

    h4 {
        $top: -5px;
        $bottom: -7px;

        font-family: $font-flexo-regular;
        font-size: 20px;
        line-height: 26px;
        top: $top;
        margin-bottom: #{$top + $bottom};
    }

    p {
        $top: -5px;
        $bottom: -7px;

        font-size: 14px;
        line-height: 22px;
        top: $top;
        margin-bottom: #{$top + $bottom};
    }

    * + h4 {
        margin-top: 50px;
    }

    p.errors + p.errors {
        margin-top: 20px;
    }

    .wrapper-checkbox
    {
        margin-top: 0;
        margin-bottom: 20px;

        .checkbox-description {
            padding-top: 10px;
        }

    }

    *:not(.wrapper-checkbox) + .wrapper-checkbox {
        margin-top: 50px;
    }
}

.error-wrapper {
    > p:empty + p {
        margin-top: 0;
    }
}
