
.myrm__sub-nav {
    $padding-s: 20px;
    $padding-m-l: 40px;

    $height-s: $height-myrm-sub-nav-s;
    $height-m-l: $height-myrm-sub-nav-m-l;

    position: fixed;
    display: flex;
    width: 100%;
    background-color: $color-ultra-light-grey; // $color-light-grey;
    padding: 0 20px;

    z-index: $z-index-myrm-sub-nav;
    top: $height-top-bar;

    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    @media #{$mq-medium-up} {
        padding: 0 $padding-m-l;
    }

    .cms-toolbar-expanded & {
        top: $height-top-bar + 40px;
    }

    .rm-webview & {
        top: 0;
    }

    .myrm__sub-nav-item {
        position: relative;
        border-bottom: none;
        height: $height-s;
        line-height: $height-s;
        text-transform: uppercase;
        color: $color-medium-grey;
        white-space: nowrap;

        @media #{$mq-small-only} {
            font-size: $fontsize_breadcrump;
        }

        &.active {
            color: $color-black;
        }

        &:hover {
            .no-touch & {
                color: $color-black;
                border-bottom: none;
            }
        }

        &.active {
            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                background-color: $color-black;
            }
        }

        @media #{$mq-medium-up} {
            height: $height-m-l;
            line-height: $height-m-l;
        }

        & + .myrm__sub-nav-item {
            margin-left: $padding-s;

            @media #{$mq-medium-up} {
                margin-left: 40px;
            }

            &:last-child {
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    right: -($padding-s);
                    bottom: 0;
                    width: $padding-s;
                    height: 1px;

                    @media #{$mq-medium-up} {
                        right: -40px;
                        width: 40px;
                    }
                }

            }
        }
    }
}
