
.m39-emo {
    // todo - rebrush
    width: 100%;
    height: 400px;

    //.mask {
    //    position: relative;
    //    width: 100%;
    //    height: 400px;
    //    overflow: hidden;
    //}
    //
    //figure {
    //    position: absolute;
    //    left: 0;
    //    bottom: 0;
    //    width: 100%;
    //    height: 200%;
    //}
}