
.m49-magazine-teaser {
    .poster-image {
        position: relative;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 50px;

        @media #{$mq-medium-up} {
            margin-top: 10px;
            padding: 70px 8% #{70px + 70px + 35px} 8%;
        }

        @media #{$mq-large-up} {
            padding: 70px 15% #{70px + 70px + 35px} 15%;
        }

        .rb__inner {
            width: 100%;
            max-width: 800px;
            // prevent glitch while btn-mouse-over
            transform: translate3d(0, 0, 0);
        }

        figure {
            width: 100%;
        }

        .button-circle {
            margin-top: 50px;
            @media #{$mq-medium-up} {
                position: absolute;
                right: 0;
                bottom: 70px;
                margin-top: 0;
            }
        }
    }

    .wrapper-offset {
        width: 100%;
    }

    .grid.medium-only {
        figure + .rb__wrapper-figure {
            @media #{$mq-medium-only} {
                margin-top: 20px;
            }
        }
    }

    // only MQ small
    .header-img-teaser {
        position: relative;
        display: flex;
        align-items: flex-end;

        figure {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }

        .magazine-teaser-wrapper,
        .accordion-expand {
            position: relative;
            z-index: 2;
            margin-bottom: 30px;
        }
    }

    // padding deranges internal grid
    .card-poster {
        @media #{$mq-medium-up} {
            padding: 0;
        }
    }

    .flex-end {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        width: 100%;
    }


    .magazine-teaser-wrapper + .triple-img,
    .accordion-expand + .triple-img {
        margin-top: 35px;
    }

    .accordion-expand {
        @media #{$mq-medium-up} {

            &:not(.deactivate) .mask-expand {
                // read more button needs on medium devices a bit more space
                padding-right: 20px;
            }

            // ausrichtung oberkante text
            .wrapper-button {
                margin-top: -64px;
            }
        }
    }

    .container-quote {
        margin-top: 60px;

        .wrapper-button {
            margin-top: 35px;
        }

        .caption {
            color: $color-black;
            margin-top: 30px;
        }

        @media #{$mq-large-up} {

            h2.quote, .caption {
                // ansch 181211: TODO warum wurde das inline gesetzt? Abstand soll jetzt immer gleich sein
                //display: inline;
                vertical-align: baseline;
            }

            h2.quote {
                margin-right: 10px;
            }

            .caption {
            //    display: block;
            //    // same offset as h2
            //    top: -6px;
            //    line-height: 32px;
                white-space: nowrap;
            }
        }
    }

    // ie edge layout fix
    @media #{$mq-large-up} {
        .ie-edge & {
            .grid {
                & > div:last-child {
                    margin-right: -1px;
                }
            }
        }
    }
}