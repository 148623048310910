// Konstanten für Media-Queries
// ACHTUNG: Immer mit Constants.js synchronisieren!

$screen-extra-small: 0px;
$screen-small: 480px;
$screen-medium: 768px;

$screen-large: 1024px;
$screen-extra-large: 1280px;
$screen-max-width: 1400px;

$screen-iphone-vertical: 320px;

// Media-Queries: Ab Auflösung x und darüber hinaus

$mq-small-up: "only screen and (min-width: #{$screen-small} )";
$mq-medium-up: "only screen and (min-width: #{$screen-medium} )";
$mq-large-up: "only screen and (min-width: #{$screen-large} )";
$mq-extra-large-up: "only screen and (min-width: #{$screen-extra-large} )";

$mq-max-width-up: "only screen and (min-width: #{$screen-max-width} )";
$mq-max-width-up-with-margin: "only screen and (min-width: #{$screen-max-width + 2 * $padding-container-center-large} )";

// Media-Queries: Innerhalb des Bereichs zweier Auflösungen. Um Überlappungen zu vermeiden, wird bei max-width von der Konstante 1px abgezogen
$mq-extra-small-only: "only screen and (max-width: 479px)";

$mq-small-only: "only screen and (max-width: #{$screen-medium - 1px} )";
$mq-medium-only: "only screen and (min-width: #{$screen-medium} ) and (max-width: #{$screen-large - 1px} )";

// todo -- mit oder ohne Einschränkung nach oben?
$mq-large-only: "only screen and (min-width: #{$screen-large} ) and (max-width: #{$screen-max-width - 1px} )";

$mq-medium-down: "only screen and ( max-width: #{$screen-medium - 1px} )";
$mq-large-down: "only screen and ( max-width: #{$screen-large - 1px} )";



// helper classes
.small-only
{
  display: none;

  @media #{$mq-small-only}
  {
    display: block;
  }
}

.medium-down-only
{
    display: block;

  @media #{$mq-large-up}
  {
    display: none;
  }
}

.large-up-only
{
  display: none;

  @media #{$mq-large-up}
  {
    display: block;
  }
}