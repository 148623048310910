
.m26-downloads {
    p {
        word-wrap: break-word;
    }

    .cms-plugin {
        display: block;
    }

    .grid,
    .cms-plugin {
        margin-bottom: 0;

        &:nth-last-child(n+2) {
            margin-bottom: $thickness-line;
        }
    }

    .grid {
        background-color: $color-white;

        & > * {
            margin-top: #{$gap-smallest-part * 2};
            margin-bottom: #{$gap-smallest-part * 2};

            line-height: 20px;

            @media #{$mq-small-only} {
                &:nth-child(n+3) {
                    margin-top: 0;
                }
            }
        }

        &:before {
            content: '';
            position: absolute;
            width: $gap-smallest-part;
            height: 100%;
            background-color: $color-ultra-light-grey;
        }

        &:after {
            @media #{$mq-large-up} {
                content: '';
                position: absolute;
                right: 0;
                width: calc(100% / 6);
                height: 100%;
                background-color: $color-white;
            }
        }
    }

    p span, a {
        display: block;

        margin-top: 0;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        display: block;
        border: none;

        & > * {
            pointer-events: none;
        }

        span {
            border-bottom: 1px solid $color-black;
            transition: border-bottom-color 0.25s ease-out
        }

        &:hover {
            border: none;

            .no-touch & {
                color: $color-warm-grey;

                span {
                    border-bottom-color: $color-warm-grey;
                }
                .btn-icon {
                    // background-color: $color-warm-grey;
                    &:before {
                        color: $color-warm-grey;
                    }
                }
            }
        }
    }

    .btn-icon {
        position: relative;
        display: inline-block;
        top: 7px;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: $gap-smallest-part;
        background-color: transparent;
        transition: color 0.25s ease-out;

        &:before {
            color: $color-black;
            font-size: 10px;
            line-height: 16px !important;
        }

        &:after {
            display: none;
        }
    }
}
