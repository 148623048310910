$dropdown--max-visible-items: 4.5;
$dropdown--height: 55px;
$dropdown--padding: 20px;
$dropdown--line-height: 20px;
$dropdown--ease-out-cubic: cubic-bezier(0,.54,.19,1);
$dropdown--item-min-height: $dropdown--line-height + $dropdown--padding * 2;

.dropdown {
    position: relative;
    cursor: pointer;
    font-size: 16px;

    select {
        display: none;
    }

    .dropdown__arrow__back {
        opacity: 0;
    }

    &--expanded {
        .dropdown__pop-up {
            opacity: 1;
            pointer-events: auto;
        }

        .dropdown__label {
            color: $color-black;
            z-index: 2000;
        }

        .dropdown__arrow {
            opacity: 0;
        }

        .dropdown__arrow__back {
            opacity: 1;
            z-index: 2000;
        }
    }
}

.dropdown--reduced-mobile-view {
    @media #{$mq-medium-down} {
        .dropdown__arrow:not(.dropdown__arrow__back) {
            display: none;
        }

        .dropdown__label {
            width: 40px;

            span {
                opacity: 0;
                pointer-events: none;
            }

            &::after {
                opacity: 0;
            }
        }
    }

    @media #{$mq-medium-up} {
        .dropdown__icon-mobile {
            display: none;
        }
    }

    &.dropdown--expanded {
        .dropdown__label {
            width: auto;

            span {
                opacity: 1;
            }

            &::after {
                opacity: 1;
            }
        }

        .dropdown__icon-mobile {
            opacity: 0;
        }
    }
}

.dropdown__icon-mobile {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;

    svg {
        width: 24px;
        height: 24px;
        fill: $color-black;
    }
}

.dropdown__label {
    position: relative;
    // text-transform: uppercase;
    font-family: $font-flexo-demi;
    line-height: $dropdown--line-height;

    &:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $color-black;
    }
}

.dropdown__arrow {
    position: absolute;
    right: 0;
    top: 5px;
    width: 22px;
    height: 15px;
    pointer-events: none;
    stroke: $color-black;
    transition: opacity 0.2s $dropdown--ease-out-cubic;
}

.dropdown__pop-up {
    position: absolute;
    left: -$dropdown--padding;
    top: -$dropdown--padding;
    width: calc(100% + #{2 * $dropdown--padding});
    padding-top: $dropdown--item-min-height - $dropdown--padding / 2;
    border: 1px solid $color-black;
    background-color: $color-white;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s $dropdown--ease-out-cubic;
    z-index: 1000;
}

.dropdown__mask {
    width: 100%;
    max-height: $dropdown--item-min-height * $dropdown--max-visible-items + $dropdown--padding / 2;
    overflow-x: hidden;
    overflow-y: scroll;

    @include custom-scrollbar();
}

.dropdown__items {
    padding: $dropdown--padding / 2 0;
    background-color: $color-white;
}

.dropdown__item {
    $height: 50px;

    position: relative;
    width: 100%;
    line-height: $dropdown--line-height;
    padding: $dropdown--padding / 2 $dropdown--padding;

    &:hover,
    &--selected {
        font-family: $font-flexo-demi;
    }

    &--selected {
        pointer-events: none;
        display: none;
    }
}
