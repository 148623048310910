
.wrapper-youtube {
    background-color: $color-black;
    @include relative-height(16/9);
}

iframe.youtube {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
