
.m35b-testimonials {
    h3 {
        text-align: center;
    }

    p,
    h3 > span {
        color: $color-medium-grey;
    }

    .slide {
        padding: 5px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .slide > * {
        @media #{$mq-small-only} {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }

    .slider .caption {
        z-index: 10;
        opacity: 1;
    }

    .btn-external-link {
        margin-left: 6px;
        padding-right: 0;
        font-size: inherit;
        text-transform: uppercase;
    }

    .container-dots {
        @media #{$mq-medium-up} {
            top: 0;
        }
    }
}
