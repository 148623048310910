.link-teaser {
    position: relative;

    figure {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    figure::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.1) 100%);
      }
      

    .container-center {
        
    }
}

.link-teaser__contents {
    min-height: 350px;
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    flex-direction: column;

    @media #{$mq-medium-up} {
        flex-direction: row;
    }
}

.link-teaser__texts {
    width: 100%;
    align-self: flex-start;
    
    @media #{$mq-medium-up} {
        max-width: 400px;
        align-self: center;
    }
}

.link-teaser__button {
    align-self: flex-end;

    @media #{$mq-small-only} {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 40px;
    }
}

