
.m51-warranty-teaser {
    @media #{$mq-small-only} {
        order: 2;
    }
}

.production-status {
    @media #{$mq-small-only} {
        order: 1;
    }

    .card-poster {
        position: relative;
        overflow: hidden;
        padding-top: 150px;

        &--no-background {
            padding-top: 45px;
        }
    }

    .button-circle.button-label-right {
        .circle {
            margin-left: 0;
        }
    }

    &__background {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 110%;
        transition: opacity 0.45s ease-in-out;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.8));
        }

        @media #{$mq-medium-up} {
            height: 130%;
        }
    }

    &__headline {
        a {
            position: relative;
            top: 0;
            font-size: inherit;
            line-height: inherit;

        }
    }

    &__copy {
        max-width: 600px;
    }

    &__form,
    &__texts {
        position: relative;
        z-index: 3;
        transition: opacity 0.45s ease-in-out;
    }

    &__form {
        display: flex;
        width: 100%;
        height: 50px;
        margin-top: 30px;
        justify-content: space-between;
        align-items: center;
        transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
    }

    &__input {
        $height: 50px;

        width: 100%;
        max-width: 500px;
        color: $color-black;
        font-family: $font-flexo-demi;
        text-align: center;
        height: $height !important;
        line-height: $height !important;

        &::placeholder {
            color: $color-black;
            font-family: $font-flexo-demi;
            text-transform: uppercase;
            transition: color 0.2s ease-out;
        }

        &--error {
            &::placeholder {
                color: $color-error;
            }
        }
    }

    &__button-submit {
        margin-left: 25px;
    }

    &__mail-update-active,
    &__mail-update-inactive {
        &:before {
            $size: 12px;
            content: '';
            display: inline-block;
            width: $size;
            height: $size;
            border-radius: 50%;
            margin-right: 10px;
        }
    }

    &__mail-update-active {
        &:before {
            background-color: $color-green;
        }
    }

    &__mail-update-inactive {
        margin-bottom: 20px;

        &:before {
            background-color: $color-error;
        }
    }

    &__mail-update-subscribe {
        a {
            cursor: pointer;
        }
    }

    &__wrapper-button-right {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }

    &__cta-add-bike {
        .button-bike {
            margin-right: 12px;
        }
    }

    &__mail-update-subscribe,
    &__mail-update-active,
    &__mail-update-inactive,
    &__cta-my-bike,
    &__cta-add-bike,
    &__button-back {
        display: none;
    }


    &[data-step='response'] {
        .production-status__form {
            pointer-events: none;
            opacity: 0;
            height: 0;
        }

        .production-status__background {
            // override js-fade-in after pre-load imgs
            opacity: 0 !important;
        }

        .card-poster {
            padding-top: 25px;
        }

        .production-status__button-back {
            display: flex;
        }

        &[data-status='in_production'],
        &[data-status='holded'],
        &[data-status='dunning'],
        &[data-status^='postponement_'] {

            &[data-user-authenticated='true'] {
                &[data-mail-update-active='true'] {
                    .production-status__mail-update-active {
                        display: block;
                    }
                }

                &[data-mail-update-active='false'] {
                    .production-status__mail-update-inactive {
                        display: block;
                    }
                }
            }

            &[data-mail-update-active='false'] {
                .production-status__mail-update-subscribe {
                    display: block;
                }
            }
        }

        &[data-status='complete'] {
            // base = home
            &[data-page-id='base'] {
                .production-status__cta-my-bike {
                    display: block;
                }
            }

            &[data-page-id='my_bikes'] {
                .production-status__cta-add-bike {
                    display: block;
                }
            }
        }
    }

    &[data-step='submitting'] {
        .production-status__texts,
        production-status__form {
            opacity: 0.5;
            pointer-events: none;
            transition: opacity 0.3s ease-in-out 0.5s;
        }
    }
}
