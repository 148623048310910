.breadcrump {
    display: block;
    background-color: transparent;

    &--hidden {
        & > div {
            display: none !important;
        }
    }

    .medium-up {
        display: flex;
        transform: translateZ(0);
    }

    a.text-breadcrump {
        margin-top: 0;
        white-space: nowrap;
    }

    a {
        display: block;
        position: relative;
        padding-left: 6px;
        padding-right: 6px;
        margin-right: 12px;

        // todo -- rebrush
        color: $color-medium-grey;
        font-size: $fontsize_breadcrump;

        &:hover {
            .no-touch & {
                color: $color-black;
            }
        }

        // damit click-handler Klick von a und nicht span erhält
        span {
            pointer-events: none;
        }

        @media #{$mq-medium-only} {
            &:first-child {
                &:before {
                    content: 'P';
                    position: relative;
                    top: -1px;
                    font-family: $font-icons;
                    -webkit-font-smoothing: antialiased;
                    text-transform: none;
                    font-size: 12px;
                }

                & > span {
                    display: none;
                }
            }
        }

        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }

        &:nth-last-child(n+2) {
            &:after {
                position: absolute;
                display: block;
                top: 0;
                height: 100%;
                content: '|';
                // content: 'c'; // siehe icons-reference für richtiges element
                // font-family: $font-icons;
                text-transform: none;
                color: $color-warm-grey;
                font-size: 9px;
                text-align: center;
                font-style: normal;
                right: -12px;
                width: 12px;
            }
        }

        &:last-child {
            color: $color-black;
            pointer-events: none;
        }
    }
}
