@font-face{
    font-family:"FlexoW03-Thin";
    src:url("/static/assets/font/flexo/FlexoW03-Thin.eot?#iefix");
    src:url("/static/assets/font/flexo/FlexoW03-Thin.eot?#iefix") format("eot"),url("/static/assets/font/flexo/FlexoW03-Thin.woff2") format("woff2"),url("/static/assets/font/flexo/FlexoW03-Thin.woff") format("woff"),url("/static/assets/font/flexo/FlexoW03-Thin.ttf") format("truetype");
}
@font-face{
    font-family:"FlexoW03-ThinItalic";
    src:url("/static/assets/font/flexo/FlexoW03-ThinItalic.eot?#iefix");
    src:url("/static/assets/font/flexo/FlexoW03-ThinItalic.eot?#iefix") format("eot"),url("/static/assets/font/flexo/FlexoW03-ThinItalic.woff2") format("woff2"),url("/static/assets/font/flexo/FlexoW03-ThinItalic.woff") format("woff"),url("/static/assets/font/flexo/FlexoW03-ThinItalic.ttf") format("truetype");
}
//@font-face{
//    font-family:"FlexoW03-Light";
//    src:url("/static/assets/font/flexo/FlexoW03-Light.eot?#iefix");
//    src:url("/static/assets/font/flexo/FlexoW03-Light.eot?#iefix") format("eot"),url("/static/assets/font/flexo/FlexoW03-Light.woff2") format("woff2"),url("/static/assets/font/flexo/FlexoW03-Light.woff") format("woff"),url("/static/assets/font/flexo/FlexoW03-Light.ttf") format("truetype");
//}
//@font-face{
//    font-family:"FlexoW03-LightItalic";
//    src:url("/static/assets/font/flexo/FlexoW03-LightItalic.eot?#iefix");
//    src:url("/static/assets/font/flexo/FlexoW03-LightItalic.eot?#iefix") format("eot"),url("/static/assets/font/flexo/FlexoW03-LightItalic.woff2") format("woff2"),url("/static/assets/font/flexo/FlexoW03-LightItalic.woff") format("woff"),url("/static/assets/font/flexo/FlexoW03-LightItalic.ttf") format("truetype");
//}
@font-face{
    font-family:"FlexoW03-Regular";
    src:url("/static/assets/font/flexo/FlexoW03-Regular.eot?#iefix");
    src:url("/static/assets/font/flexo/FlexoW03-Regular.eot?#iefix") format("eot"),url("/static/assets/font/flexo/FlexoW03-Regular.woff2") format("woff2"),url("/static/assets/font/flexo/FlexoW03-Regular.woff") format("woff"),url("/static/assets/font/flexo/FlexoW03-Regular.ttf") format("truetype");
}
//@font-face{
//    font-family:"FlexoW03-Italic";
//    src:url("/static/assets/font/flexo/FlexoW03-Italic.eot?#iefix");
//    src:url("/static/assets/font/flexo/FlexoW03-Italic.eot?#iefix") format("eot"),url("/static/assets/font/flexo/FlexoW03-Italic.woff2") format("woff2"),url("/static/assets/font/flexo/FlexoW03-Italic.woff") format("woff"),url("/static/assets/font/flexo/FlexoW03-Italic.ttf") format("truetype");
//}
//@font-face{
//    font-family:"FlexoW03-Medium";
//    src:url("/static/assets/font/flexo/FlexoW03-Medium.eot?#iefix");
//    src:url("/static/assets/font/flexo/FlexoW03-Medium.eot?#iefix") format("eot"),url("/static/assets/font/flexo/FlexoW03-Medium.woff2") format("woff2"),url("/static/assets/font/flexo/FlexoW03-Medium.woff") format("woff"),url("/static/assets/font/flexo/FlexoW03-Medium.ttf") format("truetype");
//}
//@font-face{
//    font-family:"FlexoW03-MediumItalic";
//    src:url("/static/assets/font/flexo/FlexoW03-MediumItalic.eot?#iefix");
//    src:url("/static/assets/font/flexo/FlexoW03-MediumItalic.eot?#iefix") format("eot"),url("/static/assets/font/flexo/FlexoW03-MediumItalic.woff2") format("woff2"),url("/static/assets/font/flexo/FlexoW03-MediumItalic.woff") format("woff"),url("/static/assets/font/flexo/FlexoW03-MediumItalic.ttf") format("truetype");
//}
@font-face{
    font-family:"FlexoW03-Demi";
    src:url("/static/assets/font/flexo/FlexoW03-Demi.eot?#iefix");
    src:url("/static/assets/font/flexo/FlexoW03-Demi.eot?#iefix") format("eot"),url("/static/assets/font/flexo/FlexoW03-Demi.woff2") format("woff2"),url("/static/assets/font/flexo/FlexoW03-Demi.woff") format("woff"),url("/static/assets/font/flexo/FlexoW03-Demi.ttf") format("truetype");
}
//@font-face{
//    font-family:"FlexoW03-DemiItalic";
//    src:url("/static/assets/font/flexo/FlexoW03-DemiItalic.eot?#iefix");
//    src:url("/static/assets/font/flexo/FlexoW03-DemiItalic.eot?#iefix") format("eot"),url("/static/assets/font/flexo/FlexoW03-DemiItalic.woff2") format("woff2"),url("/static/assets/font/flexo/FlexoW03-DemiItalic.woff") format("woff"),url("/static/assets/font/flexo/FlexoW03-DemiItalic.ttf") format("truetype");
//}
@font-face{
    font-family:"FlexoW03-Bold";
    src:url("/static/assets/font/flexo/FlexoW03-Bold.eot?#iefix");
    src:url("/static/assets/font/flexo/FlexoW03-Bold.eot?#iefix") format("eot"),url("/static/assets/font/flexo/FlexoW03-Bold.woff2") format("woff2"),url("/static/assets/font/flexo/FlexoW03-Bold.woff") format("woff"),url("/static/assets/font/flexo/FlexoW03-Bold.ttf") format("truetype");
}
//@font-face{
//    font-family:"FlexoW03-BoldItalic";
//    src:url("/static/assets/font/flexo/FlexoW03-BoldItalic.eot?#iefix");
//    src:url("/static/assets/font/flexo/FlexoW03-BoldItalic.eot?#iefix") format("eot"),url("/static/assets/font/flexo/FlexoW03-BoldItalic.woff2") format("woff2"),url("/static/assets/font/flexo/FlexoW03-BoldItalic.woff") format("woff"),url("/static/assets/font/flexo/FlexoW03-BoldItalic.ttf") format("truetype");
//}
//@font-face{
//    font-family:"FlexoW03-Heavy";
//    src:url("/static/assets/font/flexo/FlexoW03-Heavy.eot?#iefix");
//    src:url("/static/assets/font/flexo/FlexoW03-Heavy.eot?#iefix") format("eot"),url("/static/assets/font/flexo/FlexoW03-Heavy.woff2") format("woff2"),url("/static/assets/font/flexo/FlexoW03-Heavy.woff") format("woff"),url("/static/assets/font/flexo/FlexoW03-Heavy.ttf") format("truetype");
//}
//@font-face{
//    font-family:"FlexoW03-HeavyItalic";
//    src:url("/static/assets/font/flexo/FlexoW03-HeavyItalic.eot?#iefix");
//    src:url("/static/assets/font/flexo/FlexoW03-HeavyItalic.eot?#iefix") format("eot"),url("/static/assets/font/flexo/FlexoW03-HeavyItalic.woff2") format("woff2"),url("/static/assets/font/flexo/FlexoW03-HeavyItalic.woff") format("woff"),url("/static/assets/font/flexo/FlexoW03-HeavyItalic.ttf") format("truetype");
//}
//@font-face{
//    font-family:"FlexoW03-Black";
//    src:url("/static/assets/font/flexo/FlexoW03-Black.eot?#iefix");
//    src:url("/static/assets/font/flexo/FlexoW03-Black.eot?#iefix") format("eot"),url("/static/assets/font/flexo/FlexoW03-Black.woff2") format("woff2"),url("/static/assets/font/flexo/FlexoW03-Black.woff") format("woff"),url("/static/assets/font/flexo/FlexoW03-Black.ttf") format("truetype");
//}
//@font-face{
//    font-family:"FlexoW03-BlackItalic";
//    src:url("/static/assets/font/flexo/FlexoW03-BlackItalic.eot?#iefix");
//    src:url("/static/assets/font/flexo/FlexoW03-BlackItalic.eot?#iefix") format("eot"),url("/static/assets/font/flexo/FlexoW03-BlackItalic.woff2") format("woff2"),url("/static/assets/font/flexo/FlexoW03-BlackItalic.woff") format("woff"),url("/static/assets/font/flexo/FlexoW03-BlackItalic.ttf") format("truetype");
//}



// FONT RM
@font-face {
    font-family: "riesemueller";
    src: url("/static/assets/font/icons/riesemueller.eot");
    src: url("/static/assets/font/icons/riesemueller.eot?#iefix") format("embedded-opentype"),
    url("/static/assets/font/icons/riesemueller.woff") format("woff"),
    url("/static/assets/font/icons/riesemueller.ttf") format("truetype"),
    url("/static/assets/font/icons/riesemueller.svg#riesemueller") format("svg");
    font-weight: normal;
    font-style: normal;

}

// font-vars
$font-flexo-thin: 'FlexoW03-Thin';
$font-flexo-thin-italic: 'FlexoW03-ThinItalic';
$font-flexo-regular: 'FlexoW03-Regular';
// todo -- PLAYGROUND
$font-flexo-demi: 'FlexoW03-Demi';
$font-flexo-bold: 'FlexoW03-Bold';
$font-icons: 'riesemueller';
