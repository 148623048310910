
.m06-headline {
    nav.breadcrump {
        margin-bottom: 20px;
        @media #{$mq-large-up} {
            margin-bottom: 28px;
        }
    }

    .link-to-model-chooser {
        text-transform: uppercase;
        border-bottom: none;
        cursor: pointer;
    }

    .flag {
        font-family: $font-flexo-regular;
        font-size: 20px;
        line-height: 26px;
        color: $color-brand-green;
        text-transform: uppercase;
    }

    .module.breadcrump {
        &:before,
        &:after {
            display: none;
        }

        & + h1 {
            @media #{$mq-small-only} {
                margin-top: 0;
            }

            @media #{$mq-medium-up} {
                margin-top: 40px;
            }
        }
    }
}
