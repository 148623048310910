
// Übergreifendes Styling der Module
.module {
    position: relative;
    background-color: $color-white;
    width: 100%;

    &.use-viewport-height {
        margin: 0 auto;

        &:before, &:after {
            display: none;
        }
    }

    // Module in Modulen (meist Text) dürfen Texte an Rändern nicht abschneiden
    & > .module {
        overflow: visible;
    }
}
