
// label
.form-label {
    display: block;
    width: auto;
    max-width: 100%;
    margin: 0;
    color: $color-warm-grey;
    text-align: left;
    -webkit-touch-callout: none;
    @include no-select();

    transition: color 0.4s ease-in-out;
}

.error-text {
    color: $color-error;

    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.35s ease-in-out, opacity 0.35s ease-in-out;
}

.error + .error-text,
.error + label .error-text,
.error-text.error {
    opacity: 1;
    max-height: 220px;
}

.optional-link {
    color: $color-warm-grey;
    font-family: $font-flexo-regular;
}
