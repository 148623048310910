
.m43-engine {
    .container-headline {
        z-index: 4;
        margin-bottom: 0;
    }

    .container-products {
        margin-bottom: 0;
        z-index: 2;

        @media #{$mq-small-only} {
            margin-top: 30px;
            margin-bottom: #{40px + 10px};
        }
    }

    .container-specs-bottom {
        display: flex;
        justify-content: space-between;
    }

    .product {
        position: relative;
    }

    .container-btn-layer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .btn-open-layer-set + .btn-open-layer-set {
        margin-top: 12px;
        cursor: pointer;
    }

    .btn-external-link {
        font-family: $font-flexo-bold;
        font-size: 14px;
        line-height: 22px;

        &:before {
            content: '>';
            font-size: 14px;
            line-height: 22px;
        }
    }

    .spec {
        display: none;

        p {
            font-size: 12px;
            line-height: 18px;
        }

        @media #{$mq-medium-up} {
            display: block;
        }
    }

    .wrapper-specs {
        display: flex;
        justify-content: space-between;
    }

    &.layout_pack {
        .container-products {
            margin-bottom: calc(50px + 3.9%);
        }

        .engine {
            position: absolute;
            width: 59.6%;
            left: 0;
            bottom: 0;
            z-index: -2;
            max-width: 325.469px;
        }

        .display {
            position: absolute;
            width: 55.3%;
            left: 38.9%;
            bottom: -5.6%;
            z-index: -1;
            max-width: 300.75px;
        }

        .battery {
            width: 100%;
            z-index: -3;
            max-width: 547.125px;
        }

        @media #{$mq-medium-up} {
            .container-products {
                margin-top: 60px;
                margin-bottom: 3.6%;
            }

            .engine {
                width: 257.469px;
                left: 94.8125px;
                z-index: -2;
            }

            .display {
                width: 238.625px;
                left: 265.016px;
                bottom: -15.6094px;
                z-index: -1;
            }

            .battery {
                width: 427.031px;
                left: 94.8125px;
                z-index: -3;
            }

            .container-specs-bottom {
                position: relative;
                display: block;
            }

            .wrapper-products {
                margin-left: auto;
                margin-right: auto;
                position: relative;
                width: 565px;
            }

            .wrapper-specs {
                margin-left: auto;
                margin-right: auto;
                position: relative;
                width: 626px;
            }

            .spec {
                width: 32%;
            }

            .spec-battery {
                position: absolute;
                right: -38px;
                top: -61.8594px;
                width: 200.953px;
                transform: translateY(-100%);
            }

            .spec-display {
                margin-top: 60px;
                width: 207.641px;
                // position: absolute;
                top: 0;
                // left: 426.359px;
            }

            .spec-engine {
                margin-top: 0;
                margin-bottom: 80px;
                width: 207.641px;
            }
        }

        @media #{$mq-large-up} {
            .container-products {
                margin-top: 60px;
                margin-bottom: 0;
            }

            .wrapper-specs {
                width: 796px;
            }

            .engine {
                position: relative;
                width: 325.469px;
                left: 0;
            }

            .display {
                position: absolute;
                width: 300.75px;
                left: calc(352.672px - 138.422px);
                bottom: -16px;
            }

            .battery {
                position: absolute;
                width: 547.125px;
                left: 0;
                bottom: 0;
            }

            .spec {
                width: 207.641px;
            }

            .spec-battery {
                top: -189.469px;
                right: auto;
                left: 480px;
                width: 300px;
                transform: translateY(0);
            }

            .spec-display {
                margin-top: 60px;
                width: 207.641px;
                // position: absolute;
                top: 0;
                margin-right: 20px;
                // left: 571.359px;
            }

            .spec-engine {
                margin-top: 0;
                margin-bottom: 80px;
                width: 207.641px;
            }
        }
    }

    &.layout_rack {
        .engine {
            position: absolute;
            width: 47.5%;
            left: 10%;
            bottom: 7.8%;
        }

        .display {
            position: absolute;
            width: 44%;
            left: 0;
            bottom: 0;
        }

        .battery {
            width: 80%;
            left: 20%;
        }

        @media #{$mq-medium-up} {
            .container-products {
                margin-top: 60px;
                margin-bottom: 84.9688px;
            }

            .wrapper-products {
                margin-left: auto;
                margin-right: auto;
                position: relative;
                width: 591px;
            }

            .wrapper-specs {
                margin-left: auto;
                margin-right: auto;
                position: relative;
                width: 647px;
            }

            .engine {
                position: relative;
                width: 282.078px;
                left: 105.25px;
                bottom: auto;
            }

            .display {
                position: absolute;
                width: 261.438px;
                left: 46.0938px;
                bottom: -26.8281px;
            }

            .battery {
                position: absolute;
                width: 467.828px;
                left: 166.484px;
                bottom: -26.8281px;
            }

            .spec {
                width: 220.156px;
            }

            .spec-engine {
                position: absolute;
                right: -100px;
                top: -60.0781px;
            }

            .spec-battery {
                // position: absolute;
                top: 0;
                // left: 508px;
                margin-right: -80px;
            }

            .spec-display {
                width: 200px;
            }
        }

        @media #{$mq-large-up} {
            .container-products {
                margin-top: 60px;
                margin-bottom: 62.2031px;
            }

            .wrapper-products {
                width: 684px;
            }

            .engine {
                width: 329.594px;
                left: calc(140.078px - 70.0312px);
            }

            .display {
                width: 296.625px;
                left: 0;
                bottom: -32.0469px;
            }

            .battery {
                width: 543.828px;
                left: calc(208.469px - 70.0312px);
                bottom: -32.0469px;
            }

            .spec {
                width: 207.641px;
            }

            .spec-engine {
                right: -42.7656px;
                top: -27.1406px;
            }

            .spec-display {
                margin-left: -70.64062px;
                margin-top: 10.84375px;
            }

            .spec-battery {
                top: 0;
                margin-right: 10px;
                // left: 450.828px;
            }
        }
    }

    &.layout_tube {
        .container-products {
            margin-bottom: calc(50px + 4.2%);
        }

        .engine {
            position: absolute;
            width: 53.9%;
            left: 0;
            bottom: 0;
            z-index: -2;
        }

        .display {
            position: absolute;
            width: 49.6%;
            left: 37%;
            bottom: -6.2%;
            z-index: -1;
        }

        .battery {
            width: 90%;
            left: 10%;
            z-index: -3;
        }

        @media #{$mq-medium-up} {
            .container-products {
                margin-top: 60px;
                margin-bottom: 41.4688px;
            }

            .wrapper-products {
                margin-left: auto;
                margin-right: auto;
                position: relative;
                width: 565px;
            }

            .wrapper-specs {
                margin-left: auto;
                margin-right: auto;
                position: relative;
                width: 572px;
            }

            .engine {
                width: 232.766px;
                left: 64.4062px;
            }

            .display {
                width: 214.688px;
                left: 223.734px;
                bottom: -15.5938px;
            }

            .battery {
                width: 389.844px;
                left: 106.219px;
            }

            .spec {
                width: 200.312px;
            }

            .spec-battery {
                position: absolute;
                right: 0;
                top: -20.9688px;
                width: 180.797px;
            }

            .spec-display {
                width: 200.312px;
                margin-top: 11px;
                margin-right: -10px;
            }
        }

        @media #{$mq-large-up} {
            .container-products {
                margin-top: 40px;
                margin-bottom: 1.2%;
            }

            .wrapper-specs {
                width: 796px;
            }

            .wrapper-products {
                width: 610px;
            }

            .engine {
                width: 323.828px;
                left: 0;
            }

            .display {
                width: 299.922px;
                left: calc(329.594px - 108.766px);
                bottom: -22.5px;
            }

            .battery {
                width: 543.828px;
                left: calc(168.906px - 108.766px);
            }

            .spec {
                width: 207.641px;
            }

            .spec-battery {
                right: -45px;
                top: 35.6406px;
            }

            .spec-engine {
                margin-left: 12.6094px;
                width: 170px;
            }

            .spec-display {
                margin-top: 30px;
                margin-right: 37px;
            }
        }
    }
}
