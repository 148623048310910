.m28-special-button {
    padding: 25px 0 50px 0;
    overflow: hidden;

    img {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

    figure {
        width: calc(100% + 40px);
        height: 210px;

        @media #{$mq-medium-up} {
            width: 100%;
            height: auto;
            min-height: 100%;
        }
    }

    .grid > div:first-child {
        margin-bottom: 0;
    }

    .text-spaces {
        @media #{$mq-small-only} {
            margin-top: 25px;
        }
    }

    .wrapper-button {
        margin-top: 15px;

        @media #{$mq-small-only} {
            margin-top: 20px;
        }
    }

    .special-button-image-wrapper {
        position: relative!important;
        display: block;
        top: -25px;

        @media #{$mq-medium-only} {
            height: calc(100% + 90px);
        }

        @media #{$mq-small-only} {
            width: 100%;
            margin-left: -5px;
        }

        @media #{$mq-medium-up} {
            position: absolute!important;
            top: -55px;
            padding: 0;
        }

        @media #{$mq-large-up} {
            height: calc(100% + 85px);
        }
    }

    @media #{$mq-medium-up} {
        padding: 55px 0 55px 0;
    }
}