
.container-btn-load-more {

    // prevent overflow hidden of parent container to cut circle
    @media #{$mq-small-only} {
        padding-bottom: 5px;
    }

    .grid & {
        margin-top: 20px;
    }
}