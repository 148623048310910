button {
    background-color: transparent;
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
    border: none;

    &:focus {
        outline: 0;
        outline: none;
    }
}

.btn-base + .btn-base {
    margin-top: 10px;

    @media #{$mq-small-up} {
        margin-top: 0;
        margin-left: 10px;
    }
}

/*
 BASE BUTTON
*/
a.btn-base,
.btn-base {
    $btn-background: var(#{--btn-base-background-color});
    $btn-color: var(#{--btn-base-color});
    $btn-background-hover: var(#{--btn-base-background-color-hover});
    $btn-color-hover: var(#{--btn-base-color-hover});

    display: block;
    position: relative;
    width: 100%;
    height: 45px;
    cursor: pointer;
    margin: 0 auto;
    z-index: $z-index-button;
    text-transform: uppercase;
    text-align: center;
    font-family: $font-flexo-regular;

    // todo - playground
    line-height: 45px;
    background: $btn-background;
    color: $btn-color;

    @media #{$mq-small-up} {
        display: inline-block;
        width: auto;
        margin: 0;
        // brsch: weiter unten definiert
        // padding: 0 #{$gap-smallest-part * 2};
    }

    // todo - playground
    .no-touch & {
        transition: color 0.25s ease-out, background-color 0.25s ease-out;

        &:focus,
        &:hover {
            background-color: $btn-background-hover;
            color: $btn-color-hover;
            border: none;
        }
    }

    &:focus {
        outline: 0;
        outline: none;
    }
}

// p, a style muss überschrieben werden
a.btn-base {
    top: 0;
}

a.btn-base,
button.btn-base {
    border: none;
    padding: 0 #{$gap-smallest-part * 2};
}
