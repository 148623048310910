
// override regular custom-selects
// entire hard-copy is to "expensive"
.custom-select,
.input-search {
    &.medium-select,
    &.large-select {

        width: 100%;

        input,
        select {
            border: 2px solid $color-black;
        }

        .rb__arrow-down {
            position: absolute;
            right: 20px;
            top: 50%;
            pointer-events: none;
            width: 17px;
            transform: translate3d(0, -50%, 0);
            transition: transform 0.3s ease-in-out;

            @media #{$mq-medium-up} {
                width: 20px;
            }
        }

        input,
        select,
        .option {
            padding-top: 0;
            padding-bottom: 0;
            font-family: $font-flexo-thin;

            // force ellipsis (truncate text with "...")
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .option {
            &:hover {
                .no-touch & {
                    font-family: $font-flexo-demi;
                    background-color: $color-white;
                }
            }

            // selection by keyboard
            &.hover {
                font-family: $font-flexo-demi;
                background-color: $color-light-grey;
            }
        }

        .container-options {
            border-color: $color-black;
            border-width: 2px;
        }

        .icon-arrow-down {
            top: 50%;
        }

        &.expanded {
            input,
            select {
                font-family: $font-flexo-demi;
                border-color: $color-white;
                background-color: $color-white;
            }

            .rb__arrow-down {
                transform: translate3d(0, -50%, 0) rotate(180deg);
            }
        }

        &.custom-select--upside-down {
            .container-options {
                padding-top: 0;
            }
        }
    }
}
