$select-colors--diameter-dot: 24px;
$select-colors--diameter-ring: 44px;
$select-colors--thickness-ring: 2px;
$select-colors--diameter-total: $select-colors--diameter-ring + $select-colors--thickness-ring * 2;

.select-colors {
    position: relative;
    padding: 0 20px;
}

.select-colors__items {
    position: relative;
    left: 50%;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + (100% - (#{$select-colors--diameter-total} * 4)) / 6 * 2);
    transform: translate(-50%);
}

.select-colors__item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 75px;
}

.select-colors__item--disabled {
    pointer-events: none;

    .select-colors__label {
        opacity: 0.3;
    }

    &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 2px;
        height: #{$select-colors--diameter-dot + 12px};
        background-color: $color-light-grey;
        border: 2px solid $color-white;
        transform: translate(-50%, -50%) rotate(45deg);
        z-index: 2;
    }
}

.select-colors__label {
    display: block;
    position: relative;
    height: $select-colors--diameter-dot;
    width: $select-colors--diameter-dot;
    border-radius: 50%;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        height: $select-colors--diameter-ring;
        width: $select-colors--diameter-ring;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        border: $select-colors--thickness-ring solid $color-black;
        opacity: 0;
        transition: opacity 0.2s ease-out;
    }

    &:hover {
        &:after {
            opacity: 0.2;
        }
    }

    // special cases for certain color-names
    &[data-color-name="white"] {
        border: 1px solid $color-light-grey;
    }
}

.select-colors__dot {
    height: $select-colors--diameter-dot;
    width: $select-colors--diameter-dot;
    border-radius: 50%;
}

.select-colors__tooltip {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
}

.select-colors__input {
    padding: 0;
    @include hide-input-for-custom-checkbox();

    &:checked {
        & + .select-colors__label {
            &:after {
                opacity: 1;
            }
        }
    }
}

