.retailer-detail {
    .icon-only {
        &:before {
            display: block;
            font-family: $font-icons;
            -webkit-font-smoothing: antialiased;
            font-size: 36px;
            line-height: 40px;
            color: lightgray;
        }
    }

    .retailer-info-maps-container {
        background-color: $color-light-grey;
        height: 500px;
        margin-top: 30px;
    }

    table {
        width: 100%;

        .opening-day {
            vertical-align: top;
            padding-right: 30px;
        }

        .opening-day:nth-child(2) {
            padding-right: 0;
        }
    }

    .text-wrapper {
        table {
            width: 100%;

            tr {
                vertical-align: top;
            }

            td {
                line-height: 1.5em;
            }

            td:first-child {
                padding-right: 0.6em;
            }
        }

        .openings br {
            @media #{$mq-medium-up} {
                display: none;
            }
        }
    }

    .btn-icon {
        display: flex;
        margin-left: 10px;
        padding: 0;
        width: 24px;
        height: 24px;

        svg {
            z-index: -1;
            width: 24px;
            height: 24px;
        }
    }

    .retailer-detail__info {

        .grid-item {
            display: flex;

            + .grid-item {
                margin-top: 70px;
            }
        }

        @media #{$mq-small-only} {
            > div > .grid-item {
                margin-top: 55px;
            }

            > div:first-child > .grid-item:first-child {
                margin-top: 0;
            }
        }
    }


    .column-icon {
        margin-right: 20px;
        flex: 0 0 35px;
    }

    .link-icon {
        text-transform: uppercase;

        a {
            display: flex;
            transition: none;

            span {
                display: flex;
                align-items: center;
                margin-left: 10px;
                border: 2px solid;
                border-radius: 50%;
                height: 28px;
                width: 28px;

                svg {
                    width: 12px;
                    height: 12px;
                    margin-left: 6px;
                }

            }
        }

        a:hover {
            color: initial;
            border-bottom: none;
        }
    }

    .column-icon {
        svg ellipse, polyline, line, path {
            stroke: lightgrey;
        }
    }
}
