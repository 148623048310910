// Base Variables
$padding: 20px;
$margin: 20px;

// Button Variables
$button-bg: $color-dark-grey;
$button-shadow: rgba(0, 0, 0, 0.16);
$button-radius: 50%;
$button-size: 70px;
$button-position: $margin;

// Chat Variables
$chat-position-right: $margin;
$chat-position-bottom: $margin + $button-size + $padding;

// Icon Variables
$icon-size: $button-size / 2;
$icon-color: $color-white;

 #intercom-container {
    .intercom-app {
        .intercom-messenger-frame {
            /**
             * The position of the Chatbot container is initially set in the Intercom settings (see link).
             * These settings are overridden here as a precaution. Currently, the overridden value is the same as the one set in the settings.
             * Link: https://app.eu.intercom.com/a/apps/ztbo1gw2/settings/channels/messenger/web?section=launcher&tab=styling
             */
            bottom: $chat-position-bottom !important;
            right: $chat-position-right !important;
        }
    }
 }

#intercom-chatbot-button {
    position: fixed;
    z-index: $z-index-intercom-launcher;
    padding: 0;
    margin: 0;
    border: none;
    bottom: $button-position;
    right: $button-position;
    width: $button-size;
    height: $button-size;
    border-radius: $button-radius;
    background: $button-bg;
    cursor: pointer;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 $button-shadow;
    transition: transform 250ms cubic-bezier(0.33, 0.00, 0.00, 1.00), opacity 500ms cubic-bezier(0.33, 0.00, 0.00, 1.00);
    box-sizing: content-box;
    transform: scale(0);
    opacity: 0;

    &.is-visible {
        transform: scale(1);
        opacity: 1;
    }

    &:hover {
        transform: scale(1.1);
    }

    .open-icon, .close-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: $button-size;
        height: $button-size;
        transition:
            transform 200ms linear,
            opacity 120ms linear;

        svg {
            width: $icon-size;
            height: $icon-size;

            path {
                stroke: $icon-color;
            }
        }
    }

    .open-icon {
        opacity: 1;
        transform: rotate(0deg) scale(1);

        svg {
            path {
                fill: $icon-color;
                stroke: none;
            }
        }
    }

    .close-icon {
        opacity: 0;
        transform: rotate(90deg) scale(0);
    }

    &.is-toggled .open-icon {
        opacity: 0;
        transform: rotate(-90deg) scale(0);
    }

    &.is-toggled .close-icon {
        opacity: 1;
        transform: rotate(0deg) scale(1);
    }
}
