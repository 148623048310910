.m53-myrm-services {
    padding: 20px 0;

    .grid > div {
        margin-bottom: 0;
    }

    .button-circle {
        &.spacing {
            margin-top: 30px;
        }
    }

    // Todo Hack for color sections
    [data-state="form-state-success"],
    [data-state="form-state-error"] {
        .module-hide {
            &:before {
                content: '';
                margin-top: 400px;
                display: block;
                width: 100%;
                height: $padding-modules-mq-small;

                @media #{$mq-medium-up} {
                    height: $padding-modules-mq-medium;
                }

                @media #{$mq-large-up} {
                    height: $padding-modules-mq-large;
                }
            }
            &:after {
                content: '';
                display: block;
                width: 100%;
                height: $padding-modules-mq-small;

                @media #{$mq-medium-up} {
                    height: $padding-modules-mq-medium;
                }

                @media #{$mq-large-up} {
                    height: $padding-modules-mq-large;
                }
            }
        }
    }

    form .module {
        &:first-child {
            &:before {
                display: block;
                width: 100%;
                height: $padding-modules-mq-small;

                @media #{$mq-medium-up} {
                    height: $padding-modules-mq-medium*2;
                }

                @media #{$mq-large-up} {
                    height: $padding-modules-mq-large*2;
                }
            }

            &:after {
                display: block;
                width: 100%;
                height: $padding-modules-mq-small/2 + 25px;

                @media #{$mq-medium-up} {
                    height: $padding-modules-mq-medium/2 + 20;
                }

                @media #{$mq-large-up} {
                    height: $padding-modules-mq-large/2 + 20;
                }
            }
        }

        &:last-child {
            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 1px;

                @media #{$mq-medium-up} {
                    height: $padding-modules-mq-medium/2 - 40px;
                }

                @media #{$mq-large-up} {
                    height: $padding-modules-mq-large/2 - 40px;
                }
            }
        }
    }


    .module.background-nearlywhite {
        background-color: $color-nearly-white;
    }

    [data-state="form-state-booting"],
    [data-state="form-state-edit"],
    [data-state="form-state-submit"] {
        .module.background-nearlywhite:after {
            background-color: $color-nearly-white;
        }
    }

    &.full-width-wrapper,
    .full-width-wrapper {
        background-color: $color-nearly-white;
        // margin-top: 50px;
    }

    // only for service_teaser_active
    &.full-width-teaser-wrapper {
        background-color: $color-nearly-white;
        padding-top: 25px;
    }

    figure {
        @include relative-height(3/2);
    }

    // TODO  globale Lösung finden, damit feedback screens ohne die Lücke dargestellt werden können
    .m24-forms {
        .icon-check {
            display: none;
        }

        &[data-state="form-state-error"],
        &[data-state="form-state-success"]{
            form {
                display: none;
            }
        }


        &[data-state="form-state-success"] {
            .myrm-info-box {
                display: none;
            }
        }

        &[data-state="form-state-success"]{
            .icon-exclamation-mark {
                display: none;
            }

            .icon-check {
                display: block;
            }
        }

        &[data-state="form-state-error"] form + .feedback.success + .feedback.error,
        &[data-state="form-state-error"] form + .container-center .feedback.error,
        &[data-state="form-state-success"] form + .feedback.success,
        &[data-state="form-state-success"] form + .container-center .feedback.success {
            position: relative;

            .grid>* {
                margin-top: 0;
            }
        }

        .icon-check {
            svg {
                fill: $color-light-grey;
            }
        }

        &[data-state="form-state-error"] form + .feedback.success + .feedback.error,
        &[data-state="form-state-error"] form + .container-center .feedback.error,
        &[data-state="form-state-success"] form + .container-center .feedback.success {
            margin-top: 60px;
        }
    }

    .wrapper-form-fields {
        .grid > *:first-child { margin-top: 0; }

        left: 0;
        padding-right: 15px;
        padding-bottom: 15px;
        background-color: $color-white;

        @media #{$mq-medium-up} {
            padding-right: 20px;
            padding-bottom: 20px;
        }

        @media #{$mq-large-up} {
            padding-right: 25px;
            padding-bottom: 25px;
        }

        .text-spaces.grid {
            margin-left: 0;
            left: 0px;
        }
    }

    .wrapper-form-fields.grid {
        margin-top: 30px;
        width: 100%;

        + .wrapper-form-fields.grid {
            margin-top: 20px;
        }
    }

    .wrapper-checkbox label span:first-child,
    .wrapper-radiobutton label span:first-child {
        margin-bottom: 10px;
    }
}
