
.m14-bike-header {
    // figure
    .mask-resize .content {
        // overflow + Eigenschaften des child-imgs in _figure.scss ausgelagert
        @include relative-height(3/2);

        div,
        figure {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    .container-color-chooser {
        $width: 130px;
        position: relative;
        width: $width;
        height: $width;
        margin: 0 calc(50% - 20px); //wegen left -40px
        left: -40px;
        opacity: 0;

        @media #{$mq-large-up} {
            position: absolute;
            left: #{$width * -0.75};
            top: 20px;
            margin: 0;
        }

        .color-chooser {
            transition: transform 0.35s ease-in-out;
            transform: translateZ(0) scale(0.5);

            &.detail {
                position: absolute;
                left: 78%;
                top: 0;

                @media #{$mq-large-up} {
                    left: auto;
                    right: -54%;
                    top: -54%;
                }
            }

            &.focus {
                transform: translateZ(0) scale(1);
            }
        }

        .indicator, .icon-bike, .icon-pedal {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 40px;
            height: 40px;
            margin: -20px 0 0 -20px;
            pointer-events: none;
        }

        .indicator {
            background-color: $color-white;
            border: 1px solid $color-warm-grey;
            border-radius: 50% 50% 50% 0;
        }

        .icon-bike, .icon-pedal {
            &:before {
                font-family: $font-icons;
                -webkit-font-smoothing: antialiased;
                text-transform: none;
                text-align: center;
                position: absolute;
                font-size: 18px;
                line-height: 40px;
                width: 100%;
                height: 100%;
            }
        }

        svg {
            display: block;
            cursor: pointer;
        }

        circle {
            stroke: $color-ultra-light-grey;
            stroke-width: 1px;
            stroke-linecap: butt;
        }
    }
    .container-color-chooser.extended-chooser {
        margin: 0 auto;
    }

    .disclaimer-bike {
        width: 100%;
        height: 54px;
        color: $color-medium-grey;
        margin: $gap-smallest-part 0 0 0;
        padding-top: 6px;
        text-align: center;

        @media #{$mq-large-up} {
            width: 80%;
            margin: 0 auto;
        }
    }
}
