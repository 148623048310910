
$product-overview-filter-bar--height: 50px;

.filter-bar {
    position: relative;
    height: $product-overview-filter-bar--height;
    background-color: $color-white;
    z-index: $z-index-product-overview-filter-bar;
}

.filter-bar__inner {
    $duration: 0.0s;

    position: relative;
    height: $product-overview-filter-bar--height;
    width: 100%;
    transition: color $duration ease-out, background-color $duration ease-out;
}

.filter-bar__inner--sticky {
    $duration: 0.3s;

    position: fixed;
    top: $height-top-bar;
    background-color: $color-black;
    left: 0;
    transition: color $duration ease-out, background-color $duration ease-out;

    .dropdown__label,
    .filter-bar__count-results,
    .filter-bar__button-open-filters {
        color: $color-white;
    }

    .filter-bar__button-open-filters svg {
        fill: $color-white;
    }

    .dropdown__arrow:not(.dropdown__arrow__back) {
        stroke: $color-white;
    }

    .button-switch__line {
        background-color: $color-white;
    }

    .button-switch {
        svg {
            fill: $color-white;
        }
    }

    .dropdown__icon-mobile {
        svg {
            fill: $color-white;
        }
    }
}

.filter-bar__layout {
    display: flex;
    align-items: center;
    height: $product-overview-filter-bar--height;
}

.filter-bar__wrapper-sort {
    position: relative;
    width: 80%;

    @media #{$mq-medium-up} {
        max-width: 260px;
    }
}

.filter-bar__wrapper-sort + .filter-bar__count-results {
    @media #{$mq-medium-up} {
        margin-left: 50px;
    }
}

.filter-bar__count-results {
    // auf anforderung seitens rm vorerst ausgeblendet
    display: none;

    //margin-left:  auto;
    //font-family: $font-flexo-regular;
    //
    //@media #{$mq-small-only} {
    //    position: absolute;
    //    right: 75px;
    //    top: 50%;
    //    transform: translateY(-50%);
    //}
}

.filter-bar__button-open-filters {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: $font-flexo-demi;
    // margin-left: 25px;
    margin-left:  auto;

    svg {
        width: 24px;
        height: 24px;
        fill: $color-black;

        @media #{$mq-medium-up} {
            margin-left: 10px;
        }
    }

    @media #{$mq-small-only} {
        span {
            display: none;
        }
    }
}

.filter-bar__condition-toggle {
    display: flex;
    transition: opacity 0.2s ease-in-out;

    .pill + .pill {
        margin-left: 10px;
    }
}

.filter-bar__inner--sticky .filter-bar__condition-toggle {
    opacity: 0;
}

.filter-bar__condition-toggle--mobile {
    flex-wrap: wrap;
    padding-bottom: 20px;

    @media #{$mq-medium-up} {
        display: none;
    }

    .pill {
        width: 100%;
        justify-content: center;
    }

    .pill + .pill {
        margin: 10px 0 0 0;
    }
}

.filter-bar__condition-toggle--desktop {
    @media #{$mq-medium-down} {
        display: none;
    }

    margin-left: 25px;
}

.filter-bar__condition-toggle--hidden {
    opacity: 0 !important;
    pointer-events: none;
}
