
// wie präzise muss selektor sein?
// .cta-scroll[data-partial="cta-scroll-1"]
.cta-scroll {
    display: none;
    $diameter: 60px;

    @media #{$mq-medium-up} {
        display: block;
        position: absolute;
        left: 50%;
        bottom: 130px;
        cursor: pointer;
        width: $diameter;
        height: $diameter;
        transform: translateX(-50%);
    }

    &:hover {
        .no-touch & {
            .circle {
                border-color: $color-brand-green;
            }
            span {
                color: $color-brand-green;
            }
        }
    }

    .mask-circle, span, .circle {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .circle {
        border: 3px solid $color-white;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        transition: border-color 0.25s ease-out;
    }

    .mask-circle {
        overflow: hidden;
        border-radius: 50%;
    }

    span {
        display: block;
        top: -100%;
        font-family: $font-icons;
        -webkit-font-smoothing: antialiased;
        text-transform: none;
        text-align: center;
        color: $color-white;
        font-size: 18px;
        line-height: $diameter;
        pointer-events: none;
        @include no-select();
        transition: color 0.25s ease-out;
    }
}
