.m52-myrm-mydata {
    &.full-width-wrapper {
        padding-top: var(--grid-gutter-vertical);
        padding-bottom: var(--grid-gutter-vertical);
        background-color: $color-nearly-white;
    }

    .wrapper-form-fields {
        left: 0;
        padding-right: var(--grid-gutter-horizontal);
        padding-top: var(--grid-gutter-vertical);
        background-color: $color-white;

        &.grid {
            width: 100%;
            margin-bottom: 0;
        }
    }

    .wrapper-form-fields + .bottom-bar {
        &.extra-margin {
            margin: 50px -25px 25px 0;
        }
    }

    // TODO wie kann das negative margin verhindert werden?
    form > .grid {
        margin-top: 0;
    }

    //// TODO  globale Lösung finden, damit feedback screens ohne die Lücke dargestellt werden können
    .m24-forms {
        &[data-state="form-state-error"],
        &[data-state="form-state-success"]{
            .fake-form,
            form {
                display: none;
            }
        }

        &[data-state="form-state-error"] form + .feedback.success + .feedback.error,
        &[data-state="form-state-success"] form + .feedback.success,
        &[data-state="form-state-error"] form + .feedback.error,
        &[data-state="form-state-error"] form + .container-center .feedback.error,
        &[data-state="form-state-success"] form + .container-center .feedback.success {

            position: relative;
            left: 0;
            width: 100%;

            @media #{$mq-medium-up} {
                width: calc(100% + 25px);
            }

            .background-wrapper {
                padding: 25px;
                background: $color-white;
            }

            h2 {
                @extend h4;
            }

            p:empty {
                margin-top: 0;
            }

            .wrapper-button {
                width: 100%;
                justify-content: flex-end;
            }

            .grid > *:first-child {
                .custom-inputs & {
                    margin-top: 0;
                }
            }
        }
    }

    // Payment
    .payment-method-toggle {
        padding: 0;
        margin-bottom: 0!important;

        > .grid > *:last-child {
            margin-bottom: 0;
        }
    }
}

.m52-myrm-mydata--newsletter {

    .wrapper-form-fields {
        > p {
            padding: 0;
            margin-top: 0;
        }
    }

    .feedback {
        width: 100%;
        margin-bottom: 80px;

        .grid {
            margin-top: 0;

            p {
                margin-top: 15px;
            }
        }

        @media #{$mq-medium-up} {
            margin-bottom: 110px;
        }
    }

    .wrapper-button {
        margin-top: 30px;
    }


    &.active {
        .show-inactive {
            display: none;
        }
        .show-pending {
            display: none;
        }
        .show-active {
            display: block;

            &.wrapper-button {
                display: flex;
            }
        }
    }

    &.inactive {
        .show-active {
            display: none;
        }
        .show-pending {
            display: none;
        }
        .show-inactive {
            display: block;

            &.wrapper-button {
                display: flex;
            }
        }
    }

    &.pending {
        .show-active {
            display: none;
        }
        .show-inactive {
            display: none;
        }
        .show-pending {
            display: block;

            &.wrapper-button {
                display: flex;
            }
        }
    }
}
