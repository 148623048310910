.myrm-services {

    &.full-width-wrapper {
        padding-top: 30px;
        background-color: $color-nearly-white;
    }

    &.myrm-services__content {
        flex: 1 1 auto;
    }

    a {
        pointer-events: auto;
    }

    .accordion {
        .bar,
        .content {
            padding: $gap-2-medium;

            &.accordion-body--big-side-padding {
                @media #{$mq-medium-up} {
                    padding-left: $background-circle-large + $gap-2-small + $gap-2-medium;
                    padding-right: $background-circle-large + $gap-2-small+ $gap-2-medium;
                }
            }

            h4 {
                margin-bottom: 17px;
            }
        }

        li:nth-child(n+2) {
            margin-top: 20px;

            @media #{$mq-medium-up} {
                margin-top: 30px;
            }

            &.service--package {
                margin-top: 46px;

                @media #{$mq-medium-up} {
                    margin-top: 56px;
                }
            }
        }

        .bar__content--top {
            align-items: flex-start;

            @media #{$mq-small-only} {
                flex-direction: column;
            }
        }

        .label {
            &.left {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: auto;
                font-family: $font-flexo-demi;

                @media #{$mq-small-only} {
                    order: 1;

                    h2 {
                        margin: 15px 0 5px;
                    }
                }
            }

            &.right {
                max-width: none;

                @media #{$mq-small-only} {
                    order: 0;
                    align-self: flex-end;
                }
            }
        }

        .label__for {
            font-family: $font-flexo-regular;
            color: $color-warm-grey;

            @media #{$mq-small-only} {
                margin-bottom: 3px;
            }
        }

        section > .content {
            &.accordion-body--white {
                padding-bottom: 30px;
            }

            &.accordion-body--black {
                padding-top: 30px;
                padding-bottom: 15px;
            }
        }

        & > li,
        & > .cms-plugin > li,
        & > .container-li > li {
            &.expanded {
                .bar {
                    background-color: $color-white!important;
                }
            }

            &:hover {
                .no-touch & {
                    .bar {
                        background-color: $color-white!important;
                    }
                    .accordion__label-container {
                        color: $color-medium-grey;

                        .uppercase {
                            color: $color-medium-grey;
                        }
                    }

                    .button-arrow:before {
                        color: $color-medium-grey;
                    }
                }
            }
        }

        .accordion__label-container {
            display: flex;
            flex-direction: row;
            transition: color 0.25s ease-out;

            .uppercase {
                transition: color 0.25s ease-out;
            }

            .button-arrow {
                display: flex;
                justify-content: center;
                flex-direction: column;
                height: auto;
                padding-left: $gap-1-small;

                &:before {
                    transition: rotate(180deg), color 0.25s ease-out;
                }
            }
        }


        .button-underline {
            @media #{$mq-medium-up} {
                width: 300px;
            }

            .button-label {
                flex: 1 1 auto;
                text-align: left;
                text-align-last: left;
            }
        }

        .button-disabled {
            .circle {
                display: block;
            }
        }

        .bottom-content--first {
            flex: 1 0 100%;
            order: 0;
            text-align: right;
            text-align-last: right;

            @media #{$mq-medium-up} {
                order: 0;
            }
        }

        .bottom-content--right {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;
            order: 1;
            text-align: right;
            text-align-last: right;
            grid-column-start: 1;
            grid-column-end: 3;
            padding-right: ($min-height-bar / 2) + $gap-1-small;

            @media #{$mq-medium-up} {
                width: auto;
                order: 0;
                grid-column-start: 3;
            }
        }

        .left__order-label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: self-start;
        }

        .link-container {
            margin-top: 40px;

            @media #{$mq-medium-up}{
                width: 300px;
                margin: 80px -80px 0 auto;
            }
        }
    }

    &.extra-padding--top {
        padding-top: 50px;
    }

    &.extra-padding--bottom {
        padding-bottom: 50px;
    }

    .myrm_no-service-booked {
        @media #{$mq-medium-up}{
            padding-top: 15px;
        }

        .button-circle {
            margin-top: 30px;
            float: right;
        }
    }

    .service--package {
        position: relative;
        z-index: 1;

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            transform: translate3d(10px, -10px, 0);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $color-ultra-light-grey;
            z-index: -1;

            transition: transform .2s ease-in;

            @media #{$mq-medium-up} {
                transform: translate3d(18px, -18px, 0);
            }
        }

        &:after {
            background: $color-light-grey;
            transform: translate3d(20px, -20px, 0);
            z-index: -2;

            @media #{$mq-medium-up} {
                transform: translate3d(36px, -36px, 0);
            }
        }

        &.expanded {
            &:before,
            &:after {
                transform: translate3d(0, 0, 0);
            }
        }
    }

    .uppercase {
        font-weight: 100;
    }
}
