.m56-product-detail {
    .product-detail {

        &__checkout-total {
            margin-left: 10px;
            margin-bottom: 0;
            flex: 1 1 auto;
        }

        &__checkout-tax {
            line-height: 1;
            align-self: flex-end;
        }

        // order mobile
        @media #{$mq-small-only} {
            &__title-container { order: 1; }
            &__image-container { order: 2; }
            &__checkout-container { order: 3; }
            &__variants-container { order: 4; }
        }

    }

    &.product-detail--no-image {
        .product-detail {

            > .grid > * {
                width: 100%;
            }

            &__bikeinfo {
                margin: 0;
                margin-top: 20px;
            }

            &__variants {
                margin-bottom: 0;
            }

            &__content {
                @media #{$mq-medium-up} {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    //align-items: flex-end;

                    > div {
                        flex: 0 0 50%;

                        &:nth-child(2) {
                            align-self: flex-end;
                        }
                    }
                }
            }

            &__checkout {
                margin-top: 30px;

                @media #{$mq-medium-up} {
                    margin-top: 0;
                }

                &-tax,
                &-total,
                &-uvp {
                    text-align: left;
                    margin-left: 0;
                }
            }
        }
    }

    .product-detail {

        // Überschreiben des Globalen Select Form Label -> hate hate hate // todo make it global again...
        .form-label {
            .custom-inputs & {
                font-size: 14px;
                color: $color-black;
            }
        }

        &__image {
            .unavailable {
                opacity: .35!important;
            }
        }

        &__title-container {
            @media #{$mq-small-only} {
                margin-bottom: 60px;
            }
        }

        &__description {
            display: flex;
            flex-direction: column;

            @media #{$mq-large-up} {
                flex-direction: row;
            }

            > div {
                display: flex;
                align-items: center;

                & + div {
                    margin-top: 5px;

                    @media #{$mq-large-up} {
                        margin-left: 7.5px;
                        margin-top: auto;

                        &::before {
                            content: "|";
                            margin-right: 7.5px;
                        }
                    }
                }
            }

            .btn-open-layer {
                position: relative;
                top: 0;
                margin-left: 5px;
                display: flex;
                align-items: center;

                svg {
                    height: 20px;
                    width: 20px;

                    * {
                        fill: $color-black;
                        stroke: none;
                    }
                }
            }
        }

        &__gallery {
            .slider-rebrush {
                .owl-item .slider-rebrush__slide {
                    .slide {
                        background-color: $color-nearly-white;
                    }
                }

                &.single-image .owl-stage {
                    padding-bottom: 0;
                }
            }
        }

        &__bikeinfo {
            display: flex;
            align-items: flex-start;

            margin-left: #{$padding-container-center-small};

            @media #{$mq-medium-up} {
                margin-left: #{$padding-container-center-medium};
            }

            @media #{$mq-large-up} {
                margin-left: #{$padding-container-center-large};
            }

            margin-top: 20px;

            &.hidden {
                display: none;
            }

            &-icon {
                flex: 0 0 20px;
                width: 20px;
                height: 20px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
                font-size: 12px;

                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        &__variants {
            display: flex;
            flex-direction: column;

            .radio-pill__label {
                cursor: default;
            }
        }

        &__variant {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            .radio-pill, .pill {
                label {
                    cursor: pointer;
                }

                &--selected label {
                    cursor: default;
                }

                &.pill--preview {
                    cursor: default;
                }
            }
        }

        &__variant--framecolor {
            order: -1
        }

        &__combinables {
            &:not(:empty) {
                margin-top: 50px;
            }
        }

        &__combinable {
            margin-bottom: 20px;

            &-checkbox {

                .wrapper-checkbox label {
                    padding-left: 0;
                    padding-right: 36px;
                }

                .wrapper-checkbox label:before {
                    left: auto;
                    right: 0;
                }

                .wrapper-checkbox label:after {
                    left: auto;
                    right: 2px;
                }

                .wrapper-checkbox .circle-focus {
                    transform: translate(50%,-50%);
                    left: auto;
                    right: 6px;
                }
            }

            &-more {
                font-family: $font-flexo-demi;
                text-transform: uppercase;
            }

            &-detail {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;

                margin-top: 6px;
            }

                &-price {
                    font-family: $font-flexo-demi;
                }
        }

        &__checkout {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            &-summary {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;

                > * {
                    flex: 1 1 100%;
                    text-align: right;
                    margin-bottom: 7px;
                }
            }

                &-tax {
                    align-self: center;
                    margin-bottom: 25px;
                }

                &-total span {
                    display: inline-block;
                    height: 25px;
                    padding: 0 10px;
                    color: $color-white;
                    background: $color-black;
                    font-family: $font-flexo-demi;
                    font-size: 18px;
                    line-height: 1.4;
                    text-align: right;
                }

                &-uvp span {
                    font-family: $font-flexo-regular;
                    font-size: 16px;
                    line-height: 1.6;

                    &.uvp_value {
                        text-decoration: line-through;
                    }
                }


                &-shipping-detail {
                    &-duration {
                        display: flex;
                        flex: 1 1 100%;
                        flex-direction: column;
                        justify-content: flex-end;
                        align-items: flex-end;

                        @media #{$mq-large-up} {
                            margin-top: 0;
                            flex-direction: row;
                            justify-content: flex-end;
                            align-items: flex-end;
                        }

                        > * {
                            line-height: 22px;
                            width: fit-content;
                        }
                    }

                    &-rideReadyHomeDelivery {
                        margin-bottom: 7px;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        align-items: center;
                        font-family: $font-flexo-demi;

                        > *:first-child {
                            margin-right: 10px;
                        }

                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }

            &-buynow {

                margin-top: 35px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                stroke: white;

                @media #{$mq-small-only} {
                    display: none;
                }
            }

            .product__button--add-to-cart button[type="submit"],
            .product__button--no-bike,
            .product__button--choose-bike {
                label {
                    display: flex;
                    align-items: center;
                }
            }

            .product__button--add-to-cart {
                &.is-loading  {
                    button[type="submit"] > .circle {
                        opacity: 0;
                    }

                    button[type="submit"] > .loader-gif {
                        display: block;
                        opacity: 1; // overwrite form loader styles
                        left: auto; // overwrite form loader styles
                    }
                }
            }
        }

        &__bike-details {
            &.hide {
                display: none;
            }
        }

        &__bike-teaser {
            > .container-center {
                display: flex;
                flex-direction: row;

                > * {
                    flex-basis: 50%;
                }

                @media #{$mq-large-down} {
                    width: 100%;
                    flex-direction: column;

                    > * {
                        flex-basis: 100%;
                    }
                }
            }

            &.single {
                &::before,
                &::after {
                    display: none;
                }

                > .container-center {
                    max-width: none;
                    width: 100%;
                    margin-left: 0;
                    margin-right: 0;

                    > * {
                        flex-basis: 100%;
                    }
                }
            }

            @media #{$mq-large-down} {
                &::before,
                &::after {
                    display: none;
                }
            }
        }

        &__teaser {
            padding: 40px 0;

            h3 {
                font-size: 35px;
                line-height: 115%;
            }

            .container-center {
                height: 100%;
                display: flex;
                flex-direction: column;
            }

            &-image {
                display: flex;
                justify-content: center;
                margin-top: 20px;

                img {
                    width: 100%;

                    @media #{$mq-medium-up} {
                        width: 60%;
                    }

                    @media #{$mq-large-up} {
                        width: 80%;
                    }
                }
            }

            &-buttons {
                margin-top: 10px;
                display: flex;
                flex-basis: 100%;
                flex-direction: column;
                justify-content: flex-end;

                @media #{$mq-large-down} {
                    margin-top: 40px;
                }

                > * {
                    align-self: flex-end;

                    & + * {
                        margin-top: 20px;
                    }
                }
            }
        }

        &__bike-variants {
            .wrapper-radiobutton {
                background: $color-white;

                & + .wrapper-radiobutton {
                    margin-top: 20px;
                }

                label {
                    padding: 15px;
                    background-color: inherit;
                    display: flex;
                    align-content: center;
                    justify-content: space-between;

                    @media #{$mq-small-only} {
                        flex-direction: column;
                    }

                    &::before {
                        right: 20px;
                        bottom: 20px;
                        top: auto;
                        left: auto;
                    }
                }

                input {
                    left: auto;
                    top: auto;
                    right: 15px;
                    bottom: 15px;
                    background: inherit;
                }
            }

            &.single-option {
                label {
                    display: none;
                    cursor: auto;

                    @media #{$mq-small-only} {
                        flex-direction: column-reverse;

                        .product-detail__variant-price {
                            margin: 0 0 20px;
                            text-align: right;
                        }
                    }

                    &::before {
                        display: none;
                    }
                }

                input {
                    display: none;

                    &:checked + label {
                        display: flex;
                    }
                }
            }
        }

        &__variant-price {
            font-family: $font-flexo-demi;
            margin: 0 0 30px 30px;

            @media #{$mq-small-only} {
                margin: 30px 0 0;
            }
        }

        &__info-links {
            margin-top: 60px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            > * {
                margin-bottom: 20px;
            }
        }
    }


    .slider-rebrush {
        .owl-dots {
            margin: 0 #{$padding-container-center-small};

            @media #{$mq-medium-up} {
                margin: 0 #{$padding-container-center-medium};
            }

            @media #{$mq-large-up} {
                margin: 0 #{$padding-container-center-large};
            }
        }
    }
}


.m56-product-detail {
    .bike-features {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        &__item + .bike-features__item {
            margin-left: 40px;
        }

        &__buy {
            justify-content: flex-end;
            margin-left: auto !important;

            .product-detail__checkout-buynow {
                margin-top: 0;
            }
        }

        &__fake-header {
            .fake-header__button-wrapper {
                .button-circle {
                    --color: white;

                    .circle {
                        width: 40px;
                        height: 40px;

                        & svg {
                            width: 14px;
                            height: auto;
                            margin-left: 3px;
                        }
                    }
                }

                &-buynow {
                    display: block;
                }
            }
        }
    }
}

.m56-product-detail .product-detail--service {
    .product-detail__image .slider-rebrush {
        .owl-item .slider-rebrush__slide img {
            max-width: 280px;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
        }
    }
}


.product-detail {
    .product-detail__fake-header {
        position: fixed;
        z-index: 2;
        top: 55px;
        width: 100%;
        height: 56px;
        left: 0;
        background: rgba(0,0,0,0.9);
        color: white;
        align-content: center;
        font-size: 20px;
        font-family: $font-flexo-thin;


        transition: all 500ms;
        animation: header-animation 500ms linear;

        .fake-header__wrapper {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }

        .fake-header__button-wrapper {
            display: flex;
            align-items: center;
            gap: 50px;
        }

        .fake-header__title {
            font-size: inherit;
            color: inherit;
        }
        .fake-header__price {
            font-size: 18px;
            color: inherit;
            text-align: right;
        }

        .button-circle {
            background: none;

            .circle {
                width: 40px;
                height: 40px;

                & svg {
                    width: 14px;
                    height: auto;
                    margin-left: 3px;
                }
            }
        }

        &-buynow {
            display: block;
        }

    }

    .product-detail--fixed {
        &.product-detail__image {
            position: fixed;
        }
    }

    .product-detail__gallery-container {
        display: flex;

        .product-detail__image {
            align-self: flex-start;
            width: 100%;
        }
    }

    .product-detail__image-disclaimer {
        margin-top: 20px;
    }

    .product-detail--align-bottom {
        .product-detail__image {
            align-self: flex-end;
        }
    }

    @keyframes header-animation {
        0% {
            background: white;
            color: black;
            font-size: 30px;
        }
        50% {
            font-size: 20px;
        }
        100% {
            background: rgba(0,0,0,0.9);
            color: white;
        }
    }
}
