
.rb__parallax-background {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    // todo -- remove. only for debugging
    // background-color: red !important;

    .rb__parallax-figure {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-color: $color-medium-grey;
        // force 3d-rendering
        backface-visibility: hidden;

        & > figure {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}