// Link ins CMS Modules
.header-article,
.m02-highlight-content,
.m06-headline,
.m09-article,
.m18-accordion,
.m30-layer {

    // External Link
    .text-spaces p:not(.dateline) {
        a[href*="api/"],
        a[href*="http://"]:not([href*="localhost"]),
        a[href*="https://"]:not([href*="r-m.de"]) {

            &:not(.button) {
                //margin-right: 2em; // use this if there is no max-width
                margin-right: 32px;
                white-space: nowrap;

                &:after {
                    display: inline-block;
                    position: absolute;
                    content: ' ';
                    background-image: url(/static/assets/img/externer-link.svg);
                    background-repeat: no-repeat;
                    max-height: 28px;
                    max-width: 28px;
                    width: 1.75em;
                    height: 1.75em;
                    //width: 28px;
                    //height: 28px;
                    right: -32px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:before {
                    content: initial;
                }
            }
        }
    }

    // Treat Buttons with display type link as visual link
    button[data-display-type="link"] {
        @include text-link-base();

        // Dont allow normal underline
        u {
            text-decoration: none;
        }
    }

    // If button is inside strong, underline it in default
    strong button[data-display-type="link"] {
        color: $color-black;
        border-bottom: 1px solid $color-black;
    }
}
