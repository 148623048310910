
.m23-vcard {
    figure {
        width: 130px;
        margin: 0 0 20px 0;
        @include relative-height(1/1);

        @media #{$mq-medium-up} {
            margin: 0 40px 0 0;
        }
    }

    .container-card {
        background-color: $color-ultra-light-grey;
        padding: 20px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        @media #{$mq-medium-up} {
            padding: 40px;
            margin-top: 40px;
            flex-direction: row;
        }
    }

    .container-share-buttons {
        display: flex;
    }

    .btn-icon {
        color: $color-warm-grey;
        background-color: transparent;
        width: 20px;
        height: 20px;
        margin: 20px 0 0 0;

        &:nth-child(n+2) {
            margin-left: $gap-smallest-part;
        }

        &:before {
            font-size: 20px;
            line-height: 20px;
        }

        &:after {
            display: none;
        }

        &:hover {
            .no-touch & {
                color: $color-black;
            }
        }
    }

    .btn-icon + .btn-icon {
        margin-left: 20px;
    }
}