.configurator__preview-area {

    // overall layout in _m16-configurator

    .configurator__preview-content-wrapper {
        position: relative;
        height: 27%;

        h2 {
            font-size: 22px;
            line-height: 30px;
            margin: 15px 0 15px 0;

            @media #{$mq-small-only} {
                max-width: 70%;
                z-index: 1; // over bike images on very small screens
            }

            @media #{$mq-medium-up} {
                font-size: 30px;
                line-height: 39px;
                margin: 30px 0 15px 0
            }
        }

        .configurator__price--wrapper {
            padding: 2px 5px;
            background-color: #000000;
            color: #ffffff;
            display: inline;

            // position above image for very small devices
            z-index: 1;
            position: relative;

            @media #{$mq-small-only} {
                position: absolute;
                top: 2px;
                right: 20px;
            }
        }
    }

    .configurator__preview-image-wrapper {
        position: relative;
        height: 73%;
        margin-top: 0;

        display: flex;
        justify-content: center;
        align-items: flex-end;
        transition: background-color 0.2s ease-out;

        .configurator__preview-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 80%;

            // image is set via css
            // background-image: url(/media/bikes/F00115/26_5_detail.jpg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-color: transparent;

            // @media #{$mq-medium-up} {
            //     width: 90%;
            //     left: 5%;
            // }

            // @media #{$mq-max-width-up} {
            //     width: 100%;
            //     left: 0;
            // }
        }

        &.accessory-content {
            //height: 73%;


            .configurator__preview-image {
                // height: 85%;
                // top: 7%;
                height: 100%;
                bottom: 0;
                background-position: bottom right;
                background-size: cover;

                // @media #{$mq-medium-up} {
                //     background-position: center right;
                // }

                @media #{$mq-max-width-up} {
                    width: 100%;
                    left: 0;
                    //background-size: 65%;
                    //background-position: center right;
                    background-position-y: 100%;
                }
            }
        }

        p.text-small {
            text-align: center;
            padding-top: 5px;
            padding-bottom: 15px;

            display: none;
            width: calc(100% - 20px);
            background-color: rgba(255, 255, 255, 0.6);

            position: absolute;
            bottom: 20px;

            @media screen and (max-height: 560px) {
                padding-bottom: 0px;
            }

            @media #{$mq-medium-up} {
                width: calc(100% - 110px);
                left: 5%;
                bottom: 30px;
                padding-bottom: 25px;
            }

            @media #{$mq-large-up} and (max-aspect-ratio: 4/3) {
                padding-bottom: 55px;
            }

            @media #{$mq-extra-large-up} and (max-aspect-ratio: 5/3) {
                padding-bottom: 55px;
            }
        }
    }
}
