
// ALL TEXTSTYLES:
html {
    color: $color-black;
    background: $color-white;

    @include html-text();
}

// All text-items must be relative
.h1,
h1, h2, h3, h4, p, a, label, mark, .caption, ul, ol, li {
    @include base-text();
}

.h1,
h1, h2, h3, h4 {
    @include base-headline();
}

.h1,
h1 {
    @include headline-h1();
}

h2, h2 > * {
    @include headline-h2();

    $top: -6px;
    $bottom: -11px;

    &.small-h3 {
        @media #{$mq-small-only} {
            font-size: 25px;
            line-height: 32px;

            .text-spaces > & {
                top: $top;
                margin-bottom: #{$top + $bottom};
            }
        }
    }

    // e.g. configurator radio labels
    &.small-h4 {
        @media #{$mq-small-only} {
            font-size: 20px;
            line-height: 24px;
        }
    }
}

h3 {
    @include headline-h3();

    &.h4 {
        @include headline-h4();
    }
}

h4 {
    @include headline-h4();
}

.h1,
h1, h2, h3 {
    @media #{$mq-small-only} {
        word-wrap: break-word;
    }
}

a {
    display: inline-block;
}

p {
    a {
        display: inline;
        font-size: 16px;
    }
}

.dateline {
    @include dateline();
}

p, a, label, span {
    padding: 0;
    margin: 0;

    &.poster {
        $top: -10px;
        $bottom: -11px;
        font-family: $font-flexo-thin;
        font-size: 22px;
        line-height: 36px;

        > a {
            font-size: 22px;
        }

        .text-spaces > & {
            top: $top;
            margin-bottom: #{$top + $bottom};
        }
    }
}

table {
    line-height: 22px;
}

p, a, label, ul, ol, table {
    $top: -6px;
    $bottom: -8px;

    color: $color-black;
    font-size: 16px;
    line-height: 26px;

    &.uppercase {
        line-height: 1.4em;
    }

    .text-spaces > & {
        top: $top;
        margin-bottom: #{$top + $bottom};

        li {
            top: $top;
            margin-bottom: #{$top + $bottom};
        }
    }
}

// labels and links must have different font sizes
a {
    font-size: 14px;
}

.rb__small-text {
    $top: -6px;
    $bottom: -6px;

    p, a, label, ul, ol {
        font-size: $fontsize_cards;
        line-height:  $lineheight_cards;
    }

    .text-spaces > p,
    .text-spaces > a,
    .text-spaces > label,
    .text-spaces > ul,
    .text-spaces > ol {
        top: $top;
        margin-bottom: #{$top + $bottom};
    }
}

a.btn-base {
    .text-spaces > & {
        top: 0;
        margin-bottom: 0
    }
}

.text-medium {
    font-size: 14px;
    line-height: 22px;
}

label,
small,
.text-small {
    $top: -4px;
    $bottom: -5px;
    letter-spacing: 0.05em;
    font-size: $tsmall_fontsize;
    line-height: $tsmall_lineheight;

    .text-spaces > & {
        top: $top;
        margin-bottom: #{$top + $bottom};
    }

    .btn-external-link:before {
        font-size: $tsmall_fontsize !important;
        line-height: $tsmall_lineheight !important;
    }

    p,
    a {
        letter-spacing: 0.05em !important;

        &:not(.text-size--normal) {
            font-size: $tsmall_fontsize;
            line-height: $tsmall_lineheight;
        }
    }
}

@mixin text-link-base() {
    font-family: $font-flexo-demi;
    text-decoration: none;
    border-bottom: 1px solid transparent;

    transition: color 0.25s ease-out, border 0.25s ease-out;

    &:hover {
        .no-touch & {
            border-bottom: 1px solid $color-black;
        }
    }
}

a {
    @include text-link-base();

    color: $color-black;

    &.inverted {
        color: $color-warm-grey;
        border-color: $color-warm-grey;

        &:hover {
            .no-touch & {
                color: $color-white;
                border-color: $color-white;
            }
        }
    }

    &.hover-white {
        &:hover {
            color: $color-white;
        }
    }
}

.caption {
    $top: -3px;
    $bottom: -6px;

    font-size: 13px;
    line-height: 18px;
    color: $color-black;
    font-family: $font-flexo-regular;

    .text-spaces > & {
        top: $top;
        margin-bottom: #{$top + $bottom};
    }
}

/*********************
    AUSZEICHNUNGEN
*********************/
strong, b {
    font-family: $font-flexo-demi;
}

// emphasize, italic
em,
.italic,
.quote {
    font-style: normal !important;
    font-family: $font-flexo-thin-italic;
}


.bold {
    font-family: $font-flexo-bold !important;
}

mark {
    display: inline-block;
    color: $color-white;
    background-color: $color-black;
    // text-transform: uppercase;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.05em;
    // Höhe durch line-height definieren, um bessere Zentrierung des Textes zu erzielen
    padding: 0 8px;
}

.text-breadcrump {
    text-transform: uppercase;
    border-bottom: none;

    &:hover {
        .no-touch & {
            border-bottom: none;
        }
    }
}

// muss getrennt stehen
::selection {
    background: $color-light-grey;
}

::-moz-selection {
    background: #bbbbbb;
}

button {
    line-height: 22px;

    .text-small {
        line-height: 18px;
        margin-top: 3px; //todo
    }
}

// override colors
.color-white {
    --color: #{$color-white};
}

.color-black {
    --color: #{$color-black};
}

.color-medium-grey {
    --color: #{$color-medium-grey};
}

.color-white,
.color-black,
.color-medium-grey {
    color: var(--color);

    p, a, label, h1, h2, h3, h4 {
        color: var(--color);
    }

    a {
        border: var(--color);
    }
}

// HELPER
.text-right {
    text-align: right;
}

.uppercase {
    text-transform: uppercase !important;
}

.font--bold {
    font-family: $font-flexo-bold;
}

.font--demi {
    font-family: $font-flexo-demi;
}

.font--regular {
    font-family: $font-flexo-regular;
}

.font--thin {
    font-family: $font-flexo-thin;
}

.font--thin-italic {
    font-family: $font-flexo-thin-italic;
}

.todo {
    color: red;
}

// only used in configurator todo: refactor...
p.is-warm-grey,
a.is-warm-grey {
    color: $color-warm-grey;
}

// name-attributes on anchor-tags are distinct indicators for deeplink-anchors created by ckeditor
// since these are usually nested within headlines, all styles must be inherited
a[name] {
    color: inherit !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    letter-spacing: inherit !important;
    margin: 0 !important;
    top: 0 !important;
    text-decoration: none !important;
    border: none !important;
}
