.bike-advisor__question {
    --duration-animate-height: var(--bike-advisor-duration-large);
    transition: height var(--duration-animate-height) var(--ease-in-out-cubic);
 }    // background-color: cyan

.bike-advisor__question-inner {
    --sentence-whitespace: 12px;
    --sentence-height: 38px;
    --sentence-font-size: 25px;
    --placeholder-height: 32px;
    --placeholder-font-size: 14px;
    --placeholder-line-height: 25px;
    --placeholder-padding-vertical: 5px;
    --placeholder-margin-vertical: 3px;

    @include bike-advisor__size--large() {
        --sentence-whitespace: 16px;
        --sentence-height: 70px;
        --sentence-font-size: 40px;
        --placeholder-height: 40px;
        --placeholder-font-size: 25px;
        --placeholder-line-height: 46px;
        --placeholder-padding-vertical: 3px;
        --placeholder-margin-vertical: 9px; }

    @include bike-advisor__size--extra-large() {
        --sentence-whitespace: 18px;
        --sentence-height: 94px;
        --sentence-font-size: 55px;
        --placeholder-height: 60px;
        --placeholder-font-size: 40px;
        --placeholder-line-height: 70px;
        --placeholder-padding-vertical: 2px;
        --placeholder-margin-vertical: 10px; }

    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 calc(var(--sentence-whitespace) * -0.5); }
