.retailer-card {
    display: flex;
    flex-direction: column;

    > div:last-child {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    button {
        text-decoration: underline;
        line-height: inherit;

        &.button-circle,
        &.button-back {
            text-decoration: none;
        }
    }

    .retailer-info {
        display: none;

        &.active {
            display: block;
        }

        .addition + h4 {
            margin-top: 17px;
        }
    }

    .testbikes-teaser__wrapper {
        &--exact-match::before {
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-right: 5px;
            margin-bottom: -1px;
            background: $color-green;
            border-radius: 50%;
        }
    }

    .testbikes-detail,
    .retailer-info {
        flex-grow: 100;

        &.active {
            margin-bottom: 30px;
        }
    }

    .testbikes-detail {
        display: none;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;

        &.active {
            display: flex;
        }
    }

    .button-back {
        display: grid;
        grid-template-columns: repeat(2, auto);
        text-transform: uppercase;
        grid-gap: 10px;

        * {
            display: inline-flex;
            align-items: center;
            height: 20px;
        }

        + div {
            flex-grow: 1;
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            flex-direction: column;
        }
    }
}
