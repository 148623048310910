// Todo: Move to m08 multimedia or an other slider module (or both)
// also use css variables of theming for styling

.module.background-black {

    .slider-rebrush {
        figure.slide {
            &.fitting {
                background-color: $color-black;
            }
        }

        .owl-counter > span {
            color: $color-medium-grey;

            &.owl-counter__active {
                color: $color-white;
            }
        }

        .owl-prev, .owl-next {
            background-color: rgba(55, 52, 51, 0.5);

            &:before {
                color: $color-white;
            }
        }

        .owl-dots {
            .owl-dot {
                &:before {
                    background: #373433;
                }

                &.active {
                    &:before {
                        background: $color-white;
                    }
                }
            }
        }
    }

    .slider-rebrush.slider-snap-scroll {
        .owl-progress,
        .owl-dots-custom {
            background: #373433;
        }

        .owl-progress {
            .owl-progress__dragger {
                &:before {
                    background: $color-white;
                }
            }
        }
    }
}
