$select-options--height: 50px;
$select-options--line-height: $p_lineheight;
$select-options--padding-t-b: ($select-options--height - $select-options--line-height) / 2;
$select-options--padding-l-r: 20px;
$select-options--padding-l: $select-options--padding-l-r * 2 + 16px;

.select-options {}

.select-options__item {
    position: relative;

    &:hover {
        .select-options__label {
            background-color: $color-ultra-light-grey;
        }
    }
}

.select-options__item--disabled {
    pointer-events: none;
}

.select-options__label {
    display: block;
    min-height: $select-options--height;
    font-size: $p_fontsize;
    font-family: $font-flexo-regular;
    line-height: $select-options--line-height;
    padding: $select-options--padding-t-b $select-options--padding-l $select-options--padding-t-b $select-options--padding-l-r;
    transition: background-color 0.1s ease-in-out;
    cursor: pointer;

    .select-options__item--disabled & {
        color: $color-warm-grey;
        font-family: $font-flexo-thin;
    }
}

.select-options__box {
    $size-inner: 20px;
    $border-thickness: 2px;
    $size-outer: $size-inner + $border-thickness;

    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 50%;
    width: $size-outer;
    height: $size-outer;
    margin-top: - $size-outer / 2;
    border: $border-thickness solid $color-black;
    transition: background-color 0.15s ease-out, opacity 0.15s ease-out;
    pointer-events: none;

    svg {
        height: 11px;
        opacity: 0;
        backface-visibility: hidden;
        transform: scale(1.6);
        transition: opacity 0.2s ease-out, transform 0.15s ease-out;
    }

    path {
        stroke: $color-white;
    }

    .select-options__item--disabled & {
        display: none;
    }
}

.select-options__icon-plus {
    $size: 16px;
    position: absolute;
    right: 20px;
    top: 50%;
    width: $size;
    height: $size;
    margin-top: - $size / 2;
    transition: transform 0.2s ease-out;
    pointer-events: none;
}

.select-options__input {
    @include hide-input-for-custom-checkbox();

    &:checked {
        & + .select-options__label + .select-options__box {
            background-color: $color-black;

            svg {
                opacity: 1;
                transform: scale(1);
            }

            .select-options__item--disabled & {
                display: block;
                border-color: $color-white;
                background-color: $color-white;

                path {
                    stroke: $color-warm-grey;
                }
            }
        }
    }
}

