#cookiefirst-cookies-table {
    width: 100%;
    overflow-x: hidden;

    svg, p, h2 {
        display: none;
    } 

    table {
        width: auto;
        overflow-x: scroll;

        tr {
            background: transparent;

            h4 {
                margin-top: 15px;
                font-weight: 300;
                font-size: 20px;
            }
        }
    }
}