.m02-highlight-content {
    @media #{$mq-small-only} {
        .container-figure {
            margin-bottom: 20px;
        }
    }

    figure {
        width: 100%;
    }

    .grid {
        align-items: flex-start;
    }

    &.highlight-content--center .grid {
        align-items: center;
    }

    .s-12:first-child {
        @include gap-for(margin-bottom, $gap-2);
    }

    &:not(.highlight-content--no-image) {
        // Bild- und Text-Container mobile + tablet immer in gleicher Reihenfolge: Text > Bild
        @media #{$mq-large-down} {
            .grid {
                display: flex;

                .container-figure {
                    order: 0;
                    margin-bottom: 30px;
                }

                .container-text,
                .rb__container-text {
                    order: 1;
                }
            }
        }
    }

    // ie edge layout fix
    @media #{$mq-large-up} {
        .ie-edge & {
            .grid {
                & > div:last-child{
                    margin-right: -56px;
                }
            }
        }
    }
}
