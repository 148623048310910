
.m22-equipment {

    $offset_small_up: 20px;
    $offset_medium_up: 70px;
    $offset_large_up: 100px;

    h2:last-child {
        margin-bottom: 43px;
    }

    h3 {
        overflow-wrap: break-word;
    }

    h3 {
        padding-right: 10px;
    }

    .dateline:only-child {
        margin-bottom: 20px;
    }

    figure {
        width: 100%;
        background-color: darkgrey;
        @include relative-height(3/2);

        &.background {
            background-position: right bottom;
        }
    }

    // special space for m22 slider
    .slider-rebrush.slider-snap-scroll {

        .owl-stage {
            padding-bottom: 32px;

            @media #{$mq-medium-up} {
                padding-bottom: 42px;
            }
        }

        .owl-dots-custom--disabled .owl-stage {
            padding-bottom: 0;
        }

    }


    &__wrapper-description {
        display: flex;
        margin-top: 30px;
    }

    &__description {}

    &__price-and-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        flex: 0 0 auto;
        margin-left: auto;
        padding-left: 20px;
    }

    &__wrapper-button-copy {
        margin-top: 40px;
    }

    &__wrapper-button-info {
        margin-top: 20px;

        .btn-icon-info {
            position: static !important;
        }
    }
}

.color-white + .m22-equipment {
    &.module::before {
        @extend .module:after;
    }
}
