
.overlay-search {
    position: fixed;
    z-index: $z-overlay-search;

    .overlay-search-safety-area {
        position: absolute;
        left: -40px;
        top: -40px;
        right: -40px;
        bottom: -40px;
        // hinter input und button, so dass diese klickbar bleiben
        z-index: 0;
    }

    .overlay-search-wrapper-animation {
        width: 100%;
    }

    .overlay-search-input {
        position: relative;
        background-color: transparent;
        color: $color-white;
        height: auto;
        padding: 0 70px 0 20px;
        font-family: $font-flexo-thin;
        font-size: 30px;
        line-height: 30px + 20px + 20px;
        z-index: 1;

        // todo - rebrush
        border-color: $color-white;

        @media #{$mq-medium-up} {
            font-family: $font-flexo-regular;
            font-size: 50px;
            line-height: 50px + 20px + 20px;
            padding-right: 90px;
        }
    }

    .btn-submit-search {
        position: absolute;
        right: 0;
        bottom: 0;

        &:before {
            $size: 30px + 20px + 20px;
            // Damit größe via css gesetzt werden kann
            display: block;
            width: $size;
            height: $size;
            line-height: $size;
            font-size: 22px;

            @media #{$mq-medium-up} {
                $size-medium-up: 50px + 20px + 20px;

                width: $size-medium-up;
                height: $size-medium-up;
                line-height: $size-medium-up;
                font-size: 38px;
            }
        }
    }

    .btn-icon {
        width: auto;
        padding: 0;
        background-color: transparent;
        height: auto;

        &:after {
            display: none;
        }

        &:before {
            position: static;
            // todo - rebrush
            color: $color-white;
        }
    }
}
