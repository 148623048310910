
.product-service {
    display: flex;
    flex-direction: column;
    border: none;
    background: $color-white;
    font-family: $font-flexo-regular;

    & + & {
        margin-top: 50px;
    }

    .grid {
        @media #{$mq-medium-up} {
            flex-wrap: nowrap;
        }
    }

    &:hover {
        .no-touch & {
            cursor: pointer;
            border: none;
            color: $color-black;

            .button-next {
                span {
                    transition: transform 0.45s ease-out;
                    transform: translate3d(110%, 0, 0);
                }
            }
        }
    }

    .grid {
        flex: 1 0 100%;
        margin-bottom: 0;

        @media #{$mq-small-only} {
            flex-direction: column;
        }

        & > div {
            margin-bottom: 0;
        }
    }

    &--no-image {
        min-height: auto;

        @media #{$mq-medium-up} {
            padding-left: 50px;
        }

        .product-service__wrapper-content {

            @media #{$mq-small-only} {
                padding: 20px;
            }
        }
    }
}

.product-service__container-figure {
    background-color: $color-ultra-light-grey-hex;
    @include relative-height(595/335);

    figure {
        position: absolute;
        left: 50%;
        top: 50%;
        display: flex;
        align-items: flex-end;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        max-width: 420px;
        background-position: center center;
        background-size: contain;
        transform: translate(-50%, -50%);
    }
}

.product-service__caption {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    min-height: 40px;
    padding: 10px 0;
    background: $color-nearly-white;
}

.product-service__wrapper-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @media #{$mq-small-only} {
        padding-bottom: 20px;
        margin-top: 25px;
    }

    @media #{$mq-medium-up} {
        padding: 0 50px 30px 0;
        min-height: 410px;
    }
}

.product-service__info {
    font-family: $font-flexo-demi;
    margin-top: -7px; // align with image
    margin-bottom: 5px;
}

.product-service__headline {
    margin-bottom: 15px;
}

.product-service__wrapper-price {
    margin-top: 35px;
}

.product-service__tax {
    margin-top: 10px;
}

.product-service__delivery-info {
    position: relative;
    padding-left: 20px;
    margin-top: 10px;

    &:before {
        content: '';
        position: absolute;
        top: 3px;
        left: 0;
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $color-product-available;
    }

    &.product--not-available:before {
        background: $color-product-not-available;
    }
}

.product-service__price {
    height: 20px;
    padding: 0 10px;
    color: $color-white;
    background: $color-black;
}


.product-service__wrapper-button {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .button-circle {
        display: inline-flex;
        margin-top: 20px;

        @media #{$mq-medium-up} {
            margin-top: 10px;
        }
    }
}


