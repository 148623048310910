@mixin grid-column-classes($size, $gap) {
    $columns: 12;

    @for $i from 1 through $columns {
        $factor: $columns/$i;
        & > .#{$size}-#{$i} {
            width: calc((100% - (#{$gap} * #{$factor})) / #{$factor});
        }
    }
}

@mixin grid-margin-classes($size, $gap, $side: left) {
    $columns: 12;

    @for $i from 1 through $columns {
        $factor: $columns/$i;
        $factorOffset: 0;

        @if $side == left {
            $factorOffset: 2;
        } @else {
            $factorOffset: 1;
        }
        & > .#{$size}-#{$side}-#{$i} {
            margin-#{$side}: calc((100% - (#{$gap} * #{$factor})) / #{$factor} + (#{$gap} * #{$factorOffset}));
        }
    }
}

@mixin grid-extend-classes($size, $gutter, $padding, $side, $index-start: 1, $index-end: 12) {
    $columns: 12;

    @for $i from 1 through $columns {
        $factor: $columns/$i;

        @if $i >= $index-start and $i <= $index-end {
            & > .#{$size}-#{$i}-extend-#{$side} {
                width: calc(((100% - (#{$gutter} * 12)) / (12/#{$i})) + (#{$gutter} * #{$i - 1}) + #{$padding});

                @if $side == left {
                    margin-left: calc(-1 * #{$padding} + #{$gutter});
                } @else {
                    margin-right: calc(-1 * #{$padding});
                }
            }
        }
    }
}

@mixin grid-extend-full-class($size, $gutter, $padding) {
    & > .#{$size}-12-extend-full {
        width: calc((100% - #{$gutter}) + (#{$padding} * 2));
        margin-left: calc(#{-1 * $padding} + #{$gutter});
        margin-right: #{-1 * $padding};
    }
}
