//.sentence

.sentence__part,
.sentence__placeholder {
    opacity: 0;
    transform: translateY(-8px);
    --duration-fade-in: var(--bike-advisor-duration-large);
    --duration-fade-out: var(--bike-advisor-duration-medium);

    &.show {
        opacity: 1;
        transform: translateY(0); }

    &.fade-in {
        opacity: 1;
        transform: translateY(0);
        transition: opacity var(--duration-fade-in) var(--ease-in-out-cubic), transform var(--duration-fade-in) var(--ease-in-out-cubic); }

    &.fade-out {
        opacity: 0;
        transition: opacity var(--duration-fade-out) var(--ease-in-cubic); } }

.sentence__wrapper {
    display: flex;
    min-height: var(--sentence-height);
    margin: 0 calc(var(--sentence-whitespace) * 0.5);

    .sentence__part {
        margin: 0; } }

.sentence__part {
    display: block;
    font-size: var(--sentence-font-size);
    line-height: var(--sentence-height);
    font-family: $font-flexo-thin;
    margin: 0 calc(var(--sentence-whitespace) * 0.5); }

.sentence__placeholder {
    display: block;
    position: relative;
    line-height: var(--placeholder-line-height);
    font-size: var(--placeholder-font-size);
    font-family: $font-flexo-demi;
    margin: var(--placeholder-margin-vertical) 0;
    padding: var(--placeholder-padding-vertical) 15px;
    border-radius: 12px;
    color: $color-warm-grey;
    background-color: $color-light-grey;
    transform-origin: 100% 50%;
    cursor: default;

    &.sentence__placeholder--chosen {
        color: $color-white;
        background-color: $color-black;
        cursor: pointer;

        &:hover {
            transition: background-color 0.2s var(--ease-out-cubic);
            background-color: $color-warm-grey; }

        &:not(.show) {
            transition: color 0.2s var(--ease-out-cubic), background-color 0.2s var(--ease-out-cubic);
            animation: animation-choose-placeholder 0.4s var(--ease-out-back) 0s 1;

            .sentence__placeholder-text {
                animation: animation-choose-placeholder-text 0.4s var(--ease-out-back) 0s 1; } } } }

.sentence__placeholder-text {
    display: block;

    // .sentence__placeholder--chosen &
 }    //    animation: animation-choose-placeholder-text 0.4s var(--ease-out-back) 0s 1

.sentence__placeholder-spacer {
    color: transparent; }


@keyframes animation-choose-placeholder {
    70% {
        transform: translate3d(0.35em,0,0); } }

@keyframes animation-choose-placeholder-text {
    70% {
        transform: translate3d(0.3em,0,0); } }
