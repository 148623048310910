.configurator__step-color-chooser {
    overflow-x: hidden!important;

    @media #{$mq-large-up} {
        overflow-x: visible!important;

        .configurator__step-item {
            overflow: visible!important;
        }
    }

    .configurator__step-item {
        width: 0;
        flex-basis: auto;
        transition: all.3s;
        overflow: hidden;
    
        h3 {
            max-height: 160px;
            opacity: 0!important;
            color: $color-white;
            transition: opacity .1s!important;

            @media #{$mq-large-up} {
                max-height: auto;
                opacity: 1!important;
            }
        }

        label {
            background-image: linear-gradient(208deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.06) 32%, rgba(0, 0, 0, 0.57));

            span {
                opacity: 0;
                transition: opacity .3;
                transition-delay: 0s;

                @media #{$mq-large-up} {
                    opacity: 1;
                    white-space: nowrap;
                    transition-delay: .1s;
                }
            }
        }

        &.active {
            flex-grow: 4.5!important;
    
            label {
                span {
                    transition-delay: .4s;
                    opacity: 1;
                }
            }

            h3 {
                transition-delay: .3s!important;
                opacity: 1!important;

                @media #{$mq-large-up} {
                    transition-delay: none!important;
                }
            }
            
            @media #{$mq-large-up} {
                flex-grow: 2.5!important;
            }
        }
    }

    .configurator__step-tooltip {
        @media #{$mq-large-up} {
            &:after {
                position: absolute;
                content: '';
                top: 50%;
                right: -25px;
                border-top: 30px solid transparent;
                border-bottom: 30px solid transparent;
                border-left: 25px solid $color-light-grey;
                transform: translateY(-50%);
                z-index: 1;
            }
        }
    }
}