
.wrapper-select {
    position: relative;

    &:nth-last-child(n+2) {
        &:after {
            content: '';
            position: absolute;
            display: block;
            width: calc(100% - 40px);
            height: 1px;
            left: 20px;
            bottom: 0;
            background-color:  $color-ultra-light-grey;
            pointer-events: none;
        }
    }
}

.wrapper-select--expanded {
    padding-bottom: 20px;
}

.wrapper-select__header {
    padding: 40px 20px 0 20px;
    cursor: pointer;

    &:hover {
        .wrapper-select__button-expand {
            top: 2px;
        }

        .wrapper-select--expanded & {
            .wrapper-select__button-expand {
                top: -2px;
            }
        }
    }
}

.wrapper-select__header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}

.wrapper-select__label {
    font-size: 16px;
    font-family: $font-flexo-demi;
}

.wrapper-select__button-expand {
    position: relative;
    line-height: 0;

    svg {
        width: 20px;
    }

    .wrapper-select--expanded & {
        transform: rotate(180deg);
    }
}

.wrapper-select__content {
    height: 0;
    padding: 0;
    overflow: hidden;

    .wrapper-select--expanded & {
        height: auto;
        padding-top: 20px;
        overflow: visible;
    }
}

.wrapper-select__preview {
    font-size: 12px;
    padding-bottom: 10px;

    &:empty {
        display: none;
    }

    .wrapper-select--expanded & {
        display: none;
    }
}
