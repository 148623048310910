// used in forms on retailer search (only?)
// in combination with select or submits
// maybe this should be moved to forms?

.button-icon {
    position: relative;
    width: 16px;
    @include relative-height(1/1);

    svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
