.tabs--step-circ {
    nav {
        ul {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: center;
            overflow: hidden;
            margin: 0;
            padding: 0;
            list-style: none;

            li {

                $circ_size: 40px;
                $separator-size: 45px;
                $separator-gap-min: 5px;
                $separator-gap: 10px;

                display: flex;
                justify-content: center;
                flex: 0 1 #{$separator-gap * 2 + $separator-size + $circ_size};
                max-width: $separator-gap * 2 + $separator-size + $circ_size;

                &.tab-current {
                    pointer-events: none;
                    cursor: default;

                    a {
                        pointer-events: none;
                        cursor: default;
                    }
                }

                a {
                    text-decoration: none;
                    border-bottom: none;

                    display: flex;

                    //width: $separator-gap * 2 + $separator-size + $circ_size;
                    //min-width: $separator-gap * 2 + $separator-size-min + $circ_size;
                    //max-width: $separator-gap * 2 + $separator-size + $circ_size;

                    color: $color-light-grey;
                    -webkit-tap-highlight-color: $color-light-grey;

                    * {
                        pointer-events: none;
                    }

                    .tab__check,
                    .tab__count {
                        width: $circ_size;
                        height: $circ_size;
                        border-radius: 50%;

                        background: transparent;
                        border: 2px solid $color-light-grey;
                        color: $color-light-grey;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .tab__check {
                        display: none;
                    }
                }

                &:nth-child(n+2) {
                    &::before {
                        content: '';
                        height: 2px;
                        background-color: $color-black;
                        position: absolute;
                        top: $circ_size/2 - 2px;
                        //width: $separator-size;
                        //left: -$separator-size - $separator-gap;

                        left: calc(50% - #{$circ_size/2} - (100% - #{$circ_size}) + #{$separator-gap});
                        width: calc(100% - #{$circ_size} - #{$separator-gap*2});

                        @media #{$mq-small-only} {
                            left: calc(50% - #{$circ_size/2} - (100% - #{$circ_size}) + #{$separator-gap-min});
                            width: calc(100% - #{$circ_size} - #{$separator-gap-min*2});
                        }
                    }
                }

                a {

                    color: $color-black;
                    -webkit-tap-highlight-color: $color-black;

                    .tab__check {
                        display: flex;
                        background: $color-black;
                        border: 2px solid $color-black;
                        color: $color-white;

                        svg {
                            path, line {
                                stroke: $color-white;
                            }
                        }
                    }

                    .tab__count {
                        display: none;
                    }
                }

                // todo: should depend on class tab-checked wich only should be set when everything is selected
                &.tab-current ~ li {
                    a {
                        .tab__check {
                            display: none;
                        }
                        .tab__count {
                            display: flex;
                        }
                    }

                    &::before {
                        background-color: $color-light-grey;
                    }
                }

                a:hover,
                &.tab-current a {
                    color: $color-black;
                    //color: $color-light-grey;

                    text-decoration: none;
                    border-bottom: none;

                    .tab__count {
                        //color: $color-black;
                        //border-color: $color-black;
                    }
                }

                &.tab-current a {
                    color: $color-black;

                    .tab__count {
                        display: flex;
                        background: $color-black;
                        border: 2px solid $color-black;
                        color: $color-white;
                    }

                    .tab__check {
                        display: none;
                    }
                }

            }
        }
    }

    .content-wrapper {
        position: relative;

        .content-item {
            display: none;

            &.content-current {
                display: flex;
            }
        }
    }
}



.tabs--default {
    nav {
        ul {
            display: flex;
            flex-wrap: nowrap;
            overflow: hidden;
            flex-direction: row;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                a {
                    margin: 0 20px;
                    //padding-bottom: 20px;
                }

                a:hover,
                &.tab-current a {
                    //border-bottom: 2px solid white;
                    text-decoration: underline;
                    border-bottom: none;
                }



                // Disable click for only one item
                &:nth-last-child(-n+1):first-child,
                &:nth-last-child(-n+1):first-child ~ & {
                    pointer-events: none;

                    a {
                        margin: 0;
                        text-decoration: none;
                        font-family: $font-flexo-regular;
                        font-size: $p_fontsize;
                        pointer-events: none;

                        &:before {
                            content: '\000A0';
                        }
                    }
                }
            }
        }
    }

    .content-wrapper {
        position: relative;

        .content-item {
            display: none;

            &.content-current {
                display: flex;
            }
        }
    }
}

//
.tabs--step-circ[data-module="tabbed-form-helper"] {
    &.form-error,
    &.form-success {
        nav {
            display: none;
        }
        .content-wrapper .content-item {
            display: none;

            &:last-child {
                display: flex;
            }
        }
    }
}



.tabs--cms-edit {
    position: relative;
    overflow: hidden;
    width: 100%;

    @include gap-for(margin-bottom, $gap-3);
    @include gap-for(margin-top, $gap-3);

    nav {
        text-align: center;

        ul {
            display: flex;
            position: relative;
            margin: 0;
            padding: 0;
            max-width: 1200px;
            list-style: none;
            overflow: hidden;
            flex-flow: row wrap;
            justify-content: flex-start;
        }

        ul li {
            position: relative;
            z-index: $z-index-tab;
            display: block;
            margin: 0;
            text-align: center;
            background: $color-ultra-light-grey;

            // zwei Tabs nebeneinander
            @media #{$mq-small-only} {
                width: 48%;
            }

            &:before {
                content: '';
                position: absolute;
                z-index: $z-index-tab-before;
                width: 100%;
                height: 100%;
                top: 100%;
                left: 0;
                background: $color-white;
                border-top: 2px solid $color-black;
                transform: translate3d(0, -2px, 0);
                transition: transform 0.35s cubic-bezier(0.7, 0, 0.3, 1);
            }

            &.tab-current {
                &:before {
                    transition-delay: 0.05s;
                    transform: translate3d(0, -54px, 0);
                }
            }

            &.tab-current a {
                background: none;
                color: $color-black;
            }

            &:not(:last-child) {
                margin-right: 2px;
            }
        }

        a {
            position: relative;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border: none;
            z-index: $z-index-tab-before-a;
            margin: 0;
            padding: 13px 45px;

            color: $color-black;
            line-height: 28px;
            transition: color 0.2s;

            @media #{$mq-small-only} {
                padding: 13px 0;
            }

            span {
                vertical-align: middle;
                text-transform: uppercase;
            }

            &:focus {
                outline: none;
                color: $color-black;
            }

            &:hover {
                .no-touch & {
                    outline: none;
                    color: $color-black;
                }
            }
        }
    }

    .content-wrapper {
        position: relative;
        padding-top: $gap-2-small;

        @media #{$mq-large-up} {
            padding-top: $gap-3-large;
        }

        > section,
        > .cms-plugin > section {
            display: none;

            &.content-current {
                display: flex;
            }
        }
    }
}
