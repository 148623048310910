
.m21-search-results {
    .container-center {
        overflow: hidden;
    }

    // prevent clipping when h2 is only child (no results)
    h2:only-child {
        margin-bottom: 0;
    }

    // keyword highlight for search
    .highlighted {
        font-family: $font-flexo-bold;
    }
}
