.button-info-layer {
    display: flex;
    align-items: center;
    transition: color 0.25s ease-out, border 0.25s ease-out;

    svg {
        fill: currentColor;
        height: 20px;
        margin-right: 10px;
    }

    span {
        font-size: 16px;
        pointer-events: none;
        text-decoration: none;

        border-bottom: 1px solid transparent;
    }

    &:hover {
        .no-touch & {
            color: $color-medium-grey;

            span {
                border-bottom: 1px solid $color-black;
            }
        }
    }
}
