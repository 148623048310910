// storeview inactive
.storeview-item {
    display: none;
}

// storeview active
.storeview {
    .storeview-item {
        display: block;

        & + .storeview-item {
            margin-left: 30px;
        }
    }

    // logo
    .top-bar__logo {
        pointer-events: none;
    }

    //breadcrump
    nav.breadcrump {
        div.medium-only, div.large-up {
            a:first-child {
                display: none;
            }
        }
    }

    //modul 08, 11, 13, 28
    // div.m08-multimedia,
    .storeview-hide,
    div.m11-lifestyle-slider,
    div.retailer-search,
    footer {
        display: none;
    }
}

//default model page styling
body[data-type="page-default"].storeview {
    //modul 28
    div.m28-special-button {
        display: none;
    }

    //breadcrump
    nav.breadcrump {
        div.medium-only, div.large-up {
            display: none;
        }

        &::before {
            content: none;
        }
    }
}
