.m12-bike-chooser {

    .bike-chooser__header {
        @media #{$mq-medium-up} {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
        }
    }
    .bike-chooser__title {
        // this is to align the button
        margin-bottom: 16px;
    }

    .bike-chooser__slider {
        margin-top: 39px;
    }

    .grid {
        margin-bottom: #{$gap-smallest-part * -2};

        & > * {
            margin-bottom: #{$gap-smallest-part * 2};
        }
    }


    h4 + p {
        @media #{$mq-small-only} {
            width: 80%;
        }
    }

    .price {
        font-family: $font-flexo-demi;
        font-size: 16px;
        line-height: 26px;
        margin: 0;
    }

    ul {
        margin: 24px 0 10px 0;
        padding: 0;
        list-style: none;

        li {
            min-height: 53px;
            margin-bottom: 5px;
        }

        p {
            display: inline-block;
            font-size: 14px;
            line-height: 20px;
            padding-left: 40px;

            span {
                display: block;
            }
        }

        .icon {
            display: inline-block;
            position: absolute;
            width: auto;
            height: 28px;
            top: 6px;
            left: 0;

            > svg {
                max-height: 100%;
            }
        }
    }

    mark {
        margin-bottom: 5px;
        padding: 2px 8px 0 8px;
    }

    .container-text {
        height: 100%;
    }

    .card {
        &__content {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
        }
    }
}
