
#backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $color-black;
    z-index: $z-index-backdrop;
    opacity: 0;
    transform: translateZ(0);
}
