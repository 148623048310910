.bike-advisor__progress {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20px;
    background-color: $color-ultra-light-grey;
    transform: translateY(100%);
    transition: transform var(--bike-advisor-duration-medium) var(--ease-out-cubic);
    z-index: 9;

    @include bike-advisor__size--medium() {
        height: 20px; }

    @include bike-advisor__size--extra-large() {
        height: 30px; }

    &[data-position='sticky-bottom'] {
        transform: translateY(0); }

    &[data-position='hidden'] {
        transform: translateY(100%); } }

.bike-advisor__progress-bar {
    position: absolute;
    width: 0;
    left: 0;
    top: 0;
    height: 100%;
    background-color: $color-black;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    transition: width 0.5s ease-in-out;

    @include bike-advisor__size--medium() {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px; } }
