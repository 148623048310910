$navigation-transition-func: cubic-bezier(.32,.33,.51,1);
$navigation-transition-time: 0.4s;

.configurator__navigation {
    position: absolute; // nav-hover-update. before: position: relative;
    width: 100%;
    height: 50px;
    padding-left: 70px;
    text-align: left;
    background-color: #000000;
    z-index: 10;
    border-bottom: 1px solid $color-warm-grey;
    transition: all $navigation-transition-time $navigation-transition-func;

    @media #{$mq-medium-up} {
        padding-left: 0px;
        text-align: center;
    }

    // navigation highlight as intro and hover state
    &.highlighted {
        @media #{$mq-large-up} {
            height: 140px;
            transform: translateY(-40px);

            .configurator__navigation-step {
                transform: translateY(20px);
            }
        }
    }

    // todo delete double style
    &.hover-enabled:hover {
        .no-touch & {
            @media #{$mq-large-up} {
                height: 140px;
                transform: translateY(-40px);
                z-index: 4; // above tooltip

                .configurator__navigation-step {
                    transform: translateY(20px);
                }
            }
        }
    }

    &.locked {
        position: fixed;
        top: 56px;
    }

    & > .configurator__navigation-step {
        opacity: 1;
        transition: opacity .3s ease;
        transition: transform $navigation-transition-time $navigation-transition-func;

        button {
            opacity: 0;
        }

        .configurator__navigation-link,
        .configurator__navigation-list-pagination {
            opacity: 1;
        }

        &.active {
            opacity: 1;
            pointer-events: all;

            button {
                opacity: 1;
            }

            @media #{$mq-large-down} {
                .configurator__navigation-btn {
                    display: block;
                }
            }
        }
    }

    .configurator__navigation-wrapper {
        display: inline-block;
        position: relative;
        padding: 12px 0px;
        line-height: 0;
        width: 230px;

        @media #{$mq-medium-up} {
            width: 300px;
        }

        @media #{$mq-large-up} {
            width: auto;
        }
    }

    p {
        position: absolute;
        bottom: 0;
    }

    nobr {
        position: relative;
    }

    ul {
        display: inline-block;
        position: relative;
        width: 100%;
        padding: 0px;
        text-align: center;
        user-select: none;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .configurator__navigation-list-item {
            display: none;
            width: auto;

            &:first-child {
                @media #{$mq-medium-up} {
                    margin-left: 15px;
                }
            }

            &:last-child {
                span {
                    display: none;
                }

                @media #{$mq-medium-up} {
                    margin-right: 15px;
                }
            }

            &.active {
                display: inline-block;
            }

            @media #{$mq-medium-up} {
                display: inline-block;
            }

            @media #{$mq-large-up} {
                width: auto;
            }
        }

        @media only screen and (min-width: 1280px) {
            max-width: 860px;
        }

        @media #{$mq-medium-up} {
            margin-left: 0px;
        }

        @media #{$mq-large-up} {
            width: auto;
            max-width: 580px;
            overflow-x: scroll;
            -webkit-overflow-scrolling: touch;
        }
    }

    span {
        color: #6e6964;
    }

    .configurator__navigation-shadow-before,
    .configurator__navigation-shadow-after {
        content: '';
        display: none;
        position: absolute;
        top: 0;
        width: 20px;
        height: 90%; // smaller than nav to prevent overlaying border bottom
        pointer-events: none;
        z-index: 1;

        @media #{$mq-large-up} {
            display: block;
        }
    }

    .configurator__navigation-shadow-before {
        left: 0;
        background-image: linear-gradient(to right,rgba(0,0,0,1) 0%, rgba(0,0,0,1) 65%, rgba(0,0,0,0) 100%)
    }

    .configurator__navigation-shadow-after {
        right: 0;
        background-image: linear-gradient(to left,rgba(0,0,0,1) 0%, rgba(0,0,0,1) 65%, rgba(0,0,0,0) 100%); //linear-gradient(to left, #000000, rgba(0, 0, 0, 0));

    }

    .configurator__navigation-list-pagination {
        position: absolute;
        display: block;
        position: absolute;
        top: 50%;
        width: 12px;
        height: 20px;
        z-index: 2;
        transform: translateY(-50%);

        svg {
            stroke: #ffffff!important;
            transition: all .3s ease;

            g {
                stroke: #ffffff!important;
            }
        }

        &.inactive {
            pointer-events: none;

            svg {
                fill: #6e6964;
                stroke: #6e6964!important;

                g, path {
                    stroke: #6e6964!important;
                }
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    .configurator__navigation-prev svg,
    .configurator__navigation-next svg {
        width: 12px;
        height: 20px;
    }

    .configurator__navigation-prev {
        left: 0px;

        @media #{$mq-medium-up} {
            left: -12px;
        }
    }

    .configurator__navigation-next.inactive {
        display: none;
    }

    .configurator__navigation-next {
        right: 0px;

        @media #{$mq-medium-up} {
            right: -12px;
        }
    }

    .configurator__navigation-last-item {
        display: none;
        width: 23px;
        height: 22px;
        right: 0px;

        svg {
            width: 100%;

            > * {
                fill: none!important; // closing stroke
            }
        }

        @media #{$mq-medium-up} {
            right: -20px;
        }
    }

    .configurator__navigation-link {
        display: none;
        width: 100%;
        padding: 0 10px;
        font-family: $font-flexo-demi;
        font-size: 14px;
        color: #6e6964;
        transition: color .3s ease;
        border-bottom: 0;
        text-transform: uppercase;
        text-overflow: ellipsis;
        overflow: hidden;

        & + span {
            display: none;
        }

        &.active {
            display: block;
            width: 180px;
            color: #ffffff;
        }

        &:hover {
            color: #ffffff;
            border-bottom: 0;
        }

        @media #{$mq-medium-only} {
            max-width: 230px;
        }

        @media #{$mq-medium-up} {
            &.active {
                width: auto;
            }
        }

        @media #{$mq-medium-up} {
            padding: 0 5px;
        }

        @media #{$mq-large-up} {
            display: inline-block;
            width: auto;

            & + span {
                display: inline-block;
            }

            &.active {
                display: inline-block;
            }
        }
    }

    .configurator__navigation-list-btn,
    .configurator__navigation-scroll-btn {
        position: absolute;
        height: 50px;

        svg {
            height: 20px;
            width: 26px;

            path {
                stroke: #ffffff!important;
            }

            &.svg-toggle-list path {
                fill: #ffffff!important;
            }
        }

        @media #{$mq-large-down} {
            display: none;
        }
    }

    .configurator__navigation-list-btn {
        width: 46px;
        top: 3px;
        left: 5px;

        @media #{$mq-medium-up} {
            width: 60px;
            left: 22px;
        }
    }

    .configurator__navigation-scroll-btn {
        width: 55px;
        top: -2px;
        left: 0px;
        transform: rotate(180deg);

        @media #{$mq-medium-up} {
            width: 30px;
            right: 40px;
            left: auto;
        }
    }
}

.configurator__navigation-btn {
    svg {
        pointer-events: none;
    }
}


@keyframes navigation-intro-animation {

    0% {
        height: 140px;
        transform: translateY(-40px);
    }
    100% {
        height: 50px;
        transform: translateY(0px);
    }
}
