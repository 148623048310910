.choice {
    --choice-color: #{$color-warm-grey};
    --duration-fade-out: var(--bike-advisor-duration-medium);
    --choice-growth-hover: 4px;
    --choice-icon-height: 35px;

    @include bike-advisor__size--large() {
        --choice-growth-hover: 6px;
        --choice-icon-height: 40px; }

    @include bike-advisor__size--extra-large() {
        --choice-growth-hover: 7px;
        --choice-icon-height: 40px; }

    display: flex;
    margin: calc(var(--choice-margin) / 2);
    opacity: 0;
    pointer-events: none;
    cursor: pointer;
    transition: opacity var(--duration-fade-out) var(--ease-in-out-cubic);

    svg {
        position: relative;
        display: inline-block;
        height: var(--choice-icon-height);
        top: 1px;
        margin-left: 20px; // 0.35em
        margin-right: calc(var(--choice-margin) / 2);
        stroke: var(--choice-color);
        stroke-width: 1.5px;
        transition: stroke 0.2s var(--ease-out-cubic);
        transform: translate3d(0,0,0); } }

.no-touch .choice:hover,
.choice--pulse {
    --choice-color: #{$color-black};

    .choice__inner {
        &:before {
            width: calc(100% + var(--choice-growth-hover));
            height: calc(100% + var(--choice-growth-hover)); } } }

.choice__inner {
    position: relative;
    font-family: $font-flexo-demi;
    // font-family: $font-flexo-thin
    font-size: 14px;
    line-height: 25px;
    padding: 5px 15px;
    background-color: $color-white;
    color: var(--choice-color);
    transition: opacity var(--duration-fade-out) var(--ease-in-out-cubic), color 0.2s var(--ease-out-cubic), border-color 0.2s var(--ease-out-cubic);

    @include bike-advisor__size--large() {
        font-size: 25px;
        line-height: 40px;
        padding: 0 15px;
        font-family: $font-flexo-regular;
        flex-shrink: 0; }

    @include bike-advisor__size--extra-large() {
        font-size: 40px;
        line-height: 60px;
        padding: 0 18px;
        font-family: $font-flexo-thin; }

    &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        height: 100%;
        border: 1px solid var(--choice-color);
        border-radius: 12px;
        transform: translate(-50%, -50%);
        transition: width 0.15s var(--ease-out-cubic), height 0.15s var(--ease-out-cubic), border-color 0.2s var(--ease-out-cubic);
        pointer-events: none;

        @include bike-advisor__size--large() {
            border: 2px solid var(--choice-color); } } }

.choice--fade-in {
    opacity: 1;
    pointer-events: auto; }

.choice--fade-out {
    opacity: 0;
    pointer-events: none; }

.choice--chosen {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity var(--duration-fade-out) var(--ease-in-out-cubic), transform var(--duration-fade-out) var(--ease-out-cubic), }
