
.button-play {
    $height: 45px;
    $padding: 15px;

    display: flex;
    opacity: 0.8;
    transition: box-shadow 0.2s ease-out;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.3), 2px 2px 5px rgba(0,0,0,0.0);

    &:hover {
        box-shadow: 5px 5px 18px rgba(0,0,0,0.45), 2px 2px 4px rgba(0,0,0,0.3);

        .button-play__icon {
            transform: scale(1.1);
        }
    }

    &__wrapper-icon,
    &__text {
        height: $height;
        line-height: $height;
    }

    &__wrapper-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $height;
        background-color: $color-dark-grey;
    }

    &__text {
        padding: 0 $padding;
        background-color: $color-white;
        font-family: $font-flexo-demi;
    }

    &__icon {
        height: 25px;
        transition: transform 0.2s ease-out;

        path {
            stroke: $color-white;
        }
    }
}

