// COLORS
$color-brand-green-hex: #c8d200;
$color-brand-green: rgba(200, 210, 0, 1);
$color-brand-green-05blk: rgba(190, 199, 002, 1);
$color-brand-green-10blk: rgba(180, 189, 002, 1);
$color-brand-green-20blk: rgba(160, 168, 002, 1);

// SHOP
$color-green: #9fd920;


// COLORS 2018
$color-yellow: rgba(217, 186, 78, 1);
$color-yellow-opaque: rgba(217, 186, 78, 0.5);


$color-white: rgba(255, 255, 255, 1);
$color-nearly-white: rgba(248, 248, 248, 1);
$color-nearly-white-hex: #f8f8f8;
$color-white-shine-through: rgba(255, 255, 255, 0.2);

$color-ultra-light-grey-hex: #f0f0f0;
$color-ultra-light-grey: rgba(240, 240, 240, 1);
$color-light-grey: rgba(226, 225, 225, 1); // #e2e1e1
$color-medium-grey: rgba(168, 165, 164, 1); // #a8a5a4
$color-warm-grey: rgba(110, 105, 103, 1); // #6e6967
$color-dark-grey: rgba(46, 45, 44, 1); // #2e2d2c

$color-main-nav-grey: #22211a;

$color-black: black;
$color-black-shine-through: rgba(0, 0, 0, 0.1);

$color-error: rgb(209, 0, 0);
$color-xing: #135353;
$color-linked-in: #0077b5;
$color-facebook: #3b5998;
$color-twitter: #55acee;
$color-youtube: #e52d27;
$color-instagram: $color-warm-grey;

$color-form-overlay: rgba(255, 255, 255, 0.7);

$color-privacy-layer-cta: #595442;

$color-product-available: $color-green;
$color-product-not-available: $color-error;
$color-product-caption: rgba(255, 255, 255, 0.7);


$color-retailer-tag--experience-store: #676973;
$color-retailer-tag--cargo-hub: #8ea297;

.is-warm-grey {
    color: $color-warm-grey;
}
