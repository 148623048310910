
.accordion-expand-layer {
    display: none;

    &.active {
        display: block;
    }

    .wrapper-clone {
        position: fixed;
        left: 0;
        width: 100%;
        z-index: $z-index-accordion-expand-layer;

        .wrapper-morph {
            &:after {
                content: '';
                position: absolute;
                left: 50%;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: inherit;
                z-index: 0;
                transform: translate(-50%,0);
                transition: width 0.7s ease-in-out;
            }
        }

        &.is-morphed {
            .wrapper-morph {
                // important to override style-attr
                background-color: $color-white !important;

                .button-circle.button-expand {
                    .svg_btn_expanded {
                        opacity: 0;
                    }
                    .svg_btn_close {
                        opacity: 1;
                    }
                }

                // overwrite white fonts, svgs...
                &.color-white {
                    color: $color-black !important;
                    p, a, label, h1, h2, h3, h4 {
                        color: $color-black !important;
                    }
                    a {
                        border: $color-black !important;
                    }
                    .button-circle {
                        color: $color-black !important;
                        .circle {
                            &:after {
                                border-color: $color-black !important;
                            }
                        }
                        svg, svg > line {
                            fill: $color-black !important;
                            stroke: $color-black !important;
                        }
                    }
                }

                &:after {
                    transition: width 0.9s ease-in-out;
                    width: 102vw;
                }
            }

            .wrapper-button {
                &:before {
                    transition: opacity 0.45s ease-in-out 0.45s;
                    opacity: 1;
                }
            }
        }

        .wrapper-button {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 26px * 2;
                background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $color-white);
                transform: translateY(calc(-100% - 19px));
                opacity: 0;
                transition: opacity 0.45s ease-in-out;
                pointer-events: none;
            }
        }

        &.hide-gradient {
            .wrapper-button {
                &:before {
                    transition: opacity 0.45s ease-in-out;
                    opacity: 0 !important;
                }
            }

            .content-expand {
                &:after {
                    display: none !important;
                }
            }
        }

        &.is-expanded {

            .accordion-expand,
            .container-center,
            .wrapper-morph {
                height: 100%;
            }

            .rb__container-text {
                overflow: scroll;
            }

            .mask-expand {
                height: auto;

                .content-expand {
                    &:after {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 26px * 2;
                    }
                }
            }
        }
    }
}
