
.list {
    $margin-icon-text: 10px;
    $radius-icon: 50px;
    $vertical-space-item--small: 70px;
    $vertical-space-item--large: 100px;

    &__layout {
        margin-top: $vertical-space-item--small;

        @media #{$mq-medium-up} {
            margin-top: $vertical-space-item--large;
        }

        &--single-column {
            @media #{$mq-medium-up} {
                display: none;
            }
        }

        &--double-column {
            @media #{$mq-small-only} {
                display: none;
            }
        }
    }

    &__column-inner {
        &--right {
            padding-top: $vertical-space-item--large;
        }
    }

    &__item {
        & + & {
            margin-top: $vertical-space-item--small;

            @media #{$mq-medium-up} {
                margin-top: $vertical-space-item--large;
            }
        }
    }

    &__item-header {
        display: flex;
        align-items: center;
    }

    &__item-icon {
        flex: 1 0 auto;
        width: $radius-icon;
        height: 0;
        max-width: $radius-icon;
        margin-right: $margin-icon-text;

        & > * {
            transform: translateY(-50%);
        }
    }

    &__item-svgimg img {
        display: inline-block;
        max-width: 100%;
    }

    &__item-index {
        $font-size--small: 40px;
        $font-size--medium: 45px;

        width: 100%;
        line-height: 0 ;
        font-size: $font-size--small;
        font-family: $font-flexo-regular;
        text-align: center;

        @media #{$mq-medium-up} {
            font-size: $font-size--medium;
        }
    }

    &__item-body {
        margin-top: 30px;
        margin-left: $radius-icon + $margin-icon-text;
    }
}
