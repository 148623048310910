
.text-spaces {
    p:empty {
        height: 0;
        margin: 0;
        top: 0;
    }

    p:empty + * {
        margin-top: 0;
    }

    // SPACES OUTSIDE OF CONTAINER_TEXT_LAYOUT
    > * + p,
    > * + ul,
    > * + ol,
    > * + .btn-base,
    > * + .wrapper-table,
    > * + .text-spaces--form,
    > * + figure {
        margin-top: 30px;
    }

    > ul + *,
    > ol + *,
    > .wrapper-table + *,
    > .text-spaces--form + *,
    > .btn-base + * {
        margin-top: 20px;
    }

    > .spacer + .wrapper-button,
    > .text-spaces--form + .wrapper-button,
    > .wrapper-button + .wrapper-button {
        margin-top: 30px;
    }

    > h1 + *,
    > h1 + .addition + *:not(.ignore-addition) {
        margin-top: 52px;

        @media #{$mq-medium-up}
        {
            margin-top: 59px;
        }
    }

    > h2 + *,
    > h2 + .addition + *:not(.ignore-addition),
    > h2 + *:empty + *,
    > h2 + *:empty + .addition + *:not(.ignore-addition) {
        margin-top: 39px;
    }

    h2 + figure {
        margin-top: 50px;
    }

    h2 + p.poster {
        margin-top: 50px;
    }


    > h3 + *,
    > h3 + .addition + *:not(.ignore-addition) {
        margin-top: 30px;

        .card {
            margin-top: 20px;
        }
    }

    > h4 + *,
    > h4 + .addition + *:not(.ignore-addition) {
        margin-top: 26px;

        .card & {
            margin-top: 20px;
        }
    }

    > * + h1 {
        margin-top: 100px;
    }

    > * + h2 {
        margin-top: 80px;
    }

    > * + h3,
    > * + h4 {
        margin-top: 60px;
    }

    > h1 + h2,
    > h2 + h3,
    > h3 + h4 {
        margin-top: 30px;

        @media #{$mq-large-up}
        {
            margin-top: 40px;
        }
    }

    // IMPLIZIT
    // .spacer is a new helper class for forgotten or special elements
    > .spacer + .spacer,
    > p + .spacer,
    > .spacer + p,
    > a + .spacer,
    > .spacer + a,
    > p + p,
    > a + a,
    > a + p,
    > p + a {
        margin-top: 25px;
    }

    > .force-spaces {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    > br + p {
        margin-top: 50px;
    }

    p.poster + * {
        margin-top: 40px;
    }

    figure + p.poster {
        top: 9px;
    }

    img + figcaption {
        margin-top: 11px;
    }

    // Just preserving / resetting the line-height
    > .addition + h1,
    > h1 + .addition {
        margin-top: #{7px + 15px};

        @media #{$mq-medium-up}
        {
            margin-top: #{9px + 16px};
        }
    }

    > .addition + h2,
    > h2 + .addition {
        margin-top: #{6px + 11px};
    }

    > .addition + h3,
    > h3 + .addition {
        margin-top: 28px;
    }

    > .addition + h4,
    > h4 + .addition {
        margin-top: 28px;
    }


    > * + mark {
        margin-top: 25px;
    }

    > .btn-base + p {
        margin-top: 60px;
    }

    > p + p.large-break {
        margin-top: 30px;
    }

    > ul li + li,
    > ol li + li {
        margin-top: 20px;
    }
}
