.overlay-mail-update-subscribe {

    &__contents {
        position: relative;
    }

    .step--input,
    .step--success,
    .step--error {
        position: absolute;
        top: 0;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    .loader-gif,
    .button--cancel,
    .button--back,
    .button--subscribe,
    .button--link-my-bike {
        display: none;
    }

    .loader-gif {
        position: absolute;
        left: 50%;
        top: 50%;
    }

    @mixin show-step() {
        position: relative;
        opacity: 1;
        transition: opacity 0.3s ease-in-out 0.3s;
    }

    &[data-status='input'] {
        .step--input {
            @include show-step();
        }

        .button--cancel,
        .button--subscribe {
            display: flex;
        }
    }

    &[data-status='requesting'] {
        .loader-gif {
            display: block;
        }

        .step--input {
            @include show-step();
        }
    }

    &[data-status='success'] {
        .step--success {
            @include show-step();
        }

        .button--link-my-bike {
            display: flex;
        }
    }

    &[data-status='error'] {
        .step--error {
            @include show-step();
        }

        .button--back {
            display: flex;
        }
    }
}
