.return-shipping-form {
    &__radiobutton-label {
        display: block !important;
    }

    &__radiobutton-label-inner {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @media #{$mq-small-only} {
            flex-wrap: wrap;
        }

        span {
            font-size: 16px;
            letter-spacing: 0.03em;

            &:first-child {
                font-family: "FlexoW03-Demi";
            }

            &:last-child {
                @media #{$mq-small-only} {
                    width: 100%;
                    margin-top: 10px;
                }
            }
        }
    }

    &__radiobutton-copy {
        display: block;
        width: 100%;
        margin-top: 20px;
    }

    &__hidden-radio-content {
        padding-top: 30px;
    }

    &__wrapper-radiobutton,
    &__hidden-radio-content {
        & + .return-shipping-form__wrapper-radiobutton {
            margin-top: 60px;
        }
    }

    &__info-box {
        position: relative;
        padding: 30px 35px;

        .label + p {
            margin-top: 10px;
        }

        .label + .button-underline {
            margin-top: 5px;
        }
    }
}
