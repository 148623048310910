
figure {
    position: relative;
    overflow: hidden;

    & > img {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        &.preview {
            opacity: 1;
        }
    }

    &.background {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }

    &.background--left { background-position: center left; }
    &.background--center { background-position: center center; }
    &.background--right { background-position: center right; }

    @media #{$mq-large-down} {
        &.background--small-left { background-position: center left; }
        &.background--small-center { background-position: center center; }
        &.background--small-right { background-position: center right; }
    }

    &.image-placeholder {
        background-color: $color-warm-grey;
        .icon-riesemueller {
            visibility: visible;
            fill: white;
            width: 100%;
            height: auto;
            margin: 40px;
        }
    }
}
