.info-box {
    background-color: $color-ultra-light-grey;
    padding: 35px;

    &__headline {
        @include base-text();
        @include base-headline();
        @include headline-h2();
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 60px;
    }

    &__bottom-right {
        margin-left: 20px;

        &:empty {
            margin-left: 0;
        }
    }
}
