.advised-bike {
    display: block;

    &[data-type='recommended'] {} }


.advised-bike__header {}


.advised-bike__media {}


.advised-bike__info {
    @media #{$mq-small-only} {
        margin-top: 20px; } }

.advised-bike__bike-button,
.advised-bike__series-button {
    margin-top: 15px;

    @media #{$mq-medium-up} {
        margin-top: 25px; } }

.bike-chooser__cta {
    margin-top: 30px;

    @media #{$mq-medium-up} {
        margin-top: 50px; } }

.advised-bike__media-figure {
    @include relative-height(3/2);

    margin-bottom: 20px;

    figure {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        transition: transform 0.3s ease-out; } }

.advised-bike__headline {
    &:is(h2) {
        margin-top: 10px; }

    &:is(h3) {
        margin-top: 5px; } }

.bike-chooser__copy {
    margin-top: 15px;
    font-size: 18px;
    line-height: 26px; }

.advised-bike__media-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 20px; }

.advised-bike__colors {
 }    // margin-top: 5px


.advised-bike__price {
    text-transform: none; }

