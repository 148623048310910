.configurator__step-options {
    flex-wrap: nowrap;

    &.scrollable {
        @media #{$mq-large-up} {
            overflow-y: scroll!important;

            .configurator__step-tooltip--desktop {
                display: none!important;
            }
        }

        @media only screen and (min-width: $screen-large) and (max-width: $screen-max-width) {           
            .configurator__step-item {
                flex-basis: 28.57%;;
            }
        }

        @media (min-width: 1400px) {
            .configurator__step-item {
                flex-basis: 22.22%;
            }
        }
    }

    .configurator__step-item {
        width: 220px;
        flex: 0 0 auto;
        flex-wrap: nowrap;
        border-right: 2px solid #ffffff;
        overflow: hidden;

        input + label {
            position: relative;
            background-color: $color-nearly-white!important; // TODO do not use important. Style soll vereinheitlicht werden
            transition: all 0.3s ease-in-out;
            padding: 0;

            .configurator__label-wrapper {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0px;
                left: 0px;
                padding: 20px 20px 13px 20px;
            }

            &:after {
                display: none;
                position: absolute;
                content: '';
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                border-top: 25px solid $color-light-grey;
                border-right: 30px solid transparent;
                border-left: 30px solid transparent;
                z-index: 99;

                @media #{$mq-large-up} {
                    top: 50%;
                    left: 0;
                    border-top: 30px solid transparent;
                    border-bottom: 30px solid transparent;
                    border-left: 25px solid $color-light-grey;
                    transform: translateY(-50%);
                }
            }

            &:hover {
                .configurator__label-wrapper {
                    opacity: 0.8;
                }
            }
        }

        // gloabl style of wrapper-radio-box overwrite
        // TODO vereinheitlichen
        //.custom-inputs .wrapper-radio-box input:checked+label,
        &.wrapper-radio-box.active input:checked + label {
            opacity: 1;
            background-color: $color-light-grey!important;
        }

        .wrapper-button {
            position: relative;
            bottom: 50px;
            opacity: 0.3;
            width: 50px;
            margin-left: 100%;
            right: 70px;

            @media #{$mq-medium-up} {
                bottom: 60px;
            }

            @media #{$mq-large-up} {
                position: absolute;
                top: 20px;
                bottom: auto;
                right: 20px;                
            }

            @media #{$mq-small-only} {
                .button-circle.button-small .circle {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        &.wrapper-radio-box.active .wrapper-button {
            opacity: 1;
        }

        span + h2 {
            margin-top: 5px;

            @media #{$mq-medium-up} {
                margin-top: 10px;
            }
        }

        &:last-child {
            border-right: 0;
        }

        .configurator__counter + h3 {
            margin-top: 5px;

            @media #{$mq-small-up} {
                margin-top: 10px;
            }
        }

        h3 + .configurator__counter {
            margin-top: 10px;

            @media #{$mq-medium-up} {
                margin-top: 20px;
            }
        }

        h3 {
            // TODO quick fix for load-75 iPad optimisation ... better scrolling enabled
            line-height: 28px;
            max-width: 100%;

            @media #{$mq-small-only} {
                font-size: 20px;
                line-height: 25px;
                max-width: none;
                padding-right: 0;

                max-height: 75px;
                overflow: hidden;
            }

            @media #{$mq-large-up} {
                padding-right: 55px;
            }
        }

        .configurator__counter,
        .configurator__price {
            font-family: $font-flexo-demi;
            font-size: 14px;

            @media #{$mq-small-up} {
                font-size: 16px;
            }
        }

        .configurator__price {
            position: absolute;
            bottom: 20px;

            @media #{$mq-large-up} {
                display: inline-block;
                position: relative;
                bottom: auto;
                margin-top: 25px;
            }
        }

        @media #{$mq-large-up} {
            width: 100%;
            flex-grow: 1;
            opacity: 1;
            border-right: 0px;
            border-bottom: 2px solid #ffffff;
            overflow: visible;

            &:last-child {
                border-bottom: 0;
            }
        }
    }

    @media #{$mq-large-up} {
        height: auto;
    }
}
