
// Common style / partial for .m31-faq and .m25-contact
.faq-filter {
    .reset-search {
        content: 'e';
        font-family: $font-icons;
        -webkit-font-smoothing: antialiased;
        text-transform: none;
        text-align: right;
        position: absolute;
        right: 10px;
        bottom: 10px;
        font-size: 14px;
        height: 25px;
        line-height: 25px;
        color: $color-warm-grey;
        cursor: pointer;
        z-index: 1;

        transition: color 0.25s ease-out, opacity 0.35s ease-in-out;

        &:hover {
            .no-touch & {
                color: $color-error;
            }
        }
    }

    .button-submit-search {
        display: block;
        margin: 0;
        // Schiebt Button an Ende der flex-box (über input jetzt label als floatendes Element)
        margin-top: auto;
    }

    .button-load-more {
        margin-top: 40px;
    }

    .hint-no-results {
        text-align: center;
        padding: 20px 0;
    }

    .container-filter-faq + .grid {
        @include gap-for(margin-top, $gap-3);
    }

    .container-filter-faq {
        transition: opacity 0.5s ease-in-out;

        &.disabled {
            opacity: 0.5;
        }

        &.disabled,
        &.disabled select,
        &.disabled .custom-inputs input {
            pointer-events: none;
        }
    }

    .faq-container-search {
        display: flex;

        .faq-wrapper-input {
            position: relative;
            flex-grow: 1000;
        }

        .button-submit-search {
            margin-left: 10px;
        }
    }
}