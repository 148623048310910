fieldset {
    border: none;
}

input,
textarea,
select {
    font-size: 14px;
}

.description {
    font-size: 14px;
    line-height: 18px;

    &-block {
        font-size: 14px;
        display: block;
        margin-top: 10px;
    }
}

.description,
.description-block {
    a {
        font-size: inherit;
        line-height: inherit;
    }
}
