
.custom-inputs {
    .input-search--dropdown-style {
        $font-size--mq-small: 18px;
        $font-size--mq-medium: 25px;
        $item-height--mq-small: 45px;
        $item-height--mq-medium: 45px;
        $line-height: 130%;
        $offset--padding: 20px;

        font-family: $font-flexo-thin;
        font-size: $font-size--mq-small;
        line-height: $line-height;

        @media #{$mq-medium-up} {
            font-size: $font-size--mq-medium;
        }

        .input-field {
            font-family: $font-flexo-thin;
            background-color: transparent;
            padding: 0 45px 0 0;
            font-size: $font-size--mq-small;
            line-height: $line-height;

            @media #{$mq-medium-up} {
                font-size: $font-size--mq-medium;
            }
        }

        .container-options {
            left: -1 * $offset--padding;
            top: -1 * $offset--padding / 2;
            right: -1 * $offset--padding;
            width: calc(100% + #{2 * $offset--padding});
            padding-top: $item-height--mq-small + $offset--padding / 2;

            @media #{$mq-medium-up} {
                padding-top: $item-height--mq-medium + $offset--padding / 2;
            }
        }

        .option {
            padding: $offset--padding;
        }

        .btn-submit-search,
        .btn-reset-input {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);

            svg {
                width: 22px;
            }
        }

        .btn-submit-search {
            display: none;
        }

        &.input-search--unfilled {
            .btn-submit-search {
                display: block;
            }
        }
    }
}
