.myrm-services-info-box {

    .service-ends {
        margin-top: 20px;

        > span{

            &.end-date {
                @extend h2;

                display: inline-block;
                background: $color-white;
                text-align: center;
                color: $color-black!important; // otherwise overwriten by .color-white
                padding: 0 10px;
            }
        }
    }

    .wrapper-button + .wrapper-button {
        margin-top: 15px;
    }

    .rb__container-text:not(.disclaimer-footer) {

        .wrapper-button {
            margin-top: 0;
        }
    }

    // TODO
    #container-fixed & {

        .m24-forms {
            display: flex;
            flex-direction: column;
            height: 100%;

            .card-poster {
                flex: 1 1 auto;
            }
        }
    }
}

.myrm-theft-service {

    .m24-forms {
        textarea {
            height: 110px;
        }
    }

    .m30-layer-content & {

        .m24-forms {

            .feedback {
                position: static;
                margin-bottom: 40px;
                display: none;

                .background-wrapper {
                    padding-bottom: 30px;
                }
            }

            &[data-state=form-state-success] {

                form {
                    display: none;
                }

                .feedback.success {
                    display: block;
                }
            }

            &[data-state=form-state-error] {

                form {
                    display: none;
                }

                .feedback.error {
                    display: block;
                }
            }
        }

        .myrm-info-box {
            background-color: $color-light-grey;

            .text-small {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0 15px;

                svg {
                    margin-right: 20px;
                    flex: 0 0 20px;

                    rect {
                        fill: $color-black;
                    }

                    path {
                        stroke: $color-black;
                    }
                }

                & > span {
                    flex: 1 1 auto;
                }
            }
        }

        .card-poster:not(.myrm-info-box) {
            padding: 10px 0 25px 0;
        }

        .myrm-theft-service__copy {
            margin-bottom: 55px;
        }

        .wrapper-button {
            margin-top: 30px;
        }

        .required-text {
            margin-bottom: 10px;
        }
    }
}
