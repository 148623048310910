// Tabs
.booking {

    .cards-separator {
        background-color: $color-white;

        .card {
            padding: 20px;

            &:nth-child(1n+2) {
                background-image: linear-gradient(to right, $color-black, $color-black);
                background-size: calc(100% - 40px) 1px;
                background-repeat: no-repeat;
                background-position: top left 20px;
                padding-top: 21px;
            }
        }

        .card {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            //justify-content: flex-end;

            &__item-product {
                display: flex;
                flex-direction: column;

                flex: 0 0 100%;
                padding-right: 40px; // for trash
                @media #{$mq-medium-up} {
                    flex: 0 0 50%;
                    padding-right: 0; // for trash
                }
            }

            &__item-variant {
                display: flex;
                flex-direction: column;

                flex: 0 0 100%;
                margin-top: 30px;

                @media #{$mq-medium-up} {
                    flex: 0 0 50%;
                    margin-top: 0;
                    padding-right: 40px; // for trash
                }
            }

            &__item-amount {
                flex: 0 0 25%;
                align-items: flex-end;
            }

            &__item-price {
                flex: 0 0 25%;
                text-align: right;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-left: auto;
            }

            &__item-delete {
                position: absolute;
                top: 0;
                right: 0;

                button {
                    padding: 20px;
                }
            }

            &__item-label {
                font-size: $tsmall_fontsize;
                line-height: $tsmall_lineheight;

                &:not(:first-child) {
                    margin-top: 30px;
                }
            }

            &__item-copy,
            &__item-headline {
                font-size: 16px;
                line-height: 26px;
            }

            &__item-headline {
                font-family: $font-flexo-demi;
            }
        }

        + .wrapper-button {
            margin-top: 30px;
        }
    }


    .booking-summary {

        @media #{$mq-medium-up} {
            display: flex;
        }

        &__headline {
            flex: 0 0 25%;

            font-size: $p_fontsize;
            line-height: $p_lineheight;
            font-family: $font-flexo-demi;
            text-transform: uppercase;

            margin-bottom: 20px;

            @media #{$mq-medium-up} {
                margin-bottom: 0;
            }

        }

        &__content {
            flex: 0 0 75%;
        }

        &__subtotal,
        &__total,
        &__brutto,
        &__netto,
        &__voucher,
        &__shipping {
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;

            font-size: 16px;
            line-height: 18px;
            color: $color-medium-grey;
        }

        .booking-summary__section + .booking-summary__section {
            background-image: linear-gradient(to right, $color-medium-grey, $color-medium-grey);
            background-size: 100% 1px;
            background-repeat: no-repeat;
            background-position: top left;
            padding-top: 25px;
            margin-top: 15px;
        }

        &__total {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            line-height: 18px;
            font-family: $font-flexo-demi;
            color: $color-white;
        }

        &__tax {
            display: flex;
            justify-content: space-between;
        }

        &__tax,
        #booking-summary__tax-value {
            font-size: $tsmall_fontsize;
            line-height: $tsmall_lineheight;
            color: $color-medium-grey;
        }
    }

    .wrapper-form-fields {
        left: 0;
        padding-right: var(--grid-gutter-horizontal);
        padding-top: var(--grid-gutter-vertical);
        background-color: $color-white;

        .text-spaces.grid {
            margin-left: 0;
            left: 0;
        }
    }

    .wrapper-form-fields.grid {
        // margin-top: 30px; // ansch: das ist eigentlich zu viel abstand --> siehe meine daten
        width: 100%;

        + .wrapper-form-fields.grid {
            margin-top: 20px;
        }
    }

    .checkout-retailer-preview:empty {
        background: red;
        margin: 0;
    }

    .text-spaces > h2 + .content-toggle {
        margin-top: 30px;
    }

    .tabs--step-circ {

        .content-wrapper {
            display: flex;
            flex-direction: column;

            // Show all items in last step
            .content-item {

                > * {
                    //flex: 1 1 auto;
                }

                &.content-current {
                    &.sub-module {
                        padding-top: $padding-sub-modules-s;

                        @media #{$mq-medium-up} {
                            padding-top: $padding-sub-modules-m;
                        }

                        @media #{$mq-large-up} {
                            padding-top: $padding-sub-modules-l;
                        }
                    }
                }
            }
        }
    }

    // Tabs
    // Edit Mode
    .content-toggle .preview {
        .wrapper-button {

            button.button-toggle-content {
                display: flex;
            }

            button[data-type="open-tab-with-edit-mode"] {
                display: none;
            }

        }
    }

    .content-toggle .edit-mode {
        button.button-toggle-content {
            display: flex;
            opacity: 1;
            pointer-events: all;
        }
    }

    .tabs--step-circ.tabs--last-tab {
        .content-toggle .preview {
            .wrapper-button {

                button.button-toggle-content {
                    display: none;
                }

                button[data-type="open-tab-with-edit-mode"] {
                    display: flex;
                }
            }
        }
    }

    [data-state="form-state-error"],
    [data-state="form-state-success"] {
        .module {
            &:before {
                height: $padding-modules-mq-small * 2;

                @media #{$mq-medium-up} {
                    height: $padding-modules-mq-medium * 2;
                }

                @media #{$mq-large-up} {
                    height: $padding-modules-mq-large * 2;
                }
            }
        }
    }
}
