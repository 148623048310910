
a.button-language-chooser {
    position: relative;

    img {
        height: 11px;
        margin-left: 6px;
        // margin-right: 6px;
        // vertical-align: bottom;
    }
}