
.tooltip {
    display: inline-block;
    width: 14px;
    height: 14px;
    top: 2px;
    position: relative;
    margin: 0 #{$gap-smallest-part * 0.5};
    z-index: $z-index-tooltip;
    cursor: pointer;

    .btn-tooltip {
        margin: 0;
    }

    .box {
        position: absolute;
        width: #{320px - $gap-2-small * 2};
        left: 50%;
        bottom: 100%;
        margin: 0 0 15px 0;
        cursor: default;
        pointer-events: none;

        opacity: 0;
        transform: translate3d(-50%, -10px, 0);
        transition: opacity 0.5s, transform 0.35s;
    }

    .arrow {
        position: absolute;
        top: 100%;
        left: 50%;
        height: 0;
        width: 0;
        border: solid transparent;
        border-color: transparent;
        border-top-color: $color-light-grey;
        border-width: 10px;
        margin-left: -10px;
    }

    .tooltip-text {
        display: block;
        position: relative;
        font-size: 12px;
        letter-spacing: 0.05em;
        font-family: $font-flexo-bold;
        background-color: $color-light-grey;
        padding: $gap-smallest-part #{$gap-smallest-part * 2};
    }
}

.touch {
    .tooltip.expanded {
        .icon-info:before {
            content: 'e';
        }

        .box {
            pointer-events: auto;
            opacity: 1;
            transform: translate3d(-50%, 0, 0) rotate3d(0, 0, 0, 0);
        }
    }
}

.no-touch {
    .tooltip:hover .box {
        pointer-events: auto;

        opacity: 1;
        transform: translate3d(-50%, 0, 0) rotate3d(0, 0, 0, 0);
    }
}