
$gap-0: 0;
$gap-1: 1;
$gap-2: 2;
$gap-3: 3;
$gap-4: 4;

// Standard-Einheit, die über alle MQs hinweg gleich bleibt
$gap-smallest-part: 10px;

$gap-1-small: 10px;
$gap-2-small: 20px;
$gap-3-small: 30px;
$gap-4-small: 55px;

$gap-1-medium: 10px;
$gap-2-medium: 20px;
$gap-3-medium: 30px;
$gap-4-medium: 70px;

$gap-1-large: 10px;
$gap-2-large: 20px;
$gap-3-large: 40px;
$gap-4-large: 100px;

$padding-container-center-small: 25px;
$padding-container-center-medium: 55px;
$padding-container-center-large: 55px;


// Größen / Abstände für bestimmte Elemente
$min-height-bar: 40px;
$thickness-line: 2px;


$gap-modules-small: 30px;
$gap-modules-medium: 40px;
$gap-modules-large: 80px;

$height-top-bar: 56px;
$height-cms-top-bar: 45px;

$height-myrm-sub-nav-s: 60px;
$height-myrm-sub-nav-m-l: 70px;

$background-circle: 50px;
$background-circle-large: 70px;


