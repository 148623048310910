svg {
    > * {
        vector-effect: non-scaling-stroke;
    }

    .color-white & {
        > * {
            stroke: $color-white;
        }
    }
}