.press-content {
    &__headline {
        @include paragraph($font-flexo-demi);
        margin-bottom: 3px;
    }

    &__wrapper-button {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }

    &--three-col {
        .card .container-text {
            padding-bottom: 30px;
        }
    }

    &--four-col {
        figure {
            margin: 20px 20px 0 20px;;
        }
    }
}
