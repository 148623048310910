
.opacity-0 {
    opacity: 0;
}

.hidden {
    pointer-events: none;
    opacity: 0;
}

.display-none {
    display: none !important;
}

.small-only {
    @media #{$mq-medium-up} {
        display: none !important;
    }
}

.medium-up {
    @media #{$mq-small-only} {
        display: none !important;
    }
}

.medium-only {
    @media #{$mq-small-only} {
        display: none !important;
    }

    @media #{$mq-large-up} {
        display: none !important;
    }
}

.large-down {
    @media #{$mq-large-up} {
        display: none !important;
    }
}

.large-up {
    @media #{$mq-small-only}, #{$mq-medium-only} {
        display: none !important;
    }
}