
// Globaler Helper-Klassen, die je nach Kontext ( Beispiel: .slider.full-height ) erweitert werden können
.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.vertical-centered {
    display: flex;
    align-items: center;
}

.absolute-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.center-button-wrapper {
    width: 100%;
    text-align: center;
}

.no-wrap {
    white-space: nowrap;
}

// Um z.B. inaktive GUI-Elemente auszublenden / auszugrauen
.disabled {
    transition: opacity 0.3s ease-in-out;
    opacity: 0.4;

    pointer-events: none !important;

    & * {
        pointer-events: none !important;
    }
}

// Für Maske mit Inhalt mit variabler Höhe
// Animationen werden mit services/handler/accordion-handler.js gesteuert
.mask-resize {
    height: 0;
    overflow: hidden;

    &.expanded {
        height: auto;
    }
}

.no-touch .touch-only {
    display: none !important;
}

// Hilfsklasse, die GPU-Rendering erzwingt
.cache-gpu {
    transform: translateZ(0);
}

.align-self-end {
    align-self: flex-end;
}
