
// folgende Klassen werden für "overlay" und "overlay-search" verwendet
.overlay-wrapper-horizontal {
    position: relative;
    min-height: 100%;
    width: 100%; //calc(100% - 20px);
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    // manche layer sind nicht fullscreen
    .overlay-search &,
    &.overlay-language-chooser-wrapper-animation {
        width: calc(100% - 20px);

        @media #{$mq-medium-up} {
            width: #{$screen-medium - 2*60px};
        }

        @media #{$mq-large-up} {
            width: #{$screen-large - 2*160px};
        }
    }
}

.overlay-base-wrapper {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
        
    &.active {
        display: block;
    }

    .overlay-btn-close {
        position: absolute;
        right: 25px;
        top: 25px;
        height: 24px;
        // Button vor overlay-content setzen
        z-index: 2;

        &:before {
            content: 'X'; // dünnes X verwenden 'e';
            font-family: $font-icons;
            -webkit-font-smoothing: antialiased;
            text-transform: none;
            line-height: 20px;
            font-size: 20px;
            transition: color 0.25s ease-out;
        }

        @media #{$mq-medium-up} {   
            right: 30px;       
            top: 35px;
            transform: scale(1.5);
        }
    }
}

