@mixin bike-advisor__size--medium() {
    @media #{$mq-medium-up} {
        @content; } }

@mixin bike-advisor__size--large() {
    @media #{$mq-medium-up} {
        @content; } }

@mixin bike-advisor__size--extra-large() {
    // @media #{$mq-extra-large-up}
    @media only screen and (min-width: 1920px ) and (min-height: 1400px ) {
        @content; } }

@import "header";
@import "tool";
@import "question";
@import "sentence";
@import "choice";
@import "choices";
@import "progress";
@import "advised-bike-card";
@import "summary";
@import "results";

.bike-advisor {
    --top-bar-height: 56px;

    --bike-advisor-duration-medium: 0.3s;
    --bike-advisor-duration-large: 0.85s;
    --bike-advisor-duration-extra-large: 1.2s;

    --ease-in-cubic: cubic-bezier(0.32, 0, 0.67, 0);
    --ease-in-out-cubic: cubic-bezier(0.33, 1, 0.68, 1);
    --ease-out-cubic: cubic-bezier(0.65, 0, 0.35, 1);
    --ease-out-back: cubic-bezier(0.34, 1.56, 0.64, 1);

    // if used as header remove top-padding
    &:first-child {
        &:before {
            display: none; } }

    &:before,
    &:after {
        position: relative;
        background-color: $color-white;
        z-index: 2; }

    &[data-state='loading'] {
        pointer-events: none;

        * {
            pointer-events: none; }

        .bike-advisor__tool {
            overflow: hidden; } }

    &[data-state='start'] {
 }        //

    &[data-state='ask'] {
        .bike-advisor__tool-button-back {
            opacity: 1;
            pointer-events: auto; } }

    &[data-state='fade'] {
        pointer-events: none;

        * {
            pointer-events: none; }

        &[data-fade-to='ask'] {
            .bike-advisor__tool-button-back {
                opacity: 1;
                pointer-events: auto; } } }

    &[data-state='end'] {
        .bike-advisor__tool-button-back,
        .bike-advisor__tool-button-restart {
            opacity: 1;
            pointer-events: auto; } } }


.bike-advisor__tool-button-back,
.bike-advisor__tool-button-restart {
    opacity: 0;
    pointer-events: none;
    transition: opacity var(--bike-advisor-duration-medium) var(--ease-in-out-cubic); }
