@mixin loader($size: 28px, $border: 4px) {
    width: $size;
    height: $size;
    margin: -14px 0 0 -14px;

    border: $border solid $color-light-grey;
    border-top: $border solid $color-black;
    border-right: $border solid $color-black;

    border-radius: 100%;
    transform: translateZ(0);
    animation: spinning-loader 1.5s linear infinite;
}
