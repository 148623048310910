.input-toggle {
    $size-handle: 20px;
    $border-width: 4px;
    $track-width: #{$size-handle * 2.2};
    $duration: 0.1s;
    $easing: ease-out;
    $color-handle: $color-white;
    $color-unchecked: $color-black;
    $color-checked: $color-green;

    &__checkbox {
        @include hide-input-for-custom-checkbox();

        &:checked {
            &:disabled + .input-toggle__label {
                opacity: 0.4
            }

            & + .input-toggle__label {
                background-color: $color-checked;
                border-color: $color-checked;

                &:after {
                    left: 100%;
                }
            }
        }

        &:disabled + .input-toggle__label {
            cursor: default;
            pointer-events: none;
            opacity: 0.35;
        }
    }

    &__label {
        box-sizing: content-box;
        display: block;
        position: relative;
        width: $track-width;
        height: $size-handle;
        background-color: $color-unchecked;
        border-radius: #{$size-handle / 2 + $border-width};
        border: $border-width solid $color-unchecked;
        cursor: pointer;
        transition: background-color $duration $easing, border-color $duration $easing;

        &:after {
            content: '';
            position: absolute;
            left: $size-handle;
            top: 0;
            width: $size-handle;
            height: $size-handle;
            border-radius: 50%;
            background-color: $color-handle;
            transform: translateX(-100%);
            transition: left $duration $easing;
        }
    }
}

