.payment-methods {

    // Accordion
    .payment-method-form {
        display: none;

        &.active {
            display: block;
        }
    }


    // Abstände
    .payment-methods__forms {
        .grid {
            margin-top: 0;
        }

        .payment-method__radios .grid > * {
            margin-bottom: 0;
        }

        .payment-method-form {
            .wrapper-form-fields.grid {
                margin-bottom: 0;
            }
        }

        //.payment-method-toggle > .grid > *:first-child,
        //.wrapper-form-fields .grid > *:first-child {
        //    margin-top: 0;
        //}

        *[data-type="payment-method-radio"] + *[data-type="payment-method-radio"] {
            margin-top: 5px;
        }

        // Submit button hack for background
        .payment-method-form .wrapper-button {
            flex-direction: column;
            align-items: flex-end;

            position: absolute;
            right: calc(var(--grid-gutter-horizontal) * -1);
            //right: -25px;

            @media #{$mq-medium-up} {
                row-gap: 20px;
            }
        }

        &--button-offset-1 {
            margin-bottom: 130px;

            @media #{$mq-medium-up} {
                margin-bottom: 130px;
            }

            .payment-method-form .wrapper-button {
                bottom: -130px;

                @media #{$mq-medium-up} {
                    bottom: -130px;
                }
            }
        }

        &--button-offset-2 {
            margin-bottom: 180px;

            @media #{$mq-medium-up} {
                margin-bottom: 220px;
            }

            .payment-method-form .wrapper-button {
                bottom: -180px;

                @media #{$mq-medium-up} {
                    bottom: -220px;
                }
            }
        }

        .form-label + .field-read-only {
            .custom-inputs & {
                margin-top: 10px;
                display: inline-block;
            }
        }
    }

    .m24-forms[data-state="form-state-error"] form,
    .m24-forms[data-state="form-state-success"] form {
        pointer-events: auto;
        opacity: 1;
    }

    // Style Radio with Copy Sizes and SVG
    .wrapper-radiobutton input+label.radiobutton__label {

        display: flex;
        justify-content: space-between;
        padding: 0 0 0 40px;

        .radiobutton__content {
            margin-top: -4px;
        }

        .radiobutton__icon {
            display: inline-flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            padding-left: 15px;

            > svg {
                width: 40px;
                height: auto;
                margin-left: 5px;
                margin-bottom: 5px;
            }
        }
    }



    // Feedback Screens
    .payment-methods__forms {
        .feedback-success,
        .feedback-error {

        }

        //&[data-state="form-state-success"],
        &[data-state="form-state-error"] {
            .payment-method__radios {
                display: none;
            }

            .payment-method-form.active {
                width: 100%;
            }
        }
    }
}

.booking {
    .radiobutton__label {
        .radiobutton__lead,
        .radiobutton__subline {
            display: block;
        }

        .radiobutton__lead {
            font-size: 16px;
            line-height: 26px;
        }

        .radiobutton__subline {
            font-size: 14px;
            color: $color-medium-grey;
        }
    }
}

.radiobutton {
    &__grid {
        display: grid;
        grid-template-columns: 3fr 1fr;
    }

    &__price {
        text-align: right;
        grid-column: 2;
        grid-row: 1;
    }
}


// Mollie Components Styling
.mollie-mount {
    width: 100%;
}

.mollie-component {
    display: block;
    width: 100%;
    height: 45px;
    background: $color-ultra-light-grey;
    border: none;
    border-bottom: 1px solid $color-black; // $color-brand-green;
    border-radius: 0;
    margin-top: 15px;
    padding: 0 20px;
    -webkit-appearance: none;
    -moz-appearance: none;

    transition: color 0.25s ease-in-out, background-color 0.25s ease-out;

    &.has-focus {
        background-color: $color-light-grey;
    }

    &.is-invalid {
        border-bottom-color: $color-error;
    }
}

