
.media {
    // Kann mit * alles zusammengefasst werden?
    // * + .caption
    figure + .caption,
    .wrapper-youtube + .caption {
        width: 100%;
        text-align: center;
        margin-top: 25px;

        @media #{$mq-medium-up} {
            margin-top: 20px;
        }
    }
}