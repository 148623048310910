
.footer {
    position: relative;

    &__menus {
        padding: 40px 0 48px;

        @media #{$mq-large-down} {
            .grid > * {
                margin-bottom: 44px;
            }
        }
    }

    &__meta {
        padding: 25px 0;
    }

    &__container-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        border-left: 1px solid $color-warm-grey;
        padding: 12px 0 12px 15px;
    }

    a {
        border-color: transparent;
    }


    &__menus {
        a {
            @include paragraph($font-flexo-demi);
        }
    }

    &__socials {

        display: flex;
        height: 40px;
        margin-bottom: 20px;
        column-gap: 25px;

        @media #{$mq-medium-up} {
            order: 2;
            justify-content: flex-start;
            margin-bottom: 0;
        }

        .btn-icon.small {
            display: block;
            color: $color-warm-grey;
            background-color: transparent;
            width: 32px;
            height: 30px;
            margin: 0;

            &:nth-child(n+2) {
                margin-left: $gap-smallest-part;
            }

            &:before {
                font-size: 20px;
                line-height: 32px;
            }

            &:after {
                background-color: transparent !important;
            }
        }

        .button-circle  {
            .circle {
                margin: 0;
            }

            svg {
                left: 28%;
                top: 28%;
                width: 44%;
                height: 44%;
            }

            svg,
            svg > path {
                fill: $color-white;
            }
        }
    }

    &__meta-links {
        margin-left: -30px;

        .link-footer {
            text-transform: uppercase;
            margin-left: 30px;
            margin-bottom: 20px;

            @include text--small($font-flexo-demi);

        }
    }
}
