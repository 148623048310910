
.m25-contact {
    .container-headline {
        margin-bottom: 30px;
    }

    .container-filter-faq > h3 {
        margin-bottom: 30px;
    }

    .btn-open-layer {
        transition: opacity 0.35s ease-in-out 0.5s
    }

    .mask-accordion {
        margin-bottom: 30px;
    }
}
