
.icon-info {
    position: relative;

    span, div {
        display: block;
        position: absolute;
        left: 6px;
        width: 1px;
        background-color: $color-black;

        pointer-events: none;

        &:first-child {
            top: 2px;
            height: 2px;
        }

        &:nth-child(2) {
            top: 6px;
            height: 5px;

            &:before, &:after {
                content: '';
                position: absolute;
                height: 1px;
                background-color: $color-black;
            }

            &:before {
                top: 0;
                left: -1px;
                width: 1px;
            }

            &:after {
                bottom: 0;
                left: -1px;
                width: 3px;
            }
        }
    }

    &:before {
        display: none;
    }
}

.btn-icon {
    &.icon-info {
        display: inline-block;
        position: relative;
        padding: 0;
        width: 13px !important;
        height: 13px !important;
    }
}

.tooltip .icon-info {
    top: auto;
}

h3 .btn-icon.icon-info,
h4 .btn-icon.icon-info {
    top: 0;
    margin-left: 5px;
}


// New for Button Icon Info
.btn--icon-info-inline {
    vertical-align: sub;
    height: 20px;

    svg {
        pointer-events: none;
        width: 20px;
        height: 20px;
    }
}
