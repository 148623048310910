.language-chooser,
#maps-permission-layer,
#overlay {
    z-index: $z-index-overlay;

    .module,
    .m30-layer-content {
        &::before {
            display: none;
        }

        padding-top: $overlay-header-height;

        @media #{$mq-medium-up} {
            padding-top: $padding-modules-mq-medium;
        }

        @media #{$mq-large-up} {
            padding-top: $padding-modules-mq-large;
        }
    }

    .m30-layer {
        .module {
            &::after {
                display: none;
            }

            &:first-child {
                margin-top: 0;
                padding-top: 20px;

                @media #{$mq-medium-up} {
                    padding-top: $padding-modules-mq-medium;
                }

                @media #{$mq-large-up} {
                    padding-top: $padding-modules-mq-large;
                }
            }

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 20px;

                @media #{$mq-medium-up} {
                    padding-bottom: $padding-modules-mq-medium;
                }

                @media #{$mq-large-up} {
                    padding-bottom: $padding-modules-mq-large;
                }
            }

            padding-top: 0px;
            margin-top: 20px
        }
    }

    // with background to scroll text under white
    // ACHTUNG: untershcied zwischen fullsize und nicht fullsize layer
    .btn-overlay-wrapper {
        $height: $overlay-header-height;

        position: relative;
        top: $height;
        background-color: $color-white;
        width: calc(100% - 20px);
        height: $height;
        // Höhe des Buttons abziehen, dass sich overlay-container in exakter Mitte befindet
        margin-top: -$height;
        // Button vor overlay-content setzen
        z-index: 2;

        @media #{$mq-medium-up} {
            $height: 60px;

            width: calc(100% - 35px);
            top: $height;
            margin-top: -$height;
            height: $height;
        }

        .color-white & {
            color: $color-white
        }
    }

    // used for layersets
    .pinned {
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
    }

    &.type-iframe {
        .wrapper-vertical {
            height: 100%;
            overflow-y: hidden;
        }

        .content {
            height: 100%;
            padding-top: 70px;

            @media #{$mq-medium-up} {
                padding-top: 80px;
            }
        }
    }

    iframe {
        width: 100%;
        height: 100%;
    }

    .content {
        position: relative;
    }

    .content > .m24-forms {
        @media #{$mq-medium-up} {
            // scrollbar reset
            transform: translateX(9px);
        }
    }

    .click-catcher {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
    }

    .container-overlay {
        opacity: 0;

        &:not(.is-set) {
            .button-pager {
                display: none;
            }
        }
    }

    // scrollable container
    .wrapper-vertical {
        width: 100%;
        background-color: $color-white;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;

        &.while-sending-form {
            overflow: hidden;

            .content {
                position: relative;

                &:before {
                    content: '';
                    position: fixed;
                    left: 50%;
                    top: 50%;
                    width: 20px;
                    height: 20px;
                    z-index: 3;
                    margin: -14px 0 0 -14px;
                    border: 4px solid $color-light-grey;
                    border-top: 4px solid $color-black;
                    border-right: 4px solid $color-black;

                    border-radius: 100%;
                    transform: translateZ(0);
                    animation: spinning-loader 1.5s linear infinite;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: $color-form-overlay;
                    z-index: 2;
                }
            }
        }
    }

    p + figure.module-spacing {
        margin-top: 70px;

        @media #{$mq-medium-up} {
            margin-top: 100px;
        }
    }

    @keyframes spinning-loader {
        from {
            transform: translateZ(0) rotate(0deg);
        }
        to {
            transform: translateZ(0) rotate(360deg);
        }
    }

    // fullsize overlay
    &.fullsize {
        position: fixed;
        top: 0;

        .wrapper-vertical {
            position: fixed;
            top: 0;
            width: 100%;
            height: 100%;
        }

        // rebrush 2019-02 fullsize: old layer html has container-center element,
        // that must be overwritten to fit in grid
        .content .m30-layer {
            > .module > .container-center {
                width: 100%;
            }

            .text-spaces > h2 + .slider-rebrush {
                margin-top: 60px;
            }

            .text-spaces .slider-rebrush + p.poster {
                margin-top: 40px;
            }

            p.poster:last-child {
                margin-bottom: 60px;
            }
        }

        .module {
            @media #{$mq-small-only} {
                padding-top: 77px;
            }
        }

        .btn-overlay-wrapper {
            position: absolute;
            right: 0;
            top: 0;
            left: 0;
            pointer-events: none;
            margin-top: 0;
            background-color: transparent;
            z-index: 2;

            .overlay-btn-close {
                right: 0;
                margin-right: 0;
                pointer-events: all;

                @media #{$mq-large-down} {
                    width: 40px;
                    height: 40px;
                    right: -10px;
                    border-radius: 50%;
                    background-color: $color-white;

                    &:before {
                        transition: color .25s ease-out;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }

        .button-pager {
            display: none;

            &:not(.disabled) {

                @media #{$mq-medium-up} {
                    display: block;
                    position: absolute;
                    top: 50%;
                    margin-top: -20px;
                }

                @media #{$mq-medium-only} {
                    //     100 Fensterbreite - (( 768px - (2 * 60px)) / 2)
                    width: calc((100vw - #{$screen-medium - 2* 60px}) / 2);
                }

                &.left {
                    left: 10px;
                }

                &.right {
                    right: 20px;
                }
            }
        }
    }

    &.background-grey {
        .wrapper-vertical {
            background-color: $color-ultra-light-grey;
        }

        @media #{$mq-large-down} {
            .btn-overlay-wrapper {
                .overlay-btn-close {
                    background-color: $color-ultra-light-grey;
                }
            }
        }
    }

    &.background-black {
        .wrapper-vertical {
            background-color: $color-black;
        }

        @media #{$mq-large-down} {
            .btn-overlay-wrapper {
                .overlay-btn-close {
                    background-color: $color-black;
                }
            }
        }
    }
}


/***************
* special styles
 - layer not fullsize
*/
.language-chooser,
#maps-permission-layer {

    .module {
        @media #{$mq-small-only} {
            padding-top: $padding-modules-mq-small;
        }
    }

    .m30-layer {
        padding: 0 20px;
        background-color: transparent;
        -webkit-transform: translateZ(0px);

        @media #{$mq-medium-up} {
            padding: 0 80px;
        }
    }

    .content,
    .container-center {
        width: 100%;
    }

    .wrapper-vertical {
        max-height: calc(100% - 20px);

        @media #{$mq-medium-up} {
            max-height: calc(100% - 120px);
        }

        @media #{$mq-large-up} {
            max-height: calc(100% - 200px);
        }
    }
}

/* layer slider */
.m30-layer {
    .slider-rebrush .slider-snap-scroll .owl-nav {
        @media #{$mq-large-up} {
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

// for layer content which sits within a template and will be moved to overlay
.m30-layer-content {
    display: none;

    #overlay & {
        display: block;
    }
}


// fix da es im configurator einen modal content gibt der nur mobile mobil ein modal sein soll
// desktop immer sichtbar
.large-up-only > .m30-layer-content {
    display: block;
}

// "hack" to enable overflow-scrolling
.language-chooser {
    .wrapper-vertical {
        max-height: 100vh;
    }
}
