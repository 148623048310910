/**
 TODO : @mixin button-underline() von button-circle
 USED: Productdetail ConnectCare
 **/

 .button-underline-small,
 .button-circle.button-underline.button-underline-small {
    display: inline-flex;
    width: auto;

    @media #{$mq-small-only} {
        height: 45px;
        margin-bottom: 0;

        > small {
            line-height: 14px;
        }
    }

    .circle.icon-download-small svg {
        max-width: 23px;
        max-height: 20px;
        width: auto;
        height: auto;
    }
 }


// <a> und <button> should be used depending if you need to trigger a function or you want to visit another page via href
// to us a button wich looks like a link (design reasons) i added the basic link style to a mixin and included it for this button class
.button--textlink {
    @include text-link-base();
}
