@mixin pill-active($bg: $color-black, $color: white) {
    background-color: $bg;
    color: $color;
}

@mixin pill-disabled($bg: white, $color: #b2b2b2) {
    background-color: $bg;
    color: $color;
    border-color: $color;
}

// Usable with: a, button, div, input
// pill wrapper for list of pills
.pills-list {
    $margin: 20px;

    &__inner {
        display: flex;
        flex-wrap: wrap;
        margin: #{-$margin/2} #{-$margin/2};
    }

    &__label {
        margin-bottom: 15px;
        @include text-bold-medium()
    }

    &__label--small {
        margin-bottom: 15px;
        @include dateline()
    }

    .pill {
        margin: $margin/2 $margin/2;
    }
}

.pills-grid {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

// pill styles

// a / button
a.pill, // higher specificity for a
.pill {
    $height: 50px;
    $color: $color-black;
    $color-2nd: white;
    $color-disabled: #b2b2b2;

    display: flex;
    background-color: $color-2nd;
    border: solid 2px $color;
    border-radius: 25px;
    height: 50px;
    padding: 15px 20px;
    vertical-align: middle;
    align-items: center;

    font-family: $font-flexo-regular;
    color: $color;
    font-size: 16px;
    @media #{$mq-medium-up} {
        font-size: 18px;
    }
    line-height: 21px;
    cursor: pointer;

    transition: color .2s ease-out,
    background-color .2s ease-out;


    &:hover {
        .no-touch & {
            background-color: $color;
            color: $color-2nd;
        }
    }

    &--active {
        @include pill-active;
    }

    &--disabled {
        @include pill-disabled;
        pointer-events: none;
    }

    &.pill--small {
        height: 40px;
        font-size: 12px;

        .radio-pill__label-color {
            width: 10px;
            height: 10px;
        }
    }

    &.pill--extra-small {
        height: 30px;
        padding: 13px 20px;
        font-size: 16px;
        font-family: $font-flexo-demi;
    }
}

// pill input
.radio-pill,
.checkbox-pill {
    position: relative;

    @media #{$mq-small-only} {
        flex-basis: 100%;
    }

    input {
        @include hide-input-for-custom-checkbox();
    }

    input:checked + label {
        @include pill-active;
    }

    input[disabled] + label {
        @include pill-disabled;
        pointer-events: none;
    }

    &--inactive {
        label {
            @include pill-disabled;
        }
    }

    label:hover {
        .no-touch & {
            border-color: $color-black;
        }
    }
}
