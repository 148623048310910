
.module.base-teaser {
    .grid {
        @media #{$mq-small-only} {
            // entsprechender Ausgleich
            margin-bottom: #{$gap-modules-small * -2};
        }
    }

    .card {
        @media #{$mq-small-only} {
            // doppelter einfacher Modulabstand zwischen cards, um in MQ small einheitliche Optik zu erzielen
            margin-bottom: #{$gap-modules-small * 2};
        }
    }
}