.m09-article {
    .m02-highlight-content {
        .grid {
            // Innerhalb von Artikeln sollen Bild-Text-Kombination nach oben bündig ausgerichtet sein
            align-items: flex-start;

            @media #{$mq-small-only} {
                & > *:nth-child(2) {
                    margin-top: 10px;
                }
            }
        }
    }
}
