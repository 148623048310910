
.datepicker-container {
    background-color: #212121;
    direction: ltr;
    font-size: 12px;
    left: 0;
    line-height: 30px;
    position: fixed;
    top: 0;
    -ms-touch-action: none;
    touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: -1;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;

    @media #{$mq-small-only} {
        width: 100%; // 100%;
        left: 0 !important;
    }
    // transform: translateX(-20px);

    @media #{$mq-medium-up} {
        width: 448px;
        transform: translateX(0);
    }
}

.datepicker-container::before,
.datepicker-container::after {
    border: 5px solid transparent;
    content: " ";
    display: block;
    height: 0;
    position: absolute;
    width: 0;
}

.datepicker-dropdown {
    //border: 1px solid #ccc;
    //-webkit-box-shadow: 0 3px 6px #ccc;
    //box-shadow: 0 3px 6px #ccc;
    //-webkit-box-sizing: content-box;
    box-sizing: content-box;
    position: absolute;
    z-index: 1;
}

.datepicker-inline {
    position: static;
}

.datepicker-top-right::before,
.datepicker-top-right::after,
.datepicker-bottom-right::before,
.datepicker-bottom-right::after {
    left: auto;
    right: 10px;
}

.datepicker-panel > ul {
    margin: 0;
    padding: 0;
    width: 102%;
}

.datepicker-panel > ul::before,
.datepicker-panel > ul::after {
    content: " ";
    display: table;
}

.datepicker-panel > ul::after {
    clear: both;
}

.datepicker-panel > ul > li {
    // background-color: #212121;
    cursor: pointer;
    float: left;
    height: 30px;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 14%;
    color: white;
    line-height: 30px;
}

.datepicker-panel > ul > li.muted,
.datepicker-panel > ul > li.muted:hover {
    color: #999;
}

.datepicker-panel > ul > li[data-view="day"]:hover {
    .no-touch & {
        &:before {
            content: "";
            position: absolute;
            display: block;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            border: 1px solid $color-white;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
        }
    }
}

.datepicker-panel > ul > li.picked,
.datepicker-panel > ul > li.picked:hover {
    position: relative;
    color: $color-black;

    &:before {
        content: "";
        position: absolute;
        display: block;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: 1px solid $color-white;
        background-color: $color-white;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }
}

[data-view="month picked"] {
    &:before {
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}

[data-view="year picked"] {
    &:before {
        display: none !important;
    }
}

.datepicker-panel > ul > li.disabled,
.datepicker-panel > ul > li.disabled:hover {
    color: #ccc;
    cursor: default;
}

.datepicker-panel > ul > li[data-view="years prev"],
.datepicker-panel > ul > li[data-view="year prev"],
.datepicker-panel > ul > li[data-view="month prev"],
.datepicker-panel > ul > li[data-view="years next"],
.datepicker-panel > ul > li[data-view="year next"],
.datepicker-panel > ul > li[data-view="month next"],
.datepicker-panel > ul > li[data-view="next"] {
    font-size: 18px;
}

.datepicker-panel > ul > li[data-view="years current"],
.datepicker-panel > ul > li[data-view="year current"],
.datepicker-panel > ul > li[data-view="month current"] {
    width: 70%;
}

.datepicker-panel > ul[data-view="years"] > li,
.datepicker-panel > ul[data-view="months"] > li {
    height: 52.5px;
    line-height: 52.5px;
    width: 52.5px;
}

.datepicker-panel > ul[data-view="week"] > li,
.datepicker-panel > ul[data-view="week"] > li:hover {
    background-color: #212121;
    cursor: default;
}

.datepicker-hide {
    display: none;
}

[data-view="month"], [data-view="month current"], [data-view="year"], [data-view="year current"], [data-view="years"], [data-view="years current"] {
    padding-top: 3px !important;
}

[data-view="month prev"], [data-view="month next"], [data-view="year prev"], [data-view="year next"], [data-view="years prev"], [data-view="years next"] {
    color: $color-white !important;
    font-size: 26px !important;
}