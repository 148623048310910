.m16-bike-configurator-rb {
    @media (min-width: 1400px) {
        .configurator__preview-content-wrapper {
            h2 {
                font-size: 45px;
                margin: 50px 0 30px 0;
            }

            .configurator__price--wrapper {
                padding: 7px 10px 5px 10px;

                .configurator__price,
                .configurator__price + span {
                    font-size: 18px;
                }
            }
        }

        .configurator__stage {
            height: calc(100vh - 56px - 100px)!important;
        }

        .configurator__navigation-wrapper {
            line-height: 76px;
        }

        .configurator__navigation {
            height: 101px!important; // 100px height + 1px border

            &.highlighted {
                height: 100px;
                transform: translateY(0px);
    
                .configurator__navigation-step {
                    transform: translateY(0px);
                }
            }

            ul {
                max-width: 780px;
                vertical-align: middle;

                li {
                    font-size: 22px;
                }
            }

            .configurator__navigation-prev {
                left: -35px;
            }

            .configurator__navigation-next {
                right: -35px;
            }

            .configurator__navigation-last-item {
                right: -39px;
            }

            .configurator__navigation-link {
                font-family: $font-flexo-regular;
                font-size: 22px;
                padding: 0 10px;
            }

            .configurator__navigation-list-pagination {
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    top: calc(50% - 1px);
                    left: calc(50% + 1px);
                    transform: translate(-50%, -50%);
                    border: 2px solid $color-white;
                    border-radius: 50%;
                }

                &.inactive {
                    &:after {
                        border: 2px solid #6e6964;;
                    }
                }

                &.configurator__navigation-next {
                    &:after {
                        left: calc(50% - 1px);
                    }
                }
            }

            .configurator__navigation-list-item {    
                &:first-child {
                    margin-left: 25px;
                }

                &:last-child {
                    margin-right: 25px;
                }
            }

            .configurator__navigation-scroll-btn,
            .configurator__navigation-list-btn {
                height: 100%;
                top: 50%;
                line-height: 0;
                transform: translateY(-50%);

                svg {
                    height: 40px;
                }
            }
    
            .configurator__navigation-scroll-btn {
                transform: translateY(-50%) rotate(180deg);
            }

            &.hover-enabled:hover {
                .no-touch & {
                    height: 100px;
                    transform: translateY(0px);
    
                    .configurator__navigation-step {
                        transform: translateY(0px);
                    }
                }
            }

            .configurator__navigation-shadow-before,
            .configurator__navigation-shadow-after {
                width: 40px;
            }
        }
    }
}