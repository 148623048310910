$top-bar-z-index: $z-index-top-bar;
$top-bar-height: 105px;
$top-bar-height-narrowed: $height-top-bar;

.top-bar {
    position: fixed;
    left: 0;
    right: 0;
    z-index: $top-bar-z-index;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 20px;

    @media #{$mq-medium-up} {
        padding: 0 40px;
    }

    a {
        border: none !important;
    }

    &__button-label,
    &__primary-menu-item {
        font-family: $font-flexo-regular;
        font-size: 16px;
        letter-spacing: 0;
    }

    .btn-icon {
        width: 24px;
    }

    &__button {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 10px;

        @media #{$mq-medium-up} {
            padding: 16px;
        }

        &-label {
            padding-left: 8px;
            cursor: pointer;
            margin: 0 !important; // override margin from text-small
            line-height: 1;
        }

        &-icon {
            line-height: 0;

            svg {
                height: 25px;
                width: auto;

                circle {
                    fill: $color-brand-green;
                }
            }
        }
    }

    &__menu {
        display: flex;
        align-items: center;
        z-index: 0;
        height: 100%;
    }

    &__store-view > * {
        margin-left: 20px;

        @media #{$mq-medium-up} {
            margin-left: 40px;
        }
    }

    &__logo {
        transform: translateY(4px);
    }

    &__logo,
    &__menu {
        flex: 0 0 auto;
    }

    &__primary-menu {
        flex: 1 1 auto;
        display: flex;
        justify-content: center;
        height: 100%;
        margin: 20px;
        overflow: hidden;

        &-item {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 12px;
            z-index: 0;
            white-space: nowrap;
        }
    }

    &__button,
    &__primary-menu-item {
        position: relative;

        &::before {
            content: '';
            background-color: $color-black;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0;
            z-index: -1;
            transition: height 200ms ease-in-out, background-color 200ms ease-in-out;
        }

        &:focus,
        &:hover {
            color: $color-white;

            &::before {
                height: 100%;
            }
        }

        &:active {
            &::before {
                background-color: $color-dark-grey;
            }
        }
    }

    &__button {
        transition: stroke 0.25s ease-out, color 0.25s ease-out;

        svg {
            path,
            line,
            ellipse {
                transition: stroke 0.25s ease-out, fill 0.25s ease-out;
            }
        }

        &:focus,
        &:hover,
        &:active {
            svg {
                path,
                line,
                ellipse {
                    stroke: $color-white;
                }
            }

            .top-bar__button-icon--retailer svg path {
                stroke: none;
                stroke-width: 0;
                fill: $color-white;
            }
        }
    }

    &.top-bar--force-burger,
    &.top-bar__primary-menu--overflow {
        .top-bar__menu {
            order: 1;
            flex-direction: row-reverse;
        }

        .top-bar__primary-menu {
            order: 2;
        }

        .top-bar__logo {
            order: 3;
        }
    }

    @media #{$mq-large-down} {
        .top-bar__menu {
            order: 1;
            flex-direction: row-reverse;
        }

        .top-bar__primary-menu {
            order: 2;
        }

        .top-bar__logo {
            order: 3;
        }
    }
}

// Color inverted gui (used in context of dark header images)
.inverted-gui .top-bar:not(.narrowed) {
    .top-bar__button,
    .top-bar__primary-menu-item {
        color: $color-white;

        &:before {
            background-color: $color-black;
        }

        &:focus,
        &:hover {
            color: $color-black;

            .top-bar__button-label {
                color: $color-black;
            }

            &:before {
                background-color: $color-white;
            }
        }
    }

    .top-bar__button {
        &:focus,
        &:hover,
        &:active {
            svg {
                path,
                line,
                ellipse {
                    stroke: $color-black;
                }
            }

            .top-bar__button-icon--retailer svg path {
                stroke: none;
                stroke-width: 0;
                fill: $color-black;
            }
        }
    }

    .btn-icon {
        color: $color-white;
    }

    .top-bar__button-label {
        color: $color-white;
    }

    .top-bar__logo svg {
        fill: $color-white;
    }

    .top-bar__button-icon {
        svg {
            path,
            line,
            ellipse {
                stroke: $color-white;
            }
        }
    }

    .top-bar__button-icon--retailer svg path {
        stroke: none;
        stroke-width: 0;
        fill: $color-white;
    }
}


// Grow / Shrink Animation
.top-bar {
    &__primary-menu--overflow {
        .top-bar__primary-menu {
            opacity: 0;
        }
    }
}


// Grow / Shrink Animation
.top-bar {
    transition: height 0.25s ease-out 0.25s;
    height: $top-bar-height;

    &__button-icon svg,
    &__logo svg {
        transition: height 0.25s ease-out 0.25s;
        width: auto;
        height: 28px;
    }

    &.narrowed,
    &.narrowed .inverted-gui &,
    body[data-type="page-myrm"] & {
        transition: height 0.25s ease-out;
        height: $top-bar-height-narrowed;

        .top-bar__button-icon svg,
        .top-bar__logo svg {
            transition: height 0.25s ease-out;
        }

        .top-bar__button-icon svg {
            height: 20px;
        }

        .top-bar__logo svg {
            @media #{$mq-medium-up} {
                height: 16px;
            }

            @media #{$mq-large-up} {
                height: 20px;
            }
        }
    }
}


// Background Animation (triggered when bar narrows)
.top-bar {
    &__bg-animation {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: $top-bar-height-narrowed;

        .background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            @media #{$mq-medium-up} {
                transition: transform 0.5s ease-out 0s;
            }

            &:nth-child(1) {
                transform: translate3d(-100%, 0, 0);
                background-image: linear-gradient(to right, rgba($color-nearly-white-hex, 1) 0%, rgba($color-nearly-white-hex, 1) 50%, rgba($color-nearly-white-hex, 0) 100%);
            }

            &:nth-child(2) {
                transform: translate3d(100%, 0, 0);
                background-image: linear-gradient(to right, rgba($color-nearly-white-hex, 0) 0%, rgba($color-nearly-white-hex, 1) 50%, rgba($color-nearly-white-hex, 1) 100%);
            }
        }

        .shadow {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.1);
            opacity: 0;
            transform: translateZ(0);

            @media #{$mq-medium-up} {
                transition: opacity 0.5s ease-in-out;
            }
        }
    }

    &.narrowed,
    &.narrowed .inverted-gui &,
    body[data-type="page-myrm"] & {
        .top-bar__bg-animation {
            .background {
                @media #{$mq-medium-up} {
                    transition: transform 0.5s ease-out 0.15s;
                }

                &:nth-child(1) {
                    transform: translate3d(0, 0, 0);
                }

                &:nth-child(2) {
                    transform: translate3d(0, 0, 0);
                }
            }

            .shadow {
                opacity: 1;
            }
        }
    }
}

