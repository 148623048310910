
.configurator__bar-purchase {
    position: absolute;
    left: 0;
    top: 50px;
    height: #{40px + 2 * 15px};
    width: 100vw;
    background-color: $color-white;
    z-index: 8;

    .container-center {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &--stick-to-window-bottom {
        position: fixed;
        top: auto;
        bottom: 0;
    }

    &--stick-to-summary-bottom {
        position: absolute;
        top: auto;
        bottom: 0;
    }

    @media #{$mq-medium-up} {
        display: none;
    }
}
