// This is used in combination with the icon font

// BUTTON ICON
.btn-icon:before,
.btn-icon-text:before {
    position: absolute;
    top: 0; //25%;
    height: 100%;
    font-family: $font-icons;
    font-size: 26px;
    line-height: 48px; //  !important; //stsch: vorher 45px, waren aber nicht in er Mitte. Mit ansch besprochen.
    font-style: normal;
    text-transform: none;
}

// icons can be found in _icons.scss

.btn-icon,
a.btn-icon {
    @extend .btn-base;

    // geerbte Eigenschaft (display: block MQ < small) überschreiben
    display: inline-block;
    width: 45px;
    padding: 0;
    text-transform: none;
    text-align: center;
    border: none !important;
    cursor: pointer;

    // todo - playground
    color: $color-black;
    background-color: transparent;

    .no-touch & {
        &:hover {
            color: $color-black;
            background-color: transparent;
        }
    }

    &:before {
        left: 0;
        width: 100%;
        -webkit-font-smoothing: antialiased;
    }

    &.small {
        width: 16px;
        height: 16px;

        &:before {
            font-size: 10px;
            line-height: 16px;
        }
    }

    // zusätzliche Größe 'medium' ergänzt:
    &.medium {
        $height-medium: #{ $gap-smallest-part * 2};

        width: $height-medium;
        height: $height-medium;

        &:before {
            font-size: 12px;
            line-height: $height-medium;
        }
    }
}

p .btn-icon {
    height: 14px;
    width: 14px;

    &:before {
        line-height: 14px;
        font-size: 10px;
    }
}

h2 .btn-icon,
h3 .btn-icon,
h4 .btn-icon {
    vertical-align: middle;
}

h2 .btn-icon {
    width: 22px;
    height: 22px;
    padding: 0;
    margin: 0;

    &:before {
        line-height: 22px;
        font-size: 16px;
    }
}

h3 .btn-icon {
    $height: 16px;
    $fontSize: #{ 16px * 0.7};

    display: inline-block;
    width: $height;
    height: $height;
    padding: 0;
    // z.B. benutzt in m13b > gilt das nicht für alle?
    margin-left: $gap-smallest-part;

    &:before {
        line-height: $height;
        font-size: $fontSize; // 16px;

    }
}

// Insbesondere für Filter-Menü / evtl. auslagern
h4 .btn-icon,
li .btn-icon {
    $height: 14px;
    $fontSize: #{ 14px * 0.7};

    width: $height;
    height: $height;
    padding: 0;
    // z.B. benutzt in m13b > gilt das nicht für alle?
    margin-left: $gap-smallest-part;

    &:before {
        line-height: $height;
        font-size: $fontSize; // 16px;
    }
}

//.btn-icon-text,
a.btn-icon-text,
button.btn-icon-text {
    @extend .btn-base;

    padding-right: 42px;
    // padding: 0 42px 0 20px;

    &:before {
        right: 15px;
        font-size: 9px;
        top: -3px;
    }
}

a.btn-icon-text {
    // padding: 13px 4em 0 20px;
}

// please check if this is deprecated
// there is a new external link .link--external here: _link.scss
.btn-external-link {
    display: inline-block;
    position: relative;
    width: auto;
    //height: 45px;
    cursor: pointer;
    z-index: $z-index-button;
    text-align: left;
    font-family: $font-flexo-regular;
    padding: 0 0 0 0;
    text-transform: none;
    border-bottom: none;
    font-size: 16px;
    margin: 0 0 5px 0;

    &:before {
        content: '>';
        position: absolute;
        top: 0; //25%;
        right: -15px; //2.5em;
        height: 100%;
        font-family: $font-icons;
        font-size: 13px;
        line-height: 22px;
        font-style: normal;
        text-transform: none;
    }

    &.reverse {
        &:before {
            position: static;
            right: auto;
            padding-right: 5px;
        }
    }

    &:hover {
        .no-touch & {
            color: $color-black;
            border-bottom: none;
        }
    }

    @media #{$mq-small-up}
    {
        display: inline-block;
        width: auto;
        transition: color 0.35s
    }
}


// btn with icons only (used in social media module)
// new since 05/2020

.btn--icon-only {
    display: inline-block;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 0 5px;
    cursor: pointer;

    border-bottom: none;

    .btn__icon {
        display: inline-block;
        height: 30px;
    }

    svg {
        width: auto;
        height: 30px;
    }

    .no-touch & {

        svg * {
            transition: stroke 0.25s ease-out, fill 0.25s ease-out;
        }

        &:hover {

            border-bottom: none;

            svg {
                * {
                    fill: $color-medium-grey;
                }
            }
        }
    }
}
