.privacy-container {
    position: fixed;
    bottom: 0;
    z-index: $z-index-overlay;

    width: 100%;

    .privacy-layer {
        display: none;
        width: 100%;
        text-align: left;
        background: $color-nearly-white-hex;
        padding: 40px 0 30px 0;
        margin-bottom: 0;
        border-top: 1px solid rgba(0,0,0,.1);

        h3,
        small {
            color: $color-black;
            border-color: $color-black;
        }

        small {
            @media #{$mq-medium-up} {
                padding-right: 25%;
            }

            display: block;
            margin-top: 10px;

        }

        @media #{$mq-medium-down} {
            p {
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.05em;

                a {
                    font-size: inherit;
                }
            }
        }

        br:last-child {
            @media #{$mq-medium-up} {
                display: none;
            }
        }
    }
}

.m18-accordion.m18-accordion--privacy {
    .accordion .bar {
        align-items: center;
    }

    .accordion .bar .label.left {
        width: calc(100% - 120px);
    }
}

.privacy-layer, .privacy-settings {

    .btn-external-link {
        font-family: $font-flexo-demi;
        color: $color-privacy-layer-cta;
        margin: 0;

        &:before {
            content: none;
        }
    }

    a:hover {
        .no-touch & {
            color: $color-black;
            border-color: $color-black;
        }
    }

    .wrapper-button {
        flex-direction: column;

        &.flex-right {
            align-items: flex-end;
        }

        @media #{$mq-medium-up} {
            justify-content: flex-end;
            flex-direction: row;
            flex-wrap: wrap;
        }

        button + button {
            margin-top: 10px;
        }
    }
}

.checkbox.checkbox--privacy {
    position: relative;
    padding-left: 0;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        width: 15px;
        height: 15px;
        text-align: center;
        border: solid 2px #000;
        box-sizing: border-box;
        background-color: #fff;

        svg {
            position: absolute;
            display: none;
            top: -3px;
            left: 2px;
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    input:checked ~ .needed,
    input:checked ~ .security {
        opacity: 0.5;
    }

    input:checked ~ .checkmark {
        border-right: none;

        svg {
            display: block;
        }
    }
}

