.m55-model-series-scroll-button {
    position: relative;
    width: 100%;
    height: 1px;
    overflow: visible;

    // move trigger
    .model-series-scroll-button-trigger {
        position: absolute;
        top: -100px;

        @media #{$mq-medium-up} {
            bottom: 30px;
            top: -350px;
        }
    }

    .model-series-scroll-button-item {
        position: fixed;
        height: 72px;
        left: auto;
        right: 10px;
        bottom: 30px;
        background-color: $color-dark-grey;
        border-radius: 50px;
        overflow: hidden;
        color: #ffffff;
        padding: 30px 30px 28px 35px;
        font-size: 14px;
        line-height: 14px;
        text-transform: uppercase;
        z-index: 99;
        pointer-events: none;
        transition: opacity .3s, background-color .3s;
        opacity: 0;
        text-align: left;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: auto;
            width: 70px;
            height: 100%;
            background-color: $color-dark-grey;
            transition: background-color .3s;
            z-index: 5;
        }

        @media #{$mq-medium-up} {
            bottom: 30px;
            right: 20px;
        }

        span {
            display: inline-block;
            margin-right: 59px;
            opacity: 0;
            text-align: right;
            line-height: 19px;
            margin-top: -3px;
        }

        svg {
            display: inline-block;
            position: absolute;
            width: 70px;
            right: 10px;
            top: 50%;
            fill: $color-white;
            transform: translateY(-50%);
            transition: background-color .3s;
            z-index: 6;

            path {
                stroke: $color-white;
            }
        }

        &:hover {
            .no-touch & {
                background-color: $color-ultra-light-grey-hex;

                &:after {
                    background-color: $color-ultra-light-grey-hex;
                }
            }

            svg {
                path {
                    stroke: $color-dark-grey;
                }
            }
        }

        &.active {
            position: fixed;
            bottom: 30px;
            opacity: 1;
            pointer-events: all;

            &:hover {
                .no-touch & {
                    background-color: $color-ultra-light-grey-hex;
                    color: $color-dark-grey;

                    svg {
                        fill: $color-dark-grey;
                    }
                }
            }
        }

        &.small {
            background-color: $color-ultra-light-grey-hex;
            pointer-events: all;
            color: $color-dark-grey;

            svg {
                transform: translateY(-50%);
                fill: $color-dark-grey;

                path {
                    stroke: $color-dark-grey;
                }
            }

            &:after {
                background-color: $color-ultra-light-grey-hex;
            }
        }

        &.inactive {
            pointer-events: none;
        }

        &.text-break {
            span {
                margin-top: -12px;
            }
        }
    }

    .scrollmagic-pin-spacer {
        width: 100%!important;
        right: 0!important;
    }
}
