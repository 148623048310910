/**************************************
    INPUT RADIO WITH BOX LAYOUT
**************************************/


// Wrapper, um css-Regeln mit :not zu vermeiden
.wrapper-radio-box {

    input {
        position: absolute;
        right: 20px;
        bottom: 16px;
        z-index: 1; // vor label

        width: 20px;
        height: 20px;
        padding: 0;
        border-radius: 100%;
        border: 2px solid black;

        &:focus {
            + label:before {
                border-color: $color-warm-grey;
            }
        }

        &:hover {
            + label:before {
                .no-touch & {
                    border-color: $color-warm-grey;
                }
            }
        }

        &:active + label:before {
            transition-duration: 0s;
        }

        &:checked {
            + label {

                background-color: $color-light-grey; // $color-medium-grey;
                transition: background-color 0.3s ease-out;

                &:before {
                    transform: scale(1);
                }

                .icon--checked {
                    display: flex;
                    flex-direction: column;
                    background: $color-medium-grey;

                    .svg-check {
                        height: 10px;
                        padding: 0;
                    }

                    .svg-bike {
                        height: 23px;
                        padding: 0;
                        margin-bottom: 5px;
                    }
                }

                .icon--unchecked {
                    display: none;
                }

                svg {

                    fill: $color-medium-grey;

                    .change { display: none; }
                    .check { display: block; }
                }

                .radio-box-icon {

                    .radio-box-icon-checked {
                        opacity: 1;
                    }
                }
            }
        }

        &:disabled {
            border: 2px solid #9e9d9d;

            + label h2 {
                opacity: 0.1;
            }

            &.inactive {
                display: none;

                + label {
                    opacity: 0.4;
                    background: transparent;
                    border: 2px solid $color-light-grey;

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    input + .radio-box-label__container {

        .radio-box-icon--small {
            display: inline-flex;
            justify-content: flex-start;
            align-content: flex-start;
            height: 38px;

            & + .radio-box-label__text {
                margin-top: 10px;
            }
        }

        .radio-box-label__text {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            margin-right: 30px;
            margin-top: 35px;

            & > p {
                margin-top: 0;
                font-size: 14px;
            }
        }
    }

    input + label {
        width: 100%;
        height: 100%;
        display: inline-block;
        cursor: pointer;
        color: $color-black;

        background-color: $color-nearly-white-hex; // $color-light-grey;
        padding: 20px 20px 13px 20px;

        &:before {
            content: '';
            display: block;
            position: absolute;
            right: 25px;
            bottom: 26px;
            z-index: 2;

            width: 10px;
            height: 10px;
            border-radius: 100%;
            background-color: $color-black;

            transform-origin: center;
            transform: scale(0);
            transition: transform 0.3s ease-out;
        }

        > * {
            pointer-events: none;
        }

        .icon--unchecked {
            display: block;
        }

        .icon--checked {
            display: none;
        }

        h4 + .text-small {
            margin-top: 2px;
            color: $color-black;
        }

        h4 + .text-size--normal {
            font-family: $font-flexo-thin;
        }

        * + .additional-info {
            margin-top: 30px;
            color: $color-black;

            font-size: $p_fontsize;
            line-height: $p_lineheight;
        }

        svg {
            fill: $color-light-grey; // #6e6967;

            .change { display: block; }
            .check { display: none; }

        }

        svg + h4 {
            margin-top: 17px;
        }

        .radio-box-icon {
            opacity: 0.5;
            margin-bottom: 13px;
            transition: opacity 0.3s ease-out;

            .radio-box-icon-checked {
                opacity: 0;
                transition: opacity 0.2s ease-out;
            }
        }

        .radio-box-icon--small {
            display: inline-flex;
            flex-wrap: wrap;
            justify-content: flex-end;

            > svg {
                width: 30px;
                height: auto;
                margin-bottom: 10px;
            }
        }
    }

    .icon-circle-background {
        width: 70px;
        height: 70px;
    }
}


.wrapper-radio-box--v2 {
    position: relative;
    height: 100%;

    input + label:before {
        bottom: 21px;
    }
}

.container-toggle .wrapper-form-fields .wrapper-radio-box {
    input {
        bottom: auto;
        right: auto;
        left: 0;
        top: 3px;

        + label {
            background: none;
            padding: 0 0 0 40px;

            &::before {
                bottom: auto;
                right: auto;
                top: 8px;
                left: 5px;
            }
        }
    }
}
