.truncate-text {}
.truncate-text__button {}

.truncate-text__hidden-text {
    display: none;

    .truncate-text--expanded & {
        display: inline;
    }
}

.truncate-text__button-show {
    display: inline-block;

    .truncate-text--expanded & {
        display: none;
    }
}

.truncate-text__button-hide {
    display: none;

    .truncate-text--expanded & {
        display: inline-block;
    }
}

.truncate-text__placeholder {
    .truncate-text--expanded & {
        display: none;
    }
}
