
.m04-dialogue .dialogue-content {
    position: absolute;
    left: 0;
    bottom: $padding-container-center-small;
    width: 100%;

    @media #{$mq-medium-up} {
        top: $padding-container-center-medium;
    }

    @media #{$mq-large-up} {
        top: $padding-container-center-large;
    }
}
