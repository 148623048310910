
// WERTVOLLSTE HILFE ÜBERHAUPT!

$z-index-button-after: -1;
$z-index-button: 1;

$z-index-cover-filter-results: 2;

$z-index-flyout: 50;

$z-index-tooltip: 70;

$z-index-product-overview-filter-bar: 75;

$z-index-retailer-clip: 80;

$z-index-parallax-overlay-header: 82;

// top-bar beinhaltet MAIN-NAV
$z-index-top-bar: 84;
$z-index-myrm-sub-nav: 85;

$z-index-retailer-clip-active: 86;

$z-index-m27-precision-layer: 90;

$z-index-backdrop: 95;
$z-index-overlay: 100;
$z-main-nav: 110;
$z-overlay-search: 120;
$z-index-accordion-expand-layer: 125;
$z-index-product-overview-sidebar-filter: 126;

$z-index-preloader: 2000;

$z-index-tab: 1;
$z-index-tab-before: 2;
$z-index-tab-before-a: 3;

$z-configurator-container-sticky: 10;

// CUSTOM INPUTS
$z-index-input-wrapper-first-child: 100;

// Intercom
$z-index-intercom-launcher: 200;
