$select-range--thumb-diameter: 24px;
$select-range--total-height: 24px;
$select-range--track-height: 5px;

.select-range {
    padding: 0 20px;
}

.select-range__slider {
    position: relative;
    height: $select-range--total-height;
    margin-top: 20px;
}

.select-range__gui {
    position: absolute;
    left: $select-range--thumb-diameter / 2;
    right: $select-range--thumb-diameter / 2;
    height: $select-range--total-height;
}

.select-range__track {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: $select-range--track-height;
    background-color: $color-light-grey;
    border-radius: $select-range--track-height / 2;
    transform: translateY(-50%);
    z-index: 0;
}

.select-range__range {
    position: absolute;
    left: 0;
    top: 50%;
    height: $select-range--track-height;
    background-color: $color-black;
    transform: translateY(-50%);
}

.select-range__input {
    position: absolute;
    left: 0;
    height: 14px;
}

// wrap to force css-overwrite
.custom-inputs {
    input.select-range__input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: $select-range--total-height;
        padding: 0;
        z-index: 3;
        opacity: 0;
        border: none;
        pointer-events: none;
        -webkit-appearance: none;

        &:focus {
            outline: none;
        }

        &::-ms-tooltip {
            display: none;
        }
    }
}

.select-range__thumb {
    position: absolute;
    top: 0;
    height: $select-range--thumb-diameter;
    width: $select-range--thumb-diameter;
    margin-left: - $select-range--thumb-diameter / 2;
    background-color: $color-black;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
}

@mixin style-thumb() {
    pointer-events: all;
    width: $select-range--thumb-diameter;
    height: $select-range--thumb-diameter;
    border-radius: 0;
    border: 0 none;
    background: red;
    -webkit-appearance: none;
    cursor: pointer;
}

@mixin ms-fill() {
    background: transparent;
    border: 0 none;
}

.select-range__input::-webkit-slider-thumb {
    @include style-thumb()
}

.select-range__input::-moz-range-thumb {
    @include style-thumb()
}

.select-range__input::-ms-thumb {
    @include style-thumb()
}

.select-range__input::-ms-fill-lower {
    @include ms-fill()
}

.select-range__input::-ms-fill-upper {
    @include ms-fill()
}

.select-range__input::-ms-track,
.select-range__input::-moz-range-track,
.select-range__input:focus::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    color: transparent;
}


// labels
.select-range__labels {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

.select-range__label-dash {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
}
