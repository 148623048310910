.parallax-overlay-header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: $z-index-parallax-overlay-header;

    @media #{$mq-small-only} {
        top: $height-top-bar;
    }

    .rb__inner {
        position: relative;
        width: 100%;
        height: 100%;

        @media #{$mq-medium-up} {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }

    .rb__container-headline {
        position: relative;

        @media #{$mq-small-only} {
            $padding-t: 70px;
            $padding-r: #{$padding-container-center-small + 20px};
            $padding-b: 40px;
            $padding-l: $padding-container-center-small;

            display: flex;
            flex-direction: column;
            padding: $padding-t $padding-r $padding-b $padding-l;
            width: 100%;
            height: 100%;
        }

        @media #{$mq-medium-up} {
            height: auto;
            padding: 70px 0 0 0;
            width: 380px;
            margin: 0 0 0 $padding-container-center-large;
        }

        @media #{$mq-large-up} {
            max-width: 400px;
        }
    }

    .parallax-overlay-header-h1 {
        hyphens: none;
        @include headline-h2--large();

        @media #{$mq-small-only} {
            max-width: 210px;
        }

        & + .m01-header-overlay__container-buttons {
            @media #{$mq-small-only} {
                margin-top: auto;
            }

            @media #{$mq-medium-up} {
                margin-top: 45px;
            }
        }
    }

    .button-circle {
        & + .button-circle {
            margin-top: 20px;
        }
    }

    .rb__hint-scroll {
        cursor: pointer;
        position: absolute;
        width: 30px;
        left: 50%;
        bottom: 35px;
        transform: translateX(-50%);
    }

    &.color-white {
        .rb__hint-scroll svg {

            path {
                stroke: $color-white;
            }
        }
    }

    &.splitscreen {
        .rb__container-headline {
            display: block;
        }

        @media #{$mq-small-only} {
            .rb__container-headline {
                position: absolute;
                width: calc(80% - 20px);
                top: calc(50% + 56px);
                left: 20px;
                padding: 0;
                margin: 0;
            }

            .parallax-overlay-header-h1 {
                display: block;
                width: 100%;
                position: relative;
                bottom: auto;
                top: auto;
                margin: 0 0 20px 0;
                padding: 0;
                left: auto;
                right: 0;
            }

            // disable parallax for small screens
            .rb__inner {
                transform: none!important;
            }

            .rb__hint-scroll {
                svg {
                    opacity: 0.4;
                }
            }
        }

        // iPhone 5
        @media #{$mq-small-only} and (max-height: 666px) {
            .rb__container-headline {
                width: calc(90% - 20px);
                top: calc(50% + 42px);
            }

            .parallax-overlay-header-h1 {
                font-size: 18px;
                line-height: 26px;
                margin: 0 0 15px 0;
            }

            .rb__hint-scroll {
                bottom: 15px;
            }
        }

        // iPhone 6/7/8
        @media #{$mq-small-only} and (min-height: 667px) {
            .parallax-overlay-header-h1 {
                font-size: 22px;
                line-height: 26px;
            }

            .rb__hint-scroll {
                bottom: 25px;
            }
        }

        // iPhone 6/7/8
        @media #{$mq-small-only} and (min-height: 812px) {
            .parallax-overlay-header-h1 {
                font-size: 28px;
                line-height: 32px;
            }

            .rb__hint-scroll {
                bottom: 25px;
            }
        }

        @media #{$mq-medium-only} {
            .rb__container-headline {
                width: calc(50% - 70px);
            }
        }
    }
}
