
.accordion {
    padding: 0;
    list-style-type: none;

    & > li + li {
        margin-top: 0;
    }

    & > li,
    & > .cms-plugin > li,
    & > .container-li > li {
        &:nth-child(n+2) {
            margin-top: $gap-smallest-part;
        }

        // Wenn geöffnet / expanded, wird Klasse gesetzt, um Inhalte / gui der bar anzupassen
        &.expanded {
            .button-arrow:before {
                transform: rotate(180deg);
            }
        }

        &.expanded {
            .bar {
                background-color: $color-light-grey;
            }
        }

        &:hover {
            .no-touch & {
                .bar {
                    background-color: $color-light-grey;
                }
            }
        }
    }

    &.accordion--light {

        .bar {
            background-color: $color-white;
        }
    }

    .button-toggle-accordion {
        cursor: pointer;
    }

    .bar {
        display: flex;
        justify-content: space-between;
        // grid überschreiben
        margin-bottom: 0;
        padding: #{$gap-smallest-part * 2} #{$gap-smallest-part * 3};
        transition: background-color 0.25s ease-out;

        h4 {
            transition: color 0.25s ease-out;
        }

        .label {
            color: $color-black;
            margin-bottom: 0;
            // Um für tracking data-name richtig auslesen zu können, muss click-event vom parent-Element stammen!
            pointer-events: none;
            display: flex;
            align-items: center;

            $max-width-right: #{ $gap-smallest-part * 4};

            &.left {
                width: calc(100% - #{$max-width-right});
            }

            &.right {
                max-width: $max-width-right;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            // flex: 1 0 100%;
            width: 100%;
            gap: $gap-4-small;

            &--top {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

            &--bottom {
                display: grid;
                width: 100%;
                grid-template-columns: fit-content(70px) 1fr;
                grid-gap: $gap-3-small $gap-2-small;
                align-items: center;

                @media #{$mq-medium-up} {
                    grid-template-columns: fit-content(70px) 1fr auto;
                    grid-gap: 0 $gap-2-small;
                }
            }
        }
    }

    section.content {
        // Fix, damit Höhe von js richtig augelesen werden kann: inline-block oder flex
        display: inline-block;
        padding: #{$gap-smallest-part * 3} #{$gap-smallest-part * 3} #{$gap-smallest-part * 6} #{$gap-smallest-part * 3};

        & > .cms-plugin {
            width: 100%;
        }
    }

    .button-arrow {
        height: #{$min-height-bar / 2};
        // fix: chrome rendering bug
        overflow: hidden;
        transform: translateZ(0);

        &:before {
            display: block;
            height: #{$min-height-bar / 2};
            font-family: $font-icons;
            font-size: 12px; // 10px;
            font-style: normal;
            text-transform: none;
            line-height: #{$min-height-bar / 2};
            color: $color-black;

            transform: translate3d(0, 0, 0);
            transition: transform 0.35s ease-in-out, color 0.25s ease-out;
        }
    }
}

.cms-edit-on .segment .bar {
    .cms-hover-tooltip {
        position: relative;
        z-index: 1;
    }

    /* Kreis, welcher Anzeigt ob ein Artikel bereits veröffentlicht ist oder nicht */
    .cms-pagetree-node-state {
        position: absolute;
        top: 40%;
        right: 10%;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        border: 2px solid $color-white;
        background: $color-white;
    }

    .cms-pagetree-node-state-published {
        border-color: #693;
        background-color: #693;
    }

    .cms-pagetree-node-state-empty {
        border-color: $color-medium-grey;
        background-color: $color-white;
    }
}
