.bike-advisor__tool {
    position: relative;
    min-height: calc(var(--vh, 1vh) * 100);
    background-color: $color-white;
    z-index: 3;

    @media #{$mq-medium-up} {
        min-height: calc(var(--vh, 1vh) * 100 - 56px); } }

.bike-advisor__tool-inner {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $color-white;
    // background-color: lightblue
    z-index: 2; }

.bike-advisor__tool-space {
    height: 50px;

    @include bike-advisor__size--large() {
        height: 130px; }

    @include bike-advisor__size--extra-large() {
        height: 150px; } }

.bike-advisor__tool-footer {
    display: flex;
    margin-top: 50px;

    @include bike-advisor__size--large() {
        margin-top: 130px; }

    @include bike-advisor__size--extra-large() {
        margin-top: 150px; }

    .button-circle + .button-circle {
        margin-left: 80px; } }
