
.retailer-search-mask {
    $fontsize-small: 25px;
    $fontsize-medium-large: 28px;

    margin: 0 auto;
    padding-bottom: 70px;
    width: 100%;
    max-width: 580px + 20px;
    position: relative;
    display: flex;
    flex-wrap: wrap;

    .input-search {
        width: 100%;
        margin-top: 20px;
    }
}

.retailer-search-mask__label {
    font-size: 16px;
    font-family: $font-flexo-demi;
    line-height: 20px;
}

.retailer-search-mask__dropdown-filter {
    @media #{$mq-small-only} {
        width: 100%;
        margin-top: 25px;
    }

    @media #{$mq-medium-up} {
        margin-left: 50px;
        flex-grow: 1;
    }
}

.retailer-search-mask__flex-break {
    width: 100%;
    height: 0;
}

.retailer-search-mask.retailer-search-mask--simple {
    justify-content: left;
    margin: 0;

    .input-search input {
        background-color: $color-white;
    }
}
