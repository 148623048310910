.double-teaser > .container-center {
    display: flex;
    flex-direction: row;

    > * {
        flex-basis: 50%;
    }

    @media #{$mq-large-down} {
        width: 100%;
        flex-direction: column;

        > * {
            flex-basis: 100%;
        }
    }
}

.double-teaser--fullwidth > .container-center {
    max-width: none;
    width: 100%;
    margin-left: 0;
    margin-right: 0;

    .button-circle {
        margin-top: 20px;
    }

    > * {
        flex-basis: 100%;
    }
}

.double-teaser {
    @media #{$mq-large-down} {
        &:before,
        &:after {
            display: none;
        }
    }

    .background-black p {
        color: $color-white;
    }
}

.double-teaser--fullwidth {
    &:before,
    &:after {
        display: none;
    }
}

.double-teaser .teaser {
    padding: 40px 0;

    .container-center {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.double-teaser .teaser-image {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    img {
        width: 100%;

        @media #{$mq-medium-up} {
            width: 60%;
        }

        @media #{$mq-large-up} {
            width: 80%;
        }
    }
}

.double-teaser .teaser-buttons {
    margin-top: 10px;
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    justify-content: flex-end;

    @media #{$mq-large-down} {
        margin-top: 40px;
    }

    > * {
        align-self: flex-end;

        & + * {
            margin-top: 20px;
        }
    }
}
