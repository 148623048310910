
.m35a-awards {
    $margin: 15px;

    figure {
        height: 100px;
        margin: 0 0 $margin $margin;

        & > img {
            width: 100%;
            height: 100%;
        }
    }

    .grid .grid {
        margin-bottom: #{-$margin};
    }
}