.tile-teaser {
    position: relative;
    padding: 40px 25px 60px;
    
    @media #{$mq-medium-up} {
        padding: 40px 40px 60px;
    }
}

.tile-teaser__contents {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tile-teaser__texts {
    h3 {
        font-size: 35px;
        line-height: 115%;
    }
}

.tile-teaser__button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 40px;
}

