// this is a new button style which came with mobility magazin

@mixin btn-base-style {
    text-transform: uppercase;
    font-family: $font-flexo-demi;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
}

// This is a textlink / button with copy and circular icon
// The css adds a circ to to icon
.btn--link-icon {
    @include btn-base-style;

    display: flex;
    align-items: baseline;
    border-bottom: none;

    span.btn__icon {
        height: 28px;
        width: 28px;
        border: 2px solid;
        border-radius: 50%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 0 0 28px;

        margin-left: 10px;

        svg {
            width: 12px;
            height: 12px;
        }
    }

    .no-touch & {
        transition: color 0.25s ease-out, background-color 0.25s ease-out;

        svg * {
            transition: stroke 0.25s ease-out, background-color 0.25s ease-out;
        }

        &:hover {
            border-bottom: none;

            svg {
                * {
                    stroke: $color-medium-grey;
                }
            }
        }
    }
}

.btn--link-icon + .btn--link-icon {
    margin: 10px 0 0 0;
}
