.configurator__summary {
    position: relative;
    background: $color-black;
    padding-top: 90px;
    padding-bottom: 50px;

    &--purchase-enabled {
        @media #{$mq-small-only} {
            padding-bottom: #{50px + 70px};
        }
    }

    @media #{$mq-medium-up} {
        padding-top: 140px;
    }

    .text-spaces {
        margin-bottom: 40px;

        @media #{$mq-small-only} {
            margin-bottom: 80px;
        }
    }

    .wrapper-button {
        align-items: flex-end;

        & + .wrapper-button {
            margin-top: 20px;
        }
    }

    .summary__row {
        display: flex;
        align-items: baseline;
        flex-direction: row;
        flex-wrap: wrap;

        + .summary__row {
            margin-top: 17px;
        }

        .summary__col {
            display: flex;

            &.summary__item--feature {
                padding-right: 10px;
                font-family: $font-flexo-demi;
                font-weight: 100;
            }
        }

        .summary__col:nth-child(1),
        .summary__col:nth-child(2) {
            width: 60%;
        }

        .summary__col:nth-child(2) {
            width: 100%;
            display: block;
            margin-top: 5px;

            @media #{$mq-small-only} {
                order: 3;
            }
        }

        .summary__col:last-child {
            width: 40%;
            display: inline-block;
            text-align: right;
        }

        &.summary__row--setting-price {
            margin-top: 0;
        }

        @media #{$mq-medium-up} {
            flex-wrap: nowrap;

            .summary__col:nth-child(1),
            .summary__col:nth-child(2) {
                width: 40%;
                order: unset;
                margin-top: 0;
            }

            .summary__col:last-child {
                width: 20%;
            }
        }
    }

    .summary__row--fullwidth {
        @extend .summary__row;

        margin-top: 0;

        .summary__col:first-child {
            width: 100%;
            text-align: right;
        }

        &.summary__row--full-price {
            margin-top: 10px;
            font-family: $font-flexo-demi;
        }

        &.text-small {
            margin-top: -1px;
        }
    }

    .summary__row--feature-data {
        & + .summary__row--fullwidth {
            margin-top: 40px;
        }
    }

    hr {
        height: 1px;
        margin: 20px 0;
    }
}
