.bike-advisor__summary-wrapper {
    --summary-bar-height: 20px;
    --summary-button-height: 20px;

    @include bike-advisor__size--large() {
        --summary-bar-height: 30px;
        --summary-button-height: 30px; }

    @include bike-advisor__size--extra-large() {
        --summary-bar-height: 40px;
        --summary-button-height: 40px; }

    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0;
    pointer-events: none;

    &[data-position='attached-to-tool'] {
        position: absolute;
        bottom: 0;
        background-color: $color-black;
        z-index: 20; }

    &[data-position='fixed-top'] {
        position: fixed;
        top: 0;
        transform: translateY(calc(-100% + var(--top-bar-height))); }

    &[data-button-rotation='top'] {
        .bike-advisor__button-toggle-output {
            svg {
                transform: rotate(180deg); } } }

    &[data-button-rotation='bottom'] {
        .bike-advisor__button-toggle-output {
            svg {
                transform: rotate(0deg); } } } }


.bike-advisor__summary {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    backface-visibility: hidden;
    transform: translateY(var(--summary-bar-height));
    transition: transform var(--bike-advisor-duration-large) var(--ease-out-cubic); }

.bike-advisor__summary-inner {
    position: relative;
    color: $color-white;
    background-color: $color-black;
    padding: 40px 0;
    z-index: 2;

    @include bike-advisor__size--large() {
        padding: 70px 0; } }

.bike-advisor__summary-wrapper-button {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: center;
    z-index: 2; }

.bike-advisor__summary-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    opacity: 0;

    @include bike-advisor__size--large() {
        margin-top: 100px; } }

.bike-advisor__button-toggle-output {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    bottom: 0;
    width: 110px;
    height: calc(var(--summary-button-height) * 2);
    color: $color-white;
    background-color: $color-black;
    border-radius: 20px;
    transform: translate(-50%, 50%);

    @include bike-advisor__size--large() {
        width: 200px;
        border-radius: 30px; }

    @include bike-advisor__size--extra-large() {
        width: 280px;
        border-radius: 40px; }

    path {
        stroke: $color-white; }

    svg {
        height: 14px;
        // transition: transform 0.2s var(--ease-out-cubic)

        @include bike-advisor__size--large() {
            height: 22px; }

        // .bike-advisor__button-toggle-output:hover &
 } }        //    transform: translateY(4px)


.bike-advisor__summary-question-inner {
    --sentence-whitespace: 10px;
    --sentence-height: 31px;
    --sentence-font-size: 18px;

    --placeholder-height: var(--sentence-height);
    --placeholder-font-size: var(--sentence-font-size);
    --placeholder-line-height: var(--sentence-height);
    --placeholder-padding-vertical: 0;
    --placeholder-margin-vertical: 0;
    --placeholder-offset-top: -1px;

    @include bike-advisor__size--large() {
        --sentence-whitespace: 12px;
        --sentence-height: 46px;
        --sentence-font-size: 30px; }

    //+bike-advisor__size--extra-large()
    //    --sentence-whitespace: 14px
    //    --sentence-height: 70px
    //    --sentence-font-size: 40px

    @media only screen and (min-width: $screen-extra-large) and (min-height: 960px) {
        --sentence-whitespace: 14px;
        --sentence-height: 54px;
        --sentence-font-size: 34px; }

    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 calc(var(--sentence-whitespace) * -0.5);

    .sentence__part {
        &--punctuation {
            margin-left: calc(var(--sentence-whitespace) * -0.5); } }

    .sentence__part,
    .sentence__placeholder {
        transform: none; }

    .sentence__placeholder {
        top: var(--placeholder-offset-top);
        background: none;
        padding: 0;
        color: $color-white;
        margin: 0 calc(var(--sentence-whitespace) * 0.5); } }
