/*
GRID KLASSEN

siehe Dokumentation:
https://confluence.s-v.de/display/BRMI/Grid
*/

// horizontal + vertical spacing
$grid-gutter--mq-small: 25px;
$grid-gutter--mq-medium: 25px;
$grid-gutter--mq-large: 25px;

$grid-gutter-horizontal-medium--mq-small: 40px;
$grid-gutter-horizontal-medium--mq-medium: 40px;
$grid-gutter-horizontal-medium--mq-large: 40px;

$grid-gutter-vertical-large--mq-small: 80px;
$grid-gutter-vertical-large--mq-medium: 80px;
$grid-gutter-vertical-large--mq-large: 80px;

:root {
    --grid-gutter-horizontal: #{$grid-gutter--mq-small};
    --grid-gutter-vertical: #{$grid-gutter--mq-small};
}

@media #{$mq-medium-up} {
    :root {
        --grid-gutter-horizontal: #{$grid-gutter--mq-medium};
        --grid-gutter-vertical: #{$grid-gutter--mq-medium};
    }
}

@media #{$mq-large-up} {
    :root {
        --grid-gutter-horizontal: #{$grid-gutter--mq-large};
        --grid-gutter-vertical: #{$grid-gutter--mq-large};
    }
}

.grid {
    position: relative;
    width: calc(100% + var(--grid-gutter-horizontal));
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    left: calc(-1 * var(--grid-gutter-horizontal));
    margin-bottom: calc(-1 * var(--grid-gutter-vertical));

    & > * {
        position: relative;
        margin-bottom: var(--grid-gutter-vertical);
        margin-left: var(--grid-gutter-horizontal);
    }

    &--gutter-vertical-default {
        --grid-gutter-vertical: #{$grid-gutter--mq-small};

        @media #{$mq-medium-up} {
            --grid-gutter-vertical: #{$grid-gutter--mq-medium};
        }
        @media #{$mq-large-up} {
            --grid-gutter-vertical: #{$grid-gutter--mq-large};
        }
    }

    &--gutter-vertical-large {
        --grid-gutter-vertical: #{$grid-gutter-vertical-large--mq-small};

        @media #{$mq-medium-up} {
            --grid-gutter-vertical: #{$grid-gutter-vertical-large--mq-medium};
        }
        @media #{$mq-large-up} {
            --grid-gutter-vertical: #{$grid-gutter-vertical-large--mq-large};
        }
    }

    &--gutter-horizontal-medium {
        --grid-gutter-horizontal: #{$grid-gutter-horizontal-medium--mq-small};

        @media #{$mq-medium-up} {
            --grid-gutter-horizontal: #{$grid-gutter-horizontal-medium--mq-medium};
        }
        @media #{$mq-large-up} {
            --grid-gutter-horizontal: #{$grid-gutter-horizontal-medium--mq-large};
        }
    }

    $small: s;
    $medium: m;
    $large: l;

    @media #{$mq-small-only} {
        @include grid-column-classes($small, var(--grid-gutter-horizontal));
        @include grid-margin-classes($small, var(--grid-gutter-horizontal), left);
        @include grid-margin-classes($small, var(--grid-gutter-horizontal), right);

        @include grid-extend-full-class($small, var(--grid-gutter-horizontal), $padding-container-center-small);
    }

    @media #{$mq-medium-only} {
        @include grid-column-classes($medium, var(--grid-gutter-horizontal));
        @include grid-margin-classes($medium, var(--grid-gutter-horizontal), left);
        @include grid-margin-classes($medium, var(--grid-gutter-horizontal), right);

        @include grid-extend-classes($medium, var(--grid-gutter-horizontal), $padding-container-center-medium, left, 5, 10);
        @include grid-extend-classes($medium, var(--grid-gutter-horizontal), $padding-container-center-medium, right, 5, 10);

        @include grid-extend-full-class($medium, var(--grid-gutter-horizontal), $gap-4-medium);
    }

    @media #{$mq-large-up} {
        @include grid-column-classes($large, var(--grid-gutter-horizontal));
        @include grid-margin-classes($large, var(--grid-gutter-horizontal), left);
        @include grid-margin-classes($large, var(--grid-gutter-horizontal), right);

        @include grid-extend-classes($large, var(--grid-gutter-horizontal), $padding-container-center-large, left, 5, 10);
        @include grid-extend-classes($large, var(--grid-gutter-horizontal), $padding-container-center-large, right, 5, 10);

        @include grid-extend-full-class($large, var(--grid-gutter-horizontal), $padding-container-center-large);
    }

    @media #{$mq-max-width-up} {
        & > .l-12-extend-full {
            width: 100%;
            margin-left: 10px;
            margin-right: 0;
        }
    }
}
