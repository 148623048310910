
// bei grauem Modul-Hintergrund müssen pager-Punkte weiß werden
.background-grey .slider {
    .dot:before {
        background-color: $color-white;
    }
}

.slider {
    position: relative;

    // Bei full-height passt sich der slider samt aller items automatisch der Höhe des parent-containers an.
    // Hier empfiehlt es sich, bei allen figure-items die Klasse 'background' zu verwenden,
    // so dass Bilder nach dem Preloading als bg-image eingefügt werden.
    &.full-height {
        position: absolute;
        // Wird schon von der globalen Helper-Klasse definiert
        // height: 100%;

        .container-slides {
            height: 100%;
        }

        .slide {
            position: absolute;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
        }
    }

    .container-slides {
        position: relative;
    }

    .container-captions {
        overflow: visible;
        margin: 0 auto;
    }

    figure.slide {
        background-size: contain;
        @include relative-height(3/2);

        &.fitting {
            background-color: $color-white;
        }
    }

    .slide {
        width: 100%;

        img {
            margin: 0 auto;
            max-width: 1600px;
            pointer-events: none;
            user-drag: none;
            -webkit-user-drag: none;
            @include no-select();
        }

        & > figure.background {
            @include relative-height(3/2);
        }
    }

    .container-dots {
        position: relative;
        width: 100%;
        z-index: 2;
        margin-top: 15px;
        margin-bottom: 0;

        @include gap-for(height, $gap-2);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 0.5s ease-in-out;

        @media #{$mq-large-up} {
            margin-top: 20px;
        }
    }

    .dot {
        position: relative;
        width: 15px;
        height: 15px;
        background-color: transparent;
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            display: block;
            left: 3px;
            top: 3px;
            width: 8px;
            height: 8px;
            background-color: $color-light-grey;

            // todo -- rebrush
            border-radius: 50%;
            // @include border-radius(2px);

            transition: background-color 0.25s ease-in-out;
        }

        &.active:before {
            // todo -- rebrush
            background-color: $color-black;
            // background-color: $color-brand-green;
            pointer-events: none;
        }

        &:hover:before {
            .no-touch & {
                // todo -- rebrush
                background-color: $color-black;
                // background-color: $color-brand-green;
            }
        }
    }

    .container-captions {
        justify-content: center !important;
        position: relative;
        margin-top: 25px;

        @media #{$mq-small-up} {
            margin-top: 8px;
        }

        @media #{$mq-medium-up} {
            margin-top: $gap-2-small;
        }

        // Nur captions außerhalb der slides werden ein- und ausgeblendet
        .caption {
            opacity: 0;
        }
    }

    .mask-captions {
        width: 100%;
        overflow: hidden;
    }

    .caption {
        text-align: center;
        z-index: 1;
        transition: opacity 0.25s ease-in-out 0s;

        &.active {
            z-index: 10;
            opacity: 1;
            transition: opacity 0.25s ease-in-out 0.25s;
        }
    }

    // Variable nur für untenstehendes Mixin
    $total-items: 10;

    @mixin caption-classes() {
        @for $i from 2 through $total-items - 1 {
            &:nth-child(#{$i}) {
                left: #{-10% * ($i - 1)};
            }
        }
    }

    // SPACER für unterschiedliche hohe (max 10!) child-Elemente (nur erstes sichtbar) erzwingt richtige Höhe des parent-Elements
    .spacer-height {
        width: 1000%;

        display: flex;
        justify-content: flex-start;
        flex-direction: row; // flex-start flex-end center space-between space-around
        flex-wrap: wrap;

        & > * {
            position: relative;
            width: 10% !important;

            @include caption-classes();
        }
    }

    // OWL STUFF FROM HERE
    .owl-nav {
        height: 0;
    }

    .owl-prev, .owl-next {
        @extend .button-pager-extend;

        position: absolute;
        top: 50%;
        text-align: center;
        cursor: pointer;
    }

    .owl-prev {
        left: -39px;
        transform: translate(-100%, -50%);

        &:before {
            content: 'b';
        }

        &:hover:before {
            .no-touch & {
                transform: translate3d(-4px, 0, 0);
            }
        }

        @media #{$mq-medium-only} {
            left: -30px;
        }
    }

    .owl-next {
        right: -39px;
        transform: translate(100%, -50%);

        &:before {
            content: 'c';
        }

        &:hover:before {
            .no-touch & {
                transform: translate3d(4px, 0, 0);
            }
        }

        @media #{$mq-medium-only} {
            right: -30px;
        }
    }
}
