
.m32-events {
    form {
        @media #{$mq-small-only} {
            margin-bottom: $gap-2-small;
        }

        &.disabled,
        &.disabled select {
            pointer-events: none;
        }
    }

    .button-load-more {
        margin: 50px auto 0 auto;
    }

    .label.left {
        flex-direction: column;
        align-items: flex-start;
    }

    li {
        // todo - Da h4 in li gewrapped, ersetzen Margins die normalen text-spaces
        &.month {
            margin-bottom: 26px;

            &:first-child {
                margin-top: 0;
            }

            &:nth-child(n + 2) {
                margin-top: 60px;
            }
        }
    }

    section.content {
        .grid {
            margin-bottom: -25px;

            & > div {
                margin-bottom: 25px;
            }
        }
    }

    .container-buttons {
        @media #{$mq-medium-up} {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .btn-base {
                margin: $gap-smallest-part 0 0 0;
            }
        }
    }
}
