
// todo - rebrush -- DELETE?

.accordion.filter-menu li.expanded .bar,
.accordion.filter-menu li:hover .bar {
    background-color: $color-ultra-light-grey;
}

.accordion.filter-menu {

    li.segment {
        border-bottom: 2px solid $color-ultra-light-grey;
    }

    .bar {
        .label {
            margin-top: 17px;
            pointer-events: auto;

            &.left {
                max-width: 30%;

                @media #{$mq-medium-up} {
                    & > span:first-child {
                        margin-right: $gap-smallest-part;
                    }
                }
            }

            &.right {
                max-width: 70%;

                & > span:first-child {
                    margin-right: $gap-smallest-part;
                }
            }
        }
    }

    .mask-resize.mask-tags {
        height: auto;
        background-color: transparent;
        width: 100%;
        padding: 0;
        left: 0;

        .reset-filters > a {
            font-family: inherit;
            text-transform: uppercase;
            color: $color-black;
            font-size: 10px;
            border-bottom: 1px solid $color-black;
            line-height: 15px;
            justify-content: flex-end;
            margin-top: 0;
        }

        .reset-filters {
            justify-content: flex-end;
            margin-bottom: 36px;
            margin-top: 3px;

            @media #{$mq-large-up} {
                margin-top: 0;
            }

            &.disabled {
                a {
                    pointer-events: none;
                    color: $color-light-grey !important;
                    font-size: 10px;
                    border-bottom: 1px solid $color-light-grey !important;
                    opacity: 0;
                }
            }
        }
    }
}

.m07-series-chooser .accordion.filter-menu {
    .label {
        margin-top: 0;
    }
}

.accordion.filter-menu.job-filter .bar {
    width: 300%;
    left: -100%;
    padding-left: 100%;
    padding-right: 100%;
}
