

// todo - rebrush
.structured-text,
.structured-text table,
.structured-text table p,
.structured-text table span {
    font-size: $fontsize_tables;
    line-height: 20px;
}
// todo - rebrush
.structured-text table a {
    font-size: $fontsize_tables;
    line-height: 30px;
    height: 30px;
}

.structured-text {
    // Abstände für ol und ul
    $width-list-style: 30px;

    ul, ol {
        padding: 0;
        list-style: none;
        max-width: 750px;

        li {
            position: relative;
            padding-left: $width-list-style;

            &:last-child {
                margin-bottom: 0;
            }

            &:before {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: $width-list-style;
                text-align: left;
            }
        }
    }

    ul li:before {
        content: "•";
    }

    ol {
        counter-reset: item;

        li {
            padding-left: 32px;

            &:before {
                top: -2px;
                font-size: 20px;
                font-family: $font-flexo-demi;
                counter-increment: item;
                content: counter(item);
            }
        }

        ol {
            li {
                &:before {
                    content: counter(item, lower-alpha);
                }
            }
        }
    }

    // todo: wie wird für mobile das overflow-x scrolling umgesetzt?
    .wrapper-table {
        display: block;
        width: calc(100% + #{$gap-2-small + 10px});
        overflow-x: auto;

        @media #{$mq-medium-up} {
            width: calc(100% + #{$gap-4-medium + 10px});
        }

        @media #{$mq-large-up} {
            width: 100%;
        }

        &.auto-width {
            table {
                table-layout: auto;
            }
        }
    }

    // TABLE-STYLE
    table {
        display: inline-table;
        table-layout: fixed;
        min-width: calc(100% - #{$gap-2-small + 10px});
        margin-right: #{$gap-2-small + 10px};
        margin-bottom: 10px;

        @media #{$mq-medium-up} {
            min-width: calc(100% - #{$gap-4-medium + 10px});
            margin-right: #{$gap-4-medium + 10px};
        }

        @media #{$mq-large-up} {
            display: table;
            width: 100%;
            margin-right: 0;
            margin-bottom: 0;
        }

        td, th {
            padding: 20px;
            min-width: 150px;
            word-wrap: break-word;

            @media #{$mq-medium-up} {
                min-width: auto;
                transition: background-color 0.25s ease-out
            }
        }

        th {
            font-family: $font-flexo-bold;
            text-align: left;
        }

        tr:nth-child(odd) {
            background-color: $color-light-grey;
        }

        tr:nth-child(even) {
            background-color: $color-ultra-light-grey;
        }

        .m09-article & {
            tr:nth-child(even) {
                background-color: $color-white;
            }

            tr:nth-child(odd) {
                background-color: $color-ultra-light-grey;
            }
        }
    }
}
