$padding-m44-super-narrow-mq-small: 30px;
$padding-m44-super-narrow-mq-medium: 30px;
$padding-m44-super-narrow-mq-large: 30px;

$padding-m44-narrow-mq-small: 50px;
$padding-m44-narrow-mq-medium: 50px;
$padding-m44-narrow-mq-large: 50px;

$padding-modules-zero: 0;
$padding-modules-mq-small: 70px;
$padding-modules-mq-medium: 100px;
$padding-modules-mq-large: 100px;

$padding-sub-modules-s: 40px;
$padding-sub-modules-m: 50px;
$padding-sub-modules-l: 60px;

.sub-module + .sub-module {
    margin-top: $padding-sub-modules-s;

    @media #{$mq-medium-up} {
        margin-top: $padding-sub-modules-m;
    }

    @media #{$mq-large-up} {
        margin-top: $padding-sub-modules-l;
    }
}

//.sub-module.background-grey + .sub-module.background-black,
//.sub-module.background-black + .sub-module.background-grey {

.sub-module[class*="background-"] + .sub-module[class*="background-"] {
    margin-top: 0;
}

.sub-module.background-nearly-white,
.sub-module.background-grey,
.sub-module.background-black {
    padding-top: $padding-sub-modules-s;
    padding-bottom: $padding-sub-modules-s;

    @media #{$mq-medium-up} {
        padding-top: $padding-sub-modules-m;
        padding-bottom: $padding-sub-modules-m;
    }

    @media #{$mq-large-up} {
        padding-top: $padding-sub-modules-l;
        padding-bottom: $padding-sub-modules-l;
    }
}

.sub-module.background-nearly-white + .sub-module.background-nearly-white,
.sub-module.background-grey + .sub-module.background-grey,
.sub-module.background-black + .sub-module.background-black {
    margin-top: 0;
    padding-top: 0;
}

.sub-module.sub-module-gapless {
    margin-top: 0;

    + .sub-module {
        margin-top: 0;
    }
}


.sub-module.sub-module-gap-before {
    padding-top: $padding-sub-modules-s;

    @media #{$mq-medium-up} {
        padding-top: $padding-sub-modules-m;
    }

    @media #{$mq-large-up} {
        padding-top: $padding-sub-modules-l;
    }
}

.sub-module.sub-module-gap-after {
    padding-bottom: $padding-sub-modules-s;

    @media #{$mq-medium-up} {
        padding-bottom: $padding-sub-modules-m;
    }

    @media #{$mq-large-up} {
        padding-bottom: $padding-sub-modules-l;
    }
}


.module.background-white {
    .sub-module.background-nearly-white:last-child,
    .sub-module.background-grey:last-child,
    .sub-module.background-black:last-child {
        padding-bottom: 0;

        &:after {
            @extend .module:after;
            position: absolute;
        }
    }

    .sub-module.background-nearly-white:last-child::after {
        background: $color-nearly-white;
    }
    .sub-module.background-grey:last-child::after {
        background: $color-ultra-light-grey;
    }
    .sub-module.background-black:last-child::after {
        background: $color-black;
    }
}


.module {
    &:before,
    &:after {
        display: block;
        width: 100%;
        height: $padding-modules-mq-small;

        @media #{$mq-medium-up} {
            height: $padding-modules-mq-medium;
        }

        @media #{$mq-large-up} {
            height: $padding-modules-mq-large;
        }
    }

    &:after {
        content: '';
    }

    &:first-child {
        &:before {
            content: '';
        }
    }
}

// Used in myrm with myrm__sub-nav for first module
.module--small {
    &:first-child {
        &:before {
            display: block;
            width: 100%;
            height: #{$padding-modules-mq-small - 40px};

            @media #{$mq-medium-up} {
                height: #{$padding-modules-mq-medium - 40px};
            }

            @media #{$mq-large-up} {
                height: #{$padding-modules-mq-large - 40px};
            }
        }

        &:after {
            display: block;
            width: 100%;
            height: #{$padding-modules-mq-small - 35px};

            @media #{$mq-medium-up} {
                height: #{$padding-modules-mq-medium - 55px};
            }

            @media #{$mq-large-up} {
                height: #{$padding-modules-mq-large - 55px};
            }
        }
    }
}




.m44-module-space:first-child + .module {
    &:before {
        content: '';
    }
}

.module.fixed,
.module.module-gapless {
    &:before,
    &:after {
        display: none;
    }
}

.module.module-gapless-before {
    &:before {
        display: none;
    }
}

.module.module-gapless-after {
    &:after {
        display: none;
    }
}

.background-image,
.background-image--inverted,
.container-center + .module.background-black,
.module.background-white + .module:not(.background-white),
.module.background-black + .module:not(.background-black),
.module.background-grey + .module:not(.background-grey),
.module:not(.background-white) + .module.background-white,
.module:not(.background-black) + .module.background-black,
.module:not(.background-grey) + .module.background-grey {
    &:before {
        content: '';
    }
}

.module.background-white + .m55-model-series-scroll-button + .module.background-white,
.module.background-black + .m55-model-series-scroll-button + .module.background-black,
.module.background-grey + .m55-model-series-scroll-button + .module.background-grey {
    &:before {
        content: none;
    }
}

.module.background-white + .m44-module-space + .module:not(.background-white),
.module.background-black + .m44-module-space + .module:not(.background-black),
.module.background-grey + .m44-module-space + .module:not(.background-grey),
.module:not(.background-white) + .m44-module-space + .module.background-white,
.module:not(.background-black) + .m44-module-space + .module.background-black,
.module:not(.background-grey) + .m44-module-space + .module.background-grey {
    &:before {
        content: '';
    }
}

// 19-04-17 ansch: e.g. configurator -- ok?
.card-poster + .module {
    &:before {
        content: '';
    }
}

.module + .m44-module-space {
    &.module-padding-small {
        margin-top: #{($padding-modules-mq-small - $padding-m44-super-narrow-mq-small) * -1};

        @media #{$mq-medium-up} {
            margin-top: #{($padding-modules-mq-medium - $padding-m44-super-narrow-mq-medium) * -1};
        }

        @media #{$mq-large-up} {
            margin-top: #{($padding-modules-mq-large - $padding-m44-super-narrow-mq-large) * -1};
        }
    }

    &.module-padding-medium {
        margin-top: #{($padding-modules-mq-small - $padding-m44-narrow-mq-small) * -1};

        @media #{$mq-medium-up} {
            margin-top: #{($padding-modules-mq-medium - $padding-m44-narrow-mq-medium) * -1};
        }

        @media #{$mq-large-up} {
            margin-top: #{($padding-modules-mq-large - $padding-m44-narrow-mq-large) * -1};
        }
    }
}

.m44-module-space + .m44-module-space,
.m44-module-space:last-child {
    margin: 0;
}

// BREADCRUMB (typo...) wird wie ein Modul gehandhabt, hat jedoch zu headern mit content 30px (MQ medium up) Abstand
.breadcrump {
    &:before {
        height: 0;

        @media #{$mq-medium-up} {
            height: 30px;
        }
    }
}

// Breadcrumb erzeugt bei leeren headern mindest-Abstand von content zu Seitenanfang / Topbar mit Navigation
// header:not(div) + .section .breadcrump, -- da dieser selektor *nicht* funktioniert, muss eine andere Lösungen (seitens BE?) gefunden werden, da: <header> </header> *nicht* als empty gilt
header:empty + .section .breadcrump,
header .breadcrump {
    &:before {
        @media #{$mq-medium-up} {
            height: #{ 40px + 25px + 40px};
        }
    }

    &:after {
        @media #{$mq-small-only} {
            height: #{$height-top-bar * 2};
        }
    }
}

.m03-dispatcher {
    padding: 10px 0;
}

// Um Dopplungen der Abstände vermeiden: Erstes + letztes Sub-Modul ohne oberen / unteren Abstand
.m09-article,
.m30-layer {
    .module {
        &:first-child:before {
            display: none;
        }
        &:last-child:after {
            display: none;
        }
    }
}

// Sonderregel: Sicherstellen, dass in "grauen Sections" keine doppelten Abstände dargestellt werden
.section.background-grey {
    .module + .module {
        &:before {
            display: none;
        }
    }
}

// auch der bike-header bekommt seine oberen Abstände von der breadcrumb (siehe weiter oben)
.m14-bike-header {
    &:after {
        display: none;
    }
}

// generell immer über Händlersuche, weißen Balken zwischen grauen Flächen vermeiden
.m16-bike-configurator {
    &:after {
        display: none;
    }
}

.m20-sale,
.m41-job-filter {
    &:after {
        background-color: $color-ultra-light-grey;
    }
}

.m35b-testimonials {
    &:before,
    &:after {
        @media #{$mq-medium-up} {
            height: 90px;
        }

        @media #{$mq-large-up} {
            height: 90px;
        }
    }
}

header {
    .cms-plugin {
        &:before,
        &:after {
            display: none !important;
        }
    }
}
