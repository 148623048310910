// doku (in progress) https://confluence.s-v.de/display/CRM/Buttons

a.button-circle {
    border: none;
}

.button-circle {
    $size-extra-small: 15px;
    $size-small: 40px;
    $size-medium: 70px;
    $size-large: 100px;

    $icon-edit-sm-width: 17px;
    $icon-edit-md-width: 30px;
    $icon-close-sm-width: 17px;
    $icon-close-md-width: 26px;
    $icon-check-sm-width: 20px;
    $icon-check-md-width: 34px;

    $btn-background: var(#{--background-color});
    $btn-color: var(#{--color});

    $btn-background-filled: var(#{--btn-circle-filled-background-color});
    $btn-color-filled: var(#{--btn-circle-filled-color});

    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    // overrida a-tag
    color: $btn-color;
    border: none;

    // so click-handler gets correct classes (not of childs)
    * {
        pointer-events: none;
    }

    // global möglich? (ansch, 19-05-17)
    svg,
    svg > path,
    svg > line {
        stroke: $btn-color;
    }

    .button-label {
        text-transform: uppercase;
        font-family: $font-flexo-demi;
        font-size: 14px;
        cursor: pointer;
    }

    .circle {
        position: relative;
        width: $size-medium;
        height: $size-medium;
        border-radius: 50%;
        flex: 0 0 auto;
        backface-visibility: hidden;
        mask-image: radial-gradient($btn-background, $btn-color); // fixes safari hover

        .touch &,
        .ie-edge & {
            overflow: hidden; // but iee11 needs overflow hidden
        }

        &:after {
            content: '';
            position: absolute;
            box-sizing: border-box;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 2px solid $btn-color;
            transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
        }

        @media #{$mq-small-only} {
            width: $size-small;
            height: $size-small;
        }

        & > svg,
        & > span {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transform: translate3d(0,0,0);
        }

        & > span {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    // used for button with big and small circle icon
    // This is a special button at my bike page
    .circle-small {
        $size: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        right: 0;
        width: $size;
        height: $size;
        border-radius: 50%;
        border: 2px solid $btn-color;
        background-color: $btn-background;
        transform: translate(50%, -50%);
        z-index: 1;

        svg {
            width: 11px;
            height: auto;
        }
    }

    &.button-circle-filled {
        .circle {
            background-color: $btn-background-filled;
        }

        svg > path,
        svg > line {
            stroke: $btn-color-filled;
        }
    }

    &.button-label-top {
        flex-direction: column;
        .button-label {
            order: 1;
        }
        .circle {
            order: 2;
            margin-top: 15px;
        }
    }

    &.button-label-right {
        .button-label {
            margin-left: 15px;
            order: 2;
            text-align: left !important;
        }
        .circle {
            order: 1;
        }
    }

    &.button-label-bottom {}

    &.button-label-left {
        .button-label {
            order: 1;
        }
        .circle {
            order: 2;
            margin-left: 15px;
        }

        // TODO LAdeanimation für simple button dokumentieren
        > .loader-gif {
            position: absolute;
            right: 11px;
            top: 19px;
            display: none;

            @media #{$mq-medium-up} {
                right: 21px;
                top: 38px;
            }

        }

        &.is-loading  {
            > .circle {
                opacity: 0;
            }

            > .loader-gif {
                display: block;
            }
        }
    }

    // used for button oval only on small devices
    // This is a special button at my bike page
    &.button-oval-small-only {
        .circle {
            @media #{$mq-small-only} {
                width: 80px;
                border-radius: 20px;
            }

            &:after {
                @media #{$mq-small-only} {
                    border-radius: 20px;
                }
            }
        }
    }

    &.button-oval {
        .circle {
            width: 80px !important;
            height: 40px;
            border-radius: 20px !important;

            &:after {
                border-radius: 20px !important;
            }
        }
    }

    .no-touch & {
        &:hover {
            // override a-tag
            border: none;
        }
    }

    // from here: button types
    &.button-next {

        .circle svg {
            width: 14px;
            height: auto;
            margin-left: 3px;

            @media #{$mq-medium-up} {
                width: 26px;
                margin-left: 6px;
            }
        }

        .circle > span {
            transition: transform 0.35s ease-in-out;
            &:first-child {
                left: -110%;
            }
        }
        &:hover {

            .no-touch & {
                .circle > span {
                    transition: transform 0.45s ease-out;
                    transform: translate3d(110%, 0, 0);
                }
            }
        }
    }

    &.button-prev {
        @extend .button-next;

        .circle svg {
            margin-left: 0;
        }

        .circle > span {
            &:first-child {
                left: 0;
            }
            &:last-child {
                left: 110%;
            }
        }

        &:hover {
            .no-touch & {
                .circle > span {
                    transform: translate3d(-110%, 0, 0);
                }
            }
        }
    }

    &.button-check {
        @extend .button-next;

        .circle svg {
            width: $icon-check-sm-width;
            margin-left: 0;

            @media #{$mq-medium-up} {
                width: $icon-check-md-width;
                margin-left: 4px;
            }
        }
    }

    &.button-edit {
        @extend .button-next;

        .circle svg {
            width: $icon-edit-sm-width;
            margin-left: 0;

            @media #{$mq-medium-up} {
                width: $icon-edit-md-width;
                margin-left: 0;
            }
        }
    }

    &.button-minus,
    &.button-close {
        @extend .button-next;

        .circle svg {
            width: $icon-close-sm-width;
            margin-left: 0;

            @media #{$mq-medium-up} {
                width: $icon-close-md-width;
                margin-left: 0;
            }
        }
    }

    &.button-bike {
        @extend .button-next;

        .circle svg {
            width: 34px;
            margin-left: 0;

            @media #{$mq-medium-up} {
                width: 40px;
                margin-left: 0;
            }
        }

        &.button-oval-small-only {
            .circle svg {
                width: 34px;
                margin-left: 0;

                @media #{$mq-medium-up} {
                    width: 40px;
                    margin-left: 0;
                }
            }
        }
    }

    &.button-edit-terms {
        @extend .button-next;

        .circle svg {
            width: auto;
            height: 22px;
            margin-left: 0;

            @media #{$mq-medium-up} {
                width: auto;
                height: 40px;
                margin-left: 0;
            }
        }
    }

    &.button-load-more {
        svg {
            width: 26px;
            height: auto;
            margin-top: 6px;
            padding-left: 1px; // zentrierung ausgleichen

            @media #{$mq-medium-up} {
                width: 40px;
            }

        }
        .circle > span {
            transition: transform 0.35s ease-in-out;
            &:first-child {
                top: -110%;
            }
        }
        &:hover {
            .no-touch & {
                .circle > span {
                    transition: transform 0.45s ease-out;
                    transform: translate3d(0, 110%, 0);
                }
            }
        }
    }

    &.button-expand {
        svg {
            width: 50%;
            left: 25%;
            transition: opacity 0.3s ease-in-out;
        }

        // icon transition must be triggered by different class: .is-morphed
        // to reach a different timing
        @media #{$mq-medium-up} {
            .is-expanded & {
                .svg_btn_expanded {
                    opacity: 0;
                }
            }
        }
    }

    &.button-expand-small {
        @extend .button-circle.button-expand;

        .circle {
            width: 40px;
            height: 40px;
        }
    }

    // used for animated header only
    // button with . . .
    // refactor: this should be based on svg class
    &.button-additional-info {
        svg {
            width: 31px;
            margin-left: 0;
            height: 8px;
            overflow: visible;
        }

        @media #{$mq-small-only} {
            svg {
                width: 15px;
            }
        }
    }

    &.button-download {
        position: absolute;
        right: 0;

        svg {
            width: 26px;
            height: auto;
            transition: transform 0.20s ease-in-out;
        }

        .circle {
            span {
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 26px;
                    height: 2px;
                    background: $btn-color;
                    bottom: 21px;
                    left: 22px;
                }
            }
        }

        &.animate {
            svg {
                transform: translateY(-6px);
            }
        }
    }

    // animation for icon loop vertical (used for expand icons)
    &.button-show-layer {
        svg {
            width: 50%;
            height: auto;

            @media #{$mq-medium-up} {
                margin-top: 3px;
            }
        }
        .circle > span {
            transition: transform 0.35s ease-in-out;
            &:first-child {
                top: -110%;
            }
        }
        &:hover {
            .circle > span {
                transition: transform 0.45s ease-out;
                transform: translate3d(0, 110%, 0);
            }
        }

        label {
            transform: translateZ(0);
        }
    }

    &.button-transition-down {
        svg {
            width: 18px;
            height: auto;

            @media #{$mq-medium-up} {
                width: 30px;
            }
        }

        svg > * {
            fill: none !important; // closing stroke
        }

        .circle > span {
            transition: transform 0.35s ease-in-out;
            &:first-child {
                top: -110%;
            }
        }
        &:hover {
            .circle > span {
                transition: transform 0.45s ease-out;
                transform: translate3d(0, 110%, 0);
            }
        }
    }

    &.button-disabled {
        opacity: 0.35;
        pointer-events: none;
    }

    &.button-small {
        .circle {
            position: relative;
            width: $size-small;
            height: $size-small;
            margin-left: 10px;
        }

        &.button-next {
            svg {
                width: 15px;
                height: 22px;
                margin-left: 4px;
            }
        }

        // caused by inflationary usage of @extends
        // i suppose this place is best for fixing svg offset
        &.button-prev {
            svg {
                margin-left: -3px;
            }
        }
    }

    &.button-extra-small {
        .circle {
            position: relative;
            width: $size-extra-small;
            height: $size-extra-small;
            margin-left: 10px;

            // für alle extra smalls oder nur btn-open-layer
            &:after {
                border-width: 1px;
            }

            span {
                font-size: 11px;
            }
        }
    }

    &.button-extra-small.btn-icon-info {
        position: absolute;
        right: 0;
        top: -5px;
        z-index: 1;

        .circle-wrapper svg {
            width: 20px;
            height: 20px;
        }
    }

    &.button-heavy {
        .circle:after {
            border-width: 4px;
        }

        .button-label {
            font-family: $font-flexo-bold;
        }
    }

    @mixin button-underline() {
        $height-underlined: 30px;
        width: 100%;
        height: $height-underlined;
        justify-content: space-between;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: $btn-color;
            transform: translateY(100%);
        }

        &.button-heavy {
            &:after {
                height: 2px;
            }
        }

        .button-label {
            order: 1;
            flex: 1 1 auto;
            text-align: left;
            text-align-last: left;
        }

        .circle {
            height: 30px;
            border-radius: 0;
            order: 2;
            margin-left: 15px;

            &:after {
                width: 50%;
                border: none;
                border-radius: 0;
                background-image: linear-gradient(to right, $btn-background, rgba($btn-background, 0));
            }

            & > span {
                justify-content: flex-end;
            }
        }

        .circle svg {
            width: 13px;
            // make outline thicker
            stroke-width: 0.5px;
        }

        .button-label {
            line-height: 100%;
        }

        &:hover {
            .no-touch & {
                svg {
                    transform: none;
                }
            }
        }

        & + .button-underline,
        & + .button-underline-small {
            margin: 20px 0 0 0;
        }

        &.button-disabled {
            .circle {
                display: none;
            }
        }
    }

    &.button-underline-small {
        @media #{$mq-small-only} {
            @include button-underline();

            .button-label {
                text-align: left!important;
            }
        }
    }

    &.button-underline {
        @include button-underline();
    }

    &:not(.button-underline) {
        .button-label {
            text-align: right;
        }
    }



    //
    &--small {
        .circle {
            width: $size-small;
            height: $size-small;
        }

        &.button-edit .circle svg { width: $icon-edit-sm-width; }
        &.button-check .circle svg { width: $icon-check-sm-width; }

        &.button-minus .circle svg,
        &.button-close .circle svg { width: $icon-close-sm-width; }
    }
}
