.m24-forms {
    .wrapper-form {
        position: relative;
    }

    .fieldset-no-input {
        @include gap-for(margin-top, $gap-2);
        @include gap-for(margin-bottom, $gap-2);
    }

    &.edit-mode {
        .button-toggle-content {
            pointer-events: none;
            opacity: 0;
        }

        // Show buttons inside active preview
        // Fix for multiple content toggles in one form
        .container-toggle.preview.active {
            .button-toggle-content {
                opacity: 1;
                pointer-events: auto;;
            }
        }
    }

    /**
     *  State handling triggered by js
     */
    &[data-state="form-state-submit"] {
        form {
            pointer-events: none;

            .button-circle {
                opacity: 0;
            }

            .loader-gif {
                opacity: 1;
            }
        }
    }

    &[data-state="form-state-error"],
    &[data-state="form-state-success"] {
        .fake-form,
        form {
            pointer-events: none;
            opacity: 0.0;
        }
    }

    &[data-state="form-state-error"] {
        .feedback.error {
            opacity: 1;
            pointer-events: auto;
            transition: opacity 0.5s ease-in-out 0.35s;
        }
    }

    &[data-state="form-state-success"] {
        .feedback.success {
            opacity: 1;
            pointer-events: auto;
            transition: opacity 0.5s ease-in-out 0.35s;
        }
    }

    &.login {
        &:before {
            display: none;
        }

        .grid {
            margin-top: 0;
        }
    }

    // feedback screen
    .feedback {
        position: absolute;
        left: 0;
        bottom: 0;
        opacity: 0;
        max-width: 100%;
        pointer-events: none;
        transition: opacity 0.3s ease-in-out;
    }

    .required-text {
        text-align: right;
    }

    .loader-gif {
        position: absolute;
        left: 50%;
        top: 50%;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    .background-wrapper + .wrapper-button {
        margin-top: 25px;
    }

    .button-circle[type="submit"] {
        transition: opacity 0.3s ease-in-out;
    }

    .fake-form,
    form {
        transition: opacity 0.3s ease-in-out;
    }

    .wrapper-button {
        flex-wrap: wrap;
        align-items: flex-start;

        @media #{$mq-small-only} {
            flex-direction: column;
            &.flex-right {
                align-items: flex-end;
            }

            .button-next {
                order: 1;
                margin: 0 0 20px 0;

                &.button-toggle-content {
                    margin-top: 30px;
                }
            }

            .button-edit {
                margin-top: 30px;
            }

            .button-close {
                order: 2;
                margin: 0 0 0 20px;
            }
        }
    }

    button[type=submit]:disabled {
        pointer-events: none;
        opacity: .5;
    }

    .accordion-expand {
        .grid {
            margin-top: 0;
        }
    }

    .errors {
        color: $color-error;
    }
}

// TODO @brsch variation so ok?
.m24-forms.feedback-align-top {
    &[data-state="form-state-error"],
    &[data-state="form-state-success"] {
        form {
            display: none;
        }
    }

    // Todo @anna anders lösen???
    //&[data-state="form-state-error"] form + .feedback.success + .feedback.error,
    //&[data-state="form-state-error"] form + .container-center .feedback.error,
    //&[data-state="form-state-success"] form + .feedback.success,
    //&[data-state="form-state-success"] form + .container-center .feedback.success {
    //    position: relative;
    //}

    &[data-state="form-state-error"] {
        form + * {
            &.feedback.error,
            + .feedback.error,
            .feedback.error {
                position: relative;
                top: 0;
            }
        }
    }

    &[data-state="form-state-success"] {
        form + * {
            &.feedback.success,
            + .feedback.success,
            .feedback.success {
                position: relative;
                top: 0;
            }
        }
    }
}

.m24-forms,
.m30-layer-content {
    // Show and hide Backend Message in Feedback Screen
    &[data-state="form-state-error"] .feedback.error .backend-error-msg:not(.error) .backend,
    &[data-state="form-state-error"] .feedback.error .backend-error-msg.error .org,
    &[data-state="form-state-success"] .feedback.success .backend-success-msg:not(.success) .backend,
    &[data-state="form-state-success"] .feedback.success .backend-success-msg.success .org {
        display: none;
    }
}

.registration-success {
    .registration-link {
        display: none;
    }
}
