.container-tags {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.single-tag,
.tag {
    height: 22px;
    line-height: 14px;
    padding: 4px 10px 4px 10px;
    font-family: $font-flexo-demi;
    font-size: 14px;
    cursor: default;
}

.tag.experience_store {
    min-width: 120px;
    background-color: $color-black;
}

.tag.ecargo_hub {
    min-width: 120px;
    background-color: $color-retailer-tag--cargo-hub;
}
