.bike-advisor__choices {
    --duration-animate-height: var(--bike-advisor-duration-large);

    transition: height var(--duration-animate-height) var(--ease-in-out-cubic);
    margin-top: 50px;
    // background-color: yellow

    @include bike-advisor__size--medium() {
        margin-top: 100px; }

    @include bike-advisor__size--large() {
        margin-top: 130px; }

    @include bike-advisor__size--extra-large() {
        margin-top: 150px; } }

.bike-advisor__choices-inner {
 }    //

.bike-advisor__choices-inner {
    padding: 0.05px; }

.bike-advisor__choices-output {
    --choice-margin: 20px;

    @include bike-advisor__size--large() {
        --choice-margin: 30px; }

    display: flex;
    flex-wrap: wrap;
    margin: calc(-1 * (var(--choice-margin) / 2)); }
