.m15-feature-table {
    p {
        font-size: 12px;
        line-height: 20px;
    }

    .container-center>.grid {
        @include gap-for(margin-bottom, $gap-3, $gap-0, -1);
    }

    .grid .grid>div {
        @include gap-for(margin-bottom, $gap-2);
    }

    strong+p {
        margin-top: 12px;
    }

    mark {
        margin-top: 12px;
    }

    .icon {
        &:before {
            display: block;
            font-family: $font-icons;
            -webkit-font-smoothing: antialiased;
            text-transform: none;
            width: 100%;
            text-align: center;
            font-size: 36px;
            line-height: 40px;
        }
    }

    .wrapper-frame-geometries {
        figure {
            overflow: hidden;
            width: 100%;
            margin-bottom: 0;
            margin-left: auto;
            margin-right: auto;

            & img {
                position: static;
            }
        }

        &+.wrapper-table {
            margin-top: 15px;
        }
    }

    .accordion {
        margin-top: 10px;
    }

    [data-name="frame-geometries"]+.mask-resize {
        .table-with-frame-geometry table {

            tr th:first-child,
            tr td:first-child {
                width: 60px;
                min-width: 40px;
                max-width: 60px;
            }
        }
    }
}

@media #{$mq-medium-up} {
    .m15-feature-table {
        .accordion {
            margin-top: 20px;
        }
    }
}