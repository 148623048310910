.series-chooser {}

.series-chooser__wrapper-results {
    margin-top: 100px;
}

.series-chooser__grid-columns {
    display: block;
    column-count: 1;
    column-gap: 0;

    @media #{$mq-medium-up} {
        column-count: 2;
    }

    @media #{$mq-large-up} {
        column-count: 3;
    }
}

.series-item-list {
    page-break-inside: avoid;
    break-inside: avoid;
    padding: 5px 0;

    h4 {
        padding: 5px 0;
        text-align: left;
    }

    a {
        display: block;
        padding: 5px 0;
        border-bottom: none;
        font-family: $font-flexo-bold;

        &:hover {
            border-bottom: none;
        }
    }
}
