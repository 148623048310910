.series-chooser__results-photo,
.product-overview__results {
    @media only screen and (min-width: $screen-extra-large) {
        --grid-gutter-horizontal: 75px;

        .xl-4 {
            width: calc((100% - (var(--grid-gutter-horizontal) * 3)) / 3);
        }

        .xl-left-0 {
            margin-left: var(--grid-gutter-horizontal);
        }
    }
}

.product-bike {
    display: block;
    border: none;

    .no-touch & {
        &:hover {
            border: none;
            color: inherit;

            figure {
                transform: scale(1.03);
            }
        }
    }

    &__label-top {
        line-height: 1.7;
        min-height: 50px;
    }

    &__wrapper-content {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
    }

    &__container-figure {
        @include relative-height(3/2);

        @media #{$mq-small-only} {
            margin-bottom: 25px;
        }

        figure {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            backface-visibility: hidden;
            transition: transform 0.3s ease-out;
        }
    }

    &__headline {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 10px;
        padding-bottom: 20px;

        h2 {
            @include headline-h3();
        }
    }

    &__colors {
        margin-top: 5px;

        .colors__color {
            cursor: pointer;
        }
    }

    &__wrapper-price {
        flex: 1 0 auto;
        margin-top: 5px;

        display: flex;
        flex-direction: column;
        align-content: baseline;
        align-items: flex-end;
    }

    &__price {
        text-transform: none;
    }


    &__condition-detail {
        font-family: $font-flexo-regular;
    }
}
