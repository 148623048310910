
#lightbox-gallery {
    z-index: 120;

    &.active {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .overlay-btn-close {
        right: 20px;
        top: 12px;
        color: $color-white;

        @media #{$mq-medium-up} {
            right: 40px;
            top: 60px;
        }
    }

    #pages-counter,
    #pages-total {
        position: absolute;
        top: 20px;
        left: 50%;

        @media #{$mq-medium-up} {
            display: none;
        }
    }

    .container-dots.disabled {
        display: none !important;
    }

    .slider {
        width: 100%;
        height: 100%;

        // Abstand oben & unten, minus Abstand für die Caption (ca 40px)
        @media #{$mq-medium-up} {
            width: calc(100% - 130px)
        }

        @media #{$mq-large-up} {
            width: calc(100% - #{$gap-4-large * 2});
        }
    }

    .container-slides {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .owl-stage-outer {
        height: 100%;
        top: 0;
    }

    .owl-stage {
        height: 100%;
    }

    .owl-item {
        height: 100%;
    }

    .slide {
        position: relative;
        height: 100%;
    }

    figure {
        position: relative;
        overflow: visible;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    img {
        position: relative;
        margin: 0;
        width: auto;
        max-width: 100%;
        max-height: calc(100% - 200px);
        // clicks are fetched by img
        // so click-listener in "lightbox-gallery.js" can identify clicks on backdrop
        pointer-events: auto;
        @media #{$mq-small-up} {
            max-height: calc(100% - 100px);
        }

        @media #{$mq-medium-up} {
            max-height: calc(100% - 200px);
        }
    }

    .caption {
        height: 0;
        top: 20px;
        opacity: 0;
        transition: opacity 0.3s ease-in-out
    }

    img + .caption {
        opacity: 1;
    }

    .owl-nav {
        height: 100%;

        .owl-prev,
        .owl-next {
            visibility: hidden;
            top: 50%;

            &:before {
                top: 50%;
                bottom: 50%;
                color: $color-white !important;
            }
        }

        .owl-next {
            @media #{$mq-medium-up} {
                top: 50%;
                visibility: visible;
                right: -15px;
            }

            @media #{$mq-large-up} {
                right: -28px;
            }
        }

        .owl-prev {
            @media #{$mq-medium-up} {
                top: 50%;
                visibility: visible;
                left: -15px;
            }

            @media #{$mq-large-up} {
                top: 50%;
                left: -28px;
            }
        }
    }
}
