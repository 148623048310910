a.family-card {
    border-bottom: none;
    text-decoration: none;

    &:hover {
        border-bottom: none;
        color: $color-black;

        .owl-stage-outer {
            transform: scale(1.03);
        }
    }
}

.family-card {
    display: block;
    overflow: hidden;

    &:hover {
        .owl-stage-outer {
            transform: scale(1.03);
        }
    }

    // zoom whole owl-stage (instead of slide or figure) to avoid flickering
    .owl-stage-outer {
        padding-top: 67%;
        transition: transform 0.3s ease-out;
    }

    .slider-rebrush {
        margin-top: 20px;
    }

    // nested specificity is necessary for overwrite
    .slider-rebrush .owl-dots .owl-dot {
        height: 20px;
        margin: 0;
        padding: 0 2.5px;

        &:before {
            top: 9px;
            left: 2.5px;
            height: 2px;
            width: calc(100% - 5px);
            transition: background 0.3s ease-in-out;
        }
    }

    // nested specificity is necessary for overwrite
    .slider-rebrush .owl-stage {
        position: absolute;
        left: 0;
        top: 0;
        padding-bottom: 0;
    }

    .slider-rebrush .owl-dots:hover {
        .owl-dot.active:before {
            background: rgba(0,0,0,.12);
        }
    }

    .slider-rebrush .owl-dot:hover {
        &:before {
            background: $color-black !important;
        }
    }

    .owl-nav {
        display: none !important;
    }

    h2 {
        // force thinner text rendering
        text-rendering: geometricPrecision;
    }

    .slider-rebrush__slide {
        position: relative;
        padding-top: 67%;
    }

    figure {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    figure img {
        padding: 20px;
        top: auto;
        bottom: 0;
    }
}

.family-card__details {
    margin-top: 13px;
}

.family-card__frame-variants {
    display: flex;
    margin-top: 16px;

    img {
        height: 22px;
    }

    img + img {
        margin-left: 10px;
    }
}

.family-card__colors {
    margin-top: 20px!important;
    pointer-events: none;

    .colors__container {
        margin-bottom: 0;
    }
}
