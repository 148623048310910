.icon-only {
    height: 100%;
    font-family: $font-icons;
    font-size: 35px;
    line-height: 85px;
    font-style: normal;
    text-transform: none;
    text-align: center;
    color: $color-black;

    @media #{$mq-medium-up} {
        font-size: 30px;
    }
}

.icon-circle-background {
    @include background-circle();
}

.icon-circle-background-medium {
    @include background-circle-medium();
}

.icon-circle-background-small {
    @include background-circle-small();
}

.icon-circle-outline-small {
    @include svg-in-outline-circle(47px, 47px, 10px);
}

.icon-circle-outline-medium {
    @include svg-in-outline-circle(47px, 70px, 17%);
}

// icons
.icon-nav:before {
    content: 'W';
}

.icon-close-bold:before {
    content: 'e';
}

.icon-close:before {
    content: 'X';
}

.icon-plus:before {
    content: 'i';
}

.icon-facebook:before {
    content: 'E';
}

.icon-twitter:before {
    content: 'F';
}

.icon-youtube:before {
    content: 'D';
}

.icon-download:before {
    content: 'f';
}

.icon-arrow-left:before {
    content: 'b';
}

.icon-arrow-right:before {
    content: 'c';
}

.icon-arrow-up:before {
    content: 'd';
}

.icon-arrow-down:before {
    content: 'a';
}

.icon-arrow-link:before {
    content: '>';
}

.icon-nav-arrow-up:before {
    content: 'V';
}

.icon-nav-arrow-up-center:before {
    content: 'R';
}

.icon-view-small:before {
    content: 'x';
}

.icon-view-medium:before {
    content: 'z';
}

.icon-search:before,
.icon-magnifier:before {
    content: 'r';
}

.icon-bike:before {
    content: 'j';
}

.icon-bike-small:before {
    content: 't';
}

.icon-pedal:before {
    content: 'I';
}

.icon-map:before {
    content: 'w'
}

.icon-map-white:before {
    content: '1'
}

.icon-list:before {
    content: 'y'
}

.icon-home:before {
    content: 'Y'
}

.icon-arrow-down-inverted:before {
    content: 'O';
}

.icon-linkedin:before {
    content: 'J';
}

.icon-xing:before {
    content: 'K';
}

.icon-instagram:before {
    content: 'G';
}

.icon-contact:before {
    content: 's';
}

.icon-openings:before {
    content: 'l';
}

.icon-hyperlink:before {
    content: 'B';
}

.icon-facebook-square:before {
    content: '!';
}

.icon-twitter-square:before {
    content: '$';
}

.icon-youtube-square:before {
    content: '&';
}

.icon-xing-square:before {
    content: '%';
}

.icon-linkedin-square:before {
    content: '§';
}

.icon-instagram-square:before {
    content: '"';
}

// feature icons
.icon {
    &.battery:before {
        content: '2';
    }

    &.suspension:before {
        content: '3';
    }

    &.motor:before {
        content: '4';
    }

    &.shifting:before {
        content: '5';
    }

    &.display:before {
        content: '6';
    }

    &.disc-brake:before {
        content: '7';
    }

    &.calliper-brake:before {
        content: '8';
    }

    &.light:before {
        content: '0';
    }

    &.option:before {
        content: '9';
    }

    &.wheel:before {
        content: 'A';
    }
}

// brsch: überschrieben, da "i" jetzt mittels spans dargestellt wird
.icon-info {
    &:before {
        // content: 'h';
        display: none;
    }
}
