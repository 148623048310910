@mixin headline-h1--large() {
    font-size: 36px;
    line-height: 47px;

    @media #{$mq-medium-up} {
        font-size: 66px;
        line-height: 77px;
    }
}

@mixin headline-h2--large() {
    font-size: 36px;
    line-height: 42px;

    @media #{$mq-medium-up} {
        font-size: 50px;
        line-height: 57px;
    }
}

@mixin dateline--large() {
    font-size: 16px;
    line-height: 24px;
    font-family: $font-flexo-demi;

    @media #{$mq-medium-up} {
        font-size: 20px;
        line-height: 26px;
    }
}

@mixin html-text() {
    font-family: $font-flexo-regular;
    font-weight: 100;
    font-size: 14px;
    letter-spacing: 0.03em;
}


@mixin base-paragraph($font-family: $font-flexo-regular) {
    font-family: $font-family;
    font-weight: 100;
    letter-spacing: 0.03em;
}

@mixin paragraph--small($font-family: $font-flexo-regular) {
    font-size: $p_fontsize;
    line-height: $p-lineheight;
    @include base-paragraph($font-family);
}

@mixin paragraph($font-family: $font-flexo-regular) {
    font-size: $pr_fontsize;
    line-height: $pr-lineheight;
    @include base-paragraph($font-family);
}

@mixin text--small($font-family: $font-flexo-regular) {
    font-size: $tsmall_fontsize;
    line-height: $tsmall_lineheight;
    @include base-paragraph($font-family);
}


@mixin base-text() {
    position: relative;
    font-weight: 100;
    margin-top: 0;
    margin-bottom: 0;
}

@mixin base-headline() {
    font-family: $font-flexo-thin;
    @include hyphens;
}

@mixin headline-h1() {
    $top: -7px;
    $bottom: -15px;

    font-size: 40px;
    line-height: 51px;

    .text-spaces > & {
        top: $top;
        margin-bottom: #{$top + $bottom};
    }

    @media #{$mq-medium-up} {
        $top: -12px;
        $bottom: -13px;

        font-size: 45px;
        line-height: 57px;

        .text-spaces > & {
            top: $top;
            margin-bottom: #{$top + $bottom};
        }
    }
}

@mixin headline-h2() {
    $top: -6px;
    $bottom: -11px;

    font-size: 30px;
    line-height: 39px;

    .text-spaces > & {
        top: $top;
        margin-bottom: #{$top + $bottom};
    }
}

@mixin headline-h3() {
    $top: -5px;
    $bottom: -9px;

    font-size: 25px;
    line-height: 32px;

    .text-spaces > & {
        top: $top;
        margin-bottom: #{$top + $bottom};
    }
}

@mixin headline-h4() {
    $top: -6px;
    $bottom: -8px;

    font-size: 22px;
    line-height: 30px;

    .text-spaces > & {
        top: $top;
        margin-bottom: #{$top + $bottom};
    }
}

@mixin dateline() {
    @include paragraph--small($font-flexo-demi);
}


@mixin text-bold-medium {
    font-family: $font-flexo-demi;
    font-size: 16px;
    line-height: 22px;
}
