/**
Todo: Refactor whole theming logic and use css variables
*/

:root {
    --background-color: #{$color-white};
    --color: #{$color-black};

    --btn-base-background-color: #{$color-black};
    --btn-base-color: #{$color-white};
    --btn-base-background-color-hover: #{$color-warm-grey};
    --btn-base-color-hover: #{$color-white};

    --btn-circle-filled-background-color: #{$color-black};
    --btn-circle-filled-color: #{$color-white};
}

@mixin overwrite-text-color($color) {
    color: $color;

    p, a, label {
        color: $color;
    }

    a {
        color: $color;
        border-color: $color;

        &:hover {
            .no-touch & {
                color: $color;
                border-color: $color;
            }
        }
    }

    .btn-icon {
        color: $color !important;

        &:hover {
            .no-touch & {
                color: $color !important;
                background-color: transparent !important;
            }
        }
    }
}


// SOME BASE-BACKGROUND_STYLES
.background-image--inverted,
.background-dark-grey,
.background-black {
    --background-color: #{$color-black};
    --color: #{$color-white};

    --btn-base-background-color: #{$color-white};
    --btn-base-color: #{$color-black};
    --btn-base-background-color-hover: #{$color-light-grey};
    --btn-base-color-hover: #{$color-black};

    --btn-circle-filled-background-color: #{$color-white};
    --btn-circle-filled-color: #{$color-black};

    background-color: var(--background-color);
    @include overwrite-text-color(var(--color));
}

.background-image,
.background-nearly-white,
.background-medium-grey,
.background-grey,
.background-white {
    --background-color: #{$color-white};
    --color: #{$color-black};

    --btn-base-background-color: #{$color-black};
    --btn-base-color: #{$color-white};
    --btn-base-background-color-hover: #{$color-warm-grey};
    --btn-base-color-hover: #{$color-white};

    --btn-circle-filled-background-color: #{$color-black};
    --btn-circle-filled-color: #{$color-white};

    background-color: var(--background-color);
    @include overwrite-text-color(var(--color));
}

.background-nearly-white {
    --background-color: #{$color-nearly-white};
}

.background-medium-grey {
    --background-color: #{$color-light-grey};
}

.background-dark-grey {
    --background-color: #{$color-dark-grey};
}

.background-grey {
    --background-color: #{$color-ultra-light-grey};

    // Wenn Klasse auf section gesetzt wird, sollen alle child-module auch grau werden
    &.section {
        & > .module,
        & > .cms-plugin > .module,
        & > .module > .module {
            background-color: var(--background-color);
        }
    }

    & > .module {
        background-color: var(--background-color);
    }
}



