@import "partials/input-search";
@import "partials/retailer-card";
@import "partials/retailer-list";
@import "partials/retailer-map";
@import "partials/retailer-search-mask";

.retailer-search {

    &:after {
        background-color: $color-ultra-light-grey;
    }

    .no-results-found {
        position: relative;
        display: flex;
        padding: 20px;
        background-color: $color-black;
        width: 100%;
        flex-direction: column;

        span {
            flex: 0 0 auto;
        }

        p {
            color: $color-white;
            margin-top: 20px;
        }

        @media #{$mq-medium-up} {
            align-items: center;
            padding: 70px 20px;
            flex-direction: row;
            justify-content: center;

            p {
                margin-top: 0;
                margin-left: 20px;
                text-align: center;
            }
        }
    }

    .wrapper-filter-results {
        margin-top: 0;
    }

    .powered-by-google {
        display: block;
        margin: 50px auto 0 auto;
        width: 144px;
        height: 18px;

        // Ausnahme: da in "small-only" :after des Moduls nur 30px hoch, werden 20px für gleichmäßige Abstände "ergänzt"
        @media #{$mq-small-only} {
            margin-bottom: 20px;
        }
    }
}

.retailer-search__filter-bar {
    padding-bottom: 50px;
    background-color: $color-ultra-light-grey;
}

.retailer-search__results {
    @media #{$mq-large-down} {
        font-size: 25px;
    }

    @media #{$mq-extra-small-only} {
        font-size: 18px;
        line-height: 1.5;
    }
}
