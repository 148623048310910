
select::-ms-expand {
    display: none;
}

select > option {
    border-radius: 0;
}

.custom-select,
.input-search {
    $padding-horizontal: 20px;
    position: relative;

    &.readonly {
        pointer-events: none;
    }

    select,
    .option {
        padding: 0 $padding-horizontal;
    }

    select {
        color: $color-medium-grey;
        width: 100%;
        // Damit Text nicht unterhalb des grünen Pfeils läuft
        padding-right: 50px;

        &.selected {
            color: $color-black;
        }
    }

    .container-options {
        display: none;
    }

    select.error {
        color: $color-error;
    }

    .icon-arrow-down {
        position: absolute;
        height: 30px;
        width: 30px;
        right: 10px;
        top: 22px;
        pointer-events: none;

        transform: translate3d(0, -50%, 0);

        &:before {
            display: block;
            height: 100%;
            width: 100%;
            font-family: $font-icons;
            font-size: 9px;
            line-height: 30px;
            font-style: normal;
            text-transform: none;
            text-align: center;
            color: $color-black;
            margin-top: 1px;
        }
    }

    .click-catcher {
        display: none;
    }
}

.custom-select {

    .search-input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        margin: 0;
    }

    &.expanded {
        .search-input {
            opacity: 1;
        }
    }

    &.custom-select--white {
        select {
            background-color: $color-white;
            color: $color-black;
        }

        input {
            cursor: pointer;
        }

        &.expanded {
            input {
                cursor: auto;
            }
        }
    }

    //.no-touch & { // why for no-touch???
    .touch & {
        select {
            pointer-events: none;
        }

        .click-catcher {
            display: block;
        }
    }


    &[data-autocomplete="false"].expanded {
        .search-input {
                opacity: 0;
        }
    }

    &.loading.disabled {
        .icon-arrow-down {
            opacity: 0;
        }
        &::after {
            content:'';
            position: absolute;

            @include loader(16px, 2px);

            right: 16px;
            top: 26px;

            pointer-events: none;

        }
    }
}

.input-search {
    .click-catcher {
        display: block;
    }
}

.custom-select,
.input-search {
    $height: 45px;
    $height-option: 45px;
    $padding-horizontal: 20px;

    cursor: pointer;
    transition: background-color 0.25s ease-out;

    &.expanded {
        z-index: 3;

        .icon-arrow-down {
            transform: translate3d(0, -50%, 0) rotate(180deg);
        }

        .container-options {
            z-index: $z-index-flyout;
        }
    }

    .icon-arrow-down {
        transition: transform 0.3s ease-in-out;
    }

    .container-options {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: $z-index-flyout;
        padding-top: #{$height - 1px};
        visibility: hidden;
        border: 1px solid $color-warm-grey;
        opacity: 0;
    }

    .mask {
        // necessary to readout correct childs offsetTop
        position: relative;
        height: auto;
        background-color: $color-white;

        &.scrollable {
            overflow-x: hidden;
            overflow-y: scroll;

            @include custom-scrollbar();
        }
    }

    .option {
        position: relative;
        width: 100%;
        cursor: pointer;
        min-height: $height-option;
        line-height: 21px;
        padding: 12px 20px;
        background-color: $color-white;
        overflow: hidden;

        transition: background-color 0.25s ease-out;

        &.hover {
            background-color: $color-ultra-light-grey;
        }

        &.hover,
        &:hover {
            font-family: $font-flexo-demi;
        }

        &::selection {
            background: transparent;
        }
        &::-moz-selection {
            background: transparent;
        }
    }

    .click-catcher {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &.custom-select--upside-down {
        .container-options {
            top: auto;
            bottom : 0;
            z-index: $z-index-flyout;
            padding-top: 0;
            padding-bottom: #{$height - 1px};

            .mask {
                display: flex;
                flex-direction: column-reverse;
            }
        }
    }
}
