
@function gap-in-px( $gap, $mq ) {
    $n: $gap + $mq + 1;
    $list-gaps: 0 $gap-1-small $gap-2-small $gap-3-small $gap-4-small 0 $gap-1-medium $gap-2-medium $gap-3-medium $gap-4-medium 0 $gap-1-large $gap-2-large $gap-3-large $gap-4-large;

    @return nth($list-gaps, $n);
}

@mixin gap-for( $css-value, $gap, $gap2 : 0, $factor:1 ) {
    #{$css-value}: #{ ( gap-in-px($gap,0) + gap-in-px($gap2,0)) * $factor};

    @media #{$mq-medium-up}
    {
        #{$css-value}: #{ ( gap-in-px($gap,5) + gap-in-px($gap2,5)) * $factor};
    }

    @media #{$mq-large-up}
    {
        #{$css-value}: #{ ( gap-in-px($gap,10) + gap-in-px($gap2,10)) * $factor}; // 8
    }
}

@mixin gap-0( $css-value ) {
    @include gap-for($css-value, $gap-0);
}

@mixin gap-1( $css-value ) {
    @include gap-for($css-value, $gap-1);
}

@mixin gap-2( $css-value ) {
    @include gap-for($css-value, $gap-2);
}

@mixin gap-3( $css-value ) {
    @include gap-for($css-value, $gap-3);
}

@mixin gap-4( $css-value ) {
    @include gap-for($css-value, $gap-4);
}

/**
 * Basierend auf: http://www.mademyday.de/css-height-equals-width-with-pure-css.html
 */
@mixin relative-height( $ratio: 1/1, $position: relative ) {
    position: $position;

    &:before {
        content: "";
        display: block;
        padding-top: 100% / $ratio;
    }
    .inner {
      position: absolute;
      top:0;
      right:0;
      bottom: 0;
      left:0;
      width: 100%;
      height: 100%;
    }
}

@mixin hide-input-for-custom-checkbox() {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@mixin no-select() {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}


// http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount;
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;
    &:before {
      content: '...';
      position: absolute;
      right: 0;
      bottom: 0;
    }
    &:after {
      content: '';
      position: absolute;
      right: 0;
      width: 1em;
      height: 1em;
      margin-top: 0.2em;
      background: $bgColor;
    }
}

@mixin hyphens {
    word-break: keep-all!important;
    overflow-wrap: break-word;
    hyphens: auto;
    box-align: start;
    -webkit-box-align: start;
}

@mixin background-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: $color-light-grey;
    width: $background-circle;
    height: $background-circle;

    @media #{$mq-medium-up} {
        width: $background-circle-large;
        height: $background-circle-large;
    }

    svg {
        height: 100%;
        width: 100%;
        padding: 15px;

        @media #{$mq-medium-up} {
            padding: 20px;
        }

        &,
        & line {
            fill: $color-white;
            stroke: $color-white;
        }

        & path,
        & circle,
        & polyline,
        & polygon {
            stroke: $color-white;
        }

        &.svg-bike {
            padding: 15px;
        }
    }

    &.background-circle-dark {
        background: $color-dark-grey;
    }
}

@mixin background-circle-medium {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: $color-light-grey;
    width: 50px;
    height: 50px;

    svg {
        height: 100%;
        width: 100%;
        padding: 10px;

        &,
        & line {
            fill: $color-white;
            stroke: $color-white;
        }

        & path,
        & circle,
        & polyline,
        & polygon {
            stroke: $color-white;
        }
    }

    &.background-circle-dark {
        background: $color-dark-grey;
    }

    &.background-circle-black {
        background: $color-black;
    }
}

@mixin background-circle-small {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: $color-light-grey;
    width: 40px;
    height: 40px;

    svg {
        height: 100%;
        width: 100%;
        padding: 10px;

        &,
        & line {
            fill: $color-white;
            stroke: $color-white;
        }

        & path,
        & circle,
        & polyline,
        & polygon {
            stroke: $color-white;
        }
    }

    &.background-circle-dark {
        background: $color-dark-grey;
    }

    &.background-circle-black {
        background: $color-black;
    }
}

@mixin svg-in-outline-circle($diameter--mq-small: 47px, $diameter--mq-medium-up: 47px, $svg-padding: 10px) {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: $diameter--mq-small;
    height: $diameter--mq-small;
    border: 2px solid $color-white;

    @media #{$mq-medium-up} {
        width: $diameter--mq-medium-up;
        height: $diameter--mq-medium-up;
    }

    svg {
        height: 100%;
        width: 100%;
        padding: $svg-padding;

        &,
        & line {
            fill: $color-white;
            stroke: $color-white;
        }

        & path,
        & circle,
        & polyline,
        & polygon {
            stroke: $color-white;
        }
    }
}

@mixin custom-scrollbar() {
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-warm-grey;
    }

    &::-webkit-scrollbar-track {
        background-color: $color-ultra-light-grey;
    }
}

@mixin hidden-scrollbar() {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
}
