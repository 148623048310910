.product-detail__bike-teaser {
    > .container-center {
        display: flex;
        flex-direction: row;

        > * {
            flex-basis: 50%;
        }

                @media #{$mq-large-down} {
            width: 100%;
            flex-direction: column;

            > * {
                flex-basis: 100%;
            }
        }
    }

    &.single {
    &::before,
            &::after {
        display: none;
    }

            > .container-center {
        max-width: none;
        width: 100%;
        margin-left: 0;
        margin-right: 0;

        > * {
            flex-basis: 100%;
        }
    }
}

            @media #{$mq-large-down} {
        &::before,
                &::after {
            display: none;
        }
    }
}

.product-detail__teaser {
    padding: 40px 0;

    h3 {
        font-size: 35px;
        line-height: 115%;
    }

    .container-center {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.product-detail__teaser-image {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    img {
        width: 100%;

        @media #{$mq-medium-up} {
            width: 60%;
        }

        @media #{$mq-large-up} {
            width: 80%;
        }
    }

    &.single {
        width: 100%;

        @media #{$mq-medium-up} {
            width: 80%;
        }

        @media #{$mq-large-up} {
            width: 50%;
        }
        
    }
}

.product-detail__teaser-buttons {
    margin-top: 10px;
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    justify-content: flex-end;

    @media #{$mq-large-down} {
        margin-top: 40px;
    }

    > * {
        align-self: flex-end;

        & + * {
            margin-top: 20px;
        }
    }
}
