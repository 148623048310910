.language-chooser {
    .m30-layer {
        padding-top: $overlay-header-height;

        @media #{$mq-medium-up} {
            padding-top: $padding-modules-mq-medium;
        }

        @media #{$mq-large-up} {
            padding-top: $padding-modules-mq-large;
        }
    }

    z-index: 120;

    .custom-select .mask {
        .custom-inputs & {
            height: 0;
        }
    }

    .btn-change-language {
        margin-top: 20px;
    }

    h2 {
        @media #{$mq-small-only} {
            margin-top: 5px;
        }
    }

    .errors p {
        color: $color-error;
        line-height: 18px !important;
    }
}
