.button-pager-extend {
    $width: 40px;

    width: $width;
    height: $width;
    // globalen Button-Style überschreiben
    margin: 0;

    opacity: 0;
    transform: translateY(0);

    &:before {
        font-family: $font-icons;
        -webkit-font-smoothing: antialiased;
        text-transform: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        font-size: 28px;
        line-height: $width;

        // todo - rebrush
        color: $color-black;
        // color: $color-brand-green;

        transition: transform 0.15s ease-out;
    }

    &.small {
        width: 24px;
        height: 24px;

        &:before {
            font-size: 12px;
            line-height: 22px;
        }
    }

    &.dark {
        &:before {
            color: $color-black;
        }
    }

    &:hover {
        .no-touch & {
            &.left:before {
                transform: translate3d(-4px, 0, 0);
            }
            &.right:before {
                transform: translate3d(4px, 0, 0);
            }
        }
    }

    &:not(.invisible) {
        opacity: 1;
    }
}

.slider.loaded {
    .button-pager-extend {
        &.visible {
            pointer-events: auto;
            opacity: 1;
        }

        &.visible.disabled {
            pointer-events: none;
            opacity: 0.25;
        }
    }
}

.button-pager {
    @extend .button-pager-extend;
}
