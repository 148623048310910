
// global nutzbarer, grauer Balken mit Mindest-Höhe und sein schäbiger Bruder
.bar, .bar-background {
    position: relative;
    width: 100%;
    min-height: $min-height-bar;

    // muss getrennt stehen
    ::selection {
        background: transparent;
    }
    ::-moz-selection {
        background: transparent;
    }
}

.bar-background {
    position: relative;
    width: 300%;
    left: -100%;
    margin-bottom: #{- $min-height-bar};
}

// Bar soll immer weiß sein, wenn sie sich innerhalb eines grauen Moduls befindet
.background-grey .bar,
.background-grey .bar-background {
    background-color: $color-white;
}