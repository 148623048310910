
.accordion-expand {
    width: 100%;

    &.deactivate {
        .mask-expand {
            height: auto;

            &:after {
                display: none;
            }
        }

        .wrapper-button {
            display: none!important;
        }
    }

    &.accordion-expand-variant-2 {
        position: relative;
        padding-bottom: 70px;

        .mask-expand {
            height: 0;
            overflow: hidden;
            margin-bottom: -70px;

            @media #{$mq-medium-up} {
                height: 0;
                margin-bottom: -100px;
            }
        }

        .button-expand {
            position: absolute;
            top: 30px;
            right: 0;
            z-index: 1;
        }

        @media #{$mq-medium-up} {
            padding-bottom: 100px;
        }
    }

    .mask-expand {
        height: 0;
        overflow: hidden;

        @media #{$mq-medium-up} {
            height: 26px * 3; // 78px;
        }

        &:after {
            @media #{$mq-medium-up} {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $color-white);
                transition: opacity 0.5s ease-in-out;
                pointer-events: none;
            }
        }
    }

    &.is-expanded {
        .mask-expand {
            &:after {
                @media #{$mq-medium-up} {
                    opacity: 0;
                }
            }
        }
    }

    // set span to "block" to prevent letters cutted
    .content-expand {
        display: block;
    }

    // content for small devices
    .content-expand-small-only {
        display: block;

        @media #{$mq-large-up} {
            display: none;
        }
    }

    .wrapper-morph {
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: $color-white;
        transition: background-color 0.8s ease-in-out;

        .wrapper-button {
            z-index: 2;

            @media #{$mq-small-only} {
                flex: 0 0 auto;
            }
            @media #{$mq-medium-up} {
                display: block;
            }
        }

        .rb__container-text {
            position: relative;
            z-index: 1;
            flex: 1 1 auto;
        }
    }
}
