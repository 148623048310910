
.wrapper-filter-results {
    position: relative;
    overflow: hidden;
    margin-top: 40px;

    @media #{$mq-medium-up} {
        margin-top: 80px;
    }

    .cover {
        visibility: hidden;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $color-white;
        z-index: $z-index-cover-filter-results;
        opacity: 0;
        transition: opacity 0.25s ease-in-out;

        &--hidden {
            opacity: 1;
            visibility: visible;
        }
    }
}
