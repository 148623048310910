.pagination {}

.pagination__track {
    position: relative;
    width: 100%;
    height: 2px;
    background-color: $color-light-grey;
}

.pagination__bar {
    position: absolute;
    left: 0;
    width: 0;
    height: 2px;
    background-color: $color-black;
    transition: left 0.2s ease-out, width 0.3s ease-in-out;
}

.pagination__footer {
    display: flex;
    justify-content: space-between;
}

.pagination__label {
    margin-top: 10px;
    pointer-events: none;
}

.pagination__label-bold {
    font-family: $font-flexo-demi;
}

.pagination__label-light {
    color: $color-medium-grey;
}

.pagination__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;
}

.pagination__button-next {
    margin-left: 30px;
}
