
p + .wrapper-button,
.rb__container-text + .wrapper-button {
    margin-top: 26px;

    @media #{$mq-medium-up} {
        margin-top: 30px;
    }
}

.wrapper-button {
    display: flex;
    width: 100%;

    &.flex-right {
        justify-content: flex-end;

        // wrapper can contain own grid
        // also children should be aligned right
        // !!! should be removed - used?
        & > .grid > div {
            display: flex;
            justify-content: flex-end;
        }
    }

    &.flex-center {
        justify-content: center;
    }

    .button-circle,
    button {
        & + .button-circle,
        & + button {
            margin-left: 35px;
        }
    }
}
