// scss modules

.specs {

    &__header {
        display: flex;
        flex-wrap: nowrap;
    }

    &__table {
        //display: flex;
        //flex-wrap: wrap;
        //flex-direction: row;

        margin-left: -$grid-gutter--mq-small/2;
        margin-right: -$grid-gutter--mq-small/2;

        @media #{$mq-medium-up} {
            column-count: 2;
            column-gap: 0;

            margin-left: -$grid-gutter--mq-medium/2;
            margin-right: -$grid-gutter--mq-medium/2;
        }

        @media #{$mq-large-up} {
            column-count: 3;
            column-gap: 0;

            margin-left: -$grid-gutter--mq-large/2;
            margin-right: -$grid-gutter--mq-large/2;
        }
    }

    &__item {

        padding: 0 $grid-gutter--mq-small/2;

        @media #{$mq-medium-up} {
            //flex: 0 0 50%;
            //max-width: calc(100% / 2);
            break-inside: avoid-column;

            padding: 0 $grid-gutter--mq-medium/2;
        }

        @media #{$mq-large-up} {
            //flex: 0 0 33.3333333%;
            //max-width: calc(100% / 3);
            break-inside: avoid-column;

            padding: 0 $grid-gutter--mq-large/2;
        }

        &-inner {
            display: flex;
            border-bottom: 1px solid $color-black;
            padding: 16px 0 3px;
            flex-wrap: nowrap;
            justify-content: space-between;

            .background-black & {
                border-bottom-color: $color-white;
            }
        }
    }

    &__name {
        flex: 0 0 auto;
        margin-right: 10px;
    }

    &__value {
        flex: 0 1 auto;
    }

    &__header {
        margin-bottom: 15px;

        &-headline {
            text-transform: uppercase;
            display: flex;
            align-items: center;
        }

        &-item-wrapper {

        }
    }

    .grid > *:last-child {
        margin-bottom: 0;
    }
}


.specs--basic {
    .specs__item-inner {
        flex-direction: column;
    }

    .specs__name {
        font-family: $font-flexo-demi;
        margin-bottom: 2px;
    }

    .specs__value {
        margin-bottom: 3px;
    }

    .specs__footer {
        margin-top: 90px;
    }
}
