.sidebar-filters {
    $duration-in: 0.35s;
    $duration-out: 0.4s;

    $easing-in: cubic-bezier(0,.54,.19,1);
    $easing-out: cubic-bezier(.64,.06,.62,.42);

    $delay-in: 0.10s;
    $delay-out: 0.07s;

    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    max-width: 375px;
    background-color: $color-white;
    opacity: 1;
    transform: translate3d(110%, 0, 0);
    transition: opacity $duration-out $easing-out $delay-out, transform $duration-out $easing-out $delay-out;
    pointer-events: none;
    z-index: $z-index-product-overview-sidebar-filter;

    &--opened {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: opacity $duration-in $easing-in $delay-in, transform $duration-in $easing-in $delay-in;
        pointer-events: auto;
    }

    &--loading {
        .sidebar-filters__click-catcher {
            display: block;
        }

        .sidebar-filters__button-apply {
            opacity: 0;
        }

        .sidebar-filters__loader {
            opacity: 1;
        }
    }

    &--no-results {
        .sidebar-filters__hint-no-results {
            opacity: 1;
        }

        .sidebar-filters__button-apply {
            opacity: 0;
            pointer-events: none;
        }

        .sidebar-filters__loader {
            opacity: 0;
        }
    }
}

.sidebar-filters__header {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: $color-ultra-light-grey;
}

.sidebar-filters__button {
    display: flex;
    line-height: 0;

    svg {
        height: 22px;
    }

    span {
        font-size: 16px;
        line-height: 100%;
        margin-top: 4px;
        margin-left: 15px;
        font-family: $font-flexo-demi;
    }
}

.sidebar-filters__content {
    height: 100%;
    // padding: 20px 0;
    overflow-x: hidden;
    overflow-y: scroll;

    @include custom-scrollbar();
}

.sidebar-filters__footer {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding: 15px 20px;
}

.sidebar-filters__button-apply {
    transition: opacity 0.1s ease-out;
}

.sidebar-filters__click-catcher {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background: transparent;
    z-index: 1000;
}

.sidebar-filters__hint-no-results {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: $font-flexo-demi;
    text-transform: uppercase;
    transition: opacity 0.1s ease-out;
    opacity: 0;
}

.sidebar-filters__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 28px;
    height: 28px;
    margin: -14px 0 0 -14px;
    border: 4px solid $color-light-grey;
    border-top: 4px solid $color-dark-grey;
    border-right: 4px solid $color-dark-grey;

    border-radius: 100%;
    transform: translateZ(0);
    animation: spinning-loader 1.5s linear infinite;
    opacity: 0;
    transition: opacity 0.1s ease-out;
}

.sidebar-filters__total-count {
    // hide due to request from RM...
    display: none;
}
