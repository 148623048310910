
.m03-dispatcher {
    margin-bottom: #{$gap-smallest-part * -1};

    .main-teaser {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;

        @media #{$mq-medium-up} {
            left: #{$gap-smallest-part * -1};
            width: calc(100% + #{$gap-smallest-part});
        }

        &--center {
            width: 100%;
        }
    }

    .m03-teaser {
        position: relative;
        display: block;
        // globalen anchor-Style überschreiben
        font-family: $font-flexo-thin;
        margin: 0;
        border-bottom: none;
        height: 100%;
        overflow: hidden;

        & * {
            pointer-events: none;
        }
    }


    .m03-teaser {
        margin-bottom: $gap-smallest-part;

        &.half, &.third, &.full {
            width: 100%;
            @include relative-height(3/1);
        }

        @media #{$mq-small-only} {
            margin-left: 0 !important;
        }

        @media #{$mq-medium-up} {
            &.full {
                width: 100%;
                margin-left: 0;
                @include relative-height(3/2);
            }
            &.half {
                width: calc((100% - #{$gap-smallest-part * 2}) / 2);
                margin-left: $gap-smallest-part;
                @include relative-height(3/2);
            }
            &.third {
                width: calc((100% - #{$gap-smallest-part * 3}) / 3);
                margin-left: $gap-smallest-part;
                @include relative-height(2.5/3);
            }
        }

        @media #{$mq-large-up} {
            &.half, &.full {
                @include relative-height(3/1.5);
            }
            &.third {
                @include relative-height(3/2.5);
            }
        }
    }

    &.below {
        @media #{$mq-small-only} {
            &.height-80 {
                height: initial;
            }

            .m03-teaser {
                width: 100% !important;
                @include relative-height(3/1);
                margin-bottom: 10px;
                margin-left: 0 !important;

                h2 {
                    transform: translateY(-50%) translateZ(0) rotate(0deg);
                }
            }
        }
    }

    figure {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }

    h2 {
        position: absolute;
        height: auto;
        top: 50%;
        width: 100%;
        text-align: center;
        vertical-align: middle;
        padding: 0 20px;
        color: $color-white;
        // Um Titel vor den after-content (weißes Cover) des Teasers zu setzen
        z-index: 2;
        font-size: 30px;
        line-height: 1.25em;

        transform: translateY(-50%) translateZ(0) rotate(90deg);
        transition: color 0.5s ease-out;

        @media #{$mq-medium-up} {
            transform: translateY(-50%) translateZ(0) rotate(0deg);
            font-size: 40px;
            font-size: calc(1rem + 1.5vw);
        }

        @media #{$mq-large-up} {
            font-size: 45px;
            font-size: calc(1rem + 2vw);
        }
    }
}

// .no-touch .m03-dispatcher
.m03-dispatcher {
    figure {
        transition: all 0.5s ease-out;

        // filter:  grayscale(55%) brightness(45%) ;
        filter: grayscale(55%) brightness(65%);
    }

    .m03-teaser {
        &:hover {
            figure {
                .no-touch & {
                    filter: grayscale(0%) brightness(100%);
                }
            }
        }
    }
}

.cms-structure-mode-content .m03-teaser {
    pointer-events: none;
}
