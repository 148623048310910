
.m50-parallax-teaser {
    .button-circle {
        position: absolute;
        right: $padding-container-center-small;
        bottom: $padding-container-center-small;

        @media #{$mq-medium-up} {
            right: $padding-container-center-medium;
            bottom: $padding-container-center-medium;
        }

        @media #{$mq-large-up} {
            right: $padding-container-center-large;
            bottom: $padding-container-center-large;
        }
    }
}