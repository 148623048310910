
.header-article {
    $vertical-space: 50px;

    &__block {
        &:nth-last-child(n+2) {
            margin-bottom: $vertical-space;
        }

        .text-spaces {
            p + p {
                margin-top: $vertical-space;
            }
        }
    }

    &__image {
        @media #{$mq-small-only} {
            margin-bottom: $vertical-space !important;
        }
    }

    &__caption {
        @media #{$mq-small-only} {
            margin-left: $padding-container-center-small;
        }
    }
}
