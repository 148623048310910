/**********************
    CHECKBOX / RADIOS
**********************/

$inputHeight: 12px;
$inputWidth: 12px;
$inputBorderWidth: 0px;
$inputActiveScale: 6px;

.wrapper-checkbox + .wrapper-checkbox {
    margin-top: 45px;
}

.wrapper-checkbox {
    input,
    input {
        @include hide-input-for-custom-checkbox();

        &:focus {
            + label:before {
                border-color: $color-warm-grey;
            }
        }

        &:hover {
            .no-touch & {
                + label:before {
                    border-color: $color-warm-grey;
                }
            }
        }

        &:active + label:before {
            transition-duration: 0s;
        }

        &.error + label:before {
            box-shadow: 0 0 0 2px $color-error;
        }

        &:focus + label {
            .circle-focus {
                opacity: 1;
            }
        }

    }

    .circle-focus {
        position: absolute;
        left: 6px;
        top: 6px;
        width: 41px;
        height: 41px;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        z-index: 0;
        border: 1px solid $color-light-grey;
    }


    input.error + label .error-text {
        display: block;
    }

    label {
        position: relative;
        display: block;
        font-size: 14px;
        min-height: 16px;
        line-height: 16px;
        padding-left: 36px;
        vertical-align: middle;
        user-select: none;
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 1px;
            width: $inputWidth;
            height: $inputHeight;
            background: $color-light-grey;
            text-align: center;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: $inputBorderWidth + $inputActiveScale/2;
            width: $inputWidth - $inputActiveScale;
            height: $inputHeight - $inputActiveScale;

            transform: scale(0);
            transform-origin: 50%;
            transition: transform 0.25s ease-out;
        }

        a {
            font-size: inherit;
            line-height: inherit;
        }

        span {
            display: inline-block;
            position: relative;
            top: -4px;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}

.wrapper-checkbox__headline,
.wrapper-checkbox__subheadline {
    display: block;
    font-size: 16px;
}

.wrapper-checkbox__headline {
    display: block;
    font-family: $font-flexo-demi;
    font-size: 16px;
}

.wrapper-checkbox__subheadline {
    margin-top: 13px;
}

.wrapper-checkbox__headline + input + label,
.wrapper-checkbox__subheadline + input + label {
    margin-top: 26px;
}

.wrapper-checkbox {
    label {
        &:before {
            box-shadow: 0 0 0 2px $color-black;
            background-color: $color-white;
        }

        &:after {
            background-color: transparent;
            top: 8px;
            border-style: solid;
            border-image: none;
            left: 2px;
            width: 8px;
            height: 3px;
            margin-top: -5px;
            border-color: $color-black;
            border-width: 0 0 1px 1px;

            transform: rotate(-45deg) scale(0);
            transition: none;
        }
    }

    input:checked + label {
        &:before {
            animation: borderscale 0.35s ease-in;
        }

        &:after {
            content: '';

            transform: rotate(-45deg) scale(1);
            transition: transform 0.25s ease-out;
        }
    }

    // this is only a fallback
    // there was no design for disabled checkboxes
    input:disabled + label {
        &:before {
            box-shadow: 0 0 0 2px $color-warm-grey;
        }
        &:after {
            border-color: $color-warm-grey;
        }
    }

    // This is disabled state used in terms
    &.checkbox--hide-for-disabled {
        input:disabled + label {
            padding-left: 0;
            cursor: default;

            &:before, &:after {
                content: initial;
            }
        }
    }
}

.wrapper-checkbox__description {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    line-height: 18px;
}

.radio-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    min-height: 80px;
    margin: 15px;
}

.radio-group {
    margin-bottom: 20px;

    &.error {
        .error-text {
            display: block;
            opacity: 1;
            max-height: 220px;
        }

        .wrapper-radiobutton input {
            border: 1px solid $color-error;
        }
    }

    .wrapper-radiobutton {

        &:first-of-type {
            margin-top: 20px;
        }

        input+label.radiobutton__label {
            display: flex;
            justify-content: space-between;
            padding: 0 0 0 30px;
        }
    }

    &--row {
        .wrapper-radiobutton {
            &:first-of-type {
                margin-top: inherit;
            }
        }
    }
}


.wrapper-radiobutton {
    position: relative;

    input {

        position: absolute;
        z-index: 1; // vor label

        width: 20px;
        height: 20px;
        padding: 0;
        border-radius: 50%;
        border: 2px solid black;

        &:focus {
            + label:before {
                border-color: $color-warm-grey;
            }
        }

        &:hover {
            + label:before {
                .no-touch & {
                    border-color: $color-warm-grey;
                }
            }
        }

        &:active + label:before {
            transition-duration: 0s;
        }

        &:checked {
            + label {
                transition: background-color 0.3s ease-out;

                &:before {
                    transform: scale(1);
                }
            }
        }

        &:disabled {
            border: 2px solid #9e9d9d;

            + label h2 {
                opacity: 0.1;
            }
        }
    }

    input {
        left: 0;
        top: 0;

        + label {
            &:before {
                left: 5px;
                top: 5px;
            }
        }
    }

    input + label {
        display: inline-block;
        cursor: pointer;
        color: $color-black;
        width: 100%;

        &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: 2;

            width: 10px;
            height: 10px;
            border-radius: 100%;
            background-color: $color-black;

            transform-origin: center;
            transform: scale(0);
            transition: transform 0.3s ease-out;
        }

        > * {
            pointer-events: none;
        }

        h4 + .text-small {
            margin-top: 2px;
            color: $color-black;
        }

        h4 + .text-size--normal {
            font-family: $font-flexo-thin;
        }

        * + .additional-info {
            margin-top: 30px;
            color: $color-black;

            font-size: $p_fontsize;
            line-height: $p_lineheight;
        }
    }

}

@keyframes borderscale {
    50% {
        box-shadow: 0 0 0 1px $color-black;
    }
}
