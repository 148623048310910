
.grid.cards {
    margin-bottom: #{$gap-smallest-part * -2};

    .card-link {
        margin-bottom: #{$gap-smallest-part * 2};
    }

    .container-btn-load-more > .btn-base {
        margin-bottom: 10px;
    }
}

.card {
    display: flex;
    flex-direction: column;
    height: 100%;

    figure {
        overflow: hidden;
        @include relative-height(3/2);
    }

    &__buttons {
        margin-top: auto;
        padding-top: 20px;
    }

    .container-text {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 10;
        padding: 30px 0 0 0;
    }
}

.background-grey,
.background-black {
    .card.background-white {
        .container-text {
            padding: 20px 20px 40px 20px;
        }
    }
}

.card-link:not(.card-list) {
    .container-text {
        padding-bottom: 40px;
    }
}

.no-touch.cms-edit-off .card-link {
    cursor: pointer;

    & > * {
        pointer-events: none;
    }
}

.card-link {
    & > * {
        position: relative;
        z-index: 1;
    }

    .card-background {
        display: none;
    }
}

.card-list {
    margin-bottom: 0;

    &:nth-last-child(n+2) {
        margin-bottom: $gap-smallest-part;
    }

    //  -10px Versatz des grids nach links ausgleichen / abdecken
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: $gap-smallest-part;
        height: 100%;
        background-color: $color-white;
    }

    & > * {
        margin-bottom: 0;
    }

    & > div {
        padding: 25px 0;
    }

    // grid-Abstände hier außer Kraft setzen, um einheitliche vertikale Abstände zu erzielen
    & > div:last-child {
        margin-bottom: 0;
    }
}

.cms-edit-on .card-link {
    a.touch-only {
        display: inline-block !important;
    }
}

.cms-edit-on {
    .cms-hover-tooltip {
        position: relative;
        z-index: 1;
    }

    // Kreis, welcher Anzeigt ob ein Artikel bereits veröffentlicht ist oder nicht
    .cms-pagetree-node-state {
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        border: 2px solid $color-white;
        background: $color-white;
    }

    .cms-pagetree-node-state-published {
        border-color: #693;
        background-color: #693;
    }

    .cms-pagetree-node-state-empty {
        border-color: $color-medium-grey;
        background-color: $color-white;
    }

    /* Tooltip */
    .cms-hover-tooltip-left:before {
        -webkit-transform: translateX(-12px) translateY(50%) rotate(90deg) !important;
        transform: translateX(-12px) translateY(50%) rotate(90deg) !important;
        margin-right: 13px;
        margin-bottom: -19px;
        border-top-color: transparent;
        border-left-color: rgba(0, 0, 0, .8);
    }

    .cms-hover-tooltip-left:after {
        margin-left: 0;
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
    }

    .cms-hover-tooltip-left:after, .cms-hover-tooltip-left:before {
        right: -25px;
        bottom: 45px;
        left: auto;
    }

    .cms-hover-tooltip:after {
        content: attr(data-cms-tooltip);
        z-index: 1;
        color: #fff !important;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.2;
        text-align: center;
        width: 160px;
        padding: 6px 8px;
        background-color: rgba(0, 0, 0, .8);
        white-space: normal;
        margin-left: -80px;
    }
}

.no-touch.cms-edit-off .card-link {
    .card-background {
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        text-decoration: none;
        pointer-events: auto;
        border: none;
        z-index: 0;
        background-color: $color-light-grey;

        opacity: 0;
        transform: translate3d(0, 0, 0);
        transition: opacity 0.25s ease-in-out;
    }

    &:hover {
        .card-background {
            opacity: 1;
        }
    }
}

.cms-edit-on .m12-bike-chooser .card,
.cms-edit-on .m12-bike-chooser .card-link {
    .cms-pagetree-node-state {
        top: 7px;
        left: 7px;
        z-index: 20;
    }
}


