
.wrapper-input {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    // text-spaces ausgleichen
    .btn-icon {
        margin-bottom: -3px;
    }
}