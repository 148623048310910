.retailer-map {
    display: none;
    opacity: 0;
    background: $color-ultra-light-grey;
    margin-bottom: 0;
    @include relative-height(3/4);

    @media #{$mq-extra-small-only} {
        min-height: 520px;
        @include relative-height(3/5);
    }

    @media #{$mq-medium-only} {
        @include relative-height(1);
    }

    @media #{$mq-large-up} {
        @include relative-height(16/9);
    }

    &.active {
        display: block;
        opacity: 100;

        img {
            width: 50px;
            height: auto;
        }
    }

    &.no-results::before {
        padding-top: 0;
    }

    .google-maps-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        > * {
            height: 100%;
        }
    }
}

.retailer-map-legend {
    display: none;

    &.active {
        display: grid;
        grid-template-rows: repeat(3, auto);
        justify-content: end;
        grid-gap: 20px;
        margin: 20px 0;

        @media #{$mq-medium-up} {
            grid-gap: 70px;
            grid-template-rows: auto;
            grid-template-columns: repeat(3, auto);
        }
    }

    figure {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        & > img {
            position: relative;
            height: 50px;
            width: auto;
            margin-left: 10px;

            @media #{$mq-medium-up} {
                height: auto;
                width: 50px;
            }
        }

        figcaption {
            height: fit-content;
        }
    }
}

// fix google-maps style
.gm-style-cc {
    a {
        line-height: 150%;
    }
}

// INFO_BOXEN INNERHALB DER GOOGLE_MAP
// erbt nicht von html, da in einem extra scope
.maps-overlay {
    position: absolute;
    display: block;
    margin: 0;
    width: 400px;
    background: $color-white;
    font-family: $font-flexo-regular;

    button {
        font-size: 16px;
    }

    h4 {
        max-width: 260px;
    }

    .btn-icon.icon-close {
        $size: 24px;

        position: absolute;
        top: 20px;
        right: 20px;
        width: $size;
        height: $size;
        padding: 0;
        background: none !important;

        &:before {
            width: 100%;
            height: 100%;
            line-height: $size;
            font-size: $size;
        }

        &:after {
            background: none !important;
        }
    }

    .button-underline {
        margin-top: 0;
        background: none;
    }

    &:after {
        font-family: $font-icons;
        font-size: 34px;
        color: $color-white;
        text-align: center;
        content: 'H';
        position: absolute;
        bottom: -30px;
        left: 0;
        right: 0;
    }

    .testbikes-detail.active + div {
        margin-bottom: 0 !important;
    }
}

.fixed-overlay-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    background-color: rgba($color-black, .7);

    .maps-overlay {
        position: relative;
        width: 90%;
        max-width: 400px;
        margin: auto;

        &:after {
            display: none;
        }
    }
}

// google maps markerClusterer
.google-marker-clusterer {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 448.545px;
    left: 461px;

    background-image: url(/static/assets/img/retailer-search/maps-marker-clusterer.png);
    background-position: 0px 0px;
    background-size: 100% 100%;

    color: white;
    line-height: 50px;
    text-align: center;
    font-size: 16px;
    font-family: $font-flexo-regular;
    font-weight: normal;

    cursor: pointer;
}
