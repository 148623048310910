.highlight-collage {

    &__grid {
        @media #{$mq-medium-up} {
            display: flex;
        }
    }

    &__col {
        display: flex;
        flex-direction: column;
        flex: 1 0 50%;

        @media #{$mq-medium-up} {
            justify-content: space-between;
        }

        &:first-child {
            flex: 0 0 58.3333%;

            @media #{$mq-medium-up} {
                margin-right: 10px;
            }
        }

        &:last-child {
            flex-direction: column-reverse;
            flex: 1 1 41.6666%;
            width: 100%;
            margin: auto;
            margin-right: 0;

            @media #{$mq-medium-up} {
                flex-direction: column;
                width: auto;
                margin: 0;
                margin-left: 10px;
            }

            @media #{$mq-small-only} {
                .highlight-collage__figure-wrapper {
                    width: 75%;
                    align-self: end;
                }

                > .highlight-collage__quote {
                    margin-left: var(--grid-gutter-horizontal);
                    margin-right: var(--grid-gutter-horizontal);
                }
            }

            &.highlight-collage__col--1by1 {
                @media #{$mq-medium-up} {
                    width: 50%;
                    max-width: 300px;
                }

                @media #{$mq-small-only} {
                    .highlight-collage__figure-wrapper {
                        width: 50%;
                    }
                }
            }
        }
    }

    &__figure {
        &--1by1 {
            padding-top: 100%;
        }
        &--4by3 {
            padding-top: 75%;
        }
        &--16by9 {
            padding-top: 56.25%;
        }
    }

    &__quote {
        margin: 25px 0 30px;

        @media #{$mq-medium-up} {
            margin-bottom: 0;
        }

        h2 {
            font-family: $font-flexo-demi;
            font-size: 22px;
            line-height: 30px;
        }
        p {
            margin-top: 20px;
            font-size: 14px;
        }
    }
}
