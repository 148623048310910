
$button-switch--height: 25px;

.button-switch {
    $color-disabled: $color-medium-grey;

    position: relative;
    display: inline-block;
    background-color: transparent;

    &:hover {
        $scale: 0.9;

        .no-touch & {
            svg:last-child {
                transform: scale($scale);
            }

            .button-switch__input:checked + .button-switch__label {
                svg {
                    &:first-child {
                        transform: scale($scale);
                    }

                    &:last-child {
                        transform: scale(1);
                    }
                }
            }
        }
    }
}

.button-switch__input {
    position: absolute;
    width: 100%;
    height: $button-switch--height !important;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    cursor: pointer;
    border: none;
    opacity: 0;

    &:checked + .button-switch__label {
        svg {
            &:first-child {
                opacity: 0.4;
            }

            &:last-child {
                opacity: 1;
            }
        }
    }
}

.button-switch__label {
    position: relative;
    display: flex;
    pointer-events: none;

    svg {
        fill: $color-black;
    }

    svg {
        height: $button-switch--height;
        width: auto;
        opacity: 0.5;
        transition: opacity 0.25s ease-out, transform 0.2s ease-out;

        &:first-child {
            right: 0;
            opacity: 1;
            margin-right: -5px;
        }

        &:last-child {
            left: 0;
            margin-left: -5px;
        }
    }
}

.button-switch__line {
    width: 2px;
    height: $button-switch--height;
    margin: 0 20px;
    background-color: $color-black;
}
