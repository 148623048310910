
// override regular custom-selects
// entire hard-copy is to "expensive"
.custom-select,
.input-search {
    &.medium-select {
        $height-small: 38px;

        label {
            font-family: $font-flexo-demi;
        }

        input,
        select,
        .option {
            height: $height-small;
            line-height: #{$height-small - 4px};
            font-family: $font-flexo-demi;
            font-size: 16px; // 16px;
        }

        .container-options {
            padding-top: #{$height-small - 2px};
        }


        &.custom-select--upside-down {
            .container-options {
                padding-top: 0;
                padding-bottom: #{$height-small - 2px};
            }
        }
    }
}
