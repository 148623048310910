
// override regular custom-selects
// entire hard-copy is to "expensive"
.custom-select,
.input-search {
    &.large-select {
        $height-small: 60px;
        $height-medium-large: 70px;

        input,
        select,
        .option {
            height: $height-small;
            line-height: #{$height-small - 4px};
            font-size: 25px; // 16px;

            @media #{$mq-medium-up} {
                height: $height-medium-large;
                line-height: #{$height-medium-large - 4px};
                font-size: 28px;
                font-family: $font-flexo-thin;
            }
        }

        .container-options {
            padding-top: #{$height-small - 2px};

            @media #{$mq-medium-up} {
                padding-top: #{$height-medium-large - 2px};
            }
        }


        &.custom-select--upside-down {
            .container-options {
                padding-top: 0;
                padding-bottom: #{$height-small - 2px};

                @media #{$mq-medium-up} {
                    padding-bottom: #{$height-medium-large - 2px};
                }
            }
        }
    }
}
