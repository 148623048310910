
.m30-layer {
    $padding-bottom: 40px;
    $height-footer-small: 70px;
    $height-footer-medium-up: 110px;

    &--has-footer {
        @media #{$mq-small-only} {
            padding-bottom: $height-footer-small + $padding-bottom;
        }

        @media #{$mq-medium-up} {
            padding-bottom: $height-footer-medium-up + $padding-bottom;
        }
    }

    &__footer {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1;
    }

    // dirty: scoped overwrite, no BEM
    .container-center {
        position: relative;
        height: 100%;
    }

    &__footer-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media #{$mq-small-only} {
            height: $height-footer-small;
        }

        @media #{$mq-medium-up} {
            height: $height-footer-medium-up;
        }
    }

    &__footer-right {
        margin-left: 20px;

        &:empty {
            margin-left: 0;
        }
    }
}
