.colors__container {
    display: grid;
    grid-gap: 10px;
    grid-auto-flow: column;
    justify-content: flex-start;
}

.single-color,
.colors__color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #0f74a8;
    cursor: default;
}


.colors__truncate {
    $height: 20px;
    $border-thickness: 1px;

    display: block;
    border: 1px solid $color-black;
    padding: 0 10px;
    height: $height;
    line-height: #{$height - 2 * $border-thickness};
    border-radius: #{$height / 2};
    font-size: 12px;
    user-select: none;
}

