
.m45-user-gallery {
    .wrapper-user-gallery,
    .container-script-images {
        position: relative;
        display: flex;
        flex-wrap: wrap;
    }

    .wrapper-user-gallery {
        left: -10px;
    }

    .row-item {
        margin-left: 10px;
        position: relative;
    }

    figure {
        position: relative;

        img {
            cursor: pointer;
        }
    }

    img + figcaption {
        opacity: 1;
    }

    figcaption {
        position: absolute;
        font-family: $font-flexo-regular;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;

        //change font color via cms
        &.font_dark,
        &.font_dark a {
            color: $color-black;
            border-bottom: none;
            font-size: 10px;
        }

        &.font_light,
        &.font_light a {
            color: $color-white;
            font-size: 10px;
        }

        a {
            text-decoration: underline;
            border: none;
        }

        //change text position via cms
        &.top_left {
            top: 4px;
            left: 10px;
            right: auto;
            bottom: auto;
        }

        &.top_right {
            top: 4px;
            left: auto;
            right: 10px;
            bottom: auto;
        }

        &.bottom_left {
            top: auto;
            left: 10px;
            right: auto;
            bottom: 10px;
        }

        &.bottom_right {
            top: auto;
            left: auto;
            right: 10px;
            bottom: 10px;
        }
    }

    .line-break {
        flex: 0 0 200%;
        height: 10px;
    }

    .wrapper-btn-show-more {
        margin: 10px auto;

        @media #{$mq-small-only} {
            width: 100%;
        }
    }

    @media #{$mq-small-only} {
        figure:nth-of-type(n+9) {
            display: none;
        }
    }
}