
.m27-precision-layer.teaser-like {
    figure {
        width: 100%;
        @include relative-height(3/2);
    }

    .button-circle + .button-circle {
        @media #{$mq-medium-up} {
            margin-left: 35px;
        }
    }

    .grid {
        @media #{$mq-large-up} {
            align-items: center;
        }
    }

    .wrapper-button {
        margin-top: 30px;

        @media #{$mq-small-only} {
            flex-wrap: wrap;
        }

        @media #{$mq-large-up} {
            margin-top: 35px;
        }
    }

    .rb__container-text {
        @media #{$mq-medium-only} {
            align-self: flex-end;
            // text should be a little above bike
            // some kind of guessing...
            margin-bottom: 4.5%;
        }
    }
}