
.module:not(.background-black) + .m65-text-teaser:not(.background-black) {
    @media #{$mq-medium-up} {
        min-height: calc(100vh - #{$height-top-bar} - #{$padding-modules-mq-medium});
    }
}

.m65-text-teaser {
    @media #{$mq-medium-up} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: calc(100vh - #{$height-top-bar});
    }

    &__dateline {
        @include dateline--large();

        @media #{$mq-medium-up} {
            text-align: right;
        }
    }

    &__headline {
        @include headline-h2--large();

        @media #{$mq-small-only} {
            font-size: 32px;
            line-height: 39px;
        }

        @media #{$mq-small-only} {
            margin-top: 10px;
        }

        @media #{$mq-medium-up} {
            margin-top: 15px;
            text-align: right;
        }
    }

    &__poster {
        & + & {
            margin-top: 30px;
        }
    }

    &__wrapper-button {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 20px;

        @media #{$mq-small-only} {
            margin-top: 20px;
        }

        @media #{$mq-medium-up} {
            margin-top: 40px;
        }
    }

    &__inner {
        height: 100%;
    }

    &__container-headline {
        @media #{$mq-medium-up} {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding-bottom: 120px;
        }
    }

    &__container-copy {
        @media #{$mq-small-only} {
            margin-top: 10px;
        }

        @media #{$mq-medium-up} {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding-top: 120px;
        }
    }

    &--right {
        .m65-text-teaser__container-headline {
            padding-bottom: 0;
        }
        .m65-text-teaser__container-copy {
            padding-top: 0;
        }
        .m65-text-teaser__headline,
        .m65-text-teaser__dateline {
            display: block;
            text-align: right;
        }
    }
}

// Image
.m65-text-teaser {
    &.background-image,
    &.background-image--inverted {
        @media #{$mq-small-only} {
            height: 560px;
        }

        @media #{$mq-small-only} {
            .m65-text-teaser__content-wrapper-parent {
                height: calc(100% - #{$padding-modules-mq-small} * 2);
            }

            .m65-text-teaser__content-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
            }
        }
    }

    &__image {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }

    &--image-overlay-mobile {
        @media #{$mq-small-only} {
            .m65-text-teaser__image:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.4);
            }

            color: white;

            label,
            .button-circle {
                color: white;

                .circle:after {
                    border-color: white;
                }
            }

            svg path,
            svg line {
                stroke: white;
            }
        }
    }
}

.m65-text-teaser {
    &__video {
        position: absolute;
        left: 50%;
        top: 50%;
        min-width: 100%;
        min-height: 100%;
        transform: translate(-50%, -50%);

        @supports (object-fit: cover) {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            min-width: auto;
            min-height: auto;
            object-fit: cover;
            transform: none;
        }
    }
}
