
figure {
    margin: 0;
    padding: 0;
}

ul {
    margin: 0;
}

iframe {
    border: none;
}

hr {
    display: block;
    height: 2px;
    margin: 0;
    border: none;
    color: $color-white;
    background-color: $color-white;
}

.cms {
    letter-spacing: 0;
}
