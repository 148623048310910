
.m52-myrm-bikes {
    position: relative;

    @media #{$mq-large-up} {
        min-height: calc(100vh - 56px - 50px);
    }

    $padding--small: #{$padding-modules-mq-small - 40px};
    $padding-medium: #{$padding-modules-mq-medium - 40px};
    $padding-large: #{$padding-modules-mq-large - 44px};

    &.module {
        &:before,
        &:after {
            display: block;
            width: 100%;
            height: $padding--small;

            @media #{$mq-medium-up} {
                height: $padding-medium;
            }

            @media #{$mq-large-up} {
                height: $padding-large;
            }
        }
    }

    // module emo as last child overwrites module space bottom
    & > .sub-module:last-child {
        .myrm__container-bike {
            .m39-emo:last-child {
                margin-bottom: -$padding--small;

                @media #{$mq-medium-up} {
                    margin-bottom: -$padding-medium;
                }

                @media #{$mq-large-up} {
                    margin-bottom: -$padding-large;
                }
            }
        }
    }

    .mask-toggle {
        margin-bottom: 0;

        transition: opacity 0.2s ease-in-out;
        &.dragging {
            opacity: 0.8;
        }
    }

    .myrm-info-box {
        background-color: $color-light-grey;
        padding: 20px;

        p {
            display: flex;
            align-items: center;
        }

        svg {
            margin-right: 20px;

            rect {
                fill: $color-black;
            }

            path {
                stroke: $color-black;
            }

            & + p {
                margin-top: 10px;
            }
        }
    }

    .m52-myrm-bikes__bike {
        display: none;

        &.active {
            display: block;
        }

        figure {
            margin-bottom: 40px;

            @media #{$mq-large-up} {
                margin-bottom: 0;
            }
        }

        img {
            position: relative;
            user-select: none;
            pointer-events: none;
        }

        &.m52-myrm-bikes__bike--image {
            @media #{$mq-large-up} {
                margin-bottom: 120px;
            }
        }

        .m52-myrm-bikes__bike--image-wrapper {
            figcaption {
                margin-top: 10px;
                font-size: 14px;
                text-align: right;
            }
        }
    }

    .m52-myrm-bikes__bike--teaser {
        &.slider-rebrush {
            .owl-nav {
                max-height: 30%; // Todo ansch 19-10-23 hotfix. @juschr bitte prüfen, wie das sauber gelöst werden kann
            }
        }
    }

    &__header,
    &__bike_controls {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        row-gap: 25px;
        column-gap: 25px;
    }

    &__bike_controls-footer {
        display: flex;
        flex-direction: column-reverse;
        row-gap: 20px;
        align-items: flex-end;
        margin-top: 20px;

        @media #{$mq-medium-up} {
            flex-direction: row;
            align-items: center;
            margin-top: 40px;
            justify-content: space-between;
        }
    }

    &__bike_controls {
        justify-content: flex-end;

        @media #{$mq-medium-up} {
            justify-content: space-between;
        }

        &--bikes-1 {
            @media #{$mq-medium-up} {
                .m52-myrm-bikes__bikes-total {
                    flex: 1 1 0;
                }
            }
        }
    }

    &__bike_controls,
    &__header {
        margin-bottom: 50px;
    }

    &__header {
        align-items: end;

        @media #{$mq-small-only} {
            .button-circle .circle {
                margin: 0;
            }
            .button-circle .button-label {
                display: none;
            }
        }

        input.large-input {
            background: none;
            padding: 0;
            border-color: transparent;
            transition: border-color 0.25s ease-out;
            pointer-events: none;
            white-space: nowrap;
            text-overflow: ellipsis;

            font-size: 25px;
            line-height: 30px;

            @media #{$mq-small-only} {
                flex-wrap: nowrap;
                height: 45px;
            }

            @media #{$mq-medium-up} {
                font-size: 45px;
                line-height: 57px;
            }
        }

        &--edit-mode {
            @media #{$mq-small-only} {
                flex-wrap: wrap;

                > * {
                    flex: 1 1 100%;
                }
            }

            .m52-myrm-bikes__header-btn-group {
                display: flex;

                @media #{$mq-small-only} {
                    justify-content: flex-end;
                }
            }

            .button-enter-edit-mode {
                display: none;
            }

            input.large-input {
                pointer-events: all;
                border-color: $color-black;
            }
        }
    }

    &__header-btn-group {
        display: none;
        column-gap: 20px;
    }

    &__bike-header {
        flex: 1 1 50%;

        @media #{$mq-large-up} {
            flex: 0 1 66%;
        }
    }

    &__bike-edit {
        margin-bottom: 3px;

        @media #{$mq-medium-up} {
            margin-bottom: 13px;
        }
    }

    &__bikes-total {
        flex: 0 0 100%;
        order: 0;
        font-size: 20px;

        @media #{$mq-medium-up} {
            font-size: 22px;
        }
    }

    &__bikes-select {
        flex: 0 0 100%;

        @media #{$mq-medium-up} {
            flex: 0 1 50%;
        }
    }

    .accordion {
        li {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0
            }
        }

        .bar {
            font-family: $font-flexo-demi;
            padding: 20px 20px;
        }
    }

    .m52-myrm__teaser {
        height: 100%;
        padding: 25px 20px;
        border-bottom: 20px solid $color-white;

        svg {
            max-width: 100%;
        }

        .m52-myrm__teaser-image {
            position: relative;

            @include relative-height(3/2);

            img {
                position: absolute;
                display: block;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
                padding: 35px;
                max-width: 280px;
            }
        }

        > p + a,
        > p + button,
        > p + form button {
            margin-top: 56px;
        }

        p a {
            font-size: 16px;
        }

        .button-underline {
            margin-bottom: 0;

            &.last {
                margin-top: 50px;

                + .rm-status {
                    margin-top: 0;
                }
            }
        }

        .m52-myrm__teaser-activating-icon,
        .m52-myrm__teaser-reported-icon {
            display: flex;
            align-items: center;
            margin: 30px 0 27px 0;

            .icon-circle-background-medium {
                margin-right: 13px;
            }
        }

        .rm-status {
            margin-top: 40px;
        }
    }

    .m52-myrm-bikes--no-bike {
        margin-top: -30px;

        button {
            float: right;
            margin-top: 30px;
        }

        .container-center {
            @media #{$mq-large-up} {
                position: absolute;
                left: 50%;
                bottom: 25%;
                transform: translateX(-50%);
            }
        }

        @media #{$mq-medium-up} {
            margin-top: -60px;
        }

        @media #{$mq-large-up} {
            margin-top: 0;
        }

        .no-bike-image-wrapper {
            width: 100%;
            margin-bottom: 50px;

            .m52-myrm__teaser-image,
            svg,
            .m52-myrm__teaser-activating-icon {
                & + h3 {
                    margin-top: 0;
                }
            }

            img {
                width: 100%;
            }

            @media #{$mq-large-up} {
                position: absolute;
                width: 50%;
                height: 100%;
                top: 0;
                overflow: hidden;

                img {
                    position: absolute;
                    width: auto;
                    height: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    .slider-rebrush {
        .text-spaces {
            svg + h3,
            .m52-myrm__teaser-activating-icon + h3 {
                margin-top: 0;
            }
        }

        .owl-stage {
            padding-bottom: 0;
        }

        @media #{$mq-large-up} {
            .owl-prev {
                left: -35px;
            }

            .owl-next {
                right: -35px;
            }
        }
    }

    .color-code {
        $width: 10px;
        display: flex;
        height: 36px;
        align-items: center;

        &__item {
            height: 22px;
            width: $width;

            &:not(:last-of-type) {
                margin-right: $width;
            }

            &--r {
                background-color: #C10615;
            }

            &--g {
                background-color: #2C8334;
            }

            &--b {
                background-color: #2E5DB5;
            }
        }
    }
}
